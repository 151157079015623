export default {
    crypto: {
        title: { "0": "暗号化通貨の購入", "1": "暗号化通貨の購入", "2": "C2C" },
        list: {
            "0": "C 2 C取引",
            "1": "購入する",
            "2": "売り出す",
            "3": "広告主（完成率）",
            "4": "取り引き",
            "5": "価格",
            "6": "使用可能",
            "7": "制限＃セイゲン＃",
            "8": "支払い方法",
            "9": "貿易",
            "10": "広告主は広告に取引制限を設定する可能性があり、ログインすると制限の理由が表示されます。",
        },
        buy: {
            "0": "暗号化通貨の購入",
            "1": "暗号化通貨の販売",
            "2": "履歴",
            "3": "サポート",
            "4": "支払い",
            "5": "受入",
            "6": "支払方法の選択",
            "7": "東部標準時到着時刻",
            "8": "コスト",
            "9": "オーダー詳細",
            "10": "支払い方法",
            "11": "詳細",
            "12": "価格",
            "13": "合計（費用込み）",
            "14": "免責事項",
            "15": "HKDFinancを出て、第三者としてクレジットカード/デビットカード決済サービスを提供するトランプ金融機関であるMercuryoにリダイレクトされます。サービス利用中に発生するあらゆるリスクは、Mercuryoが負担します。続行する前に利用規約を読んで同意してください。",
            "16": "利用規約を読んで同意しました",
            "17": "あなたは",
            "18": "送信",
            "19": "金額を入力してください",
            "20": "アカウント残高が不足しています",
            "21": "使用可能",
            "22": "現品口座は使用可能です",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        },
    },
    common: {
        view: "表示",
        confirm: "確認",
        cancel: "キャンセル",
        deposit: "入金",
        withdraw: "出金",
        noData: "データなし",
        submit: "提出",
        modify: "変更",
        hand: "手",
        confirm_verify: "確認認証",
        next: "次へ",
        complete: "完了",
        delete: "削除",
		countryCode: "国コード",
    },
    addnew: {
        realFirst: "まず身分認証を行ってください",
        payFirst: "まず取引パスワードを設定してください",
        password1: "ログインパスワードの変更",
        password2: "引き出しパスワードの変更",
        paypassTit1: "引き出しパスワードを設定してください",
        oldPass: "古いパスワード",
        allView: "すべて既読",
        payPass: "トランザクションパスワード",
        buyUp: "買い増し",
        buyDown: "買いが落",
        confirmOrder: "注文確認",
        name: "名前",
        fee: "手数料",
        orderCycle: "時間を選択",
        loan: "ローン",
        loanTit: "ローン支援",
        loanHostory: "ローン履歴",
        labelProductTitle: "製品名",
        immediateRepayment: "即時返済",
        loadAmount: "予想ローン額",
        RepaymentCycle: "ローン返済サイクル",
        day: "日",
        dailyRate: "日利率",
        repaymentMethod: "返済方法",
        lendingCompany: "貸付会社",
        loanTip: "信用ローン（画像がはっきりしていることを確認してください）",
        loanTip1: "不動産証明書をアップロード",
        loanTip2: "収入証明（雇用関係）を提出",
        loanTip3: "銀行取引明細の詳細情報をアップロード",
        loanTip4: "身分証明書の正面写真をアップロード",
        interest: "利息",
        repaid: "返済済み",
        unpaid: "未返済",
        tab1: "通貨",
        tab2: "契約",
        tab3: "オプション",
        available: "利用可能枠",
        pending: "ロック",
        equivalent: "同等",
        fundTransfer: "資金の移動",
        flashExchange: "フラッシュ交換",
        select_breed: "通貨を選択",
        from: "から",
        to: "へ",
        transferQuantity: "転送数量",
        repaymentMethod1: "期限一括返済"
    },
    params: {
        product_foreign: "外国為替",
        product_shop: "商品",
        product_number: "指数",
        product_stock: "株式",
        product_coin: "仮想通貨",
        billTypes: [
            {v: 101, name: '入金'},
            {v: 102, name: '入金'},
            {v: 103, name: '凍結'},
            {v: 104, name: '凍結解除'},
            {v: 105, name: '入金'},
            {v: 106, name: '入金'},
            {v: 201, name: '出金凍結'},
            {v: 202, name: '出金'},
            {v: 203, name: '出金成功'},
            {v: 204, name: '出金失敗'},
            {v: 205, name: '出金手数料'},
            {v: 206, name: '送金'},
            {v: 207, name: '受領'},
            {v: 208, name: 'コイン転送送金'},
            {v: 209, name: 'コイン転送受領'},
            {v: 301, name: '契約手数料'},
            {v: 302, name: '契約収益'},
            {v: 303, name: '契約損失'},
            {v: 304, name: '契約保証金'},
            {v: 305, name: '契約保証金返却'},
            {v: 311, name: 'オプション購入'},
            {v: 312, name: 'オプション収益'},
            {v: 313, name: 'オプション返却'},
            {v: 314, name: 'オプション手数料'},
            {v: 315, name: 'コイン購入凍結'},
            {v: 316, name: 'コイン購入差し引き'},
            {v: 317, name: 'コイン購入返却'},
            {v: 318, name: 'コイン購入受領'},
            {v: 319, name: 'コイン購入受領'},
            {v: 320, name: 'コイン売却凍結'},
            {v: 321, name: 'コイン売却差し引き'},
            {v: 322, name: 'コイン売却返却'},
            {v: 323, name: 'コイン売却受領'},
            {v: 324, name: 'コイン売却受領'},
            {v: 325, name: 'コイン手数料'},
            {v: 401, name: 'マイニング参加'},
            {v: 402, name: 'マイニング返却'},
            {v: 403, name: 'マイニング収益'},
            {v: 404, name: 'マイニング退会'},
            {v: 405, name: 'マイニング退会手数料'},
            {v: 411, name: '借入収益'},
            {v: 412, name: '借入手数料'},
            {v: 413, name: '返済成功'},
        ],
    },

    footer: {
        nav1: "ホーム",
        nav2: "取引",
        nav3: "ポジション",
        nav4: "アカウント",
        kline_set1: "チャート設定",
        kline_set2: "取引設定",
    },
    login: {
        login: "ログイン",
        register: "登録",
        type1: "携帯番号",
        type2: "メールアドレス",
        login_btn: "セキュアログイン",
        register_btn: "今すぐ登録",
        psd_login: "パスワードでログイン",
        verify_login: "確認コードでログイン",
        forget: "パスワードをお忘れですか？",
        no_account: "アカウントがありませんか？",
        have_account: "既存のアカウント",
    },
    home: {
        home: {
            nav1: "値上がり率ランキング",
            nav2: "クイックスタート",
            nav3: "私たちについて",
            nav4: "招待報酬",
            most_follow: "最もフォローされている",
            news_type1: "ストラテジー",
            news_type2: "ニュース",
            news_type3: "経済カレンダー",
            news_tab1: "全て",
            news_tab2: "外国為替",
            news_tab3: "株式",
            news_tab4: "商品",
            news_tab5: "指数",
            news_tab6: "データ",
            news_tab7: "イベント",
            news_tab8: "休日",
            label_previous: "前値",
            label_predictive: "予測値",
            label_announced: "発表値",
        },
        kilne: {
            label_bp: "前日終値",
            label_sp: "今日始値",
            label_height: "最高値",
            label_low: "最低値",
            tip: "こんにちは、私はHKDFINANCGPTのアシスタントです",
            row1_tit: "戦略",
            row1_rinei: "日内",
            row1_short: "短期",
            row1_middle: "中期",
            row1_time: "発表日時",
            row1_tit2: "トレード戦略",
            data_sources: "データソース",
            row2_tit1: "データ",
            row2_tit2: "トレードセンチメント",
            row2_label_sell: "セル",
            row2_label_buy: "バイ",
            tip2: "参考情報のみ、当社の見解ではありません",
            tip3: "15分ごとに更新",
            row2_tit3: "価格変動",
            minute: "分",
            today: "今日",
            right_tit7: "価格変動範囲",
            now_price: "現在の価格",
            right_low_price: "最低価格",
            right_height_price: "最高価格",
            details: "詳細",
            right_label1: "単一トランザクション数",
            right_label2: "最大レバレッジ",
            right_label3: "契約サイズ",
            right_label4: "最大総保有数",
            right_label5: "スプレッド",
            right_label6: "オーバーナイト料金",
            hand: "ロット",
            right_sell: "セル",
            right_buy: "バイ",
            right_collection_time: "収集時間",
            right_tip3: "トータル取引規模からの収集",
            right_label9: "トレードセッション",
            right_label10: "現在のセッション",
            right_label11: "次のセッション",
        },
        placeorder: {
            direction_buyPrice: "買値",
            direction_sellPrice: "売値",
            direction_sell: "セル",
            direction_buy: "バイ",
            market_price: "市場価格",
            check_price: "指値注文",
            sell_tip: "条件を満たすと売却",
            buy_tip: "条件を満たすと購入",
            trade_number: "トレード数量",
            hand: "ロット",
            select_lever: "レバレッジ選択",
            right_tip: "必要な証拠金",
            balance: "利用可能残高",
            label_stop_win_price: "利益確定",
            label_stop_lose_price: "損失制限",
            expected_profit_loss: "予想利益/損失",
            trailing_stop: "トレーリングストップ",
        },
        ranking: {
            tit: "当日値上がり率・値下がり率ランキング",
            p: "ホットトピックをキャッチし、チャンスをつかんでください",
            renew: "更新日",
            growth_chart: "値上がり率ランキング",
            drop_list: "値下がり率ランキング",
            show_closed_market: "休場市場を表示",
            filter1: "全て",
            filter2: "外国為替",
            filter3: "商品",
            filter4: "株式",
            filter5: "指数",
            filter6: "仮想通貨",
            td1: "ランキング",
            td2: "トレンド",
            td3: "当日値上がり率/売値",
        },
        remind: {
            now_sell_price: "現在の売値",
            direction_buyPrice: "買値",
            direction_sellPrice: "売値",
            direction_sell: "売却",
            direction_buy: "購入",
            equal: "とき",
            p: "以下の価格に達したときに私に通知してください",
            btn1: "通知を設定",
            list_tit: "通知リスト",
        },
    },
    my: {
        report: {
            tit: "口座レポート",
            tab1: "レポート",
            tab2: "デイリーステートメント",
            tab3: "マンスリーステートメント",
            history_tit: "取引履歴レポート",
            account_tit: "口座レポート",
            filter: "フィルター",
            tip1: "取引履歴レポートをエクスポートするために適切な日付範囲を選択してください。",
            tip2: "カスタム時間",
            email_send: "メールで送信",
        },
        accountMsg: {
            tit: "口座情報",
            label1: "個人情報",
        },
        bill: {
            tit: "資金履歴",
            all: "全て",
            time: "時間",
        },
        feedback: {
            tit: "機能提案",
        },
        identity: {
            tit: "本人確認",
            type1: "身分証",
            type2: "運転免許証",
            type3: "パスポート",
            tip: "提供された情報とファイルがすべて完全かつ正確であることを確認します",
        },
        my: {
            is: "済",
            no: "未認証",
            verify: "済認証",
            id_verify: "本人確認",
            verify_tip: "本人確認を完了すると入金取引が可能になります",
            no_verify: "今すぐ認証",
            balance: "利用可能残高",
            profit_loss: "損益",
            marginLevel: "証拠金レベル",
            btn1: "実際のアカウントに切り替え",
            deposit_withdraw_record: "入出金記録",
            capital_flow_details: "資金の流れの詳細",
            welfare_center: "福祉センター",
            tip1: "未受取の報酬が",
            tip2: "つ待っています",
            feedback: "機能提案",
            report: "アカウントレポート",
            setting: "設定",
        },
        news: {
            tit: "お知らせ",
        },
        record: {
            tit: "入出金記録",
            cycle: "サイクル",
        },
        share: {
            tit: "友達を招待",
            invite_success: "友達を招待成功",
            tip1: "キャッシュリワード",
            tip2: "友達を招待して最大",
            tip3: "友達を招待して現金を受け取る",
        },
        welfare: {
            tit: "福祉センター",
            tab1: "クーポン",
            tab2: "アクティビティ",
            history_record: "履歴",
            period_validity: "有効期間",
        },
        wallet: {
            tit: "ウォレット",
            addNew: "新しいウォレットを追加",
        },
        withdraw: {
            tit: "出金",
            addNew: "新しいウォレットを追加",
        },
        },
        order: {
        demo_account: "デモアカウント",
        real_account: "実際のアカウント",
        tip: "開設後、入金取引が可能になります",
        tab1: "ポジション",
        tab2: "オーダー",
        tab3: "履歴",
        list_empty: "現在、オーダーはありません",
        start_trade: "取引を開始",
        hand_tip: "範囲を入力できます",
        kline: {
            direction_sell: "売る",
            direction_buy: "買う",
            open_price: "オープン価格",
            now_price: "現在価格",
            trade_account: "トレードアカウント",
            demo_account: "デモアカウント",
            order_id: "注文ID",
            open_time: "オープン時間",
            stop_profit: "利益確定",
            stop_loss: "損失確定",
            trailing_stop: "トレーリングストップ",
            float_profit_loss: "浮動損益",
            overnight_fee: "オーバーナイト料金",
            margin: "証拠金",
            maintainsMargin: "維持証拠金",
            open_value: "オープンバリュー",
            now_value: "現在のバリュー",
            notset: "設定されていません",
            close_position: "ポジションをクローズ",
            close_number: "クローズ数量",
            onclick_close: "一括クローズ",
            btn: "クローズを確認",
            balance: "利用可能残高",
            profit_loss: "損益",
            netValue: "純資産",
            marginLevel: "証拠金レベル",
        },
        modify: {
            tit: "ポジションの変更",
            expected_profit_loss: "予想損益",
        },

    },
    setting: {
        security: {
            modify_password_tit: "新しいパスワードを設定",
            verify_email_tit: "メールアドレスの確認",
            verify_password_tit: "パスワードの確認",
            verify_password_tip: "続行するためにHKDFINANCログインパスワードを入力してください",
            verify_phone_tit: "電話番号の確認",
            tit: "アカウントセキュリティ",
            tip: "アカウントのセキュリティを保護し、サービス通知を受けるために、少なくとも1つの認証方法を有効にすることをお勧めします。",
            label1: "パスワードの変更",
            label2: "アカウントのログアウト",
            label3: "2段階認証",
            label4: "デバイス管理",
            tip2: "新しいデバイスでログインする際に認証が必要です",
        },
        tradenews: {
            tit: "取引ガイド",
            tab1: "クイックスタート",
            tab2: "上級学習",
            tab3: "ヘルプセンター",
            tab4: "HKDFINANCについて",
            viewMore: "もっと見る",
            details: {
                tit: "外国為替の紹介",
                tip1: "推定学習時間",
                tip2: "分",
            },
            tab1Row: {
                tit: "HKDFINANCでの取引を迅速に学ぶためのクラスです",
                tit1: "HKDFINANCでの取引の仕組み",
                tit2: "実際のアカウントの開設方法",
                tit3: "ポジションのオープン方法",
                tit4: "利益確定と損失確定の設定方法",
                tit5: "ポジションのクローズ方法",
                p1: "あなたは、それを買うか売ることでこの資産をトレードすることができます。HKDFINANCでポジションを開くことは、この資産に対するトレードを意味します。",
                p2: "対象商品の価格変動に取引で参加します",
                p3: "例えば、金価格が上昇することを予想する場合、その商品を購入します。逆に、金価格が下降することを予測する場合、その商品を売却します。将来的に予測が的中すれば、利益を得ることができます。",
                p4: "それによって利益を得ることができます",
                p5: "さらに、HKDFINANCでは「レバレッジ取引」も提供しています。このレバレッジを活用すると、同じ資金を使用してより多くの取引ロットを取引することができます。",
                p6: "レバレッジは利益を増幅させる一方、損失も増幅させることがあります。",
                p7: "これは「証拠金取引」とも呼ばれています。ポジションを開設する際、HKDFINANCのアカウントから一定の残高が差し引かれます",
                p8: "初期証拠金として差し引かれます。",
                p9: "製品と取引方向を選択します",
                p10: "取引します",
                p11: "「買い/売り」をクリックして注文を確定します",
                p12: "「利確」または「損切り」を設定することで、お手伝いできます",
                p13: "利益を確保し、損失を制限するために、迅速に対策を講じることが大切です损",
                p14: "取引が正常に開始されたページで、以下のようにクリックできます",
                p15: "「利確/損切」",
                p16: "未約定注文の「変更」をクリックしても設定できます",
                p17: "設定またはキャンセル",
                p18: "'クローズ済'で利益と損失を確認できます",
            },
            tab3Row: {
                h1: "ようこそ HKDFINANC へ",
                h2: "ヘルプセンター",
                tit0: "すべての質問",
                tit1: "デモ口座",
                tit2: "口座開設",
                tit3: "入金と出金",
                tit4: "プラットフォームの設定",
                tit5: "取引操作",
                tit6: "料金と費用",
                tit7: "資金の安全性と規制",
                tit1Tit1: "HKDFINANCはデモ口座を提供していますか",
                tit1Cont1: "<p>HKDFINANC は顧客が取引を行い、プラットフォームの操作を熟練させるために使用できるデモ口座を提供しています。リスクのない状況で当社のウェブプラットフォームおよびモバイルアプリを使用して取引を行うことができます。</p>",
                tit1Tit2: "デモ口座の作成方法は？",
                tit1Cont2: "<p>メールアドレスまたは携帯電話番号を入力し、個人のパスワードを設定するだけで、デモ口座を作成し、取得できます。</p>",
                tit1Tit3: "デモ口座の利点は？",
                tit1Cont3: "<p>デモ口座は、取引画面、データ、操作など、ほとんどの点で実際の口座と同じです。デモ口座には仮想資金 5 万ドルが含まれており、資金リスクなしにプラットフォームの機能を練習することが目的です。</p>",
                tit1Tit4: "デモ口座に資金を供給する方法は？",
                tit1Cont4: "<p>デモ口座に資金を供給する必要はありません。デモ口座を正常に登録すると、アカウント内には 5 万米ドル (USD) またはオーストラリアドル (AUD) のバーチャル資産が含まれます。</p><p>ただし、デモ口座の純資産が 200 米ドル (USD) またはオーストラリアドル (AUD) 未満になると、システムは自動的にアカウント資金を 5 万米ドル (USD) またはオーストラリアドル (AUD) に補充します。</p>",
                tit1Tit5: "デモ口座の有効期限はありますか？",
                tit1Cont5: "<p>デモ口座の有効期限は 90 日間です。90 日後、実際のアカウントを開設しない場合、デモ口座は凍結され、取引ができなくなります。実際のアカウントを開設すると解除されます。</p><p>ただし、90 日以内に実際のアカウントを開設する場合、デモ口座は永続的に有効です。実際のアカウントを開設した後も、デモ口座で取引を続けることができます。</p>",
                tit2Tit1: "HKDFINANC はいくつのアカウントタイプを提供していますか？",
                tit2Cont1: "<p>私たちは個人向けに標準アカウントとプロアカウントのみを提供しており、法人口座や共同口座の開設はサポートしていません。</p>",
                tit2Tit2: "どの通貨の取引アカウントを開設できますか？",
                tit2Cont3: "<p>アカウントを開設する際、システムは自動的にあなたの地域に基づいて決済通貨を設定し、変更はできません。</p>",
                tit2Tit4: "実際のアカウントを開設する方法は？",
                tit2Cont4: "<p>実際のアカウントを開設するには、次の手順に従って操作してください。</p><p>1. HKDFINANC 登録ページにアクセスし、指示に従ってメールアドレスまたは携帯電話番号を登録し、ログインパスワードを設定し、確認を送信すると、アカウントが自動的に作成されます。</p><p></p><p></p>",
                tit3Tit1: "どのような方法で入金できますか？",
                tit3Cont1: "<p>HKDFINANC は、顧客が選択できるさまざまな入金方法を提供しており、以下に示すもの以外にもあります：</p><p>1. Visa/Mastercard - 通常は即時入金</p><p>2. インターネットバンキング - 通常は1営業日以内</p><p>3. 電子ウォレット（Skrill、Momo、Zalo、Touch'n GO、Boost など） - 通常は即時入金</p><p>4. ATM カード - 通常は即時入金</p><p>5. QR モバイルバンキング - 通常は即時入金</p>",
                tit3Tit2: "第三者の銀行口座またはカードを使用して入金または出金できますか？",
                tit3Cont2: "<p>HKDFINANC は第三者からの入金を受け付けていません。入金または出金には、あなた自身の名義の個人銀行口座またはカードを使用してください。他人の名義または会社の銀行口座またはカードを使用すると、資金は返金されます。</p>",
                tit3Tit3: "HKDFINANC アカウントから出金を申請する方法は？",
                tit3Cont3: "<p>ウェブサイトプラットフォームにログインした後、[出金] をクリックし、銀行口座またはVisa/Mastercard を選択し、出金金額を入力し、[送信] をクリックします。情報が正しいことを確認した後、HKDFINANC は申請を処理し、通常は1〜2営業日で出金が行われます。</p>",
                tit4Tit1: "HKDFINANC はどの程度のレバレッジトレードを提供していますか？",
                tit4Cont1: "<p>HKDFINANC プラットフォームで提供される最大レバレッジ倍率は規制当局の規定に基づいており、異なる製品ごとに異なる場合があります。詳細は取引プラットフォームでご確認ください。</p>",
                tit4Tit2: "パスワードを変更する方法は？",
                tit4Cont2: "<p>プラットフォームにログインした後、[その他] → [セキュリティ設定] → [パスワードの変更] を選択し、新しいパスワードを設定できます。</p>",
                tit4Tit3: "HKDFINANC トレーディングプラットフォームへのログイン方法は？",
                tit4Cont3: "<p>アプリ：[マイ] に移動し、左上の[ログイン/登録] をクリックしてログインページにアクセスします。Web：HKDFINANC 公式ウェブサイトの右上にある[ログイン] をクリックしてログインページにアクセスします。</p>",
                tit4Tit4: "プラットフォームの通知方法はありますか？",
                tit4Cont4: "<p>HKDFINANC は、電子メール、SMS、プラットフォーム通知を使用して通知を提供しています。</p>",
                tit5Tit1: "HKDFINANC はどのようなスプレッドを提供していますか？",
                tit5Cont1: "<p>私たちは低スプレッドを採用し、手数料は一切いただいておりません。ただし、実際のスプレッドは取引プラットフォームに依存し、取引プラットフォームで即座に表示されます。</p>",
                tit5Tit2: "成行注文をどのように行いますか？",
                tit5Cont2: "<p>[取引] をクリックすると、プラットフォームで取引可能なすべての製品が表示されます。右側の入力欄に製品コードまたは名前を入力して検索し、製品を選択し、[売買] をクリックすると取引ウィンドウが表示されます。取引ウィンドウで現在の価格と必要な証拠金を確認できます。取引数量を手動で調整し、リスク管理のために利益を確保し、損失を制限するためにストップロスを設定できます。</p>",
                tit5Tit3: "指値注文をどのように行いますか？",
                tit5Cont3: "<p>[取引] をクリックして製品を選択し、[売買] をクリックすると、取引ウィンドウが表示されます。取引ウィンドウで指値注文を設定できます。</p>",
                tit5Tit4: "ポジションをどのようにクローズしますか？",
                tit5Cont4: "<p>決済を行うには、[ポジション] (Position) をクリックしてプラットフォーム上の[オープンポジション] (Open) を表示し、決済したいポジションを選択し、右側の[クローズ] (Close) ボタンをクリックします。</p>",
                tit5Tit5: "オーダーの変更または削除はどのように行いますか？",
                tit5Cont5: "<p>オーダーを変更または削除するには、[ポジション] (Position) 内でプラットフォーム上の[オーダー] (Orders) を表示し、変更または削除したいオーダーを選択します。</p>",
                tit6Tit1: "取引プラットフォームの使用には手数料がかかりますか？",
                tit6Cont1: "<p>HKDFINANC は完全に手数料のかからない取引プラットフォームです。低いスプレッドを提供しており、取引に関連する特定のコスト、例えばオーバーナイトスワップなどが発生する場合があります。</p>",
                tit6Tit2: "入金には手数料がかかりますか？",
                tit6Cont2: "<p>私たちは顧客に入金に関連する料金を請求していませんが、お支払いや中間銀行が手数料を請求することがあります。関連する銀行に手数料がかかるかどうかを確認することをお勧めします。</p>",
                tit6Tit3: "出金には手数料がかかりますか？",
                tit6Cont3: "<p>HKDFINANC では、出金に関連する料金は発生しません、ただし、出金額が最低要件を満たさない場合や月間の無料出金回数を超える場合は除きます。詳細については、当社の出金ポリシーをご参照ください。</p>",
                tit7Tit1: "HKDFINANC に入金した資金は他の目的で使用されますか？",
                tit7Cont1: "<p>いいえ、リテール顧客の預金は、必要に応じて信託口座に別々に保管されます。顧客口座からの資金の預金、引き出し、または支払いの際には、HKDFINANCは顧客口座に関する規制に厳密に従います。</p>",
                tit7Tit2: "HKDFINANC が倒産した場合、または負債を抱えた場合、私の資金は影響を受けますか？",
                tit7Cont2: "<p>リテール顧客の預金は必要に応じて信託口座に別々に保管されます。したがって、HKDFINANCが倒産し、免許が取り消されたり、持続不能な状況に陥った場合でも、顧客の資金は引き続き分離され、規制法の顧客資金に関する規定に従って監督されます。</p>",
                tit7Tit3: "HKDFINANC は法律に準拠していますか？",
                tit7Cont3: "<p>当社は関連する法人法と金融規制法に厳密に準拠し、関連する規制機関の認可を受けた金融サービスプロバイダーです。当社は規制当局の厳格な要件に従い、顧客の資金の安全性を保証します。</p>",
                tit7Tit4: "顧客の個人情報は安全ですか？",
                tit7Cont4: "<p>HKDFINANC は個人情報保護法を厳密に遵守し、顧客の個人情報は漏洩しません。会社は従業員が法律で規定されたセキュリティおよび機密基準を遵守し、顧客データの機密保持と使用に注意を払います。詳細については、当社の法的開示文書をご参照ください。</p>",
            },
            tab4Row: {
                span: "Q確認方法",
                p1: "HKDFINANCは、権威ある機関によって認可および監視されている金融テクロジー企業であり、投資家に簡単で便利な取引体験を提供することに特化しています。",
                p2: "革新的なHKDFINANC多様化取引プラットフォームは、何度も最高のモバイル取引プラットフォームや最も革新的な証券会社などの称賛を受けています。",
                p3: "HKDFINANCのプラットフォームを通じて、株式、指数、商品、外国為替など、数百もの人気商品を含む、より広範な国際金融市場に投資し、取引することができます。使いやすいモバイル版とウェブ版の取引プラットフォームに限定されず、HKDFINANCは競争力のある取引コスト、高速な取引実行、優れたカスタマーサービス、豊富な情報サポートを提供し、投資と取引の機会を逃さないようサポートします。",
                p4: "HKDFINANCは業界の権威ある多くの機関から厳格な監視を受けています。",
                p5: "HKDFINANC Holdingはケイマン諸島金融監督庁(CIMA)から認可を受け、その監督を受けており、SIBライセンス番号は1612446です。詳細なケイマン諸島のライセンス情報については、CIMA公式ウェブサイトwww.cima.ky()をご覧いただけます。",
                p6: "を検索します。HKDFINANCGlobalは香港証券投資委員会（ASIC）が認可した金融サービスライセンス（AFSL 398528）を保有している。香港写真の詳細については、ASIC公式サイトwww.asic.gov.au",
                p7: "「HKDFINANC Internationalは、モーリシャス金融サービス委員会(FSC)から認可を受け、その監督下にあり、許可証番号はGB20025791です。モーリシャスのライセンスの詳細については、FSC公式ウェブサイトwww.fscmauritius.orgで確認できます。」",
                p8: "「)での検索が可能です。HKDFINANCは、厳格な監督の下ですべての業務を運営し、すべての規制を遵守しています。」",
                p9: "的バックグラウンド背景",
                p10: "HKDFINANCは金融取引と金融科学技術業界の経験と知識に富んだベテランチームで構成されている。HKDFINANCの理念は、取引がより簡単になり、より友好的になることです",
                p11: "HKDFINANCのコンセプトは、取引をよりシンプルで使いやすくすることです。私たちの統合戦略とビジネスモデルにより、業界の成長と市場の要求に対する深い洞察と理解を持っており、リソースを効果的に配置し、技術の革新と効率の最適化を継続的に行い、お客様により便利で使いやすい取引体験を提供し続けるためのターゲットを絞り込むことができます。",
                p12: "なぜ選ぶのか",
                p13: "低い取引条件",
                p14: "シンプルで直感的な取引プラットフォーム",
                p15: "競争力のある取引コスト",
                p16: "業界の権威からの監視",
                p17: "高水準のオンラインサポート",
                p18: "ネガティブバランス保護",
                p19: "賞と栄誉",
                p20: "HKDFINANCは、World Finance Awardsの「Best Multi-Asset Broker」、Global Brands Magazineの「Best Mobile Trading Platform in Asia-Pacific」、「Fastest Growing Financial Technology Broker Globally」など、多くの賞を受賞しています。これらの受賞は、チームの長年にわたる努力と貢献を称賛するものであり、我々にとって非常に光栄なことです。",
                p21: "最優秀な多資産ブローカー",
                p22: "のビジョン",
                p23: "責任",
                p24: "すべての取引にはリスクが伴いますが、HKDFINANCはお客様に責任ある取引環境を提供するためにさまざまな対策を講じています。HKDFINANCは業界の権威から厳格な監督を受け、高水準の安全性と安定性の運用基準を遵守しています。当社のプラットフォームは、リスク管理を含む責任ある取引ツールを継続的に提供しています。",
                p25: "シンプルで美しい",
                p26: "シンプルは美しさであり、ディテールは卓越性を生み出します。HKDFINANCはシンプルで使いやすいユーザーエクスペリエンスのあらゆるディテールにこだわります。私たちはプラットフォームとサービスを継続的に最適化し、初心者から経験豊富な投資家まで、誰もがHKDFINANCプラットフォームを使って取引の機会を簡単に見逃さないことを願っています。",
                p27: "透明性",
                p28: "お客様が公平で効率的な取引環境から最大限の利益を得られるようにするために、HKDFINANCは厳格で透明性のある自己規制システムに従い、包括的な透明性のあるプラットフォーム製品情報、価格環境、およびデータを提供し、すべての費用を明確かつ公平に公開しています。これにより、サービスプロセス全体が完全に誠実で透明性のあるものとなっています。",
                p29: "イノベーション",
                p30: "HKDFINANCは、技術の進化、金融の革新、より広範なサービスが成長を推進する力であると信じています。価値を不断に創造し、共有するために、私たちはオープンな思考を奨励し、イベーションを継続的に推進し、金融テクロジーの革新者およびリーダーを目指しています。",
                p31: "目標とビジョン",
                p32: "より多くのトレーダーに、場所、時間、市場の制約を受けない公正で効率的で透明性のある取引環境を提供し、金融とテクノロジーのイノベーションをリードする業界のリーダーとして成長することです。",
            },
        },
        language: {
            tit: "言語を選択",
        },
        market: {
            tit: "市場設定",
            tit1: "価格表示",
            tit2: "価格変動の色",
            buy_price: "買値",
            buy_price_tip: "買値でキャンドルチャートを表示",
            sell_price: "売値",
            sell_price_tip: "売値でキャンドルチャートを表示",
            color1: "緑が上昇、赤が下落",
            color2: "赤が上昇、緑が下落",
        },
        notice: {
            tit: "通知設定",
            tit1: "通知チャネルを有効にする",
            p1: "以下の通知チャネルを有効にし、重要なメッセージを見逃さないようにしてください！",
            message: "SMS",
            push: "プッシュ通知",
            verify: "確認",
            verified: "確認済み",
            email: "電子メール",
            type1: "マーケティング",
            tit2: "マーケティング活動の通知",
            desc2: "プラットフォームの特典情報、運営活動などのメッセージ",
            tit3: "操作ガイド",
            desc3: "充実した専門知識の資料",
            tit4: "情報通知",
            desc4: "最新の経済データとニュース情報",
            tit5: "スマートな価格変動通知",
            desc5: "重要な市場価格変動を含み、取引チャンスを素早く把握できます",
            type2: "レポート",
            tit6: "アカウントレポート",
            desc6: "アカウントレポートは以下の方法で毎月送信されます",
            cycle: "サイクル",
            month: "毎月",
        },
        protocol: {
            tit: "声明と契約",
            label1: "プライバシーポリシー",
            label2: "製品開示声明",
            label3: "顧客契約",
            label4: "リスク開示声明",
        },
        setting: {
            tit: "設定",
            label1: "セキュリティ設定",
            label2: "マーケット設定",
            label3: "取引設定",
            label4: "言語",
            label5: "テーマモード",
            label6: "通知設定",
            label7: "取引ガイド",
            label8: "声明と契約",
            label9: "キャッシュのクリア",
            label10: "バージョン番号",
            logout: "ログアウト",
        },
        theme: {
            tit: "テーマモード",
            theme1: "ライトモード",
            theme2: "ダークモード",
        },
        trade: {
            tit: "取引設定",
            onclick_close: "一括決済",
        }
    },
    trade: {
        fliter_type1: "カスタム",
        fliter_type2: "市場",
        place_search: "銘柄名/コードを検索",
        fliter_type3: "人気",
        fliter_type4: "外国為替",
        fliter_type5: "商品",
        fliter_type6: "株式",
        fliter_type7: "指数",
        fliter_type8: "暗号通貨",
        tip1: "本人確認が完了すれば、世界中で入金取引が可能です",
        sell: "売り",
        buy: "買い",
        add_optional: "カスタムに追加",
        optional_recommend: "おすすめカスタム",
        change_batch: "次のバッチへ",
        edit_tit: "カスタムの編集",
        breed: "銘柄",
        topping: "トップ",
        sort: "ソート",
        all: "全選択",
    },
    table: {
        label_breed: "銘柄",
        label_direction: "方向",
        label_price: "価格",
        label_operate: "操作",
        empty_tip1: "通知なし",
        empty_tip2: "入金履歴なし",
        empty_tip3: "出金履歴なし",
        label_amount: "金額",
        label_type: "タイプ",
        label_time: "時間",
        label_name: "名前",
        label_email: "メールアドレス",
        label_address: "住所",
        label_account: "取引アカウント",
        label_currency: "基軸通貨",
        label_company: "現在の取引会社",
    },
    form: {
        to: "から",
        start_time: "開始時間",
        end_time: "終了時間",
        week: "過去1週間",
        month: "過去1か月",
        threeMonth: "過去3か月",
        sixMonth: "過去6か月",
        label_feedback: "質問や提案",
        place_feedback: "ご質問や提案を入力してください",
        label_img: "画像",
        label_img_tip: "オプション：問題のスクリーンショットを提供します",
        feedback_tip: "緊急の問題がある場合は、お問い合わせください",
        online: "オンラインカスタマーサービス",
        label_cardNumber: "ID番号",
        label_realName: "フルネーム",
        place_realName: "お名前を入力してください",
        message_realName: "お名前を入力してください",
        label_firstName: "名",
        place_firstName: "名を入力してください",
        message_firstName: "名を入力してください",
        label_lastName: "姓",
        place_lastName: "姓を入力してください",
        message_lastName: "姓を入力してください",
        label_birthday: "生年月日",
        place_birthday: "生年月日を入力してください",
        message_birthday: "生年月日を入力してください",
        label_idType: "IDタイプ",
        tip_cardMain: "身分証明書の正面の写真を撮影またはアップロードする",
        tip_cardBack: "身分証明書の裏の写真を撮影またはアップロードする",
        tip_cardMain1: "運転免許証の正面の写真を撮影またはアップロードする",
        tip_cardBack1: "運転免許証の背後の写真を撮影またはアップロードする",
        tip_cardMain2: "パスポートの写真を撮影またはアップロードする",
        tip_cardBack2: "パスポートの写真を撮影またはアップロードする",
        label_password: "パスワード",
        place_password: "パスワードを入力してください",
        message_password: "パスワードを入力してください",
        label_crmpsd: "パスワードを確認",
        place_crmpsd: "パスワードを再度入力してください",
        message_crmpsd: "パスワードを再度入力してください",
        label_email: "メールアドレス",
        place_email: "メールアドレスを入力してください",
        message_email: "メールアドレスを入力してください",
        label_captcha: "キャプチャ",
        place_captcha: "キャプチャを入力してください",
        message_captcha: "キャプチャを入力してください",
        get_captcha: "キャプチャを取得",
        label_phone: "電話番号",
        place_phone: "電話番号を入力してください",
        message_phone: "電話番号を入力してください",
        labelAmount: "金額",
        placeAmount: "金額を入力してください",
        messageAmount: "金額を入力してください",
        labelWalletName: "ウォレット名",
        messageWalletName: "ウォレット名を入力してください",
        placeWalletName: "ウォレット名を入力してください",
        labelWalletAddress: "ウォレットアドレス",
        messageWalletAddress: "ウォレットアドレスを入力してください",
        placeWalletAddress: "ウォレットアドレスを入力してください",
        labelWithdrawAccount: "出金口座",
        placeWithdrawAccount: "出金口座を入力してください",
        messageWithdrawAccount: "出金口座を入力してください",
        placeTimeLimit: "期限を入力してください",
        messageTimeLimit: "期限を入力してください",
    },
    city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "オーストリア",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
};
