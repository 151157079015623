export default {
    crypto: {
        title: {
            0: "Buy Crypto",
            1: "Buy Crypto",
            2: "C2C",
        },
        list: {
            0: "C2C Trading",
            1: "Buy",
            2: "Sell",
            3: "Advertisers (Completion rate)",
            4: "Trades",
            5: "Price",
            6: "Available",
            7: "Limit",
            8: "Payment Method",
            9: "Trade",
            10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
        },
        buy: {
            0: "Buy Crypto",
            1: "Sell Crypto",
            2: "History",
            3: "Support",
            4: "Pay",
            5: "Receive",
            6: "Select payment method",
            7: "Est. arrival time",
            8: "FEE",
            9: "Order Details",
            10: "Payment method",
            11: "Learn more",
            12: "Price",
            13: "Total (fee included)",
            14: "Disclaimer",
            15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
            16: "I have read and agree to the Terms of Use",
            17: "You will get",
            18: "Submit",
            19: "Please enter the amount",
            20: "Insufficient account balance",
            21: "Available",
            22: "Spot Account Availiable",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        }
    },
    common: {
        view: "Nézd meg",
        confirm: "Define",
        cancel: "Törlések",
        deposit: "Letét",
        withdraw: "Pénzt felvenni",
        noData: "Nincs adat",
        submit: "Benyújtani (jelentést stb.)",
        modify: "Módosítások",
        hand: "Kéz",
        confirm_verify: "Validate",
        next: "A következő lépés",
        complete: "Teljesíteni",
        delete: "A eltávolítása",
		countryCode: "Ország/terület kódja",
    },
    addnew: {
        buyUp: "Szárnyaló",
        buyDown: "Vásárolni és eladni",
        confirmOrder: "Megrendelés megerősítése",
        name: "Név",
        fee: "Kezelési költség",
        orderCycle: "Idő kiválasztása",
        loan: "Hitel",
        loanTit: "Diákhitel",
        loanHostory: "Hitel nyilvántartások",
        labelProductTitle: "Termék neve",
        immediateRepayment: "Azonnali visszafizetés",
        loadAmount: "Várható kölcsönösszeg",
        RepaymentCycle: "Hitel-visszafizetési ciklus",
        day: "Nap",
        dailyRate: "Napi kamatláb",
        repaymentMethod: "Visszafizetési mód",
        lendingCompany: "Hitelező cég",
        loanTip: "Hitelhitelek (kérjük, győződjön meg róla, hogy a képek jól láthatóak)",
        loanTip1: "Tulajdoni tanúsítvány feltöltése",
        loanTip2: "Jövedelemigazolás (munkaviszony)",
        loanTip3: "Bankszámlakivonat adatai",
        loanTip4: "Töltse fel személyi igazolványa előlapi fényképét",
        interest: "Szaké",
        repaid: "Visszafizetve",
        unpaid: "Vissza nem fizetés",
        tab1: "Érmék",
        tab2: "Szerződéses",
        tab3: "Opciók",
        available: "Rendelkezésre álló hitel",
        pending: "Zár",
        equivalent: "Az összeg",
        fundTransfer: "Pénzátutalás",
        flashExchange: "Készpénz",
        select_breed: "Pénznem kiválasztása",
        from: "Át ",
        to: "Amíg",
        transferQuantity: "Átutalások száma",
        repaymentMethod1:"Egy részletben esedékes visszafizetés"
    },
    params: {
        product_foreign: "Deviza",
        product_shop: "Áru",
        product_number: "Exponents",
        product_stock: "Készlet (piac)",
        product_coin: "Titkosított",
        billTypes: [
            {v: 101, name: 'Feltöltés (pénz feltöltése kártyára)'},
            {v: 102, name: 'Letétbe helyezés (pl. bankszámlán)'},
            {v: 103, name: 'Fagyasztás'},
            {v: 104, name: 'Felolvasztás'},
            {v: 105, name: 'Letétbe helyezés (pl. bankszámlán)'},
            {v: 106, name: 'Letétbe helyezés (pl. bankszámlán)'},
            {v: 201, name: 'Visszavonás befagyasztása'},
            {v: 202, name: 'Pénzt kivenni'},
            {v: 203, name: 'Sikeres visszavonás'},
            {v: 204, name: 'Visszavonás sikertelen'},
            {v: 205, name: 'Visszavonási díj'},
            {v: 206, name: 'Áthelyezés'},
            {v: 207, name: 'Váltás'},
            {v: 208, name: 'Érmeátadás'},
            {v: 209, name: 'Érmeátadás'},
            {v: 301, name: 'Szerződéskezelési díj'},
            {v: 302, name: 'Szerződéses jövedelem'},
            {v: 303, name: 'Szerződés elvesztése'},
            {v: 304, name: 'Szerződéses árrés'},
            {v: 305, name: 'Szerződéses árrés megtérülése'},
            {v: 311, name: 'Opciók vásárlása'},
            {v: 312, name: 'Opciós nyereség'},
            {v: 313, name: 'Visszatérési opció'},
            {v: 314, name: 'Opciós kezelési díj'},
            {v: 315, name: 'Érme vásárlás befagyasztása'},
            {v: 316, name: 'Érmevásárlási levonás'},
            {v: 317, name: 'Érmevásárlás visszatérítés'},
            {v: 318, name: 'Érmés befizetés a számlára'},
            {v: 319, name: 'Érmés befizetés a számlára'},
            {v: 320, name: 'Érmeeladás befagyasztása'},
            {v: 321, name: 'Érmeeladási levonás'},
            {v: 322, name: 'Érme eladni visszatérül'},
            {v: 323, name: 'Érme eladása számlára'},
            {v: 324, name: 'Érme eladása számlára'},
            {v: 325, name: 'Érme kezelési díj'},
            {v: 401, name: 'Bányászok csatlakoznak'},
            {v: 402, name: 'Bányász visszatérés'},
            {v: 403, name: 'Bányagépekből származó bevétel'},
            {v: 404, name: 'Bányászati gép kilépése'},
            {v: 405, name: 'Bányászati kilépési díj'},
            {v: 411, name: 'Hitelfelvételből származó jövedelem'},
            {v: 412, name: 'Kölcsönzési díj'},
            {v: 413, name: 'Sikeres visszafizetés'},
        ],
    },

    footer: {
        nav1: "Ábra kezdet",
        nav2: "Ügyletek",
        nav3: "Pozíciók",
        nav4: "Az én",
        kline_set1: "Ketyereszimbólum",
        kline_set2: "Tranzakciós beállítások",
    },
    login: {
        login: "Bejelentkezés",
        register: "Beiratkozás",
        type1: "Mobiltelefonszám",
        type2: "Postaláda",
        login_btn: "Biztonságos bejelentkezés",
        register_btn: "Regisztráljon most",
        psd_login: "Jelszó bejelentkezés",
        verify_login: "CAPTCHA bejelentkezés",
        forget: "Elfelejtette a jelszavát??",
        no_account: "Nincs számla？",
        have_account: "Meglévő számlák",
    },
    home: {
        home: {
            nav1: "A hullámvölgyek rangsora",
            nav2: "Gyors indítás",
            nav3: "Rólunk",
            nav4: "Meghívásos jutalmak",
            most_follow: "Aktualitás",
            news_type1: "Legyen tapintatos",
            news_type2: "Nyilvános tájékoztatás",
            news_type3: "Pénzügyi naptár",
            news_tab1: "Teljes",
            news_tab2: "Deviza",
            news_tab3: "Készlet (piac)",
            news_tab4: "Áru",
            news_tab5: "Exponents",
            news_tab6: "Számok",
            news_tab7: "Esemény",
            news_tab8: "Nyaralás",
            label_previous: "Korábbi érték",
            label_predictive: "Tervezett érték",
            label_announced: "Közzétett érték",
        },
        kilne: {
            label_bp: "A tegnapi termés",
            label_sp: "Napjainkban",
            label_height: "Legfelsőbb",
            label_low: "Legalacsonyabb",
            tip: "Üdv, HKDFINANCGPT Variety Assistant vagyok.",
            row1_tit: "Legyen tapintatos",
            row1_rinei: "Napon belül",
            row1_short: "Rövid távú",
            row1_middle: "Félidős",
            row1_time: "Kibocsátási idő",
            row1_tit2: "Kereskedési stratégia",
            data_sources: "Adatforrások",
            row2_tit1: "Számok",
            row2_tit2: "Kereskedési hangulat",
            row2_label_sell: "Eladó (áruk)",
            row2_label_buy: "Vásárló",
            tip2: "Csak tájékoztatásul, nem az osztály álláspontja.",
            tip3: "15 percenként frissül",
            row2_tit3: "Árváltozás",
            minute: "Percek",
            today: "Jelenleg",
            right_tit7: "Árváltozás tartománya",
            now_price: "Aktuális ár",
            right_low_price: "Legalacsonyabb ár",
            right_height_price: "Legmagasabb ár",
            details: "Részletek",
            right_label1: "Egyszeri tranzakciók száma",
            right_label2: "Maximális tőkeáttétel",
            right_label3: "Szerződés mérete",
            right_label4: "A pozíciók maximális összlétszáma",
            right_label5: "Lebegő spread",
            right_label6: "Éjszakai töltés",
            hand: "Kéz",
            right_sell: "Reach (ár egy árverésen)",
            right_buy: "Buy-in",
            right_collection_time: "Gyűjtési idő",
            right_tip3: "A teljes tranzakció méretéből összegyűjtve",
            right_label9: "Nyitvatartási idő",
            right_label10: "Az aktuális idősáv",
            right_label11: "Következő intervallum",
        },
        placeorder: {
            direction_buyPrice: "Ajánlati ár",
            direction_sellPrice: "Eladási árfolyam",
            direction_sell: "Reach (ár egy árverésen)",
            direction_buy: "Buy-in",
            market_price: "Piaci árlista",
            check_price: "Függőben lévő megbízás",
            sell_tip: "Eladni, ha az ár megfelel a feltételeknek",
            buy_tip: "Vásároljon, ha az ár megfelel a feltételeknek",
            trade_number: "Tranzakciók száma",
            hand: "Kéz",
            select_lever: "A tőkeáttétel kiválasztása",
            right_tip: "Szükséges letét",
            balance: "Rendelkezésre álló egyenleg",
            label_stop_win_price: "Profitrealizálás",
            label_stop_lose_price: "Stop-loss",
            expected_profit_loss: "Várható eredmény",
            trailing_stop: "Követési stop",
        },
        ranking: {
            tit: "A nap legnagyobb nyertesei és vesztesei",
            p: "A forró pontok megragadása és a lehetőségek kihasználása",
            renew: "Frissítve",
            growth_chart: "Növekedési diagram",
            drop_list: "Vesztesek listája",
            show_closed_market: "A Hugh márka bemutatása",
            filter1: "Teljes",
            filter2: "Deviza",
            filter3: "Áru",
            filter4: "Készlet (piac)",
            filter5: "Exponents",
            filter6: "Titkosított",
            td1: "Rangsorok",
            td2: "Tendenciák",
            td3: "Napi nyereség / eladási ár",
        },
        remind: {
            now_sell_price: "Jelenlegi árajánlat",
            direction_buyPrice: "Ajánlati ár",
            direction_sellPrice: "Eladási árfolyam",
            direction_sell: "Reach (ár egy árverésen)",
            direction_buy: "Buy-in",
            equal: "(Koll.) megbukik (egy diák)",
            p: "Értesítsen, ha a következő árak elérik a következő árakat",
            btn1: "Emlékeztetők beállítása",
            list_tit: "Emlékeztető lista",
        },
    },
    my: {
        report: {
            tit: "Számlajelentések",
            tab1: "Jelentéstétel",
            tab2: "Napi kimutatás",
            tab3: "Havi kimutatás",
            history_tit: "Tranzakciótörténeti jelentés",
            account_tit: "Számlajelentések",
            filter: "Szűrés",
            tip1: "Kérjük, válassza ki a megfelelő dátumtartományt a tranzakciótörténeti jelentés exportálásához.。",
            tip2: "Egyéni idő",
            email_send: "E-mailben elküldeni",
        },
        accountMsg: {
            tit: "Számlainformációk",
            label1: "Személyes információk",
        },
        bill: {
            tit: "A pénzeszközök áramlásának részletei",
            all: "Teljes",
            time: "Times",
        },
        feedback: {
            tit: "Funkcionális ajánlások",
        },
        identity: {
            tit: "Hitelesítés",
            type1: "Személyi igazolványok",
            type2: "Jogosítvány",
            type3: "Útlevél",
            tip: "Megerősítem, hogy minden megadott információ és dokumentum teljes, igaz és pontos.",
        },
        my: {
            is: "Utána",
            no: "Ellenőrizetlen",
            verify: "Validált",
            id_verify: "Hitelesítés",
            verify_tip: "Töltse ki a személyazonosság ellenőrzését a befizetéshez és a kereskedelemhez",
            no_verify: "Ellenőrizze most",
            balance: "Rendelkezésre álló egyenleg",
            profit_loss: "Növekvő és csökkenő",
            marginLevel: "Margin szint",
            btn1: "Valódi számlára váltás",
            deposit_withdraw_record: "Befizetési és kivételi nyilvántartások",
            capital_flow_details: "A pénzeszközök áramlásának részletei",
            welfare_center: "Jóléti központ",
            tip1: "Van",
            tip2: "Igénybe vehető tolljutalmak",
            feedback: "Funkcionális ajánlások",
            report: "Számlajelentések",
            setting: "Felállítani",
        },
        news: {
            tit: "Értesítések",
        },
        record: {
            tit: "Befizetési és kivételi nyilvántartások",
            cycle: "Ciklikusság",
        },
        share: {
            tit: "Barátok meghívása",
            invite_success: "Sikeresen meghívni a barátokat",
            tip1: "Készpénzes ösztönző",
            tip2: "Hívd meg a barátaidat, és",
            tip3: "Hívja meg barátait, hogy készpénzt kapjon",
        },
        welfare: {
            tit: "Jóléti központ",
            tab1: "Kártya tekercs",
            tab2: "Funkció",
            history_record: "Történelmi feljegyzések",
            period_validity: "érvényességi idő",
        },
        wallet: {
            tit: "Pénztárcák",
            addNew: "Új pénztárca hozzáadása",
        },
        withdraw: {
            tit: "Pénzt felvenni",
            addNew: "Új pénztárca hozzáadása",
        },
    },
    order: {
        demo_account: "Demo számla",
        real_account: "Élő számla",
        tip: "Megnyitás után befizethet és kereskedhet",
        tab1: "Gazdaságok",
        tab2: "Függőben lévő megbízás",
        tab3: "Történetek",
        list_empty: "Jelenleg nincsenek függőben lévő megrendelések",
        start_trade: "A kereskedés megkezdése",
        hand_tip: "Bemeneti tartomány",
        kline: {
            direction_sell: "Reach (ár egy árverésen)",
            direction_buy: "Buy-in",
            open_price: "Nyitóár",
            now_price: "Aktuális ár",
            trade_account: "Kereskedelmi számla",
            demo_account: "Demo számla",
            order_id: "Megrendelés azonosítója",
            open_time: "Nyitvatartási idő",
            stop_profit: "Profitrealizálás",
            stop_loss: "Stop-loss",
            trailing_stop: "Követési stop",
            float_profit_loss: "Lebegő P/L",
            overnight_fee: "Éjszakai töltés",
            margin: "Margin",
            maintainsMargin: "Karbantartási különbözet",
            open_value: "Nyitóérték",
            now_value: "Aktuális érték",
            notset: "Nem rögzített",
            close_position: "Pozíciót lezárni",
            close_number: "Sorszám",
            onclick_close: "Pozíció bezárása egy kattintással",
            btn: "A pozíciózárás megerősítése",
            balance: "Rendelkezésre álló egyenleg",
            profit_loss: "Növekvő és csökkenő",
            netValue: "Nettó érték",
            marginLevel: "Margin szint",
        },
        modify: {
            tit: "Pozíciós sorrend módosítása",
            expected_profit_loss: "Várható eredmény",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Új jelszó beállítása",
            verify_email_tit: "Ellenőrizze az e-mailt",
            verify_password_tit: "Jelszó ellenőrzése",
            verify_password_tip: "A folytatáshoz adja meg a HKDFINANC bejelentkezési jelszavát",
            verify_phone_tit: "Mobiltelefonszám ellenőrzése",
            tit: "Számla biztonsága",
            tip: "A fiókja biztonságának védelme és a szolgáltatásainkról szóló értesítések fogadása érdekében ajánlott legalább egyféle hitelesítési mód engedélyezése.",
            label1: "Jelszó módosítása",
            label2: "A számlák törlése",
            label3: "Kettős ellenőrzés",
            label4: "Berendezések kezelése",
            tip2: "Hitelesítés szükséges az új eszköz bejelentkezéséhez",
        },
        tradenews: {
            tit: "Kereskedelmi útmutató",
            tab1: "Gyors indítás",
            tab2: "Haladó tanulmányok",
            tab3: "Súgóközpont",
            tab4: "A HKDFINANC-ról",
            viewMore: "Bővebben",
            details: {
                tit: "Bevezetés a devizaváltásba",
                tip1: "A tanulmányok becsült időtartama",
                tip2: "Percek",
            },
            tab1Row: {
                tit: " Ez egy gyors tanfolyam, hogy megtanuljon kereskedni a HKDFINANC-nal!",
                tit1: "Hogyan kereskedhetek a HKDFINANC-on?",
                tit2: "Hogyan nyisson élő számlát？",
                tit3: "Hogyan nyisson meg egy kereskedést？",
                tit4: "Hogyan állítsunk be Stop Loss és Take Profit？",
                tit5: "Hogyan kell lezárni egy pozíciót？",
                p1: "Az alaptermékkel vétel vagy eladás útján kereskedhet. A HKDFINANC-ban pozíciót nyitni azt jelenti, hogy érdekeltsége van ebben a",
                p2: "A mögöttes termék áringadozásának vétele és értékesítése",
                p3: "Például, ha az arany árának emelkedését várja, megvásárolja a terméket; fordítva, ha az arany árának csökkenését jósolja, eladja a terméket. Ha a jövő megfelel a várakozásoknak, akkor",
                p4: "Profitálni belőle",
                p5: "Ezenkívül a HKDFINANC 'tőkeáttételes kereskedést' kínál. A tőkeáttétel segítségével több tétellel kereskedhet ugyanazzal a pénzösszeggel.",
                p6: "A tőkeáttétel felnagyíthatja a nyereséget és a veszteséget.",
                p7: "Ezt 'margin trading'-nek is nevezik. Amikor pozíciót nyit, a HKDFINANC számláját megterhelik egy bizonyos pénzösszeggel, mivel",
                p8: "Kezdeti biztosíték",
                p9: "Kiválasztott termékek és vásárlási és értékesítési irányok",
                p10: "Ügyletek",
                p11: "Kattintson a 'Vásárlás'/'Eladás' gombra a megrendelés befejezéséhez.",
                p12: "A 'Take Profit' vagy 'Stop Loss' beállítása segít Önnek abban, hogy",
                p13: "A nyereség rögzítése és a veszteségek időben történő korlátozása",
                p14: "Pozíciót nyithat meg, ha a",
                p15: "Take Profit/Stop Loss",
                p16: "A nyitott megbízásoknál a 'Módosítás' gombra is kattinthat a stop-loss és take-profit beállításához.",
                p17: "Beállítás vagy törlés",
                p18: "A nyereséget és veszteséget a 'Lezárt pozíciók' menüpontban tekintheti meg.",
            },
            tab3Row: {
                h1: "Üdvözöljük a HKDFINANC-nál",
                h2: "Súgóközpont",
                tit0: "Minden kérdés",
                tit1: "Demo számla",
                tit2: "Számlát nyitni",
                tit3: "Befizetés és kivonás",
                tit4: "Platform beállítása",
                tit5: "Kereskedelmi műveletek",
                tit6: "Díjak és illetékek",
                tit7: "Pénzügyi biztonság és szabályozás",
                tit1Tit1: "HKDFINANC A HKDFINANC kínál demo számlákat?",
                tit1Cont1: "<p>A HKDFINANC demószámlákat kínál az ügyfelek számára, hogy kockázat nélkül kereskedhessenek és megismerkedhessenek a platformmal webalapú platformunk és mobilalkalmazásaink segítségével</p>.",
                tit1Tit2: "Hogyan hozzon létre egy demo számlát?",
                tit1Cont2: "<p>Mindössze annyit kell tennie, hogy ide kattintva kitölti e-mail címét vagy mobiltelefonszámát, és beállítja PIN-kódját, hogy befejezze a demószámla regisztrációját. </p>",
                tit1Tit3: "Mi a demó számla haszna?",
                tit1Cont3: "<p>A demószámla a kereskedési felület, az adatok és a műveletek tekintetében többnyire megegyezik a valódi számlával. A demószámla 50 000 $ virtuális pénzeszközzel rendelkezik, és arra szolgál, hogy az ügyfelek szimulált műveleteken keresztül megismerkedhessenek a platform funkcióival anélkül, hogy pénzeszközeiket kockáztatnák. </p",
                tit1Tit4: "Hogyan kell befizetni egy demószámlát?",
                tit1Cont4: "<p>Nem kell feltöltenie a demószámláját, amikor sikeresen regisztrál egy demószámlára, 50.000 USD vagy AUD lesz a számláján</p><p>És amikor a nettó érték a demószámláján 200 USD vagy AUD alá csökken, a rendszer automatikusan feltölti a számláját vissza 50.000 USD-re vagy AUD-ra</p>.",
                tit1Tit5: "Van-e a demó számlának lejárati dátuma?",
                tit1Cont5: "<p>A demószámla 90 napig érvényes. 90 nap után, ha nem nyit élő számlát, a demószámla befagyasztásra kerül, és nem tud rajta kereskedni, és feloldásra kerül, amikor élő számlát nyit. </p><p>Ha 90 napon belül nyit élő számlát, a demószámla hosszú ideig érvényes lesz. A demószámlán az élő számla megnyitása után is folytathatja a kereskedést. </p>",
                tit2Tit1: "Hány számlatípust kínál a HKDFINANC?  ",
                tit2Cont1: "<p>Kizárólag magánszemélyek számára kínálunk standard és professzionális kereskedési számlákat, vállalati és közös számlák nyitását nem támogatjuk</p>.",
                tit2Tit2: "Milyen pénznemben nyithatok kereskedési számlát??",
                tit2Cont3: "<p>A számlanyitáskor a rendszer automatikusan meghatározza az elszámolási pénznemet az Ön tartózkodási helye alapján, és azt nem tudja megváltoztatni</p>.",
                tit2Tit4: "Hogyan nyisson élő számlát?",
                tit2Cont4: "<p>Az élő számla megnyitásához kérjük, kövesse az alábbi lépéseket</p><p>1. Menjen a HKDFINANC regisztrációs oldalára, és kövesse a felszólításokat: regisztrálja e-mail címét és mobiltelefonszámát, állítson be egy belépési jelszót, küldje el a megerősítést, és a számla automatikusan létrejön</p><p></p><p></p><p></p>.",
                tit3Tit1: "Hogyan tudok befizetni?",
                tit3Cont1: "<p>A HKDFINANC különböző befizetési módszereket kínál az ügyfelek számára, amelyek közül választhatnak, beleértve és esetleg többet is az alábbiaknál:</p><p>1. Visa/Mastercard - általában azonnali</p><p>2. Internet Banking - általában 1 T napon belül</p><p>3. E-pénztárcák (pl. Skrill, Momo, Zalo, Touch'n GO, Boost, stb.) - általában azonnali</p><p>4. E-pénztárcák (pl. Skrill, Momo, Zalo, Touch'n GO, Boost stb.) - általában azonnal</p><p>4. Bankkártyák - általában azonnal</p><p>5. QR Mobile - általában azonnal</p>.",
                tit3Tit2: "Befizethetek vagy felvehetek pénzt egy harmadik fél nevére szóló bankszámlával/kártyával?",
                tit3Cont2: "<p>A HKDFINANC nem fogad el harmadik féltől származó betéteket. Kérjük, hogy a saját nevére szóló személyes bankszámláját/kártyáját használja a befizetésekhez vagy pénzfelvételekhez. Ha egy másik személy vagy cég bankszámláját/kártyáját használja pénzeszközök befizetésére, a pénzeszközöket visszatérítjük</p>.",
                tit3Tit3: "Hogyan kérhetek kifizetést a HKDFINANC számlámról?",
                tit3Cont3: "<p>A weboldal platformjára bejelentkezhet, kattintson a [Kifizetés] gombra, válasszon egy bankszámlát vagy Visa/Mastercardot, és töltse ki a kifizetési összeget, majd nyomja meg a [Beküldés] gombot, miután megbizonyosodott arról, hogy az adatok helyesek, a HKDFINANC 1-2 munkanapon belül feldolgozza a kérelmet</p>.",
                tit4Tit1: "Mekkora tőkeáttételt kínál a HKDFINANC?",
                tit4Cont1: "<p>A HKDFINANC platformok által kínált tőkeáttétel a szabályozó hatóságok szabályozásán alapul, és a maximális tőkeáttétel termékenként változik. További információért kérjük, látogasson el a kereskedési platformra. </p",
                tit4Tit2: "Hogyan változtassam meg a jelszavam?",
                tit4Cont2: "<p>A platformra való bejelentkezés után a [Bővebben]- [Biztonsági beállítások]- [Jelszó módosítása]</p> menüpont kiválasztásával új jelszót állíthat vissza.",
                tit4Tit3: "Hogyan jelentkezhetek be a HKDFINANC kereskedési platformra?",
                tit4Cont3: "<p>Alkalmazás: Menj a 'Saját' menüpontra, és kattints a 'Bejelentkezés l Regisztráció' gombra a bal felső sarokban, hogy a bejelentkezési oldalra lépj. </p>Web: Kattintson a 'Bejelentkezés' gombra a hivatalos HKDFINANC weboldal jobb felső sarkában a bejelentkezési oldalra. <p></p><p></p><p></p><p></p><p></p><p></p>.",
                tit4Tit4: "Mik a platform riasztások módjai?",
                tit4Cont4: "<p>HKDFINANC Riasztások e-mailben, SMS-ben, platform push. </p>",
                tit5Tit1: "Milyen spreadeket kínál a HKDFINANC?",
                tit5Cont1: "<p>Alacsony árkülönbséget számítunk fel, és nem számítunk fel jutalékot. Az árkülönbség változik, és a felszámított tényleges árkülönbség attól függ, hogy milyen fajtát kereskedik, amely valós időben jelenik meg a kereskedési platformon</ P>",
                tit5Tit2: "Hogyan hozzon létre piaci megbízást?",
                tit5Cont2: "<p>Kattintson a [Kereskedelem] (Kereskedelem) megjeleníti a platform nyújthat kereskedelmi minden termék, akkor adja meg a jobb oldalon a beviteli mező, adja meg a kódot vagy a faj nevét a kereséshez, válassza ki a terméket kattintson a [Vásárlás / Eladás] SELL / BUY) felugrik a kereskedelmi ablak, a kereskedelmi ablakban láthatja az aktuális ár és a becsült árrés szükséges. Kézzel beállíthatja a nyitott kereskedések számát, és beállíthatja a stop-loss és take-profit értékeket a kockázat ellenőrzéséhez</p>.",
                tit5Tit3: "Hogyan hozzon létre függőben lévő megbízást?",
                tit5Cont3: "<p>Válasszon ki egy terméket a [Kereskedés] menüpontban, és kattintson a [Vásárlás/eladás] (SELL/BUY) gombra a kereskedési ablak megjelenítéséhez.",
                tit5Tit4: "Hogyan kell lezárni egy pozíciót?",
                tit5Cont4: "<p>A pozíció bezárásához kattintson a pozícióban a platformon a Megnyitás gombra, válassza ki a bezárni kívánt pozíciót, majd kattintson a jobb oldalon a Bezárás gombra. </p",
                tit5Tit5: "Hogyan módosíthatja vagy törölheti a függőben lévő megbízásokat?",
                tit5Cont5: "<p>A függőben lévő megbízás módosításához vagy törléséhez kattintson a pozícióban a platformon a Megbízások menüpontra, majd kattintson arra a függőben lévő megbízásra, amelyet módosítani vagy törölni szeretne</p>.",
                tit6Tit1: "A kereskedési platform használatáért jutalékot kell fizetnie??",
                tit6Cont1: "<p>A HKDFINANC egy teljesen jutalékmentes kereskedési platform. Alacsony spreadeket számítunk fel, és az Ön egyedi kereskedési helyzetétől függően a platformon további díjak, például SWAP-ok merülhetnek fel. </p>",
                tit6Tit2: "Vannak-e díjak a letétbe helyezésért?",
                tit6Cont2: "<p>A betétekkel kapcsolatban nem számítunk fel díjat ügyfeleinknek, de az Ön fizetési vagy tranzitbankja jutalékot számíthat fel. Javasoljuk, hogy ellenőrizze a bankjánál, hogy felszámítanak-e Önnek bármilyen díjat. </p>",
                tit6Tit3: "Vannak-e díjak a kifizetésekért?",
                tit6Cont3: "<p>A HKDFINANC nem számít fel az ügyfeleknek semmilyen díjat a kifizetésekkel kapcsolatban, kivéve, ha a minimálisan előírt összegnél kevesebbet vesz fel, vagy ha túllépi a havi ingyenes kifizetések maximális számát. További részletekért kérjük, olvassa el a visszavonási szabályzatunkat. </p",
                tit7Tit1: "A HKDFINANC számlámon elhelyezett pénzeszközök felhasználhatók-e más célokra?",
                tit7Cont1: "<p>Nem. A lakossági ügyfelek betéteit szükség esetén a szabályozói követelményekkel összhangban külön letéti számlákon tartják. Az ügyfélpénzszámlára történő befizetések, pénzeszközök kivétele vagy kifizetések teljesítése során a HKDFINANC szigorúan betartja az ügyfélpénzre vonatkozó, a szabályozási keretben meghatározott szabályokat. </p>",
                tit7Tit2: "Ha a HKDFINANC bezár vagy eladósodik, az én pénzeszközeimet is érinti?",
                tit7Cont2: "<p>A kiskereskedelmi ügyfelek betéteit a szabályozói jogszabályoknak megfelelően szükség esetén letéti számlákon különítik el. A HKDFINANC csődje, az engedély visszavonása vagy a vállalkozás folytatásának meghiúsulása esetén az ügyfelek pénzeszközei elkülönítve maradnak, és az irányadó jogszabályok szerinti ügyfélpénz-szabályozás hatálya alá tartoznak</p>.",
                tit7Tit3: "A HKDFINANC betartja a törvényt?",
                tit7Cont3: "<p>A Társaság teljes mértékben megfelel a vonatkozó társasági és pénzügyi szabályozó jogszabályoknak, valamint az illetékes szabályozó szervek által engedélyezett és szigorúan szabályozott pénzügyi szolgáltató. A Társaság a szabályozás szigorú követelményeinek megfelelően átfogó és megfelelő védelmet nyújt az ügyfelek pénzeszközeinek biztonsága érdekében. </p",
                tit7Tit4: "Biztonságban vannak-e az ügyfél személyes adatai?",
                tit7Cont4: "<p>HKDFINANC szigorúan betartja a személyes adatok védelméről szóló rendelet rendelkezéseit, az ügyfelek személyes adatai soha nem szivárognak ki. A Társaság biztosítja, hogy munkatársai betartják a törvényben meghatározott szigorú biztonsági és titoktartási előírásokat, és nagy figyelmet fordít az ügyfelek adatainak bizalmas kezelésére és felhasználására. További információért kérjük, olvassa el a Társaság jogi közzétételi dokumentumát. </p>",
            },
            tab4Row: {
                span: "Q Ellenőrzési módszerek",
                p1: "A HKDFINANC a hatóságok által engedélyezett és szabályozott fintech vállalat, amely arra összpontosít, hogy a befektetőknek egyszerű és kényelmes kereskedési élményt nyújtson.",
                p2: "Az innovatív HKDFINANC diverzifikált kereskedési platformja többször is elnyerte az olyan díjakat, mint a legjobb mobil kereskedési platform és a leginnovatívabb brókercég.",
                p3: "A HKDFINANC platformon keresztül a nemzetközi pénzügyi piacok szélesebb körébe fektethet be és kereskedhet, amely részvényeket, indexeket, árukat, devizákat és több száz más népszerű fajtát foglal magában. A HKDFINANC nem csak a felhasználóbarát mobil és webes kereskedési platformokra korlátozódik, hanem versenyképes kereskedési költségeket, gyors kereskedési végrehajtást, kiváló ügyfélszolgálatot és rengeteg információs támogatást is biztosít Önnek, hogy segítsen megragadni az időszerű befektetési és kereskedési lehetőségeket.",
                p4: "A HKDFINANC-ot szigorúan szabályozzák az ágazati hatóságok és több szervezet.",
                p5: "A HKDFINANC Holdingot a Kajmán-szigeteki Monetáris Hatóság (CIMA) engedélyezte és szabályozza a 1612446 számú SIB-engedély alapján. A Kajmán-szigeteki engedélyre vonatkozó további részletekért kérjük, látogasson el a CIMA hivatalos weboldalára: www.cima.ky (",
                p6: ") A HKDFINANCGlobal az Ausztrál Értékpapír- és Befektetési Bizottság (ASIC) által engedélyezett pénzügyi szolgáltatási engedéllyel (AFSL 398528) rendelkezik. Az ausztrál engedélyekkel kapcsolatos további részletekért kérjük, látogasson el az ASIC hivatalos weboldalára: www.asic.gov.au.(",
                p7: ") A HKDFINANC International a mauritiusi Pénzügyi Szolgáltatások Bizottsága (FSC) által engedélyezett és szabályozott, GB20025791 számú engedéllyel. A mauritiusi engedéllyel kapcsolatos részletekért kérjük, látogasson el az FSC hivatalos weboldalára: www.fscmauritius.org.(",
                p8: ")A HKDFINANC minden üzleti tevékenységét szigorú felügyelet mellett és az összes szabályozásnak megfelelően végzi.",
                p9: "Háttér",
                p10: "A HKDFINANC egy tapasztalt és hozzáértő, a pénzügyi kereskedési és fintech iparágakkal kapcsolatos tapasztalatokkal és ismeretekkel rendelkező csapatból áll. A HKDFINANC filozófiája a kereskedés egyszerűbbé és felhasználóbarátabbá tétele.",
                p11: "A HKDFINANC filozófiája, hogy a kereskedést egyszerűbbé és barátságosabbá tegye. Holisztikus stratégiánk és üzleti modellünk lehetővé teszi számunkra, hogy globális rálátással rendelkezzünk az iparág fejlődésére és a piaci keresletre, így célzottabban tudjuk elosztani az erőforrásokat, folyamatosan innováljuk a technológiát és optimalizáljuk a hatékonyságot, hogy ügyfeleinknek kényelmesebb és felhasználóbarátabb kereskedési élményt nyújtsunk.",
                p12: "Miért válassza a",
                p13: "Alacsony küszöbértékű kereskedelmi feltételek",
                p14: "Egyszerű és intuitív kereskedési platform",
                p15: "Versenyképes tranzakciós költségek",
                p16: "Az ágazati hatóságok által szabályozott",
                p17: "Magas szintű online támogatás",
                p18: "Negatív egyenleg védelme",
                p19: "Díjak és kitüntetések",
                p20: "A HKDFINANC számára megtiszteltetés, hogy a World Finance Awards [Legjobb Multi-Asset Broker], a Global BrandsMagazine [Legjobb mobil kereskedési platform az ázsiai és csendes-óceáni térségben] [Leggyorsabban növekvő FinTech Broker a világon] és számos más díjjal tüntették ki, ami a csapat elkötelezettségét és kemény munkáját bizonyítja.",
                p21: "Legjobb Multi-Asset Bróker",
                p22: "Működési koncepciók",
                p23: "Kötelezettségek",
                p24: "Minden kereskedés kockázattal jár, és a HKDFINANC további lépéseket tett annak érdekében, hogy felelős kereskedési környezetet biztosítson ügyfelei számára. A HKDFINANC-ot az iparági hatóságok, több szervezet szabályozza, és magas szintű biztonság és stabilitás mellett működik. Platformunk továbbra is számos felelős kereskedési eszközt kínál, beleértve a kockázatkezelést is.",
                p25: "Egyszerű, de szép",
                p26: "Az egyszerűség a szépség, a részletesség a kiválóság, és a HKDFINANC-nál minden részletben a könnyű használatra és a felhasználói élményre törekszünk. Továbbra is optimalizáljuk platformunkat és szolgáltatásainkat abban a reményben, hogy a kezdőtől a tapasztalt befektetőig mindenki ki tudja majd használni a HKDFINANC platformon keresztül elérhető kereskedési lehetőségeket.",
                p27: "Nyílt (nem titkos)",
                p28: "Annak érdekében, hogy ügyfeleink teljes mértékben kihasználják a tisztességes és hatékony kereskedési környezetet, a HKDFINANC szigorú és átlátható önszabályozó rendszert követ, hogy átfogó és átlátható platform termékinformációkat, árképzési környezetet és adatokat biztosítson, minden díj világos és nyílt annak biztosítása érdekében, hogy az egész szolgáltatási folyamat teljesen őszinte és átlátható legyen.",
                p29: "Új utakat nyitni",
                p30: "A HKDFINANC szilárdan hisz abban, hogy a technológiai fejlődés, a pénzügyi innováció és a szélesebb körű szolgáltatások a növekedés motorjai, és hogy továbbra is képesek vagyunk értéket teremteni és megosztani. Ennek érdekében ösztönözzük a nyitott gondolkodást, és továbbra is ösztönözzük az innovációt, azzal a céllal, hogy újító és vezető szerepet töltsünk be a pénzügyi technológia területén.",
                p31: "Célok és jövőkép",
                p32: "Több kereskedni vágyó ember számára olyan tisztességes, hatékony és átlátható kereskedési környezet biztosítása, amelyet nem korlátoz a tér, az idő és a piac, valamint az iparág vezető szerepe a pénzügyi és technológiai innovációban.",
            },
        },
        language: {
            tit: "Nyelv kiválasztása",
        },
        market: {
            tit: "Ketyereszimbólum",
            tit1: "Ár kijelző",
            tit2: "Emelkedő vagy csökkenő színek",
            buy_price: "Ajánlati ár",
            buy_price_tip: "K-vonal húzása az ajánlati áron",
            sell_price: "Eladási árfolyam",
            sell_price_tip: "K-vonalak kirajzolása eladási áron",
            color1: "Zöld emelkedés és piros zuhanás",
            color2: "Piros emelkedés és zöld esés",
        },
        notice: {
            tit: "Értesítési beállítások",
            tit1: "Nyitott értesítési csatornák",
            p1: "Kérjük, nyissa meg/igazolja az alábbi értesítési csatornákat, hogy értesüljön a fontos hírekről!",
            message: "Szöveges üzenetküldés",
            push: "Push",
            verify: "Érvényesíteni ",
            verified: "Validált",
            email: "E-mail cím",
            type1: "Piactér",
            tit2: "Értesítés a marketingtevékenységekről",
            desc2: "A platform különleges ajánlati információi, üzemeltetési tevékenységek és egyéb hírek",
            tit3: "Használati utasítás",
            desc3: "Gazdag és speciális tudásanyag",
            tit4: "Információs riasztások",
            desc4: "Legfrissebb pénzügyi adatok és hírek",
            tit5: "Intelligens ingadozási riasztás",
            desc5: "Tartalmazza a fontos fajták áringadozását, segít a lehetőségek gyors megragadásában.",
            type2: "Jelentéstípus",
            tit6: "Számlajelentések",
            desc6: "A számlajelentéseket havonta küldjük a következő módon",
            cycle: "Ciklikusság",
            month: "Minden hónapban",
        },
        protocol: {
            tit: "Nyilatkozatok és megállapodások",
            label1: "Adatvédelmi politika",
            label2: "Termék közzétételi nyilatkozat",
            label3: "Ügyfélmegállapodás",
            label4: "Kockázati közzétételi nyilatkozat",
        },
        setting: {
            tit: "Felállítása",
            label1: "Biztonsági beállítások",
            label2: "Ketyereszimbólum",
            label3: "Tranzakciós beállítások",
            label4: "Többnyelvűség",
            label5: "Téma mód",
            label6: "Értesítési beállítások",
            label7: "Kereskedelmi útmutató",
            label8: "Nyilatkozatok és megállapodások",
            label9: "A gyorsítótár törlése",
            label10: "Verziószám",
            logout: "Kijelentkezés",
        },
        theme: {
            tit: "Téma mód",
            theme1: "Világos színű üzemmód",
            theme2: "Sötét üzemmód",
        },
        trade: {
            tit: "Tranzakciós beállítások",
            onclick_close: "Pozíció bezárása egy kattintással",
        }
    },
    trade: {
        fliter_type1: "Önválasztás",
        fliter_type2: "Piac ",
        place_search: "A kereséshez adja meg a fajtanevet/kódot",
        fliter_type3: "Divatban",
        fliter_type4: "Deviza",
        fliter_type5: "Áru",
        fliter_type6: "Készlet (piac)",
        fliter_type7: "Exponents",
        fliter_type8: "Titkosított",
        tip1: "Teljes a személyazonosság ellenőrzése lehet elhelyezni a tranzakció, befektetés a globális",
        sell: "Reach (ár egy árverésen)",
        buy: "Buy-in",
        add_optional: "Hozzáadás opcionális",
        optional_recommend: "Választható ajánlások",
        change_batch: "Batch",
        edit_tit: "A szerkesztő választása",
        breed: "Választék",
        topping: "Feltöltés",
        sort: "Rendezze sorrendbe",
        all: "Egyhangú",
    },
    table: {
        label_breed: "Választék",
        label_direction: "Orientációk",
        label_price: "Árak",
        label_operate: "Fúrótorony",
        empty_tip1: "Nincsenek riasztások",
        empty_tip2: "Nincs nyilvántartás a betétekről",
        empty_tip3: "Nincs visszavonási nyilvántartás",
        label_amount: "Pénzösszeg",
        label_type: "Tipológia",
        label_time: "Times",
        label_name: "Név",
        label_email: "E-mail cím",
        label_address: "Lakóhely szerinti ország",
        label_account: "Kereskedelmi számlaszám",
        label_currency: "Alapvaluta",
        label_company: "Folyószámla Társaság",
    },
    form: {
        to: "Amíg",
        start_time: "Indulási idő",
        end_time: "Befejezési idő",
        week: "Az elmúlt héten",
        month: "Körülbelül egy hónap",
        threeMonth: "Az elmúlt három hónap",
        sixMonth: "Az elmúlt hat hónap",
        label_feedback: "Kérdések és ajánlások",
        place_feedback: "Kérjük, írja be kérdését vagy javaslatát",
        label_img: "Fénykép",
        label_img_tip: "Nem kötelező, küldjön képernyőképet a problémáról",
        feedback_tip: "Ha sürgős kérdése van, kérjük, forduljon a következő címre",
        online: "Online ügyfélszolgálat",
        label_cardNumber: "Bizonyítvány száma",
        label_realName: "Teljes név",
        place_realName: "Kérjük, adja meg a nevét",
        message_realName: "Kérjük, adja meg a teljes nevét",
        label_firstName: "Vezetéknév",
        place_firstName: "Kérjük, adja meg a vezetéknevét",
        message_firstName: "Kérjük, adja meg a vezetéknevét",
        label_lastName: "Név (egy dolog neve)",
        place_lastName: "Kérjük, adja meg a középső nevét",
        message_lastName: "Kérjük, adjon meg egy nevet",
        label_birthday: "Születési dátum",
        place_birthday: "Kérjük, adja meg születési dátumát",
        message_birthday: "Kérjük, adja meg születési dátumát",
        label_idType: "A dokumentum típusa",
        tip_cardMain: "Készítsen vagy töltsön fel egy fényképet a személyi igazolványa előlapjáról.",
        tip_cardBack: "Készítsen vagy töltsön fel egy fényképet a személyi igazolványa hátoldaláról.",
        tip_cardMain1: "Készítsen vagy töltsön fel egy fényképet a vezetői engedélye előlapjáról.",
        tip_cardBack1: "Készítsen vagy töltsön fel egy fényképet a vezetői engedélye hátuljáról.",
        tip_cardMain2: "Útlevélképek készítése vagy feltöltése",
        tip_cardBack2: "Útlevélképek készítése vagy feltöltése",
        label_password: "Kriptográfiai",
        place_password: "Kérjük, adja meg jelszavát",
        message_password: "Kérjük, adja meg jelszavát",
        label_crmpsd: "Jelszó megerősítése",
        place_crmpsd: "Kérjük, adja meg újra a jelszavát",
        message_crmpsd: "Kérjük, adja meg újra a jelszavát",
        label_email: "E-mail cím",
        place_email: "E-mail cím",
        message_email: "Kérjük, adja meg az e-mail címét",
        label_captcha: "CAPTCHA",
        place_captcha: "Kérjük, adja meg az ellenőrző kódot",
        message_captcha: "Kérjük, adja meg az ellenőrző kódot",
        get_captcha: "CAPTCHA beszerzése",
        label_phone: "TElefonok",
        place_phone: "Kérjük, adja meg telefonszámát",
        message_phone: "Kérjük, adja meg telefonszámát",
        labelAmount: "Pénzösszeg",
        placeAmount: "Kérjük, adja meg az összeget",
        messageAmount: "Kérjük, adja meg az összeget",
        labelWalletName: "Pénztárca neve",
        messageWalletName: "Kérjük, adja meg a pénztárca nevét",
        placeWalletName: "Kérjük, adja meg a pénztárca nevét",
        labelWalletAddress: "Pénztárca cím",
        messageWalletAddress: "Kérjük, adja meg a pénztárca címét",
        placeWalletAddress: "Kérjük, adja meg a pénztárca címét",
        labelWithdrawAccount: "Kifizetési számla",
        placeWithdrawAccount: "Kifizetési számla",
        messageWithdrawAccount: "Kifizetési számla",
		placeTimeLimit: "Kérjük, adjon meg egy időkorlátot",
		messageTimeLimit: "Kérjük, adjon meg egy időkorlátot",
    },
	city: {
		albania: "Albánia",
		algeria: "Algéria",
		argentina: "Argentína",
		armenia: "Örményország",
		australia: "Ausztrália",
		pakistan: "Pakisztán",
		austria: "Osztrák",
		bahrain: "Bahrein",
		belgium: "Belgium",
		bosnia_and_Herzegovina: "Bosznia-Hercegovina rövidítése",
		brazil: "Brazil",
		brunei: "Brunei Darussalam, független szultánság Borneó északnyugati részén.",
		bulgaria: "Bulgária",
		cambodia: "Kambodzsai",
		canada: "Kanadai",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Columbia ",
		costa_Rica: "Costa Rica",
		croatia: "Horvát Köztársaság (1991-)",
		cyprus: "Ciprus",
		czech_Republic: "Cseh Köztársaság (1993-tól)",
		denmark: "Dánia",
		dominican_Republic: "Dominikai Köztársaság",
		egypt: "Egyiptom.",
		estonia: "Észtország",
		ethiopia: "Etiópia",
		finland: "Suomi",
		france: "Francia",
		georgia: "Grúzia (ország)",
		germany: "Német",
		ghana: "Ghána, Nyugat-Afrika",
		greece: "Görögország",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Kína",
		hungary: "Magyarország",
		iceland: "Izlandi",
		ireland: "Ír",
		italy: "Olaszország",
		india: "India",
		indonesia: "Indonézia",
		israel: "Izraeli",
		iran: "Iráni",
		iraq: "Iraki",
		japan: "Japán",
		kazakstan: "Kazahsztán",
		kenya: "Kenya",
		korea: "Dél-Korea (Koreai Köztársaság)",
		kuwait: "Kuvaiti",
		kyrgyzstan: "Kirgizisztán",
		laos: "Laosz",
		latvia: "Lettország",
		lithuania: "A litván köztársaság, volt balti szovjet köztársaság",
		luxembourg: "Luxemburg",
		macao_China: "Makaó, Kína",
		macedonia: "Macedóniai",
		malaysia: "Malajzia",
		malta: "Máltai",
		mexico: "Mexikó",
		moldova: "Moldovai Köztársaság, volt szovjet köztársaság a romániai határon.",
		monaco: "Monaco",
		mongolia: "Mongólia",
		montenegro: "Montenegró",
		morocco: "Marokkó",
		myanmar: "Mianmar (vagy Burma)",
		netherlands: "Hollandia",
		new_Zealand: "Új-Zéland",
		nepal: "Nepáli",
		nigeria: "Nigéria, Nyugat-Afrika",
		norway: "Norvégia",
		oman: "Ománi",
		palestine: "Palesztina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Perui",
		philippines: "Fülöp-szigeteki",
		poland: "Lengyel",
		portugal: "Portugália",
		puerto_Rico: "Puerto Rico, az Egyesült Államok nem bejegyzett, önkormányzattal rendelkező területe.",
		qatar: "Doha",
		romania: "Románia",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad és Tobago",
		rwanda: "Ruandai",
		saudi_Arabia: "Szaúd-Arábia",
		serbia: "Szerbia",
		singapore: "Szingapúri",
		slovakia: "Szlovákia",
		slovenia: "Szlovénia",
		south_Africa: "Dél-Afrika",
		spain: "Spanyol",
		sri_Lanka: "(Korábban) Ceylon",
		sweden: "Svédország",
		switzerland: "Svájc",
		taiwan_China: "Kína-Tajvan",
		tajikistan: "Tádzsikisztán",
		tanzania: "Tanzánia",
		thailand: "Thaiföld",
		turkey: "Isztambul",
		turkmenistan: "Türkmenisztán",
		ukraine: "Fehéroroszország",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "Nagy-Britannia és Észak-Írország Egyesült Királysága",
		united_States: "Amerikai Egyesült Államok",
		uzbekistan: "Üzbegisztán",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afganisztán",
		angola: "Angola",
		azerbaijan: "Azerbajdzsán, volt szovjet köztársaság és régió Irán északnyugati részén, a Kaukázusban.",
		bangladesh: "Banglades (korábban Kelet-Pakisztán)",
		belarus: "Fehéroroszország",
		belize: "Belizei",
		benin: "Benin",
		bhutan: "Bhután",
		bolivia: "Bolívia",
		botswana: "Botswana",
		british_Virgin_Islands: "Brit Virgin-szigetek",
		burkina_Faso: "Burkina Faso, Nyugat-Afrika",
		burundi: "Burundi",
		cape_Verde: "Zöld-foki-szigetek",
		cayman_Islands: "Kajmán-szigetek",
		central_African_Republic: "Közép-afrikai Köztársaság",
		chad: "Csádi",
		comoros: "Comore-szigetek",
		the_Republic_of_the_Congo: "Kongó (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongó (Demokratikus Köztársaság)",
		djibouti: "Dzsibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Egyenlítői-Guinea",
		eritrea: "Eritrea",
		fiji: "Fidzsi-szigetek",
		gabon: "Gaboni",
		gambia: "Gambia",
		greenland: "Grönland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti, a karibi Hispaniola sziget nyugati harmada.",
		isle_of_Man: "Mann-sziget",
		cote_d_Ivoire: "Elefántcsontpart vagy Elefántcsontpart Nyugat-Afrikában",
		jamaica: "Jamaikai",
		jordan: "Jordan",
		lebanon: "Ellenségeskedések",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagaszkár",
		malawi: "Malawi",
		maldives: "A Maldív-szigetek",
		mali: "Mali, Nyugat-Afrika",
		mauritania: "Mauritánia",
		mauritius: "Mauritius",
		mozambique: "Mozambik",
		namibia: "Namíbia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger (afrikai állam)",
		north_Korea: "Koreai Csoszun vagy Csoszun dinasztia 1392-1910",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Szenegál",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadishu",
		sudan: "Szultán ",
		suriname: "Suriname",
		eswatini: "Szváziföld",
		syria: "Szíria",
		togo: "Togo, Nyugat-Afrika",
		tonga: "Tonga, dél-csendes-óceáni szigetvilág királysága",
		tunisia: "Tunisz, Tunézia fővárosa",
		united_States_Virgin_Islands: "Egyesült Államok Virgin-szigetek (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikán",
		yemen: "Jemeni",
		yugoslavia: "Jugoszlávia, 1943-1992",
		zambia: "Seychelle-szigetek",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
