export default {
    crypto: {
        title: {
            0: "Buy Crypto",
            1: "Buy Crypto",
            2: "C2C",
        },
        list: {
            0: "C2C Trading",
            1: "Buy",
            2: "Sell",
            3: "Advertisers (Completion rate)",
            4: "Trades",
            5: "Price",
            6: "Available",
            7: "Limit",
            8: "Payment Method",
            9: "Trade",
            10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
        },
        buy: {
            0: "Buy Crypto",
            1: "Sell Crypto",
            2: "History",
            3: "Support",
            4: "Pay",
            5: "Receive",
            6: "Select payment method",
            7: "Est. arrival time",
            8: "FEE",
            9: "Order Details",
            10: "Payment method",
            11: "Learn more",
            12: "Price",
            13: "Total (fee included)",
            14: "Disclaimer",
            15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
            16: "I have read and agree to the Terms of Use",
            17: "You will get",
            18: "Submit",
            19: "Please enter the amount",
            20: "Insufficient account balance",
            21: "Available",
            22: "Spot Account Availiable",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        }
    },
    common: {
        view: "Checka ut",
        confirm: "Definiera",
        cancel: "Annulleringar",
        deposit: "Insättning",
        withdraw: "Ta ut pengar",
        noData: "Inga data tillgängliga",
        submit: "Inlämnad,",
        modify: "Ändringar",
        hand: "Hand",
        confirm_verify: "Validera",
        next: "Nästa steg",
        complete: "Uppfylla",
        delete: "Borttagning",
		countryCode: "Kod för land/område",
    },
    addnew: {
        buyUp: "Stigande",
        buyDown: "Köpa och sälja",
        confirmOrder: "Bekräfta order",
        name: "Namn",
        fee: "Hanteringsavgift",
        orderCycle: "Välj tid",
        loan: "Lån",
        loanTit: "Studielån",
        loanHostory: "Lånehandlingar",
        labelProductTitle: "Produktnamn",
        immediateRepayment: "Omedelbar återbetalning",
        loadAmount: "Förväntat lånebelopp",
        RepaymentCycle: "Cykel för återbetalning av lån",
        day: "Dag",
        dailyRate: "Daglig ränta",
        repaymentMethod: "Återbetalningsmetod",
        lendingCompany: "Utlåningsföretag",
        loanTip: "Kreditlån (se till att bilderna är väl synliga)",
        loanTip1: "Ladda upp fastighetscertifikat",
        loanTip2: "Bevis på inkomst (anställningsförhållande)",
        loanTip3: "Uppgifter om kontoutdrag",
        loanTip4: "Ladda upp det främre fotot av ditt ID-kort",
        interest: "Saké",
        repaid: "Betalat tillbaka",
        unpaid: "Utebliven återbetalning",
        tab1: "Mynt",
        tab2: "Avtalsenlig",
        tab3: "Alternativ",
        available: "Tillgänglig kredit",
        pending: "Låsning",
        equivalent: "Belopp till",
        fundTransfer: "Överföring av medel",
        flashExchange: "Kontanter",
        select_breed: "Välj valuta",
        from: "Från",
        to: "Till",
        transferQuantity: "Antal överföringar",
        repaymentMethod1:"Förfaller till betalning i en delbetalning"
    },
    params: {
        product_foreign: "Utländsk valuta",
        product_shop: "Varor",
        product_number: "Exponenter",
        product_stock: "Aktier",
        product_coin: "Krypterad",
        billTypes: [
            {v: 101, name: 'Ladda (pengar på ett kort)'},
            {v: 102, name: 'Insättning'},
            {v: 103, name: 'Frysning'},
            {v: 104, name: 'Frysa'},
            {v: 105, name: 'Insättning'},
            {v: 106, name: 'Insättning'},
            {v: 201, name: 'Frysning av uttag'},
            {v: 202, name: 'Ta ut medel'},
            {v: 203, name: 'Tillbakadragande framgångsrikt'},
            {v: 204, name: 'Underlåta att ta ut medel'},
            {v: 205, name: 'Uttagsavgift'},
            {v: 206, name: 'Överföra ut'},
            {v: 207, name: 'Skifta till'},
            {v: 208, name: 'Överföring av mynt'},
            {v: 209, name: 'Överföring av mynt'},
            {v: 301, name: 'Avgift för hantering av kontrakt'},
            {v: 302, name: 'Avtalsenliga intäkter'},
            {v: 303, name: 'Kontraktsförlust'},
            {v: 304, name: 'Kontraktsmarginal'},
            {v: 305, name: 'Kontraktsmarginal Avkastning'},
            {v: 311, name: 'Köp av optioner'},
            {v: 312, name: 'Vinst på option'},
            {v: 313, name: 'Avkastning på optioner'},
            {v: 314, name: 'Avgift för hantering av optioner'},
            {v: 315, name: 'Mynt Köp Frys'},
            {v: 316, name: 'Avdrag för myntköp'},
            {v: 317, name: 'Myntköp Returer'},
            {v: 318, name: 'Inköp av mynt till konto'},
            {v: 319, name: 'Inköp av mynt till konto'},
            {v: 320, name: 'Stopp för försäljning av mynt'},
            {v: 321, name: 'Avdrag för försäljning av mynt'},
            {v: 322, name: 'Mynt säljs returneras'},
            {v: 323, name: 'Mynt säljs till konto'},
            {v: 324, name: 'Mynt säljs till konto'},
            {v: 325, name: 'Avgift för hantering av mynt'},
            {v: 401, name: 'Gruvarbetare ansluter sig'},
            {v: 402, name: 'Miner Returnerar'},
            {v: 403, name: 'Intäkter från gruvmaskiner'},
            {v: 404, name: 'Utgång för gruvmaskin'},
            {v: 405, name: 'Avgift för utträde ur gruvdrift'},
            {v: 411, name: 'Intäkter från upplåning'},
            {v: 412, name: 'Låneavgift'},
            {v: 413, name: 'Lyckad återbetalning'},
        ],
    },

    footer: {
        nav1: "Fig. början",
        nav2: "Affärer",
        nav3: "Positioner",
        nav4: "Min",
        kline_set1: "Tickersymbol",
        kline_set2: "Inställningar för transaktioner",
    },
    login: {
        login: "Anmäla sig",
        register: "Inskrivning",
        type1: "Mobiltelefonnummer",
        type2: "Inkorg",
        login_btn: "Säker inloggning",
        register_btn: "Registrera dig nu",
        psd_login: "Lösenord inloggning",
        verify_login: "CAPTCHA Inloggning",
        forget: "Glömt lösenordet??",
        no_account: "Inget konto？",
        have_account: "Befintliga konton",
    },
    home: {
        home: {
            nav1: "Rangordning av upp- och nedgångar",
            nav2: "Snabbstart",
            nav3: "Om oss",
            nav4: "Belöningar för inbjudningar",
            most_follow: "Aktualitet",
            news_type1: "Vara taktfull",
            news_type2: "Offentlig information",
            news_type3: "Finansiell kalender",
            news_tab1: "Full",
            news_tab2: "Utländsk valuta",
            news_tab3: "Aktie",
            news_tab4: "Varor",
            news_tab5: "Exponenter",
            news_tab6: "Siffror",
            news_tab7: "Evenemang",
            news_tab8: "Semester",
            label_previous: "Tidigare värde",
            label_predictive: "Prognostiserat värde",
            label_announced: "Publicerat värde",
        },
        kilne: {
            label_bp: "Gårdagens skörd",
            label_sp: "Modern tid",
            label_height: "Högsta",
            label_low: "Lägsta",
            tip: "Hej, jag är HKDFINANCGPT Variety Assistant.",
            row1_tit: "Vara taktfull",
            row1_rinei: "Inom dagen",
            row1_short: "Kortfristig",
            row1_middle: "Halvtid",
            row1_time: "Tid för frisläppande",
            row1_tit2: "Handelsstrategi",
            data_sources: "Datakällor",
            row2_tit1: "Siffror",
            row2_tit2: "Handelshumör",
            row2_label_sell: "Säljare",
            row2_label_buy: "Köpare",
            tip2: "Endast i informationssyfte, inte en ståndpunkt från divisionen",
            tip3: "Uppdateras var 15:e minut",
            row2_tit3: "Prisvariationer",
            minute: "Minuter",
            today: "För närvarande",
            right_tit7: "Prisändringsintervall",
            now_price: "Aktuellt pris",
            right_low_price: "Lägsta pris",
            right_height_price: "Högsta pris",
            details: "Detaljer",
            right_label1: "Antal enskilda transaktioner",
            right_label2: "Maximal hävstångseffekt",
            right_label3: "Kontraktets storlek",
            right_label4: "Maximalt totalt antal positioner",
            right_label5: "Rörlig spread",
            right_label6: "Laddning över natten",
            hand: "Hand",
            right_sell: "Reach (ett pris i en auktion)",
            right_buy: "Inköp",
            right_collection_time: "Tid för insamling",
            right_tip3: "Samlas från den totala transaktionsstorleken",
            right_label9: "Öppettider",
            right_label10: "Den aktuella tidsluckan",
            right_label11: "Nästa intervall",
        },
        placeorder: {
            direction_buyPrice: "Anbudspris",
            direction_sellPrice: "Säljkurs",
            direction_sell: "Reach (ett pris i en auktion)",
            direction_buy: "Inköp",
            market_price: "Marknadsprislista",
            check_price: "Väntande order",
            sell_tip: "Sälj när priset uppfyller villkoren",
            buy_tip: "Köp när priset uppfyller villkoren",
            trade_number: "Antal transaktioner",
            hand: "Hand",
            select_lever: "Val av hävstångseffekt",
            right_tip: "Deposition krävs",
            balance: "Tillgängligt saldo",
            label_stop_win_price: "Göra vinst",
            label_stop_lose_price: "Stop-loss",
            expected_profit_loss: "Beräknad vinst och förlust",
            trailing_stop: "Efterföljande stopp",
        },
        ranking: {
            tit: "Dagens största vinnare och förlorare",
            p: "Fånga upp hot spots och ta vara på möjligheterna",
            renew: "Uppdaterad i",
            growth_chart: "Tillväxtdiagram",
            drop_list: "Lista över förlorare",
            show_closed_market: "Framhävande av Hughs varumärke",
            filter1: "Full",
            filter2: "Utländsk valuta",
            filter3: "Varor",
            filter4: "Aktie",
            filter5: "Exponenter",
            filter6: "Krypterad",
            td1: "Rankning",
            td2: "Tendenser",
            td3: "Dagens vinst/försäljningspris",
        },
        remind: {
            now_sell_price: "Aktuellt begärt pris",
            direction_buyPrice: "Anbudspris",
            direction_sellPrice: "Säljkurs",
            direction_sell: "Reach (ett pris i en auktion)",
            direction_buy: "Inköp",
            equal: "(Koll.) misslyckas (en student)",
            p: "Meddela mig när följande priser uppnås",
            btn1: "Ange påminnelser",
            list_tit: "Lista över påminnelser",
        },
    },
    my: {
        report: {
            tit: "Konto rapporter",
            tab1: "Rapportering",
            tab2: "Dagligt uttalande",
            tab3: "Månatligt kontoutdrag",
            history_tit: "Rapport över transaktionshistorik",
            account_tit: "Konto rapporter",
            filter: "Screening",
            tip1: "Välj lämpligt datumintervall för att exportera din transaktionshistorikrapport.。",
            tip2: "Anpassad tid",
            email_send: "Skicka till e-post",
        },
        accountMsg: {
            tit: "Kontoinformation",
            label1: "Personlig information",
        },
        bill: {
            tit: "Uppgifter om flödet av medel",
            all: "Full",
            time: "Tider",
        },
        feedback: {
            tit: "Funktionella rekommendationer",
        },
        identity: {
            tit: "Autentisering",
            type1: "Identitetskort",
            type2: "Körkort",
            type3: "Pass",
            tip: "Jag bekräftar att all information och alla dokument som tillhandahålls är fullständiga, sanna och korrekta",
        },
        my: {
            is: "Efteråt",
            no: "Ej verifierad",
            verify: "Validerad",
            id_verify: "Autentisering",
            verify_tip: "Slutför identitetsverifieringen för insättning och handel",
            no_verify: "Verifiera nu",
            balance: "Tillgängligt saldo",
            profit_loss: "Vaxande och vaggande",
            marginLevel: "Marginalnivå",
            btn1: "Byt till verkligt konto",
            deposit_withdraw_record: "Registrering av insättningar och uttag",
            capital_flow_details: "Uppgifter om flödet av medel",
            welfare_center: "Välfärdscenter",
            tip1: "Du har",
            tip2: "Penna belöningar att göra anspråk på",
            feedback: "Funktionella rekommendationer",
            report: "Konto rapporter",
            setting: "Ställa in",
        },
        news: {
            tit: "Anmälningar",
        },
        record: {
            tit: "Registrering av insättningar och uttag",
            cycle: "Cykliskhet",
        },
        share: {
            tit: "Bjud in vänner",
            invite_success: "Lyckas bjuda in vänner",
            tip1: "Kontant incitament",
            tip2: "Bjud in dina vänner och få upp till",
            tip3: "Bjuda in vänner att ta emot kontanter",
        },
        welfare: {
            tit: "Välfärdscenter",
            tab1: "Kortrulle",
            tab2: "Funktion",
            history_record: "Historiskt register",
            period_validity: "Giltighetstid",
        },
        wallet: {
            tit: "Handväskor",
            addNew: "Lägg till ny plånbok",
        },
        withdraw: {
            tit: "Ta ut pengar",
            addNew: "Lägg till ny plånbok",
        },
    },
    order: {
        demo_account: "Demo-konto",
        real_account: "Live-konto",
        tip: "Du kan göra insättningar och handla efter öppning",
        tab1: "Innehav",
        tab2: "Väntande order",
        tab3: "Historier",
        list_empty: "För närvarande inga pågående order",
        start_trade: "Påbörjande av handel",
        hand_tip: "Ingångsområde",
        kline: {
            direction_sell: "Reach (ett pris i en auktion)",
            direction_buy: "Inköp",
            open_price: "Öppningspris",
            now_price: "Aktuellt pris",
            trade_account: "Handelskonto",
            demo_account: "Demo-konto",
            order_id: "Order-ID",
            open_time: "Öppettid",
            stop_profit: "Göra vinst",
            stop_loss: "Stop-loss",
            trailing_stop: "Efterföljande stopp",
            float_profit_loss: "Rörlig vinst och förlust",
            overnight_fee: "Laddning över natten",
            margin: "Marginal",
            maintainsMargin: "Underhållsmarginal",
            open_value: "Ingående värde",
            now_value: "Aktuellt värde",
            notset: "Ej fastställd",
            close_position: "Stänga en position",
            close_number: "Ordningsnummer",
            onclick_close: "Stäng position med ett klick",
            btn: "Bekräftelse av att positionen stängts",
            balance: "Tillgängligt saldo",
            profit_loss: "Vaxande och vaggande",
            netValue: "Nettovärde",
            marginLevel: "Marginalnivå",
        },
        modify: {
            tit: "Ändring av positionsorder",
            expected_profit_loss: "Beräknad vinst och förlust",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Ange ett nytt lösenord",
            verify_email_tit: "Verifiera e-post",
            verify_password_tit: "Bekräfta ditt lösenord",
            verify_password_tip: "Ange ditt HKDFINANC-inloggningslösenord för att fortsätta",
            verify_phone_tit: "Verifiera mobiltelefonnummer",
            tit: "Kontosäkerhet",
            tip: "För att skydda ditt konto och ta emot meddelanden om våra tjänster rekommenderar vi att du aktiverar minst en typ av autentisering.",
            label1: "Ändra ditt lösenord",
            label2: "Annullering av konton",
            label3: "Dubbel verifiering",
            label4: "Förvaltning av utrustning",
            tip2: "Autentisering krävs för inloggning av ny enhet",
        },
        tradenews: {
            tit: "Handledning för handel",
            tab1: "Snabbstart",
            tab2: "Avancerad studie",
            tab3: "Hjälpcenter",
            tab4: "Om HKDFINANC",
            viewMore: "Visa mer",
            details: {
                tit: "Introduktion till utländsk valuta",
                tip1: "Beräknad studietid",
                tip2: "Minuter",
            },
            tab1Row: {
                tit: "  Detta är en snabbkurs för att lära sig handla med HKDFINANC!",
                tit1: "Hur handlar jag på HKDFINANC?",
                tit2: "Hur man öppnar ett live-konto？",
                tit3: "Hur man öppnar en handel？",
                tit4: "Hur man ställer in Stop Loss och Take Profit？",
                tit5: "Hur man avslutar en position？",
                p1: "Du kan handla det underliggande genom att köpa eller sälja. Att öppna en position i HKDFINANC innebär att du har ett intresse i denna",
                p2: "Köp och försäljning av prisfluktuationer för den underliggande produkten",
                p3: "Om du till exempel förväntar dig att guldpriset kommer att stiga, köper du produkten; omvänt, om du förutspår att guldpriset kommer att sjunka, säljer du produkten. Om framtiden uppfyller förväntningarna kan du",
                p4: "Dra nytta av det",
                p5: "Dessutom erbjuder HKDFINANC 'hävstångshandel'. Du kan använda hävstång för att handla fler partier med samma summa pengar.",
                p6: "Hävstångseffekten kan förstora vinster och förluster.",
                p7: "Detta är också känt som 'marginalhandel'. När du öppnar en position kommer ditt HKDFINANC-konto att debiteras med en viss summa pengar som",
                p8: "Initial marginal",
                p9: "Utvalda produkter och anvisningar för köp och försäljning",
                p10: "Affärer",
                p11: "Klicka på 'Köp"/"Sälj' för att slutföra beställningen.",
                p12: "Att sätta en 'Take Profit' eller 'Stop Loss' hjälper dig att",
                p13: "Låsa vinster och begränsa förluster i rätt tid",
                p14: "Du kan öppna en position genom att klicka på",
                p15: "Take Profit/Stop Loss",
                p16: "Du kan också klicka på 'Ändra' på de öppna orderna för att ställa in stop-loss och take-profit.",
                p17: "Inställning eller avbokning",
                p18: "Du kan se vinst och förlust i 'Stängda positioner",
            },
            tab3Row: {
                h1: "Välkommen till HKDFINANC",
                h2: "Hjälpcenter",
                tit0: "Alla frågor",
                tit1: "Demo-konto",
                tit2: "Öppna ett konto",
                tit3: "Insättningar och uttag",
                tit4: "Inställning av plattform",
                tit5: "Handelsverksamhet",
                tit6: "Avgifter och kostnader",
                tit7: "Finansiell säkerhet och finansiella bestämmelser",
                tit1Tit1: "HKDFINANC Erbjuder HKDFINANC demokonton?",
                tit1Cont1: "<p>HKDFINANC erbjuder demokonton för kunder att handla och bekanta sig med plattformen med hjälp av vår webbaserade plattform och mobila applikationer utan någon risk</p>.",
                tit1Tit2: "Hur man skapar ett demokonto?",
                tit1Cont2: "<p>Allt du behöver göra är att klicka här för att fylla i din e-postadress eller mobilnummer och ange din PIN-kod för att slutföra din registrering för ett demokonto. </p>",
                tit1Tit3: "Vad är nyttan med ett demokonto?",
                tit1Cont3: "<p>Demokontot är i stort sett detsamma som det riktiga kontot när det gäller handelsgränssnitt, data och operationer. Demokontot har 50 000 USD i virtuella medel och är utformat för att kunderna ska kunna bekanta sig med plattformens funktioner genom simulerade operationer utan att riskera sina medel. </p>",
                tit1Tit4: "Hur man fyller på ett demokonto?",
                tit1Cont4: "<p>Du behöver inte finansiera ditt demokonto, när du framgångsrikt registrerar dig för ett demokonto kommer det att finnas 50 000 USD eller AUD på ditt konto</p> <p>Och när nettovärdet på ditt demokonto sjunker under 200 USD eller AUD kommer systemet automatiskt att fylla på ditt konto tillbaka till 50 000 USD eller AUD</p>.",
                tit1Tit5: "Har demokontot ett utgångsdatum??",
                tit1Cont5: "<p>Demokontot är giltigt i 90 dagar. Om du inte öppnar ett live-konto efter 90 dagar kommer demokontot att frysas och du kommer inte att kunna handla på det, och det kommer att frysas upp när du öppnar ett live-konto. </p><p>Om du öppnar ett livekonto inom 90 dagar kommer demokontot att vara giltigt under en lång tid. Du kan fortsätta att handla på demokontot även efter att du har öppnat ett livekonto. </p>",
                tit2Tit1: "Hur många kontotyper erbjuder HKDFINANC?  ",
                tit2Cont1: "<p>Vi erbjuder endast standard- och professionella handelskonton för privatpersoner, vi stöder inte öppnandet av företags- och gemensamma konton</p>.",
                tit2Tit2: "Vilken valuta kan jag öppna ett handelskonto i?",
                tit2Cont3: "<p>När du öppnar ett konto kommer systemet automatiskt att fastställa din avvecklingsvaluta baserat på var du befinner dig och du kommer inte att kunna ändra den</p>.",
                tit2Tit4: "Hur man öppnar ett live-konto?",
                tit2Cont4: "<p>För att öppna ett live-konto, följ stegen nedan</p><p>1. Gå till HKDFINANC-registreringssidan och följ anvisningarna: registrera din e-postadress och ditt mobilnummer, ange ett inloggningslösenord, skicka bekräftelsen och det kommer att skapas automatiskt</p><p></p><p></p>.",
                tit3Tit1: "Hur kan jag göra en insättning?",
                tit3Cont1: "<p>HKDFINANC erbjuder olika insättningsmetoder för kunder att välja mellan, inklusive och eventuellt fler än följande:</p><p>1. Visa/Mastercard - vanligtvis omedelbart</p><p>2. Internetbank - vanligtvis inom 1 T-dag</p><p>3. E-plånböcker (t.ex. Skrill, Momo, Zalo, Touch'n GO, Boost, etc.) - vanligtvis omedelbart</p><p>4. E-plånböcker (t.ex. Skrill, Momo, Zalo, Touch'n GO, Boost, etc.) - i allmänhet omedelbart</p><p>4. Bankomatkort - i allmänhet omedelbart</p><p>5. QR Mobile - i allmänhet omedelbart</p>.",
                tit3Tit2: "Kan jag sätta in eller ta ut pengar med ett bankkonto/kort i en tredje parts namn?",
                tit3Cont2: "<p>HKDFINANC accepterar inte insättningar från tredje part. Vänligen använd ditt personliga bankkonto/kort i ditt eget namn för att sätta in eller ta ut pengar. Om du använder en annan persons namn eller företagets bankkonto/kort för att sätta in pengar, kommer pengarna att återbetalas</p>.",
                tit3Tit3: "Hur begär jag ett uttag från mitt HKDFINANC-konto?   ",
                tit3Cont3: "<p>Du kan logga in på webbplatsplattformen, klicka på [Uttag], välja ett bankkonto eller Visa/Mastercard och fylla i uttagsbeloppet, tryck sedan på [Skicka], efter att ha kontrollerat att informationen är korrekt, kommer HKDFINANC att behandla din ansökan inom 1-2 arbetsdagar</p>.",
                tit4Tit1: "Hur mycket hävstång erbjuder HKDFINANC?",
                tit4Cont1: "<p>Hävstången som erbjuds av HKDFINANC-plattformar baseras på tillsynsmyndigheternas regler, och den maximala hävstången varierar från produkt till produkt. För mer information, besök handelsplattformen. </p>",
                tit4Tit2: "Hur ändrar jag mitt lösenord??",
                tit4Cont2: "<p>Du kan återställa ett nytt lösenord efter att ha loggat in på plattformen genom att välja [Mer]- [Säkerhetsinställningar]- [Ändra lösenord]</p>.",
                tit4Tit3: "Hur loggar jag in på HKDFINANCs handelsplattform?",
                tit4Cont3: "<p>App: Gå till 'My' och klicka på 'Login l Register' längst upp till vänster för att komma till inloggningssidan. </p>Webb: Klicka på 'Logga in' i det övre högra hörnet på den officiella HKDFINANC-webbplatsen för att komma till inloggningssidan. <p></p><p></p><p></p><p></p><p></p><p></p>.",
                tit4Tit4: "På vilka sätt kan plattformsvarningar?",
                tit4Cont4: "<p>HKDFINANC Varningar via e-post, SMS, plattform push. </p>",
                tit5Tit1: "Vilken typ av spreadar erbjuder HKDFINANC?",
                tit5Cont1: "<p>Vi tar ut en låg prisskillnad och tar inte ut någon provision. Prisskillnaden är flytande, och den faktiska prisskillnaden som debiteras beror på vilken sort du handlar, som visas på handelsplattformen i realtid</p>",
                tit5Tit2: "Hur man skapar en marknadsorder?",
                tit5Cont2: "<p>Klicka på [Trade] (Trade) visar plattformen kan tillhandahålla handel med alla produkter, du kan ange höger sida av inmatningsrutan, ange koden eller namnet på arten att söka efter, välj en produkt klicka [Köp / sälj] SÄLJ / KÖP) kommer att dyka upp handelsfönstret, handelsfönstret kan du se det aktuella priset och den beräknade marginalen som krävs. Du kan manuellt justera antalet öppna affärer och ställa in stop-loss och take-profit för att kontrollera risken</p>.",
                tit5Tit3: "Hur man skapar en väntande order?",
                tit5Cont3: "<p>Välj en produkt i [Handel] och klicka på [Köp/Sälj] (SÄLJ/BUY) för att öppna handelsfönstret.</p>",
                tit5Tit4: "Hur man avslutar en position?",
                tit5Cont4: "<p>För att stänga en position klickar du på Öppna på plattformen i Position, väljer den position du vill stänga och klickar sedan på Stäng till höger.</p>",
                tit5Tit5: "Hur man ändrar eller tar bort pågående order?",
                tit5Cont5: "<p>För att ändra eller ta bort en pågående order, klicka på Order på plattformen i Position och klicka sedan på den pågående ordern som du vill ändra eller ta bort</p>.",
                tit6Tit1: "Kommer du att debiteras en provision för att använda handelsplattformen?",
                tit6Cont1: "<p>HKDFINANC är en helt provisionsfri handelsplattform. Vi tar ut låga spreadar och, beroende på din specifika handelssituation, kan plattformen medföra ytterligare avgifter såsom SWAPs. </p>",
                tit6Tit2: "Finns det några avgifter för insättning av?",
                tit6Cont2: "<p>Vi debiterar inte våra kunder några avgifter i samband med insättningar, men din betalnings- eller transitbank kan ta ut en provision. Vi rekommenderar att du kontaktar din bank för att se om de tar ut några avgifter. </p>",
                tit6Tit3: "Finns det några avgifter för uttag?",
                tit6Cont3: "<p>HKDFINANC debiterar inte kunder några avgifter relaterade till uttag såvida du inte tar ut mindre än det minsta erforderliga beloppet eller överskrider det maximala antalet fria uttag för månaden. För mer information, se vår uttagspolicy.</p>",
                tit7Tit1: "Kommer de medel som satts in på mitt HKDFINANC-konto att användas för andra ändamål?",
                tit7Cont1: "<p>Nej. Privatkunders insättningar förvaras på separata förvaltningskonton där det är nödvändigt i enlighet med lagstadgade krav. När HKDFINANC sätter in medel på eller tar ut medel eller gör betalningar från ett klientmedelskonto, följer de strikt reglerna för klientmedel som anges i det rättsliga ramverket. </p>",
                tit7Tit2: "Om HKDFINANC läggs ned eller blir skuldsatt, kommer mina medel också att påverkas?",
                tit7Cont2: "<p>Retailkunders insättningar hålls åtskilda på förvaltningskonton vid behov i enlighet med tillsynslagstiftningen. I händelse av HKDFINANC:s konkurs, indragning av licens eller underlåtenhet att fortsätta som en löpande verksamhet, förblir kundmedel segregerade och omfattas av bestämmelserna om klientmedel enligt gällande lag.</p>",
                tit7Tit3: "Följer HKDFINANC lagen?",
                tit7Cont3: "<p>Bolaget är helt förenligt med relevant bolagsrätt och finansiella tillsynslagar, och är en leverantör av finansiella tjänster som är auktoriserad och strikt reglerad av de relevanta tillsynsorganen. Företaget tillhandahåller omfattande och adekvat skydd för säkerheten för kundernas medel i enlighet med de strikta kraven i förordningen. </p>",
                tit7Tit4: "Är kundens personuppgifter säkra??",
                tit7Cont4: "<p>HKDFINANC följer strikt bestämmelserna i förordningen om skydd av personuppgifter, kundernas personuppgifter kommer aldrig att läcka ut. Företaget ser till att dess anställda följer de strikta standarder för säkerhet och sekretess som anges i lagen och ägnar stor uppmärksamhet åt sekretess och användning av kunddata. För mer information, se företagets juridiska informationsdokument. </p>",
            },
            tab4Row: {
                span: "Q Metoder för verifiering",
                p1: "HKDFINANC är ett fintech-företag som är auktoriserat och reglerat av myndigheterna, med fokus på att ge investerare en enkel och bekväm handelsupplevelse.",
                p2: "Den innovativa diversifierade handelsplattformen HKDFINANC har flera gånger vunnit utmärkelser som bästa mobila handelsplattform och mest innovativa mäklarföretag.",
                p3: "Genom HKDFINANC-plattformen kan du investera och handla på ett större antal internationella finansmarknader, som omfattar aktier, index, råvaror, valutor och hundratals andra populära arter. HKDFINANC erbjuder inte bara användarvänliga mobila och webbaserade handelsplattformar, utan även konkurrenskraftiga handelskostnader, snabb handel, utmärkt kundservice och en mängd information som hjälper dig att ta vara på investerings- och handelsmöjligheter i rätt tid.",
                p4: "HKDFINANC är strikt reglerad av branschmyndigheter och flera organisationer.",
                p5: "HKDFINANC Holding är auktoriserat och reglerat av Cayman Islands Monetary Authority (CIMA) under SIB-licens nr 1612446. För mer information om den caymanska licensen, besök CIMA:s officiella webbplats på www.cima.ky (",
                p6: ") HKDFINANCGlobal innehar en Financial Services Licence (AFSL 398528) som godkänts av Australian Securities and Investments Commission (ASIC). För mer information om australiensiska licenser, besök ASIC:s officiella webbplats på www.asic.gov.au. (",
                p7: ") HKDFINANC International är auktoriserat och reglerat av Financial Services Commission (FSC) i Mauritius under licensnummer GB20025791. För mer information om Mauritius licens, besök FSC:s officiella webbplats på www.fscmauritius.org. (",
                p8: ")All affärsverksamhet inom HKDFINANC bedrivs under strikt övervakning och i enlighet med alla bestämmelser.",
                p9: "Bakgrund",
                p10: "HKDFINANC består av ett team av erfarna och kunniga personer med erfarenhet och kunskap om finansiell handel och fintech-industrin. HKDFINANCs filosofi är att göra handel enklare och mer användarvänlig.",
                p11: "HKDFINANCs filosofi är att göra handel enklare och vänligare. Vår holistiska strategi och affärsmodell ger oss en global syn på och förståelse för branschutveckling och marknadsefterfrågan, så att vi kan fördela resurser på ett mer målinriktat sätt, kontinuerligt förnya tekniken och optimera effektiviteten för att ge våra kunder en bekvämare och mer användarvänlig handelsupplevelse.",
                p12: "Varför välja",
                p13: "Handelsvillkor med låg tröskel",
                p14: "Enkel och intuitiv handelsplattform",
                p15: "Konkurrenskraftiga transaktionskostnader",
                p16: "Regleras av branschmyndigheter",
                p17: "Hög nivå av online-support",
                p18: "Skydd mot negativ balans",
                p19: "Utmärkelser och hedersbetygelser",
                p20: "HKDFINANC har fått utmärkelsen [Best Multi-Asset Broker] av World Finance Awards, [Best Mobile Trading Platform in Asia-Pacific] [Fastest Growing FinTech Broker in the World] av Global BrandsMagazine och många andra priser, som är ett bevis på teamets hängivenhet och hårda arbete.",
                p21: "Bästa mäklare för flera tillgångar",
                p22: "Operativa koncept",
                p23: "Skulder",
                p24: "All handel innebär risk och HKDFINANC har vidtagit ytterligare åtgärder för att tillhandahålla en ansvarsfull handelsmiljö för sina kunder. HKDFINANC regleras av branschmyndigheter, flera organisationer och verkar under höga nivåer av säkerhet och stabilitet. Vår plattform fortsätter att erbjuda ett antal verktyg för ansvarsfull handel, inklusive riskhantering.",
                p25: "Enkel men vacker",
                p26: "Enkelhet är skönhet, detaljrikedom är kvalitet, och på HKDFINANC strävar vi efter användarvänlighet och användarupplevelse i varje detalj. Vi fortsätter att optimera vår plattform och våra tjänster i hopp om att alla, från nybörjare till erfarna investerare, ska kunna dra nytta av de handelsmöjligheter som finns tillgängliga via HKDFINANCs plattform.",
                p27: "Öppen (icke-hemlig)",
                p28: "För att våra kunder ska kunna dra full nytta av en rättvis och effektiv handelsmiljö följer HKDFINANC ett rigoröst och transparent självregleringssystem för att tillhandahålla en omfattande och transparent plattform med produktinformation, prismiljö och data, alla avgifter är tydliga och öppna för att säkerställa att hela serviceprocessen är helt uppriktig och transparent.",
                p29: "Bryta nya vägar",
                p30: "HKDFINANC tror starkt på att tekniska framsteg, finansiell innovation och bredare tjänster är det som driver tillväxt och att vi kan fortsätta att skapa och dela värde. Därför uppmuntrar vi öppet tänkande och fortsätter att driva innovation, med målet att vara en innovatör och ledare inom finansiell teknik.",
                p31: "Mål och vision",
                p32: "Att ge fler människor som vill handla en rättvis, effektiv och transparent handelsmiljö som inte begränsas av utrymme, tid och marknad, och att leda branschen inom finansiell och teknisk innovation.",
            },
        },
        language: {
            tit: "Välj språk",
        },
        market: {
            tit: "Tickersymbol",
            tit1: "Prisdisplay",
            tit2: "Stigande eller fallande färger",
            buy_price: "Anbudspris",
            buy_price_tip: "Dra en K-linje vid budpriset",
            sell_price: "Säljkurs",
            sell_price_tip: "Plotta K-linjer till försäljningspris",
            color1: "Grönt stigande och rött fallande",
            color2: "Röd uppgång och grönt fall",
        },
        notice: {
            tit: "Inställningar för meddelanden",
            tit1: "Öppna kanaler för meddelanden",
            p1: "Öppna/verifiera följande aviseringskanaler för att hålla koll på viktiga nyheter!",
            message: "Textmeddelanden",
            push: "Tryck",
            verify: "Validera",
            verified: "validerad",
            email: "E-postadress",
            type1: "Marknadsplats",
            tit2: "Anmälan av marknadsföringsåtgärder",
            desc2: "Plattformens information om specialerbjudanden, driftaktiviteter och andra nyheter",
            tit3: "Bruksanvisning",
            desc3: "Rikt och specialiserat kunskapsmaterial",
            tit4: "Varningar om information",
            desc4: "Nya finansiella data och nyhetsinformation",
            tit5: "Intelligent fluktuationsvarning",
            desc5: "Inkluderar prisfluktuationer för viktiga arter, vilket hjälper dig att snabbt ta vara på möjligheten",
            type2: "Rapporttyp",
            tit6: "Konto rapporter",
            desc6: "Dina kontoutdrag skickas månadsvis på följande sätt",
            cycle: "Cykliskhet",
            month: "Varje månad",
        },
        protocol: {
            tit: "Förklaringar och överenskommelser",
            label1: "Integritetspolicy",
            label2: "Redogörelse för produktinformation",
            label3: "Kundavtal",
            label4: "Riskförklaring",
        },
        setting: {
            tit: "Ställa in",
            label1: "Säkerhetsinställningar",
            label2: "Tickersymbol",
            label3: "Inställningar för transaktioner",
            label4: "Flerspråkighet",
            label5: "Temaläge",
            label6: "Inställningar för meddelanden",
            label7: "Handledning för handel",
            label8: "Förklaringar och överenskommelser",
            label9: "Rensning av cacheminnet",
            label10: "Versionsnummer",
            logout: "Logga ut",
        },
        theme: {
            tit: "Temaläge",
            theme1: "Ljusfärgat läge",
            theme2: "Mörkt läge",
        },
        trade: {
            tit: "Inställningar för transaktioner",
            onclick_close: "Stäng position med ett klick",
        }
    },
    trade: {
        fliter_type1: "Självbetjäning",
        fliter_type2: "Marknad",
        place_search: "Ange sortnamn/kod för att söka",
        fliter_type3: "På modet",
        fliter_type4: "Utländsk valuta",
        fliter_type5: "Varor",
        fliter_type6: "Aktie",
        fliter_type7: "Exponenter",
        fliter_type8: "Krypterad",
        tip1: "Slutför identitetsverifieringen kan deponeras i transaktionen, investering i den globala",
        sell: "Reach (ett pris i en auktion)",
        buy: "Inköp",
        add_optional: "Lägg till valfritt",
        optional_recommend: "Frivilliga rekommendationer",
        change_batch: "Parti",
        edit_tit: "Redaktörens val",
        breed: "Sortiment",
        topping: "Toppning",
        sort: "Ordna i ordning",
        all: "Enhälligt",
    },
    table: {
        label_breed: "Sortiment",
        label_direction: "Riktningar",
        label_price: "Priser",
        label_operate: "Rigg",
        empty_tip1: "Inga varningar",
        empty_tip2: "Ingen registrering av insättningar",
        empty_tip3: "Inget uttag registrerat",
        label_amount: "Summa pengar",
        label_type: "Typologi",
        label_time: "Tider",
        label_name: "Namn",
        label_email: "E-postadress",
        label_address: "Bosättningsland",
        label_account: "Nummer på handelskonto",
        label_currency: "Basvaluta",
        label_company: "Företag för löpande konto",
    },
    form: {
        to: "Till",
        start_time: "Starttidpunkt",
        end_time: "Sluttid",
        week: "Den senaste veckan",
        month: "Ungefär en månad",
        threeMonth: "Senaste tre månaderna",
        sixMonth: "De senaste sex månaderna",
        label_feedback: "Frågor och rekommendationer",
        place_feedback: "Ange din fråga eller ditt förslag",
        label_img: "Fotografi",
        label_img_tip: "Valfritt, bifoga en skärmdump av problemet",
        feedback_tip: "Om du har en brådskande fråga, vänligen kontakta",
        online: "Kundtjänst online",
        label_cardNumber: "Certifikatets nummer",
        label_realName: "Fullständigt namn",
        place_realName: "Vänligen ange ditt namn",
        message_realName: "Ange ditt fullständiga namn",
        label_firstName: "Efternamn",
        place_firstName: "Vänligen ange ditt efternamn",
        message_firstName: "Vänligen ange efternamn",
        label_lastName: "Namn (på en sak)",
        place_lastName: "Vänligen ange ditt mellannamn",
        message_lastName: "Vänligen ange ett namn",
        label_birthday: "Födelsedatum",
        place_birthday: "Vänligen ange ditt födelsedatum",
        message_birthday: "Vänligen ange ditt födelsedatum",
        label_idType: "Typ av dokument",
        tip_cardMain: "Ta eller ladda upp ett foto av framsidan av ditt ID-kort",
        tip_cardBack: "Ta eller ladda upp ett foto av baksidan av ditt ID-kort",
        tip_cardMain1: "Ta eller ladda upp ett foto av framsidan på ditt körkort",
        tip_cardBack1: "Ta eller ladda upp ett foto av baksidan av ditt körkort.",
        tip_cardMain2: "Ta eller ladda upp passfoton",
        tip_cardBack2: "Ta eller ladda upp passfoton",
        label_password: "Kryptografisk",
        place_password: "Ange ditt lösenord",
        message_password: "Ange ditt lösenord",
        label_crmpsd: "Bekräfta lösenord",
        place_crmpsd: "Ange ditt lösenord igen",
        message_crmpsd: "Ange ditt lösenord igen",
        label_email: "E-postadress",
        place_email: "E-postadress",
        message_email: "Ange din e-postadress",
        label_captcha: "CAPTCHA",
        place_captcha: "Vänligen ange verifieringskoden",
        message_captcha: "Vänligen ange verifieringskoden",
        get_captcha: "Hämta CAPTCHA",
        label_phone: "Telefoner",
        place_phone: "Ange ditt telefonnummer",
        message_phone: "Ange ditt telefonnummer",
        labelAmount: "Summa pengar",
        placeAmount: "Vänligen ange beloppet",
        messageAmount: "Vänligen ange beloppet",
        labelWalletName: "Namn på plånbok",
        messageWalletName: "Ange namnet på din plånbok",
        placeWalletName: "Ange namnet på din plånbok",
        labelWalletAddress: "Plånbokens adress",
        messageWalletAddress: "Vänligen ange din plånboksadress",
        placeWalletAddress: "Vänligen ange din plånboksadress",
        labelWithdrawAccount: "Konto för uttag",
        placeWithdrawAccount: "Konto för uttag",
        messageWithdrawAccount: "Konto för uttag",
		placeTimeLimit: "Ange en tidsgräns",
		messageTimeLimit: "Ange en tidsgräns",
    },
	city: {
		albania: "Albanien",
		algeria: "Algeriet",
		argentina: "Argentina",
		armenia: "Armenien",
		australia: "Australien",
		pakistan: "Pakistan",
		austria: "Österrikiska",
		bahrain: "Bahrain",
		belgium: "Belgien",
		bosnia_and_Herzegovina: "Abbr. för Bosnien-Hercegovina",
		brazil: "Brasiliansk",
		brunei: "Brunei ",
		bulgaria: "Bulgarien",
		cambodia: "Kambodjanska",
		canada: "Kanadensiska",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Columbia ",
		costa_Rica: "Costa Rica",
		croatia: "Republiken Kroatien ",
		cyprus: "Cypern",
		czech_Republic: "Tjeckien (från 1993)",
		denmark: "Danmark",
		dominican_Republic: "Dominikanska republiken",
		egypt: "Egypten",
		estonia: "Estland",
		ethiopia: "Etiopien",
		finland: "Suomi",
		france: "Franska",
		georgia: "Georgien (land)",
		germany: "Tyska",
		ghana: "Ghana, Västafrika",
		greece: "Grekland",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Kina",
		hungary: "Ungern",
		iceland: "Isländska",
		ireland: "Irländska",
		italy: "Italien",
		india: "Indien",
		indonesia: "Indonesien",
		israel: "Israeliska",
		iran: "Iranska",
		iraq: "Irakiska",
		japan: "Japanska",
		kazakstan: "Kazakstan",
		kenya: "Kenya",
		korea: "Sydkorea (Republiken Korea)",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgizistan",
		laos: "Laos",
		latvia: "Lettland",
		lithuania: "Litauen",
		luxembourg: "Luxemburg",
		macao_China: "Macao, Kina",
		macedonia: "Makedonska",
		malaysia: "Malaysia",
		malta: "Maltesiska",
		mexico: "Mexiko",
		moldova: "Moldavien",
		monaco: "Monaco",
		mongolia: "Mongoliet",
		montenegro: "Montenegro",
		morocco: "Marocko",
		myanmar: "Myanmar",
		netherlands: "Nederländerna",
		new_Zealand: "Nya Zeeland",
		nepal: "Nepali",
		nigeria: "Nigeria, Västafrika",
		norway: "Norge",
		oman: "Omani",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peruanska",
		philippines: "Filippinerna",
		poland: "Polsk",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Doha",
		romania: "Rumänien",
		russia: "Georgien",
		republic_of_Trinidad_and_Tobago: "Trinidad och Tobago",
		rwanda: "Rwandiska",
		saudi_Arabia: "Saudiarabien",
		serbia: "Serbien",
		singapore: "Singapore",
		slovakia: "Slovakien",
		slovenia: "Slovenien",
		south_Africa: "Sydafrika",
		spain: "Spanska",
		sri_Lanka: "(Tidigare) Ceylon",
		sweden: "Sverige",
		switzerland: "Schweiz",
		taiwan_China: "Kina-Taiwan",
		tajikistan: "Tadzjikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "Istanbul",
		turkmenistan: "Turkmenistan",
		ukraine: "Vitryssland",
		united_Arab_Emirates: "FÖRENADE ARABEMIRATEN",
		united_Kingdom: "Storbritannien",
		united_States: "Förenta staterna",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbajdzjan",
		bangladesh: "Bangladesh",
		belarus: "Vitryssland",
		belize: "Beliziska",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Brittiska Jungfruöarna",
		burkina_Faso: "Burkina Faso, Västafrika",
		burundi: "Burundi",
		cape_Verde: "Kap Verde",
		cayman_Islands: "Caymanöarna",
		central_African_Republic: "Centralafrikanska republiken",
		chad: "Tchad",
		comoros: "Komorerna",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Demokratiska republiken Kongo",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Ekvatorialguinea",
		eritrea: "Eritrea",
		fiji: "Fiji ",
		gabon: "Gabonesiska",
		gambia: "Gambia",
		greenland: "Grönland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isle of Mann",
		cote_d_Ivoire: "Elfenbenskusten",
		jamaica: "Jamaicansk",
		jordan: "Jordanien",
		lebanon: "Fientligheter",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libyen",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Maldiverna",
		mali: "Mali, Västafrika",
		mauritania: "Mauretanien",
		mauritius: "Mauritius",
		mozambique: "Moçambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger (afrikansk stat)",
		north_Korea: "Nordkorea",
		reunion: "Reunion Island",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadishu",
		sudan: "Sudan",
		suriname: "Surinam",
		eswatini: "Swaziland",
		syria: "Syrien",
		togo: "Togo, Västafrika",
		tonga: "Tonga",
		tunisia: "Tunisien",
		united_States_Virgin_Islands: "Amerikanska Jungfruöarna",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikanen",
		yemen: "Jemen",
		yugoslavia: "Jugoslavien",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "Kina",
	}
};
