export default {
    crypto: {
        title: {
            "0": "ซื้อ cryptocurrency",
            "1": "ซื้อ cryptocurrency",
            "2": "C2C",
        },
        list: {
            "0": "การซื้อขาย C2C",
            "1": "ซื้อ",
            "2": "ขาย",
            "3": "ผู้ลงโฆษณา (อัตราความสำเร็จ)",
            "4": "การซื้อขาย",
            "5": "ราคา",
            "6": "ใช้ได้",
            "7": "ข้อ จำกัด",
            "8": "วิธีการชำระเงิน",
            "9": "การค้าขาย",
            "10": "ผู้ลงโฆษณาอาจกำหนดขีด จำกัด การทำธุรกรรมสำหรับโฆษณาและคุณจะเห็นเหตุผลของการ จำกัด หลังจากเข้าสู่ระบบ",
        },
        buy: {
            "0": "ซื้อ cryptocurrency",
            "1": "ขาย cryptocurrency",
            "2": "ประวัติความเป็นมา",
            "3": "การสนับสนุน",
            "4": "การชำระเงิน",
            "5": "รับ",
            "6": "เลือกวิธีการชำระเงิน",
            "7": "ตามเวลาภาคตะวันออกของสหรัฐอเมริกา เวลามาถึง",
            "8": "ค่าธรรมเนียม",
            "9": "รายละเอียดการสั่งซื้อ",
            "10": "วิธีการชำระเงิน",
            "11": "เรียนรู้เพิ่มเติม",
            "12": "ราคา",
            "13": "รวม (รวมค่าธรรมเนียม)",
            "14": "การปฏิเสธความรับผิดชอบ",
            "15": "คุณจะออกจาก HKDFinanc และจะถูกเปลี่ยนเส้นทางไปยัง Mercuryo ซึ่งเป็นสถาบันการเงินที่ได้รับอนุญาตที่ให้บริการชำระเงินด้วยบัตรเครดิต / เดบิตในฐานะบุคคลที่สาม Mercuryo เป็นผู้รับผิดชอบความเสี่ยงใด ๆ ที่เกิดขึ้นระหว่างการใช้บริการ โปรดอ่านและยอมรับเงื่อนไขการใช้งานก่อนดำเนินการต่อ",
            "16": "ฉันได้อ่านและยอมรับ เงื่อนไขการใช้งาน",
            "17": "คุณจะได้รับ",
            "18": "ส่ง",
            "19": "กรุณาใส่จำนวนเงิน",
            "20": "ยอดเงินในบัญชีไม่เพียงพอ",
            "21": "ใช้ได้",
            "22": "มีบัญชี Spot",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        },
    },
    common: {
        view: "ดู",
        confirm: "ระบุ",
        cancel: "การยกเลิก",
        deposit: "รายการทอง",
        withdraw: "ทองออก",
        noData: "ไม่มีข้อมูลในขณะนี้",
        submit: "ส่ง",
        modify: "การปรับเปลี่ยน",
        hand: "มือ",
        confirm_verify: "ยืนยันการตรวจสอบ",
        next: "ขั้นตอนต่อไป",
        complete: "เสร็จสิ้น",
        delete: "ลบ",
		countryCode: "รหัสประเทศ",
    },
    addnew: {
        realFirst: "กรุณายืนยันตัวตนก่อน",
        payFirst: "กรุณาตั้งรหัสผ่านการซื้อขายก่อน",
        password1: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
        password2: "แก้ไขรหัสผ่านการถอนเงิน",
        paypassTit1: "กรุณาตั้งรหัสผ่านการถอนเงิน",
        oldPass: "รหัสผ่านเก่า",
        allView: "อ่านทั้งหมด",
        payPass: "รหัสผ่านการเทรด",
        buyUp: "ซื้อขึ้น",
        buyDown: "ซื้อและลง",
        confirmOrder: "ยืนยันการสั่งซื้อ",
        name: "ชื่อ-นามสกุล",
        fee: "ค่าธรรมเนียมการจัดการ",
        orderCycle: "เลือกเวลา",
        loan: "สินเชื่อ",
        loanTit: "สินเชื่อช่วย",
        loanHostory: "บันทึกสินเชื่อ",
        labelProductTitle: "ชื่อสินค้า",
        immediateRepayment: "ชำระคืนได้ทันที",
        loadAmount: "จำนวนเงินที่คาดว่าจะกู้",
        RepaymentCycle: "รอบการชำระหนี้เงินกู้",
        day: "วัน",
        dailyRate: "อัตราดอกเบี้ยรายวัน",
        repaymentMethod: "วิธีการชำระหนี้",
        lendingCompany: "บริษัทสินเชื่อ",
        loanTip: "สินเชื่อ (โปรดตรวจสอบให้แน่ใจว่าเห็นภาพชัดเจน)",
        loanTip1: "อัปโหลดใบรับรองอสังหาริมทรัพย์",
        loanTip2: "หลักฐานรายได้ (ความสัมพันธ์ในการจ้างงาน)",
        loanTip3: "รายละเอียดบัญชีธนาคาร",
        loanTip4: "อัพโหลดรูปภาพหน้าบัตรประชาชน",
        interest: "สิทธิประโยชน์",
        repaid: "ชำระคืนแล้ว",
        unpaid: "ยังไม่ได้ชำระหนี้",
        tab1: "เหรียญกษาปณ์",
        tab2: "สัญญา",
        tab3: "ตัวเลือก",
        available: "วงเงินที่มีอยู่",
        pending: "ล็อค",
        equivalent: "พับ",
        fundTransfer: "การโอนเงิน",
        flashExchange: "แฟลชไดรฟ์",
        select_breed: "เลือกสกุลเงิน",
        from: "เริ่มต้นจาก",
        to: "ถึง",
        transferQuantity: "จำนวนโอน",
        repaymentMethod1:"ครบกำหนดชำระหนี้ 1 ครั้ง"
    },
    params: {
        product_foreign: "ฟอเร็กซ์",
        product_shop: "สินค้าโภคภัณฑ์",
        product_number: "ดัชนี",
        product_stock: "หุ้น",
        product_coin: "การเข้ารหัส",
        billTypes: [
            { v: 101, name: 'เติมเงิน' },
            { v: 102, name: 'เพิ่ม' },
            { v: 103, name: 'แช่แข็ง' },
            { v: 104, name: 'ละลายน้ำแข็ง' },
            { v: 105, name: 'การฝากเงิน' },
            { v: 106, name: 'การฝากเงิน' },
            { v: 201, name: 'ถอนเงินแช่แข็ง' },
            { v: 202, name: 'หัก' },
            { v: 203, name: 'ถอนเงินสำเร็จ' },
            { v: 204, name: 'การถอนเงินล้มเหลว' },
            { v: 205, name: 'ค่าธรรมเนียมการถอนเงิน' },
            { v: 206, name: 'โอนออก' },
            { v: 207, name: 'โอนเข้า' },
            { v: 208, name: 'การโอนเหรียญกษาปณ์' },
            { v: 209, name: 'การโอนเหรียญกษาปณ์' },
            { v: 301, name: 'หักเงินประกัน' },
            { v: 302, name: 'การหักค่าธรรมเนียมการจัดการ' },
            { v: 303, name: 'การคืนเงินประกัน' },
            { v: 304, name: 'กำไร' },
            { v: 305, name: 'ขาดทุน' },
            {v: 311, name: 'ซื้อตัวเลือก'},
            {v: 312, name: 'ตัวเลือกรายได้'},
            {v: 313, name: 'การคืนตัวเลือก'},
            {v: 314, name: 'ค่าธรรมเนียมการจัดการตัวเลือก'},
            {v: 315, name: 'การแช่แข็งการซื้อเหรียญ'},
            {v: 316, name: 'การหักค่าซื้อเหรียญ'},
            {v: 317, name: 'ซื้อเหรียญ ผลตอบแทน'},
            {v: 318, name: 'เหรียญซื้อเข้าบัญชี'},
            {v: 319, name: 'เหรียญซื้อเข้าบัญชี'},
            {v: 320, name: 'ขายเหรียญแช่แข็ง'},
            {v: 321, name: 'หักลดหย่อนการขายเหรียญกษาปณ์'},
            {v: 322, name: 'ขายเหรียญ ผลตอบแทน'},
            {v: 323, name: 'ขายเหรียญเข้าบัญชี'},
            {v: 324, name: 'ขายเหรียญเข้าบัญชี'},
            {v: 325, name: 'ค่าธรรมเนียมการจัดการเหรียญกษาปณ์'},
            {v: 401, name: 'Mine Machine เข้าร่วม'},
            {v: 402, name: 'เครื่องจักรเหมืองกลับ'},
            {v: 403, name: 'รายได้ของเครื่องจักร'},
            {v: 404, name: 'ถอนเครื่องขุด'},
            {v: 405, name: 'ค่าธรรมเนียมการถอนตัวของเครื่องจักร'},
            {v: 411, name: 'รายได้จากการกู้ยืม'},
            {v: 412, name: 'ค่าธรรมเนียมการกู้ยืม'},
            {v: 413, name: 'ชำระหนี้สำเร็จ'},
        ],
    },
    footer: {
        nav1: "หน้าหลัก",
        nav2: "การซื้อขาย",
        nav3: "ตำแหน่งงาน",
        nav4: "ของฉัน",
        kline_set1: "การตั้งค่าสภาพตลาด",
        kline_set2: "การตั้งค่าการเทรด",
    },
    login: {
        login: "เข้าสู่ระบบ",
        register: "ลงทะเบียน",
        type1: "หมายเลขโทรศัพท์มือถือ",
        type2: "กล่องจดหมาย",
        login_btn: "เข้าสู่ระบบอย่างปลอดภัย",
        register_btn: "ลงทะเบียนตอนนี้",
        psd_login: "รหัสผ่าน เข้าสู่ระบบ",
        verify_login: "รหัสยืนยัน เข้าสู่ระบบ",
        forget: "ลืมรหัสผ่าน?",
        no_account: "ไม่มีบัญชี？",
        have_account: "มีบัญชีอยู่แล้ว",
    },
    home: {
        home: {
            nav1: "ลีดเดอร์ขึ้น-ลง",
            nav2: "เริ่มต้นอย่างรวดเร็ว",
            nav3: "เกี่ยวกับเรา",
            nav4: "รางวัลเชิญ",
            most_follow: "ให้ความสนใจมากที่สุด",
            news_type1: "กลยุทธ์",
            news_type2: "ข่าว",
            news_type3: "ปฏิทินการเงิน",
            news_tab1: "ทั้งหมด",
            news_tab2: "ฟอเร็กซ์",
            news_tab3: "หุ้น",
            news_tab4: "สินค้าโภคภัณฑ์",
            news_tab5: "ดัชนี",
            news_tab6: "ข้อมูล",
            news_tab7: "กิจกรรม",
            news_tab8: "วันหยุด",
            label_previous: "ค่าก่อนหน้า",
            label_predictive: "ค่าทำนาย",
            label_announced: "ค่าประกาศ",
        },
        kilne: {
            label_bp: "การเก็บเกี่ยวเมื่อวานนี้",
            label_sp: "อิมาไค",
            label_height: "สูงสุด",
            label_low: "ต่ำสุด",
            tip: "สวัสดีค่ะ ฉันชื่อ HKDFINANCGPT Variety Assistant",
            row1_tit: "กลยุทธ์",
            row1_rinei: "ระหว่างวัน",
            row1_short: "ระยะสั้น",
            row1_middle: "ระยะกลาง",
            row1_time: "เวลาโพสต์",
            row1_tit2: "กลยุทธ์การเทรด",
            data_sources: "แหล่งข้อมูล",
            row2_tit1: "ข้อมูล",
            row2_tit2: "ความเชื่อมั่นในการเทรด",
            row2_label_sell: "ผู้ขาย",
            row2_label_buy: "ผู้ซื้อ",
            tip2: "เพื่อเป็นข้อมูลเท่านั้นไม่ใช่ตำแหน่งประจำหน่วย",
            tip3: "อัพเดททุก 15 นาที",
            row2_tit3: "ราคาเพิ่มขึ้นและลดลง",
            minute: "นาที",
            today: "วันนี้",
            right_tit7: "ช่วงการเปลี่ยนแปลงราคา",
            now_price: "ราคาปัจจุบัน",
            right_low_price: "ราคาที่ดีที่สุด",
            right_height_price: "ราคาสูงสุด",
            details: "รายละเอียด",
            right_label1: "จำนวนรายการเดียว",
            right_label2: "เลเวอเรจสูงสุด",
            right_label3: "ขนาดสัญญา",
            right_label4: "จำนวนโพซิชั่นที่เปิดสูงสุด",
            right_label5: "สเปรดลอยตัว",
            right_label6: "ค่าธรรมเนียมข้ามคืน",
            hand: "มือ",
            right_sell: "ขาย",
            right_buy: "ซื้อ",
            right_collection_time: "เวลาเก็บ",
            right_tip3: "เรียกเก็บจากขนาดการซื้อขายทั้งหมด",
            right_label9: "ช่วงเวลาการซื้อขาย",
            right_label10: "ช่วงเวลาปัจจุบัน",
            right_label11: "ช่วงเวลาถัดไป",
        },
        placeorder: {
            direction_buyPrice: "ราคาซื้อ",
            direction_sellPrice: "ราคาขาย",
            direction_sell: "ขาย",
            direction_buy: "ซื้อ",
            market_price: "ราคาตลาด",
            check_price: "รายการแขวน",
            sell_tip: "ขายเมื่อราคาเป็นไปตามเงื่อนไข",
            buy_tip: "ซื้อเมื่อราคาเป็นไปตามเงื่อนไข",
            trade_number: "จำนวนการซื้อขาย",
            hand: "มือ",
            select_lever: "เลือกเลเวอเรจ",
            right_tip: "มาร์จิ้นที่ต้องจ่าย",
            balance: "ยอดคงเหลือที่มีอยู่",
            label_stop_win_price: "หยุดกำไร",
            label_stop_lose_price: "หยุดขาดทุน",
            expected_profit_loss: "คาดว่าจะมีกำไรและขาดทุน",
            trailing_stop: "ติดตาม Stop Loss",
        },
        ranking: {
            tit: "การจัดอันดับการขึ้นลงของวัน",
            p: "จับประเด็นร้อน ฉวยโอกาส",
            renew: "อัปเดตเมื่อ",
            growth_chart: "รายการกำไร",
            drop_list: "รายการลดลง",
            show_closed_market: "แสดงแบรนด์ฮิวจ์",
            filter1: "ทั้งหมด",
            filter2: "ฟอเร็กซ์",
            filter3: "สินค้าโภคภัณฑ์",
            filter4: "หุ้น",
            filter5: "ดัชนี",
            filter6: "การเข้ารหัส",
            td1: "การจัดอันดับ",
            td2: "แนวโน้ม",
            td3: "ราคาเพิ่มขึ้น / ขาย",
        },
        remind: {
            now_sell_price: "ราคาขายปัจจุบัน",
            direction_buyPrice: "ราคาซื้อ",
            direction_sellPrice: "ราคาขาย",
            direction_sell: "ขาย",
            direction_buy: "ซื้อ",
            equal: "เมื่อ",
            p: "เตือนฉันเมื่อถึงราคาต่อไปนี้",
            btn1: "ตั้งค่าการแจ้งเตือน",
            list_tit: "รายการเตือนความจำ",
        },
    },
    my: {
        report: {
            tit: "รายงานบัญชี",
            tab1: "รายงาน",
            tab2: "ใบแจ้งยอดรายวัน",
            tab3: "ใบแจ้งยอดรายเดือน",
            history_tit: "รายงานประวัติการซื้อขาย",
            account_tit: "รายงานบัญชี",
            filter: "การคัดกรอง",
            tip1: "โปรดเลือกช่วงวันที่ที่เหมาะสมเพื่อส่งออกรายงานประวัติการซื้อขายของคุณ",
            tip2: "เวลาที่กำหนดเอง",
            email_send: "ส่งไปยังกล่องจดหมาย",
        },
        accountMsg: {
            tit: "ข้อมูลบัญชี",
            label1: "ข้อมูลส่วนบุคคล",
        },
        bill: {
            tit: "รายละเอียดกระแสเงินทุน",
            all: "ทั้งหมด",
            time: "เวลา",
        },
        feedback: {
            tit: "คำแนะนำการทำงาน",
        },
        identity: {
            tit: "การตรวจสอบสิทธิ์",
            type1: "บัตรประชาชน",
            type2: "ใบขับขี่",
            type3: "หนังสือเดินทาง",
            tip: "ข้าพเจ้าขอยืนยันว่าข้อมูลและเอกสารที่นำเสนอครบถ้วน เป็นจริง และถูกต้อง",
        },
        my: {
            is: "มี",
            no: "ตรวจสอบแล้ว",
            verify: "ตรวจสอบแล้ว",
            id_verify: "การตรวจสอบสิทธิ์",
            verify_tip: "เสร็จสิ้นการยืนยันตัวตนเพื่อเข้าสู่ธุรกรรมทองคำ",
            no_verify: "ตรวจสอบตอนนี้",
            balance: "ยอดคงเหลือที่มีอยู่",
            profit_loss: "กำไรและขาดทุน",
            marginLevel: "ระดับมาร์จิ้น",
            btn1: "เปลี่ยนเป็นบัญชีจริง",
            deposit_withdraw_record: "บันทึกทองเข้า",
            capital_flow_details: "รายละเอียดกระแสเงินทุน",
            welfare_center: "ศูนย์สวัสดิการ",
            tip1: "คุณมี",
            tip2: "รางวัลปากกาที่จะได้รับ",
            feedback: "คำแนะนำการทำงาน",
            report: "รายงานบัญชี",
            setting: "การตั้งค่า",
        },
        news: {
            tit: "ประกาศ",
        },
        record: {
            tit: "บันทึกทองเข้า",
            cycle: "ระยะเวลา",
        },
        share: {
            tit: "เชิญเพื่อน",
            invite_success: "เชิญเพื่อนสำเร็จ",
            tip1: "รางวัลเงินสด",
            tip2: "เชิญเพื่อนมารับสูงสุด",
            tip3: "ชวนเพื่อนมารับเงินสด",
        },
        welfare: {
            tit: "ศูนย์สวัสดิการ",
            tab1: "บัตรม้วน",
            tab2: "กิจกรรม",
            history_record: "ประวัติความเป็นมา",
            period_validity: "วันหมดอายุ",
        },
        wallet: {
            tit: "กระเป๋าสตางค์",
            addNew: "เพิ่มกระเป๋าสตางค์ใหม่",
        },
        withdraw: {
            tit: "ทองออก",
            addNew: "เพิ่มกระเป๋าสตางค์ใหม่",
        },
    },
    order: {
        demo_account: "บัญชีทดลอง",
        real_account: "บัญชีจริง",
        tip: "เปิดทำการซื้อขายทองคำได้",
        tab1: "เปิดสถานะ",
        tab2: "รายการแขวน",
        tab3: "ประวัติความเป็นมา",
        list_empty: "ปัจจุบันไม่มีใบแจ้งหนี้",
        start_trade: "เริ่มต้นเทรด",
        hand_tip: "ช่วงอินพุต",
        kline: {
            direction_sell: "ขาย",
            direction_buy: "ซื้อ",
            open_price: "ราคาเปิดตำแหน่ง",
            now_price: "ราคาปัจจุบัน",
            trade_account: "บัญชีเทรด",
            demo_account: "บัญชีทดลอง",
            order_id: "รหัสการสั่งซื้อ",
            open_time: "เวลาเปิดสถานะ",
            stop_profit: "หยุดกำไร",
            stop_loss: "หยุดขาดทุน",
            trailing_stop: "ติดตาม Stop Loss",
            float_profit_loss: "กำไรและขาดทุนลอยตัว",
            overnight_fee: "ค่าธรรมเนียมข้ามคืน",
            margin: "มาร์จิ้น",
            maintainsMargin: "หลักประกันการบำรุงรักษา",
            open_value: "มูลค่าการเปิดโพซิชั่น",
            now_value: "มูลค่าปัจจุบัน",
            notset: "ยังไม่ได้ตั้งค่า",
            close_position: "การปิด",
            close_number: "ปริมาณปกติ",
            onclick_close: "การปิดเพียงคลิกเดียว",
            btn: "ยืนยันการปิดสถานะ",
            balance: "ยอดคงเหลือที่มีอยู่",
            profit_loss: "กำไรและขาดทุน",
            netValue: "มูลค่าสุทธิ",
            marginLevel: "ระดับมาร์จิ้น",
        },
        modify: {
            tit: "แก้ไขรายการสถานะ",
            expected_profit_loss: "คาดว่าจะมีกำไรและขาดทุน",
        },

    },
    setting: {
        security: {
            modify_password_tit: "ตั้งรหัสผ่านใหม่",
            verify_email_tit: "ตรวจสอบกล่องจดหมาย",
            verify_password_tit: "ยืนยันรหัสผ่านของคุณ",
            verify_password_tip: "กรอกรหัสผ่านเข้าสู่ระบบ HKDFINANC เพื่อดำเนินการต่อ",
            verify_phone_tit: "ยืนยันหมายเลขโทรศัพท์มือถือ",
            tit: "ความปลอดภัยของบัญชี",
            tip: "เพื่อความปลอดภัยของบัญชีของคุณและการยอมรับการแจ้งเตือนบริการของเราขอแนะนำให้เปิดการตรวจสอบความถูกต้องอย่างน้อยหนึ่งรายการ",
            label1: "แก้ไขรหัสผ่าน",
            label2: "ออกจากระบบบัญชี",
            label3: "การตรวจสอบคู่",
            label4: "การจัดการอุปกรณ์",
            tip2: "ต้องตรวจสอบเมื่อเข้าสู่ระบบอุปกรณ์ใหม่",
        },
        tradenews: {
            tit: "คู่มือการซื้อขาย",
            tab1: "เริ่มต้นอย่างรวดเร็ว",
            tab2: "การเรียนรู้ขั้นสูง",
            tab3: "ศูนย์ช่วยเหลือ",
            tab4: "เกี่ยวกับ HKDFINANC",
            viewMore: "ดูเพิ่มเติม",
            details: {
                tit: "แนะนำฟอเร็กซ์",
                tip1: "ประมาณระยะเวลาเรียน",
                tip2: "นาที",
            },
            tab1Row: {
                tit: "คลาสเรียนรู้การเทรดกับ HKDFINANC ได้อย่างรวดเร็ว",
                tit1: "การซื้อขายบน HKDFINANC ทำได้อย่างไร?",
                tit2: "วิธีเปิดบัญชีจริง？",
                tit3: "วิธีการเปิดโพซิชั่น？",
                tit4: "วิธีการตั้งค่า Stop Loss？",
                tit5: "วิธีปิดสถานะ？",
                p1: "คุณสามารถซื้อขายอ้างอิงโดยการซื้อหรือขาย การเปิดโพซิชั่นที่ HKDFINANC นั่นหมายความว่าคุณมีความสนใจกับ",
                p2: "ความผันผวนของราคาของสินค้าอ้างอิงที่จะซื้อและขาย",
                p3: "ตัวอย่างเช่น คุณคาดหวังว่าราคาทองคำจะเพิ่มขึ้น จากนั้นซื้อผลิตภัณฑ์นี้ ในทางตรงกันข้าม คุณคาดการณ์ว่าราคาทองคําจะลดลงและขายสินค้านั้น หากอนาคตเป็นไปตามที่คาดการณ์ไว้ก็สามารถทำได้",
                p4: "ทำกำไรจากมัน",
                p5: "นอกจากนี้ HKDFINANC ยังมี 'การซื้อขายด้วยเลเวอเรจ'' คุณสามารถใช้เลเวอเรจเพื่อซื้อขายหลักทรัพย์อ้างอิงในจำนวนล็อตที่มากขึ้นโดยใช้เงินทุนเดียวกัน",
                p6: "เลเวอเรจขยายผลกำไรและขาดทุน",
                p7: "สิ่งนี้เรียกว่า 'การซื้อขายมาร์จิ้น'เมื่อท่านเปิดสถานะ บัญชีของ HKDFINANC จะถูกหักยอดเงินบางส่วนเป็น",
                p8: "มาร์จิ้นเริ่มต้น",
                p9: "ตรวจสอบสินค้าและทิศทางการซื้อ-ขาย",
                p10: "การซื้อขาย",
                p11: "คลิก 'Buy/' เพื่อทำการสั่งซื้อให้เสร็จสมบูรณ์",
                p12: "การตั้งค่า ''Stop Profit'' หรือ ''Stop Loss'' สามารถช่วยคุณได้",
                p13: "ล็อกกำไรและจำกัดการขาดทุนได้ทันเวลา",
                p14: "คุณสามารถคลิกที่หน้าความสำเร็จในการเปิดตำแหน่ง",
                p15: "หยุดกำไร / หยุดขาดทุน",
                p16: "ทำการตั้งค่าหรือคุณสามารถคลิก 'แก้ไขคำสั่งที่ไม่ได้ปิด'ในภายหลังสำหรับ Stop Loss ของ",
                p17: "ตั้งค่าหรือยกเลิก",
                p18: "คุณสามารถดูกำไรและขาดทุนใน 'ตำแหน่งที่เปิด'",
            },
            tab3Row: {
                h1: "ยินดีต้อนรับสู่ HKDFINANC",
                h2: "ศูนย์ช่วยเหลือ",
                tit0: "คำถามทั้งหมด",
                tit1: "บัญชีทดลอง",
                tit2: "เปิดบัญชี",
                tit3: "เงินเข้าและออก",
                tit4: "การตั้งค่าแพลตฟอร์ม",
                tit5: "การดำเนินการซื้อขาย",
                tit6: "ค่าธรรมเนียมและค่าธรรมเนียม",
                tit7: "ความปลอดภัยของเงินทุนและกฎระเบียบ",
                tit1Tit1: "HKDFINANC ไม่ว่าจะเป็นบัญชีทดลองที่นำเสนอ",
                tit1Cont1: "<p>HKDFINANC บัญชีทดลองมีให้บริการสำหรับการดำเนินงานของลูกค้าและคุณสามารถเทรดและทำความคุ้นเคยกับการดำเนินงานของแพลตฟอร์มโดยใช้แพลตฟอร์มบนเว็บและแอพพลิเคชั่นมือถือของเราโดยไม่มีความเสี่ยงใด ๆ</p>",
                tit1Tit2: "วิธีการสร้างบัญชีทดลอง?",
                tit1Cont2: "<p>คุณเพียงแค่ต้องกรอกที่อยู่อีเมลหรือหมายเลขโทรศัพท์มือถือของคุณและตั้งรหัสผ่านส่วนตัวเพื่อลงทะเบียนเพื่อรับบัญชีทดลอง。</p>",
                tit1Tit3: "ประโยชน์ของบัญชีทดลองคืออะไร?",
                tit1Cont3: "<p>บัญชีทดลองเหมือนกับบัญชีจริงในอินเทอร์เฟซการซื้อขายข้อมูลการดำเนินงานส่วนใหญ่มีเงินเสมือน $ 50,000 ในบัญชีสาธิตโดยมีวัตถุประสงค์เพื่อให้ลูกค้าคุ้นเคยกับฟังก์ชั่นต่างๆของแพลตฟอร์มผ่านการดำเนินงานสาธิตโดยไม่มีความเสี่ยงด้านเงินทุน。</p>",
                tit1Tit4: "วิธีการฝากเงินเข้าบัญชีทดลอง?",
                tit1Cont4: "<p>คุณไม่จำเป็นต้องเพิ่มเงินเข้าบัญชีทดลอง และเมื่อคุณลงทะเบียนบัญชีทดลองเรียบร้อยแล้ว จะมี $50,000 USD หรือ AUD ในบัญชี LAOSHENZHEN เต็มจำนวน </p><p>และเมื่อมูลค่าสุทธิในบัญชีทดลองต่ำกว่า $200 USD หรือ AUD ระบบจะเติมเงินในบัญชีของคุณให้เป็น $50,000 USD หรือ AUD โดยอัตโนมัติ</p>",
                tit1Tit5: "บัญชีทดลองมีระยะเวลาการใช้งานหรือไม่?",
                tit1Cont5: "<p>บัญชีทดลองมีอายุ 90 วัน หากคุณไม่ได้เปิดบัญชีจริงหลังจาก 90 วัน บัญชีทดลองจะถูกระงับและไม่สามารถซื้อขายได้ และละลายเมื่อคุณเปิดบัญชีจริง。</p><p>และถ้าคุณเปิดบัญชีจริงภายใน 90 วัน บัญชีทดลองจะมีผลในระยะยาว การดำเนินการอย่างต่อเนื่องในบัญชีสาธิตแม้หลังจากเปิดบัญชีจริงแล้ว。</p>",
                tit2Tit1: "HKDFINANC ประเภทบัญชีที่เสนอ?  ",
                tit2Cont1: "<p>เราให้บริการเฉพาะบัญชีซื้อขายแบบมาตรฐานและแบบมืออาชีพสำหรับบุคคลธรรมดาเท่านั้น และไม่รองรับการเปิดบัญชีบริษัทและบัญชีร่วม</p>",
                tit2Tit2: "ฉันสามารถเปิดบัญชีซื้อขายในสกุลเงินใด?",
                tit2Cont3: "<p>เมื่อคุณเปิดบัญชีระบบจะกำหนดสกุลเงินที่ชำระบัญชีโดยอัตโนมัติตามภูมิภาคของคุณและจะไม่สามารถแก้ไขได้</p>",
                tit2Tit4: "วิธีเปิดบัญชีจริง?",
                tit2Cont4: "<p>เปิดบัญชีจริง กรุณาทำตามขั้นตอนด้านล่าง</p><p>1. เข้าสู่หน้าลงทะเบียนของ HKDFINANC ตามคำแนะนำ: ลงทะเบียนหมายเลขโทรศัพท์มือถือกล่องจดหมาย, ตั้งรหัสผ่านล็อกอิน, สร้างอัตโนมัติเมื่อส่งการยืนยัน</p><p></p><p><p></p> <p></p>",
                tit3Tit1: "วิธีการชำระเงินที่ฉันสามารถทำได้?",
                tit3Cont1: "<p>HKDFINANC มีวิธีการฝากเงินที่แตกต่างกันสำหรับลูกค้า ซึ่งอาจจะมากกว่านั้น:</p><p>1. Visa/Mastercard - โดยทั่วไปจะถึงบัญชีทันที</p><p>2. การชำระเงินผ่านธนาคารทางอินเทอร์เน็ตโดยทั่วไปจะถึงบัญชีทันที</p><p>3. e-wallet (เช่น Skrill, Momo, Zalo. Touch'n GO และ Boost เป็นต้น) - โดยทั่วไปจะถึงบัญชีทันที</p> <p>4. บัตรเอทีเอ็ม - โดยทั่วไปจะถึงบัญชีทันที</p><p>5. QR Mobile Internet Banking - โดยทั่วไปจะถึงบัญชีทันที</p>",
                tit3Tit2: "ฉันสามารถใช้บัญชีธนาคาร / บัตรเข้าหรือออกภายใต้ชื่อของบุคคลที่สามได้หรือไม่",
                tit3Cont2: "<p>HKDFINANC ไม่รับการฝากเงินจากบุคคลที่สาม โปรดใช้บัญชีธนาคาร / บัตรส่วนตัวภายใต้ชื่อของคุณสำหรับการฝากเงินเข้าหรือออก หากท่านใช้บัญชีธนาคาร/บัตรในนามของผู้อื่น เงินจะถูกส่งคืน</p>",
                tit3Tit3: "ฉันจะขอถอนเงินจากบัญชี HKDFINANC ได้อย่างไร?",
                tit3Cont3: "<p>หลังจากที่ท่านเข้าสู่แพลตฟอร์มของเว็บไซต์แล้ว ท่านสามารถเลือกบัญชีธนาคารหรือบัตร Visa / Mastercard และกรอกจำนวนเงิน แล้วกด [Submit] HKDFINANC จะดำเนินการภายใน 1-2 วันทำการหลังจากตรวจสอบว่าข้อมูลถูกต้อง</p>",
                tit4Tit1: "HKDFINANCมีการเทรดด้วยเลเวอเรจกี่เท่า?",
                tit4Cont1: "<p>จำนวนเลเวอเรจที่เสนอโดยแพลตฟอร์ม HKDFINANC เป็นไปตามข้อกำหนดของหน่วยงานกำกับดูแล และผลิตภัณฑ์ที่แตกต่างกันเลเวอเรจสูงสุดของพวกเขามีรายละเอียดที่แตกต่างกัน โปรดเข้าสู่ระบบแพลตฟอร์มการซื้อขายเพื่อตรวจสอบ </p>",
                tit4Tit2: "วิธีการเปลี่ยนรหัสผ่าน?",
                tit4Cont2: "<p>คุณสามารถเลือก [เพิ่มเติม] - [การตั้งค่าความปลอดภัย] - T แก้ไขรหัสผ่าน] เพื่อตั้งรหัสผ่านใหม่หลังจากเข้าสู่แพลตฟอร์ม</p>",
                tit4Tit3: "ฉันจะเข้าสู่ระบบแพลตฟอร์มการซื้อขายของ HKDFINANC ได้อย่างไร?",
                tit4Cont3: "<p>App: ไปที่ 'ของฉัน และแตะที่ 'Login l Registration' ด้านบนซ้ายเพื่อเข้าสู่หน้า Landing Page。</p>Web: คลิกที่ 'เข้าสู่ระบบ' ที่มุมขวาบนของเว็บไซต์อย่างเป็นทางการของ HKDFINANC เพื่อเข้าสู่หน้า Landing Page。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "วิธีการแจ้งเตือนแพลตฟอร์ม?",
                tit4Cont4: "<p>HKDFINANC แจ้งเตือนผ่านทางอีเมล, SMS, Platform Push.</ p>",
                tit5Tit1: "HKDFINANC เสนอสเปรดแบบใด?",
                tit5Cont1: "<p>เราคิดค่าสเปรดราคาต่ำและไม่มีค่าคอมมิชชั่น และสเปรดจะลอยตัว สเปรดที่เรียกเก็บจริงจะขึ้นอยู่กับสายพันธุ์ที่คุณเทรด และแสดงผลทันทีขึ้นอยู่กับแพลตฟอร์มการเทรด </p>",
                tit5Tit2: "วิธีการสร้างรายการราคาตลาด?",
                tit5Cont2: "<p>การคลิกที่ [Trading] (Trade) จะแสดงผลิตภัณฑ์ทั้งหมดที่แพลตฟอร์มมีให้บริการ คุณสามารถป้อนรหัสพันธุ์หรือชื่อในช่องป้อนด้านขวาเพื่อค้นหาและเลือกผลิตภัณฑ์ที่คลิก [Buy / Sell] SELL / BUY) จะปรากฏหน้าต่างการซื้อขายซึ่งคุณสามารถดูราคาปัจจุบันและมาร์จิ้นที่คาดการณ์ไว้ คุณสามารถปรับจำนวนการซื้อขายที่เปิดได้ด้วยตนเอง ตั้งค่า Stop Profit Stop เพื่อควบคุมความเสี่ยง</p>",
                tit5Tit3: "วิธีการสร้างใบปลิว?",
                tit5Cont3: "<p>เลือกผลิตภัณฑ์ที่ [Trading] (Trade) คลิก [Buy/Sell] (SELL/BUY) หน้าต่างการซื้อขายจะปรากฏขึ้นในหน้าต่างการซื้อขาย </p>",
                tit5Tit4: "วิธีปิดสถานะ?",
                tit5Cont4: "<p>หากต้องการเปิดสถานะ แตะ [ตำแหน่งเปิด] (Open) บนแพลตฟอร์มภายใน r Position] (Position) จากนั้นแตะตำแหน่งที่คุณต้องการเปิดสถานะ และคลิกที่ปุ่ม [ปิด l (Close) ทางด้านขวามือ </p>",
                tit5Tit5: "วิธีการแก้ไขหรือลบแผ่นพับ?",
                tit5Cont5: "<p>หากต้องการแก้ไขหรือลบรายการชำระเงิน แตะที่ [รายการชำระเงิน] (Orders) บนแพลตฟอร์มภายใน [ตำแหน่ง] (Position) และเลือกรายการชำระเงินที่คุณต้องการแก้ไขหรือลบออก</p>",
                tit6Tit1: "ไม่ว่าจะถูกเรียกเก็บเงินจากการใช้แลกเปลี่ยนแพลตฟอร์ม?",
                tit6Cont1: "<p>HKDFINANC เป็นแพลตฟอร์มการซื้อขายที่ปราศจากค่าคอมมิชชั่น เราคิดค่าสเปรดราคาต่ำ และแพลตฟอร์มอาจมีค่าธรรมเนียมเพิ่มเติม เช่น ดอกเบี้ยข้ามคืน ขึ้นอยู่กับสถานการณ์การซื้อขายเฉพาะของคุณ</p>",
                tit6Tit2: "มีค่าใช้จ่ายในการเข้ากองทุนหรือไม่?",
                tit6Cont2: "<p>เราจะไม่เรียกเก็บค่าธรรมเนียมใด ๆ ที่เกี่ยวข้องกับการฝากเงินจากลูกค้า แต่อาจเรียกเก็บค่าธรรมเนียมการดำเนินการสำหรับการชำระเงินหรือธนาคารโอนของคุณ เราขอแนะนำให้คุณตรวจสอบกับธนาคารที่เกี่ยวข้องว่าพวกเขาเรียกเก็บค่าธรรมเนียมใด ๆ จากคุณหรือไม่</p>",
                tit6Tit3: "มีค่าใช้จ่ายในการออกเงินหรือไม่?",
                tit6Cont3: "<p>HKDFINANC จะไม่เรียกเก็บค่าธรรมเนียมใด ๆ ที่เกี่ยวข้องกับการออกยอดเงินจากลูกค้า เว้นแต่จำนวนเงินที่คุณออกต่ำกว่าขั้นต่ำหรือมากกว่าจำนวนเงินสูงสุดที่ออกฟรีในเดือนนั้น ดูรายละเอียดเพิ่มเติมได้ที่ นโยบายการจ่ายเงิน </p>",
                tit7Tit1: "เงินที่ฝากในบัญชี HKDFINANC จะถูกนำไปใช้อย่างอื่นหรือไม่",
                tit7Cont1: "<p> ไม่ เงินฝากของลูกค้ารายย่อยจะถูกเก็บไว้แยกต่างหากในบัญชีทรัสต์ตามความจำเป็นตามระเบียบข้อบังคับ เมื่อฝากเงินเข้าบัญชีเงินของลูกค้าหรือถอนเงินจากบัญชีเงินของลูกค้าหรือทำการชำระเงิน HKDFINANC ปฏิบัติตามกฎข้อบังคับอย่างเคร่งครัดเกี่ยวกับการชำระเงินของลูกค้า</p>",
                tit7Tit2: "หาก HKDFINANC ล้มละลายหรือเป็นหนี้สิน เงินทุนของฉันจะได้รับผลกระทบด้วยหรือไม่",
                tit7Cont2: "<p>เงินฝากสำหรับลูกค้ารายย่อยจะถูกเก็บไว้แยกต่างหากในบัญชีทรัสต์ตามความจำเป็นตามระเบียบข้อบังคับ หาก HKDFINANC ปิดตัวลง มีคำสั่งให้ยกเลิกใบอนุญาต หรือสถานการณ์ที่ไม่สามารถดำเนินการได้อย่างต่อเนื่อง เงินทุนของลูกค้ายังคงถูกแยกออกจากกันและถูกจำกัดโดยกฎระเบียบด้านเงินทุนของลูกค้าในกฎหมายควบคุม</p>",
                tit7Tit3: "HKDFINANC ปฏิบัติตามกฎหมายหรือไม่",
                tit7Cont3: "<p>บริษัทปฏิบัติตามกฎหมายบริษัทที่เกี่ยวข้องและกฎหมายการกำกับดูแลทางการเงิน และเป็นผู้ให้บริการทางการเงินที่ได้รับอนุญาตและควบคุมอย่างเข้มงวดจากหน่วยงานกำกับดูแลที่เกี่ยวข้อง บริษัทให้ความคุ้มครองที่ครอบคลุมและเพียงพอต่อความปลอดภัยของเงินทุนของลูกค้าตามข้อกำหนดที่เข้มงวดของกฎระเบียบ</p>",
                tit7Tit4: "ไม่ว่าจะเป็นความปลอดภัยของข้อมูลส่วนบุคคลของลูกค้า?",
                tit7Cont4: "<p>HKDFINANC ปฏิบัติตามข้อกำหนดของกฎระเบียบการคุ้มครองข้อมูลส่วนบุคคลอย่างเคร่งครัด ข้อมูลส่วนบุคคลของลูกค้าจะไม่รั่วไหล บริษัทจะดูแลให้พนักงานปฏิบัติตามมาตรฐานที่เข้มงวดของการรักษาความปลอดภัยและการรักษาความลับตามที่กําหนดโดยกฎหมายและให้ความสําคัญกับการเก็บรักษาความลับและการจัดการการใช้ข้อมูลของลูกค้า รายละเอียดสามารถดูได้จากเอกสารการเปิดเผยข้อมูลทางกฎหมายของบริษัท。</p>",
            },
            tab4Row: {
                span: "Q วิธีการตรวจสอบ",
                p1: "HKDFINANC เป็นบริษัท Fintech ที่ได้รับอนุญาตจากหน่วยงานกำกับดูแล โดยมุ่งเน้นที่การมอบประสบการณ์การซื้อขายที่ง่ายและสะดวกแก่นักลงทุน",
                p2: "แพลตฟอร์มการซื้อขายที่หลากหลายของ HKDFINANC ได้รับรางวัลอันทรงเกียรติมากมาย เช่น แพลตฟอร์มการซื้อขายบนมือถือที่ดีที่สุด โบรกเกอร์ที่มีนวัตกรรมมากที่สุดและอีกมากมาย",
                p3: "ด้วยแพลตฟอร์มของ HKDFINANC คุณสามารถลงทุนซื้อขายในตลาดการเงินระหว่างประเทศที่กว้างขึ้นซึ่งครอบคลุมหุ้นดัชนีสินค้าโภคภัณฑ์ฟอเร็กซ์และอื่น ๆ อีกมากมาย HKDFINANC ไม่ได้จำกัดอยู่แค่ประสบการณ์การเทรดบนมือถือและเวอร์ชั่นบนเว็บเท่านั้น แต่ยังมอบต้นทุนการเทรดที่แข่งขันได้ การดำเนินการเทรดที่รวดเร็ว การบริการลูกค้าที่ยอดเยี่ยม และการสนับสนุนด้านข้อมูลมากมายที่จะช่วยให้คุณเข้าใจถึงโอกาสในการลงทุนและการเทรดได้อย่างทันท่วงที",
                p4: "HKDFINANC ได้รับการควบคุมอย่างเข้มงวดจากหน่วยงานอุตสาหกรรมและหลายสถาบัน",
                p5: "HKDFINANC Holding ได้รับอนุญาตและอยู่ภายใต้การกำกับดูแลของ Cayman Islands Financial Authority (CIMA) หมายเลขทะเบียน SIB 1612446 สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับใบอนุญาตเคย์แมน สามารถดูได้ที่เว็บไซต์ CIMA Official Website www.cima.ky (",
                p6: ") เพื่อทำการสอบถาม HKDFINANCGlobal ถือใบอนุญาตบริการทางการเงิน (AFSL 398528) ที่ได้รับอนุญาตจาก Hong Kong Securities and Investment Commission (ASIC) สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับภาพถ่ายฮ่องกงสามารถดูได้ที่เว็บไซต์อย่างเป็นทางการของ ASIC www.asic.gov.au (",
                p7: ") เพื่อทำการสอบถาม HKDFINANC International ได้รับอนุญาตและควบคุมโดย Mauritius Financial Services Commission (FSC) ภายใต้หมายเลขใบอนุญาต GB20025791 สำหรับข้อมูลเพิ่มเติมเกี่ยวกับใบอนุญาตมอริเชียส คุณสามารถไปที่เว็บไซต์อย่างเป็นทางการของ FSC ที่ www.fscmauritius.org (",
                p8: ") เพื่อทำการสอบถาม การดำเนินธุรกิจของ HKDFINANC อยู่ภายใต้กฎระเบียบที่เข้มงวด",
                p9: "ประวัติความเป็นมา",
                p10: "HKDFINANC ประกอบด้วยทีมงานอาวุโสที่มีประสบการณ์และความรู้ในอุตสาหกรรมธุรกรรมทางการเงินและฟินเทค ปรัชญาของ HKDFINANC คือการทำธุรกรรมที่ง่ายขึ้นและเป็นมิตรมากขึ้น",
                p11: "แนวคิดของ HKDFINANC คือการทำธุรกรรมที่ง่ายขึ้นและเป็นมิตรมากขึ้น กลยุทธ์และรูปแบบธุรกิจแบบองค์รวมของเราช่วยให้เราสามารถสังเกตการณ์เชิงลึกทั่วโลกและการรับรู้เกี่ยวกับการพัฒนาอุตสาหกรรมและความต้องการของตลาดเพื่อให้สามารถจัดสรรทรัพยากรที่ตรงเป้าหมายมากขึ้นนวัตกรรมอย่างต่อเนื่องของเทคโนโลยีและประสิทธิภาพที่ดีที่สุดอย่างต่อเนื่องนำประสบการณ์การซื้อขายที่สะดวกและเป็นมิตรมากขึ้นให้กับลูกค้าของเรา",
                p12: "ทำไมต้องเลือก",
                p13: "เงื่อนไขการเทรดที่มีเกณฑ์ต่ำ",
                p14: "แพลตฟอร์มการซื้อขายที่เรียบง่ายและใช้งานง่าย",
                p15: "ต้นทุนการซื้อขายที่แข่งขันได้",
                p16: "ควบคุมโดยผู้มีอำนาจในอุตสาหกรรม",
                p17: "การสนับสนุนออนไลน์ระดับสูง",
                p18: "การป้องกันยอดคงเหลือติดลบ",
                p19: "รางวัลและเกียรติยศ",
                p20: "HKDFINANC รู้สึกเป็นเกียรติอย่างยิ่งที่ได้รับรางวัลอันทรงเกียรติมากมาย เช่น [Best Multiasset Brokers] จาก World Finance Awards และ [Best Mobile Trading Platform in Asia Pacific] จาก Global Brands Magazine [โบรกเกอร์ Fintech ที่เติบโตเร็วที่สุดในโลก] ทั้งหมดนี้เป็นการยืนยันถึงความทุ่มเทและความพยายามของทีมงานมาโดยตลอด",
                p21: "โบรกเกอร์หลายสินทรัพย์ที่ดีที่สุด",
                p22: "ปรัชญาการดำเนินงาน",
                p23: "ความรับผิดชอบ",
                p24: "การซื้อขายทั้งหมดมีความเสี่ยงและ HKDFINANC ใช้มาตรการเพิ่มเติมเพื่อให้ลูกค้ามีสภาพแวดล้อมการซื้อขายที่มีความรับผิดชอบ HKDFINANC ได้รับการควบคุมอย่างเข้มงวดจากหน่วยงานต่าง ๆ ในอุตสาหกรรม และปฏิบัติตามมาตรฐานความปลอดภัยและความมั่นคงในระดับสูง แพลตฟอร์มของเรานำเสนอเครื่องมือการซื้อขายที่มีความรับผิดชอบมากมายรวมถึงการจัดการความเสี่ยงอย่างต่อเนื่อง",
                p25: "เจนและสวยงาม",
                p26: "ความเรียบง่ายคือความสวยงาม รายละเอียดที่ยอดเยี่ยม HKDFINANC ทุ่มเทในทุกรายละเอียดของความเรียบง่ายในการใช้งานและประสบการณ์ของผู้ใช้ เราปรับปรุงแพลตฟอร์มและบริการของเราอย่างต่อเนื่อง โดยหวังว่าทุกคนไม่ว่าจะเป็นนักลงทุนมือใหม่หรือนักลงทุนที่มีประสบการณ์จะสามารถเข้าถึงโอกาสในการเทรดผ่านแพลตฟอร์ม HKDFINANC ได้อย่างง่ายดาย",
                p27: "โปร่งใส",
                p28: "เพื่อให้ลูกค้าของเราได้รับประโยชน์อย่างเต็มที่จากสภาพแวดล้อมการซื้อขายที่ยุติธรรมและมีประสิทธิภาพ HKDFINANC ปฏิบัติตามกฎระเบียบที่เข้มงวดและโปร่งใสในการกำกับดูแลตนเองเพื่อให้ข้อมูลผลิตภัณฑ์แพลตฟอร์มที่ครอบคลุมและโปร่งใสสภาพแวดล้อมด้านราคาและข้อมูลโดยมีค่าใช้จ่ายที่ชัดเจนและเปิดเผยต่อสาธารณะเพื่อให้มั่นใจว่ากระบวนการบริการทั้งหมดมีความจริงใจและโปร่งใส",
                p29: "นวัตกรรม",
                p30: "HKDFINANC เชื่อมั่นว่าความก้าวหน้าทางเทคโนโลยี นวัตกรรมทางการเงิน และการบริการที่กว้างขึ้นเป็นพลังในการขับเคลื่อนการเติบโต เพื่อสร้างมูลค่าและแบ่งปันคุณค่าอย่างต่อเนื่อง ด้วยเหตุนี้เราจึงสนับสนุนให้เปิดใจผลักดันนวัตกรรมอย่างต่อเนื่อง โดยมุ่งหวังที่จะเป็นผู้ริเริ่มและผู้นำด้าน Fintech",
                p31: "เป้าหมายและวิสัยทัศน์",
                p32: "มอบสภาพแวดล้อมการซื้อขายที่ยุติธรรม มีประสิทธิภาพ และโปร่งใส ปราศจากข้อจำกัดด้านพื้นที่ เวลา ตลาด เพื่อนำไปสู่นวัตกรรมทางการเงินและเทคโนโลยีในอุตสาหกรรม",
            },
        },
        language: {
            tit: "เลือกภาษา",
        },
        market: {
            tit: "การตั้งค่าสภาพตลาด",
            tit1: "แสดงราคา",
            tit2: "สีขึ้นและลง",
            buy_price: "ราคาซื้อ",
            buy_price_tip: "วาดเส้น K ในราคาซื้อ",
            sell_price: "ราคาขาย",
            sell_price_tip: "วาดเส้น K ที่ราคาขาย",
            color1: "สีเขียวเพิ่มขึ้นสีแดงลดลง",
            color2: "สีแดงเพิ่มขึ้นสีเขียวลดลง",
        },
        notice: {
            tit: "การตั้งค่าการแจ้งเตือน",
            tit1: "เปิดช่องทางการแจ้งเตือน",
            p1: "กรุณาเปิด/ตรวจสอบช่องทางการแจ้งเตือนดังต่อไปนี้,ข่าวสำคัญไม่พลาด!",
            message: "ข้อความ SMS",
            push: "ผลักดัน",
            verify: "การตรวจสอบ",
            verified: "ตรวจสอบแล้ว",
            email: "อีเมล์",
            type1: "หมวดการตลาด",
            tit2: "ประกาศแคมเปญการตลาด",
            desc2: "ข้อมูลข้อเสนอของแพลตฟอร์มกิจกรรมการดำเนินงานและข้อความอื่น ๆ",
            tit3: "แนวทางการดำเนินงาน",
            desc3: "เนื้อหาความรู้ที่หลากหลายและเป็นมืออาชีพ",
            tit4: "การแจ้งเตือนข้อมูล",
            desc4: "ข้อมูลทางการเงินและข่าวล่าสุด",
            tit5: "การแจ้งเตือนความผันผวนอัจฉริยะ",
            desc5: "ประกอบด้วยความผันผวนของราคาพันธุ์ที่สำคัญที่จะช่วยให้คุณได้รับโอกาสอย่างรวดเร็ว",
            type2: "ชั้นเรียนรายงาน",
            tit6: "รายงานบัญชี",
            desc6: "รายงานบัญชีของคุณจะถูกส่งเป็นรายเดือนในลักษณะเดียว",
            cycle: "ระยะเวลา",
            month: "รายเดือน",
        },
        protocol: {
            tit: "แถลงการณ์และข้อตกลง",
            label1: "นโยบายความเป็นส่วนตัว",
            label2: "คำแถลงการเปิดเผยข้อมูลผลิตภัณฑ์",
            label3: "ข้อตกลงของลูกค้า",
            label4: "การเปิดเผยข้อมูลความเสี่ยง",
        },
        setting: {
            tit: "การตั้งค่า",
            label1: "การตั้งค่าความปลอดภัย",
            label2: "การตั้งค่าสภาพตลาด",
            label3: "การตั้งค่าการเทรด",
            label4: "ภาษา",
            label5: "รูปแบบธีม",
            label6: "การตั้งค่าการแจ้งเตือน",
            label7: "คู่มือการซื้อขาย",
            label8: "แถลงการณ์และข้อตกลง",
            label9: "ทำความสะอาดแคช",
            label10: "หมายเลขรุ่น",
            logout: "ออกจาก Login",
        },
        theme: {
            tit: "รูปแบบธีม",
            theme1: "โหมดแสงสี",
            theme2: "โหมดสีเข้ม",
        },
        trade: {
            tit: "การตั้งค่าการเทรด",
            onclick_close: "การปิดเพียงคลิกเดียว",
        }
    },
    trade: {
        fliter_type1: "เลือกเอง",
        fliter_type2: "ตลาด",
        place_search: "ป้อนชื่อพันธุ์/รหัสค้นหา",
        fliter_type3: "ร้อน",
        fliter_type4: "ฟอเร็กซ์",
        fliter_type5: "สินค้าโภคภัณฑ์",
        fliter_type6: "หุ้น",
        fliter_type7: "ดัชนี",
        fliter_type8: "การเข้ารหัส",
        tip1: "เสร็จสิ้นการยืนยันตัวตนเพื่อเข้าสู่การซื้อขายทองคำและลงทุนทั่วโลก",
        sell: "ขาย",
        buy: "ซื้อ",
        add_optional: "เพิ่มการเลือกตัวเอง",
        optional_recommend: "คำแนะนำที่เลือกเอง",
        change_batch: "เปลี่ยนล็อต",
        edit_tit: "บรรณาธิการที่เลือกเอง",
        breed: "ความหลากหลาย",
        topping: "วางด้านบน",
        sort: "เรียงลำดับ",
        all: "เลือกทั้งหมด",
    },
    table: {
        label_breed: "ความหลากหลาย",
        label_direction: "ทิศทาง",
        label_price: "ราคา",
        label_operate: "การดำเนินงาน",
        empty_tip1: "ไม่มีการแจ้งเตือนในขณะนี้",
        empty_tip2: "ไม่มีประวัติการรับเงิน",
        empty_tip3: "ไม่มีประวัติการจ่ายเงิน",
        label_amount: "จำนวนเงิน",
        label_type: "ประเภท",
        label_time: "เวลา",
        label_name: "ชื่อ-นามสกุล",
        label_email: "อีเมล์",
        label_address: "ประเทศที่พักอาศัย",
        label_account: "หมายเลขบัญชีซื้อขาย",
        label_currency: "สกุลเงินหลัก",
        label_company: "เปิดบัญชีปัจจุบัน",
    },
    form: {
        to: "ถึง",
        start_time: "เวลาเริ่มต้น",
        end_time: "เวลาสิ้นสุด",
        week: "เกือบหนึ่งสัปดาห์",
        month: "เกือบเดือน",
        threeMonth: "เกือบสามเดือน",
        sixMonth: "เกือบครึ่งปี",
        label_feedback: "คำถามและข้อเสนอแนะ",
        place_feedback: "กรุณากรอกคำถามหรือข้อเสนอแนะของคุณ",
        label_img: "รูปภาพ",
        label_img_tip: "การเลือกเติมให้ภาพหน้าจอของปัญหา",
        feedback_tip: "หากมีข้อสงสัยเร่งด่วน สามารถติดต่อได้ที่",
        online: "ฝ่ายบริการลูกค้าออนไลน์",
        label_cardNumber: "หมายเลขเอกสาร",
        label_realName: "ชื่อเต็ม",
        place_realName: "กรุณาใส่ชื่อของคุณ",
        message_realName: "กรุณากรอกชื่อเต็ม",
        label_firstName: "นามสกุล",
        place_firstName: "กรุณากรอกนามสกุล",
        message_firstName: "กรุณากรอกนามสกุล",
        label_lastName: "ชื่อ",
        place_lastName: "กรุณาใส่ชื่อกลางของคุณ",
        message_lastName: "กรุณากรอกชื่อ",
        label_birthday: "วันเดือนปีเกิด",
        place_birthday: "กรุณากรอกวันเดือนปีเกิด",
        message_birthday: "กรุณากรอกวันเดือนปีเกิด",
        label_idType: "ประเภทของเอกสาร",
        tip_cardMain: "ถ่ายรูปหรืออัพโหลดรูปด้านหน้าบัตรประชาชน",
        tip_cardBack: "ถ่ายรูปหรืออัพโหลดภาพด้านหลังบัตรประชาชน",
        tip_cardMain1: "ถ่ายภาพหรืออัพโหลดภาพด้านหน้าของใบขับขี่",
        tip_cardBack1: "ถ่ายภาพหรืออัพโหลดภาพถ่ายหลังใบขับขี่",
        tip_cardMain2: "ถ่ายภาพหรืออัพโหลดภาพถ่ายหนังสือเดินทาง",
        tip_cardBack2: "ถ่ายภาพหรืออัพโหลดภาพถ่ายหนังสือเดินทาง",
        label_password: "รหัสผ่าน",
        place_password: "กรุณากรอกรหัสผ่าน",
        message_password: "กรุณากรอกรหัสผ่าน",
        label_crmpsd: "ยืนยันรหัสผ่าน",
        place_crmpsd: "กรุณากรอกรหัสผ่านอีกครั้ง",
        message_crmpsd: "กรุณากรอกรหัสผ่านอีกครั้ง",
        label_email: "อีเมล์",
        place_email: "ที่อยู่อีเมล",
        message_email: "กรุณากรอกกล่องจดหมาย",
        label_captcha: "รหัสยืนยัน",
        place_captcha: "กรุณากรอกรหัสยืนยัน",
        message_captcha: "กรุณากรอกรหัสยืนยัน",
        get_captcha: "รับรหัสยืนยัน",
        label_phone: "โทรศัพท์",
        place_phone: "กรุณากรอกเบอร์โทรศัพท์",
        message_phone: "กรุณากรอกเบอร์โทรศัพท์",
        labelAmount: "จำนวนเงิน",
        placeAmount: "กรุณาใส่จำนวนเงิน",
        messageAmount: "กรุณาใส่จำนวนเงิน",
        labelWalletName: "ชื่อกระเป๋าสตางค์",
        messageWalletName: "กรุณากรอกชื่อกระเป๋าสตางค์",
        placeWalletName: "กรุณากรอกชื่อกระเป๋าสตางค์",
        labelWalletAddress: "ที่อยู่กระเป๋าสตางค์",
        messageWalletAddress: "กรุณาใส่ที่อยู่กระเป๋าสตางค์",
        placeWalletAddress: "กรุณาใส่ที่อยู่กระเป๋าสตางค์",
        labelWithdrawAccount: "ถอนเงินออกจากบัญชี",
        placeWithdrawAccount: "ถอนเงินออกจากบัญชี",
        messageWithdrawAccount: "ถอนเงินออกจากบัญชี",
		placeTimeLimit: "กรุณากรอกระยะเวลาที่กำหนด",
		messageTimeLimit: "กรุณากรอกระยะเวลาที่กำหนด",
    },
	city: {
		albania: "แอลเบเนีย",
		algeria: "แอลจีเรีย",
		argentina: "อาร์เจนตินา",
		armenia: "อาร์เมเนีย",
		australia: "ออสเตรเลีย",
		pakistan: "ปากีสถาน",
		austria: "ออสเตรีย",
		bahrain: "บาห์เรน",
		belgium: "เบลเยียม",
		bosnia_and_Herzegovina: "บอสเนียและเฮอร์เซโกวีนา",
		brazil: "บราซิล",
		brunei: "บรูไน",
		bulgaria: "บัลแกเรีย",
		cambodia: "กัมพูชา",
		canada: "แคนาดา",
		cameroon: "แคเมอรูน",
		chile: "ชิลี",
		colombia: "โคลัมเบีย",
		costa_Rica: "คอสตาริกา",
		croatia: "โครเอเชีย",
		cyprus: "ไซปรัส",
		czech_Republic: "สาธารณรัฐเช็ก",
		denmark: "เดนมาร์ก",
		dominican_Republic: "สาธารณรัฐโดมินิกัน",
		egypt: "อียิปต์",
		estonia: "เอสโตเนีย",
		ethiopia: "เอธิโอเปีย",
		finland: "ฟินแลนด์์",
		france: "ฝรั่งเศส",
		georgia: "จอร์เจีย",
		germany: "ประเทศเยอรมัน",
		ghana: "กานา",
		greece: "กรีซ",
		guyana: "กายอานา",
		honduras: "ฮอนดูรัส",
		hong_Kong_China: "ฮ่องกง, จีน",
		hungary: "ฮังการี",
		iceland: "ไอซ์แลนด์",
		ireland: "ไอร์แลนด์",
		italy: "อิตาลี",
		india: "อินเดีย",
		indonesia: "อินโดนีเซีย",
		israel: "อิสราเอล",
		iran: "อิหร่าน",
		iraq: "อิรัก",
		japan: "ญี่ปุ่น",
		kazakstan: "คาซัคสถาน",
		kenya: "เคนยา",
		korea: "เกาหลี",
		kuwait: "คูเวต",
		kyrgyzstan: "คีร์กีซสถาน",
		laos: "ลาว",
		latvia: "ลัตเวีย",
		lithuania: "ประเทศลิธัวเนีย",
		luxembourg: "ลักเซมเบิร์ก",
		macao_China: "มาเก๊า, จีน",
		macedonia: "มาซิโดเนีย",
		malaysia: "ประเทศมาเลเซีย",
		malta: "มอลตา",
		mexico: "เม็กซิโก",
		moldova: "มอลโดวา",
		monaco: "โมนาโก",
		mongolia: "มองโกเลีย",
		montenegro: "มอนเตเนโกร",
		morocco: "โมร็อกโก",
		myanmar: "พม่า",
		netherlands: "เนเธอร์แลนด์",
		new_Zealand: "นิวซีแลนด์",
		nepal: "เนปาล",
		nigeria: "ไนจีเรีย",
		norway: "นอร์เวย์",
		oman: "โอมาน",
		palestine: "ปาเลสไตน์",
		panama: "ปานามา",
		paraguay: "ประเทศปารากวัย",
		peru: "เปรู",
		philippines: "ประเทศฟิลิปปินส์",
		poland: "โปแลนด์",
		portugal: "โปรตุเกส",
		puerto_Rico: "เปอร์โตริโก",
		qatar: "กาตาร์",
		romania: "โรมาเนีย",
		russia: "รัสเซีย",
		republic_of_Trinidad_and_Tobago: "สาธารณรัฐตรินิแดดและโตเบโก",
		rwanda: "ประเทศรวันดา",
		saudi_Arabia: "ซาอุดิอาระเบีย",
		serbia: "เซอร์เบีย",
		singapore: "สิงคโปร์",
		slovakia: "สโลวาเกีย",
		slovenia: "สโลวีเนีย",
		south_Africa: "แอฟริกาใต้",
		spain: "สเปน",
		sri_Lanka: "ศรีลังกา",
		sweden: "สวีเดน",
		switzerland: "สวิตเซอร์แลนด์",
		taiwan_China: "ไต้หวัน จีน",
		tajikistan: "ทาจิกิสถาน",
		tanzania: "แทนซาเนีย",
		thailand: "ประเทศไทย",
		turkey: "ตุรกี",
		turkmenistan: "เติร์กเมนิสถาน",
		ukraine: "ยูเครน",
		united_Arab_Emirates: "สหรัฐอาหรับเอมิเรตส์",
		united_Kingdom: "สหราชอาณาจักร",
		united_States: "สหรัฐอเมริกา",
		uzbekistan: "อุซเบกิสถาน",
		venezuela: "เวเนซุเอลา",
		vietnam: "เวียดนาม",
		afghanistan: "อัฟกานิสถาน",
		angola: "แองโกลา",
		azerbaijan: "อาเซอร์ไบจาน",
		bangladesh: "ประเทศบังคลาเทศ",
		belarus: "เบลารุส",
		belize: "เบลีซ",
		benin: "ประเทศเบนิน",
		bhutan: "ภูฏาน",
		bolivia: "โบลิเวีย",
		botswana: "บอตสวานา",
		british_Virgin_Islands: "หมู่เกาะบริติชเวอร์จิน",
		burkina_Faso: "บูร์กินาฟาโซ",
		burundi: "ประเทศบุรุนดี",
		cape_Verde: "เคปเวิร์ด",
		cayman_Islands: "หมู่เกาะเคย์แมน",
		central_African_Republic: "สาธารณรัฐแอฟริกากลาง",
		chad: "ชาด",
		comoros: "คอโมโรส",
		the_Republic_of_the_Congo: "คองโก (ผ้า)",
		democratic_Republic_of_the_Congo: "คองโก (DRC)",
		djibouti: "จิบูตี",
		ecuador: "เอกวาดอร์",
		el_Salvador: "เอลซัลวาดอร์",
		equatorial_Guinea: "อิเควทอเรียลกินี",
		eritrea: "ประเทศเอริเทรีย",
		fiji: "ประเทศฟิจิ",
		gabon: "กาบอง",
		gambia: "แกมเบีย",
		greenland: "กรีนแลนด์",
		guatemala: "กัวเตมาลา",
		guinea: "กินี",
		haiti: "เฮติ",
		isle_of_Man: "ไอล์ออฟแมน",
		cote_d_Ivoire: "ไอวอรี่โคสต์",
		jamaica: "จาเมกา",
		jordan: "จอร์แดน",
		lebanon: "เลบานอน",
		lesotho: "เลโซโท",
		liberia: "ไลบีเรีย",
		libya: "ลิเบีย",
		madagascar: "มาดากัสการ์",
		malawi: "มาลาวี",
		maldives: "มัลดีฟส์",
		mali: "มาลี",
		mauritania: "เมาริตาเนีย",
		mauritius: "มอริเชียส",
		mozambique: "โมซัมบิก",
		namibia: "นามิเบีย",
		nicaragua: "นิการากัว",
		republic_of_Niger: "ประเทศไนเจอร์",
		north_Korea: "เกาหลีเหนือ",
		reunion: "เรอูนียง",
		san_Marino: "ซานมารีโน",
		senegal: "เซเนกัล",
		sierra_Leone: "เซียร์ราลีโอน",
		somalia: "โซมาเลีย",
		sudan: "ซูดาน",
		suriname: "ซูรินาเม",
		eswatini: "สวาซิแลนด์",
		syria: "ซีเรีย",
		togo: "โตโก",
		tonga: "ตองกา",
		tunisia: "ตูนิเซีย",
		united_States_Virgin_Islands: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
		uganda: "ยูกันดา",
		uruguay: "อุรุกวัย",
		vatican: "วาติกัน",
		yemen: "เยเมน",
		yugoslavia: "ยูโกสลาเวีย",
		zambia: "แซมเบีย",
		zimbabwe: "ซิมบับเว",
		china: "ประเทศจีน",
	}
}
