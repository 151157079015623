export default {
    crypto: {
        title: {
            0: "Buy Crypto",
            1: "Buy Crypto",
            2: "C2C",
        },
        list: {
            0: "C2C Trading",
            1: "Buy",
            2: "Sell",
            3: "Advertisers (Completion rate)",
            4: "Trades",
            5: "Price",
            6: "Available",
            7: "Limit",
            8: "Payment Method",
            9: "Trade",
            10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
        },
        buy: {
            0: "Buy Crypto",
            1: "Sell Crypto",
            2: "History",
            3: "Support",
            4: "Pay",
            5: "Receive",
            6: "Select payment method",
            7: "Est. arrival time",
            8: "FEE",
            9: "Order Details",
            10: "Payment method",
            11: "Learn more",
            12: "Price",
            13: "Total (fee included)",
            14: "Disclaimer",
            15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
            16: "I have read and agree to the Terms of Use",
            17: "You will get",
            18: "Submit",
            19: "Please enter the amount",
            20: "Insufficient account balance",
            21: "Available",
            22: "Spot Account Availiable",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        }
    },
    common: {
        view: "Pozrite si",
        confirm: "Definovať",
        cancel: "Zrušenie",
        deposit: "Vklad",
        withdraw: "Vybrať peniaze",
        noData: "Nie sú k dispozícii žiadne údaje",
        submit: "Predložiť",
        modify: "Úpravy",
        hand: "Ruka",
        confirm_verify: "Overiť",
        next: "Další krok",
        complete: "Splniť",
        delete: "Odstránenie",
		countryCode: "Kód krajiny/oblasti",
    },
    addnew: {
        buyUp: "Vznášajúce sa",
        buyDown: "Nákup a predaj",
        confirmOrder: "Potvrdenie objednávky",
        name: "Meno",
        fee: "Manipulačný poplatok",
        orderCycle: "Vyberte čas",
        loan: "Získať pôžičku",
        loanTit: "Studentská pôžička",
        loanHostory: "Záznamy o výpožičkách",
        labelProductTitle: "Názov produktu",
        immediateRepayment: "Okamžité splatenie",
        loadAmount: "Očakávaná výška úveru",
        RepaymentCycle: "Cyklus splácania pôžičky",
        day: "Obloha",
        dailyRate: "Denná úroková sadzba",
        repaymentMethod: "Spôsob splácania",
        lendingCompany: "Spoločnosť poskytujúca pôžičky",
        loanTip: "Úverové pôžičky (uistite sa, že sú jasne viditeľné obrázky)",
        loanTip1: "Nahratie osvedčenia o vlastníctve",
        loanTip2: "Doklad o príjme (pracovný pomer)",
        loanTip3: "Údaje z bankového výpisu",
        loanTip4: "Nahrať prednú fotografiu preukazu totožnosti",
        interest: "Sake",
        repaid: "Vyplatené späť",
        unpaid: "Neplatenie",
        tab1: "Mince",
        tab2: "Dodávateľ",
        tab3: "Možnosti",
        available: "Dostupný úver",
        pending: "Uzamknutie",
        equivalent: "Sumu na",
        fundTransfer: "Prevod finančných prostriedkov",
        flashExchange: "Hotovosť",
        select_breed: "Vyberte menu",
        from: "Od",
        to: "Do",
        transferQuantity: "Počet prevodov",
        repaymentMethod1:"Splatné v jednej splátke"
    },
    params: {
        product_foreign: "Devízová mena",
        product_shop: "Tovar",
        product_number: "Exponenty",
        product_stock: "Akcie",
        product_coin: "Zašifrované",
        billTypes: [
            {v: 101, name: 'Dobíjanie (peniaze na kartu)'},
            {v: 102, name: 'Vklady'},
            {v: 103, name: 'Zmrazenie'},
            {v: 104, name: 'Rozmrazenie'},
            {v: 105, name: 'Vklady'},
            {v: 106, name: 'Vklady'},
            {v: 201, name: 'Zmrazenie výberu'},
            {v: 202, name: 'Vybrať finančné prostriedky'},
            {v: 203, name: 'Úspešné stiahnutie'},
            {v: 204, name: 'Nevyberiete finančné prostriedky'},
            {v: 205, name: 'Poplatok za stiahnutie'},
            {v: 206, name: 'Transfer von'},
            {v: 207, name: 'Posunúť na'},
            {v: 208, name: 'Prenos mincí'},
            {v: 209, name: 'Prenos mincí'},
            {v: 301, name: 'Poplatok za spracovanie zmluvy'},
            {v: 302, name: 'Zmluvné príjmy'},
            {v: 303, name: 'Strata zmluvy'},
            {v: 304, name: 'Zmluvné rozpätie'},
            {v: 305, name: 'Návratnosť zmluvnej marže'},
            {v: 311, name: 'Nákup opcií'},
            {v: 312, name: 'Zisk z opcie'},
            {v: 313, name: 'Vrátenie možností'},
            {v: 314, name: 'Poplatok za manipuláciu s opciou'},
            {v: 315, name: 'Zmrazenie nákupu mincí'},
            {v: 316, name: 'Odpočet pri kúpe mincí'},
            {v: 317, name: 'Vrátenie nákupu mincí'},
            {v: 318, name: 'Zápis mincí na účet'},
            {v: 319, name: 'Zápis mincí na účet'},
            {v: 320, name: 'Zmrazenie predaja mincí'},
            {v: 321, name: 'Odpočet pri predaji mincí'},
            {v: 322, name: 'Vrátenie predaných mincí'},
            {v: 323, name: 'Predaj mincí na účet'},
            {v: 324, name: 'Predaj mincí na účet'},
            {v: 325, name: 'Poplatok za manipuláciu s mincami'},
            {v: 401, name: 'Baníci sa pridávajú'},
            {v: 402, name: 'Návraty baníkov'},
            {v: 403, name: 'Príjmy z ťažobných strojov'},
            {v: 404, name: 'Výstup z ťažobného stroja'},
            {v: 405, name: 'Výstupný poplatok za ťažbu'},
            {v: 411, name: 'Príjmy z výpožičiek'},
            {v: 412, name: 'Výpožičný poplatok'},
            {v: 413, name: 'Úspešné splatenie'},
        ],
    },

    footer: {
        nav1: "Home page",
        nav2: "Rokovania",
        nav3: "Pozície",
        nav4: "Môj",
        kline_set1: "Ticker symbol",
        kline_set2: "Nastavenia transakcií",
    },
    login: {
        login: "Prihlásiť sa",
        register: "Zápis",
        type1: "Císlo mobilného telefónu",
        type2: "Schránka",
        login_btn: "Zabezpečené prihlásenie",
        register_btn: "Zaregistrujte sa teraz",
        psd_login: "Prihlasovacie heslo",
        verify_login: "Prihlásenie pomocou CAPTCHA",
        forget: "Zabudli ste heslo??",
        no_account: "Žiadny účet？",
        have_account: "Existujúce účty",
    },
    home: {
        home: {
            nav1: "Poradie vzostupov a pádov",
            nav2: "Rýchly štart",
            nav3: "O nás",
            nav4: "Odmeny za pozvánky",
            most_follow: "Aktuálnosť",
            news_type1: "Byť taktný",
            news_type2: "Informácie pre verejnosť",
            news_type3: "Finančný kalendár",
            news_tab1: "Úplný",
            news_tab2: "Devízová mena",
            news_tab3: "Akcie",
            news_tab4: "Tovar",
            news_tab5: "Exponenty",
            news_tab6: "Císla",
            news_tab7: "Udalosť",
            news_tab8: "Dovolenka",
            label_previous: "Predchádzajúca hodnota",
            label_predictive: "Predpokladaná hodnota",
            label_announced: "Zverejnená hodnota",
        },
        kilne: {
            label_bp: "Včerajšia úroda",
            label_sp: "Moderný deň",
            label_height: "Najvyšší",
            label_low: "Najnižšia",
            tip: "Ahoj, som HKDFINANCGPT Variety Assistant.",
            row1_tit: "Byť taktný",
            row1_rinei: "V priebehu dňa",
            row1_short: "Krátkodobé",
            row1_middle: "V polovici obdobia",
            row1_time: "Čas vydania",
            row1_tit2: "Obchodná stratégia",
            data_sources: "Zdroje údajov",
            row2_tit1: "Císla",
            row2_tit2: "Obchodná nálada",
            row2_label_sell: "Predajca (tovaru)",
            row2_label_buy: "Kupujúci",
            tip2: "Len na informačné účely, nie je to stanovisko oddelenia",
            tip3: "Aktualizované každých 15 minút",
            row2_tit3: "Zmena ceny",
            minute: "Zápisnice",
            today: "V súčasnosti",
            right_tit7: "Rozsah zmeny ceny",
            now_price: "Aktuálna cena",
            right_low_price: "Najnižšia cena",
            right_height_price: "Najvyššia cena",
            details: "Údaje",
            right_label1: "Počet jednotlivých transakcií",
            right_label2: "Maximálny pákový efekt",
            right_label3: "Veľkosť zmluvy",
            right_label4: "Maximálny celkový počet pozícií",
            right_label5: "Plávajúce rozpätie",
            right_label6: "Nočné nabíjanie",
            hand: "Ruka",
            right_sell: "Reach (cena v aukcii)",
            right_buy: "Buy-in",
            right_collection_time: "Čas zberu",
            right_tip3: "Zbierané z celkovej veľkosti transakcie",
            right_label9: "Obchodné hodiny",
            right_label10: "Aktuálny časový interval",
            right_label11: "Další interval",
        },
        placeorder: {
            direction_buyPrice: "Ponuková cena",
            direction_sellPrice: "Predajná sadzba",
            direction_sell: "Reach (cena v aukcii)",
            direction_buy: "Buy-in",
            market_price: "Trhový cenník",
            check_price: "Čakajúca objednávka",
            sell_tip: "Predaj, keď cena spĺňa podmienky",
            buy_tip: "Kúpte, keď cena spĺňa podmienky",
            trade_number: "Počet transakcií",
            hand: "Ruka",
            select_lever: "Výber pákového efektu",
            right_tip: "Požadovaná záloha",
            balance: "Disponibilný zostatok",
            label_stop_win_price: "Prevzatie zisku",
            label_stop_lose_price: "Stop-loss",
            expected_profit_loss: "Predpokladaný zisk a strata",
            trailing_stop: "Trailing Stop",
        },
        ranking: {
            tit: "Najväčšie zisky a straty dňa",
            p: "Zachyťte horúce miesta a využite príležitosti",
            renew: "Aktualizované v",
            growth_chart: "Graf rastu",
            drop_list: "Zoznam porazených",
            show_closed_market: "Prezentácia značky Hugh's",
            filter1: "Úplný",
            filter2: "Devízová mena",
            filter3: "Tovar",
            filter4: "Akcie",
            filter5: "Exponenty",
            filter6: "Zašifrované",
            td1: "Rebríčky",
            td2: "Tendencie",
            td3: "Denný zisk / predajná cena",
        },
        remind: {
            now_sell_price: "Aktuálna požadovaná cena",
            direction_buyPrice: "Ponuková cena",
            direction_sellPrice: "Predajná sadzba",
            direction_sell: "Reach (cena v aukcii)",
            direction_buy: "Buy-in",
            equal: "Kedy",
            p: "Upozornite ma, keď sa dosiahnu nasledujúce ceny",
            btn1: "Nastavenie pripomienok",
            list_tit: "Zoznam pripomienok",
        },
    },
    my: {
        report: {
            tit: "Správy o účtoch",
            tab1: "Nahlasovanie",
            tab2: "Denný výkaz",
            tab3: "Mesačný výkaz",
            history_tit: "Správa o histórii transakcií",
            account_tit: "Správy o účtoch",
            filter: "Skríning",
            tip1: "Ak chcete exportovať správu o histórii transakcií, vyberte príslušný rozsah dátumov.。",
            tip2: "Vlastný čas",
            email_send: "Odoslať na e-mail",
        },
        accountMsg: {
            tit: "Informácie o účte",
            label1: "Osobné informácie",
        },
        bill: {
            tit: "Podrobnosti o toku finančných prostriedkov",
            all: "Úplný",
            time: "Krát",
        },
        feedback: {
            tit: "Funkčné odporúčania",
        },
        identity: {
            tit: "Overovanie",
            type1: "Občianske preukazy",
            type2: "Vodičský preukaz",
            type3: "Pas",
            tip: "Potvrdzujem, že všetky poskytnuté informácie a dokumenty sú úplné, pravdivé a presné.",
        },
        my: {
            is: "Potom",
            no: "Neoverené",
            verify: "Overené",
            id_verify: "Overovanie",
            verify_tip: "Dokončite overenie totožnosti na vklad a obchodovanie",
            no_verify: "Overiť teraz",
            balance: "Dostupný zostatok",
            profit_loss: "Ochabovanie a ubúdanie",
            marginLevel: "Úroveň marže",
            btn1: "Prepnutie na reálny účet",
            deposit_withdraw_record: "Záznamy o vkladoch a výberoch",
            capital_flow_details: "Podrobnosti o toku finančných prostriedkov",
            welfare_center: "Centrum sociálnej starostlivosti",
            tip1: "Máte",
            tip2: "Odmeny za perá, o ktoré je možné požiadať",
            feedback: "Funkčné odporúčania",
            report: "Správy o účtoch",
            setting: "Nastaviť",
        },
        news: {
            tit: "Oznámenia",
        },
        record: {
            tit: "Záznamy o vkladoch a výberoch",
            cycle: "Cyklickosť",
        },
        share: {
            tit: "Pozvať priateľov",
            invite_success: "Úspešne pozvať priateľov",
            tip1: "Peňažný stimul",
            tip2: "Pozvite svojich priateľov a",
            tip3: "Pozvať priateľov na príjem hotovosti",
        },
        welfare: {
            tit: "Centrum sociálnej starostlivosti",
            tab1: "Kartový rol",
            tab2: "Funkcia",
            history_record: "Historický záznam",
            period_validity: "Obdobie platnosti",
        },
        wallet: {
            tit: "Kabelky",
            addNew: "Pridať novú peňaženku",
        },
        withdraw: {
            tit: "Vybrať peniaze",
            addNew: "Pridať novú peňaženku",
        },
    },
    order: {
        demo_account: "Demo účet",
        real_account: "Zivý účet",
        tip: "Po otvorení môžete vkladať a obchodovať",
        tab1: "Holdingy",
        tab2: "Cakajúca objednávka",
        tab3: "Histórie",
        list_empty: "V súčasnosti žiadne čakajúce objednávky",
        start_trade: "Začiatok obchodovania",
        hand_tip: "Vstupný rozsah",
        kline: {
            direction_sell: "Reach (cena v aukcii)",
            direction_buy: "Buy-in",
            open_price: "Otváracia cena",
            now_price: "Aktuálna cena",
            trade_account: "Obchodný účet",
            demo_account: "Demo účet",
            order_id: "ID objednávky",
            open_time: "Cas otvorenia",
            stop_profit: "Prevzatie zisku",
            stop_loss: "Stop-loss",
            trailing_stop: "Trailing Stop",
            float_profit_loss: "Variabilný zisk a strata",
            overnight_fee: "Nočné nabíjanie",
            margin: "Margin",
            maintainsMargin: "Rozpätie údržby",
            open_value: "Otváracia hodnota",
            now_value: "Aktuálna hodnota",
            notset: "Nefixované",
            close_position: "Uzavrieť pozíciu",
            close_number: "Poradové číslo",
            onclick_close: "Zatvorenie pozície jedným kliknutím",
            btn: "Potvrdenie uzavretia pozície",
            balance: "Disponibilný zostatok",
            profit_loss: "Ochabovanie a ubúdanie",
            netValue: "Cistá hodnota",
            marginLevel: "Úroveň marže",
        },
        modify: {
            tit: "Úprava poradia pozícií",
            expected_profit_loss: "Predpokladaný zisk a strata",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Nastavenie nového hesla",
            verify_email_tit: "Overenie e-mailu",
            verify_password_tit: "Overenie hesla",
            verify_password_tip: "Zadajte svoje prihlasovacie heslo HKDFINANC a pokračujte",
            verify_phone_tit: "Overenie čísla mobilného telefónu",
            tit: "Zabezpečenie účtu",
            tip: "Na ochranu bezpečnosti vášho účtu a prijímanie oznámení o našich službách sa odporúča zapnúť aspoň jeden typ overovania.",
            label1: "Zmeniť heslo",
            label2: "Zrušenie účtov",
            label3: "Duálne overovanie",
            label4: "Správa zariadení",
            tip2: "Overovanie potrebné na prihlásenie nového zariadenia",
        },
        tradenews: {
            tit: "Sprievodca obchodovaním",
            tab1: "Rýchly štart",
            tab2: "Pokročilé štúdium",
            tab3: "Centrum pomoci",
            tab4: "O spoločnosti HKDFINANC",
            viewMore: "Zobraziť viac",
            details: {
                tit: "Úvod na devízu",
                tip1: "Odhadovaná dĺžka štúdia",
                tip2: "Zápisnice",
            },
            tab1Row: {
                tit: " Toto je rýchla trieda, ktorá vám umožní naučiť sa obchodovať s HKDFINANC!",
                tit1: "Ako môžem obchodovať na HKDFINANC?",
                tit2: "Ako si otvoriť živý účet？",
                tit3: "Ako otvoriť obchod？",
                tit4: "Ako nastaviť Stop Loss a Take Profit？",
                tit5: "Ako uzavrieť pozíciu？",
                p1: "S podkladovým nástrojom môžete obchodovať nákupom alebo predajom. Otvorenie pozície v HKDFINANC znamená, že máte záujem o túto",
                p2: "Nákup a predaj kolísania cien podkladového produktu",
                p3: "Ak napríklad očakávate, že cena zlata bude rásť, produkt kúpite; naopak, ak predpokladáte, že cena zlata bude klesať, produkt predáte. Ak budúcnosť splní očakávania, môžete",
                p4: "Profitovať z neho.",
                p5: "Okrem toho HKDFINANC ponúka 'pákové obchodovanie'. Pomocou pákového efektu môžete obchodovať s väčším počtom lotov s rovnakým množstvom peňazí.",
                p6: "Pákový efekt môže zväčšiť zisky a straty.",
                p7: "Toto obchodovanie je známe aj ako 'obchodovanie s maržou'. Keď otvoríte pozíciu, váš účet HKDFINANC bude zaťažený určitou sumou peňazí ako",
                p8: "Počiatočná marža",
                p9: "Vybrané produkty a pokyny na nákup a predaj",
                p10: "Rokovania",
                p11: "Kliknutím na tlačidlo 'Kúpiť/'Predať dokončite objednávku.",
                p12: "Nastavenie 'Take Profit' alebo 'Stop Loss' vám pomôže",
                p13: "Včas uzamknúť zisky a obmedziť straty",
                p14: "Pozíciu môžete otvoriť kliknutím na",
                p15: "Take Profit/Stop Loss",
                p16: "Na otvorených príkazoch môžete tiež kliknúť na tlačidlo 'Upraviť' a nastaviť stop-loss a take-profit.",
                p17: "Nastavenie alebo zrušenie",
                p18: "Zisk a stratu môžete zobraziť v časti 'Uzavreté pozície'.",
            },
            tab3Row: {
                h1: "Vitajte v HKDFINANC",
                h2: "Centrum pomoci",
                tit0: "Všetky otázky",
                tit1: "Demo účet",
                tit2: "Otvoriť účet",
                tit3: "Vklad a výber",
                tit4: "Nastavenie platformy",
                tit5: "Obchodné operácie",
                tit6: "Poplatky a platby",
                tit7: "Finančné zabezpečenie a predpisy",
                tit1Tit1: "HKDFINANC Ponúka HKDFINANC demo účty?",
                tit1Cont1: "<p>HKDFINANC ponúka klientom demo účty na obchodovanie a oboznámenie sa s platformou prostredníctvom našej webovej platformy a mobilných aplikácií bez akéhokoľvek rizika</p>.",
                tit1Tit2: "Ako si vytvoriť demo účet?",
                tit1Cont2: "<p>Všetko, čo musíte urobiť, je kliknúť sem a vyplniť svoju e-mailovú adresu alebo číslo mobilného telefónu a nastaviť PIN kód, aby ste dokončili registráciu demo účtu. </p>",
                tit1Tit3: "Na čo slúži demo účet?",
                tit1Cont3: "<p>Demo účet je z hľadiska obchodného rozhrania, údajov a operácií väčšinou rovnaký ako reálny účet. Demo účet má 50 000 USD vo virtuálnych prostriedkoch a je navrhnutý tak, aby sa zákazníci mohli oboznámiť s funkciami platformy prostredníctvom simulovaných operácií bez toho, aby riskovali svoje finančné prostriedky. </p",
                tit1Tit4: "Ako financovať demo účet?",
                tit1Cont4: "<p>Nemusíte financovať svoj demo účet, keď sa úspešne zaregistrujete na demo účet, na vašom účte bude 50 000 USD alebo AUD</p><p>A keď čistá hodnota vášho demo účtu klesne pod 200 USD alebo AUD, systém automaticky doplní váš účet späť na 50 000 USD alebo AUD</p>.",
                tit1Tit5: "Má demo účet dátum vypršania platnosti?",
                tit1Cont5: "<p>Demo účet je platný 90 dní. Ak si po 90 dňoch neotvoríte reálny účet, demo účet bude zmrazený a nebudete na ňom môcť obchodovať a bude rozmrazený, keď si otvoríte reálny účet. </p><p>Ak si otvoríte reálny účet do 90 dní, demo účet bude platný dlhodobo. Na demo účte môžete obchodovať aj po otvorení reálneho účtu. </p>",
                tit2Tit1: "Koľko typov účtov ponúka HKDFINANC?  ",
                tit2Cont1: "<p>Ponúkame len štandardné a profesionálne obchodné účty pre fyzické osoby, nepodporujeme otváranie firemných a spoločných účtov</p>.",
                tit2Tit2: "V akej mene si môžem otvoriť obchodný účet??",
                tit2Cont3: "<p>Pri otvorení účtu systém automaticky určí vašu menu vyrovnania na základe vašej polohy a nebude možné ju zmeniť</p>.",
                tit2Tit4: "Ako si otvoriť živý účet?",
                tit2Cont4: "<p>Ak si chcete otvoriť živý účet, postupujte podľa nasledujúcich krokov</p><p>1. Prejdite na registračnú stránku HKDFINANC a postupujte podľa pokynov: zaregistrujte svoj e-mail a číslo mobilného telefónu, nastavte prihlasovacie heslo, odošlite potvrdenie a účet sa automaticky vytvorí</p><p></p><p></p>.",
                tit3Tit1: "Ako môžem vykonať vklad?",
                tit3Cont1: "<p>HKDFINANC ponúka rôzne spôsoby vkladov, z ktorých si zákazníci môžu vybrať, vrátane nasledujúcich a prípadne aj viac:</p><p>1. Visa/Mastercard - spravidla okamžite</p><p>2. Internet banking - spravidla do 1 T dňa</p><p>3. Elektronické peňaženky (napr. Skrill, Momo, Zalo, Touch'n GO, Boost atď.) - spravidla okamžite</p><p>4. Elektronické peňaženky (napr. Skrill, Momo, Zalo, Touch'n GO, Boost atď.) - spravidla okamžite</p><p>4. Bankomatové karty - spravidla okamžite</p><p>5. QR Mobile - spravidla okamžite</p>.",
                tit3Tit2: "Môžem vkladať alebo vyberať finančné prostriedky prostredníctvom bankového účtu/karty na meno tretej strany?",
                tit3Cont2: "<p>HKDFINANC neprijíma vklady tretích strán. Na vklad alebo výber finančných prostriedkov používajte svoj osobný bankový účet/kartu na vlastné meno. Ak na vklad finančných prostriedkov použijete bankový účet/kartu inej osoby alebo spoločnosti, finančné prostriedky vám budú vrátené</p>.",
                tit3Tit3: "Ako môžem požiadať o výber z môjho účtu HKDFINANC?   ",
                tit3Cont3: "<p>Môžete sa prihlásiť na webovú platformu, kliknúť na [Výber], vybrať bankový účet alebo kartu Visa/Mastercard a vyplniť sumu výberu, potom stlačiť [Odoslať] a po uistení, že sú informácie správne, HKDFINANC spracuje vašu žiadosť do 1-2 pracovných dní</p>.",
                tit4Tit1: "Akú výšku pákového efektu ponúka HKDFINANC?",
                tit4Cont1: "<p>Pákový efekt ponúkaný platformami HKDFINANC je založený na nariadeniach regulačných orgánov a maximálny pákový efekt sa líši od produktu k produktu. Viac informácií nájdete v obchodnej platforme. </p",
                tit4Tit2: "Ako zmeniť heslo?",
                tit4Cont2: "<p>Nové heslo môžete obnoviť po prihlásení do platformy výberom položky [Viac]- [Nastavenia zabezpečenia]- [Zmeniť heslo]</p>.",
                tit4Tit3: "Ako sa prihlásim do obchodnej platformy HKDFINANC?",
                tit4Cont3: "<p>Aplikácia: Prejdite na 'My' a kliknite na 'Login l Register' vľavo hore, aby ste prešli na prihlasovaciu stránku. </p>Web: Kliknutím na 'Login' v pravom hornom rohu oficiálnej webovej stránky HKDFINANC prejdite na prihlasovaciu stránku. <p></p><p></p><p></p><p></p><p></p>.",
                tit4Tit4: "Aké sú spôsoby upozornení platformy??",
                tit4Cont4: "<p>HKDFINANC Upozornenia prostredníctvom e-mailu, SMS, platformy push. </p>",
                tit5Tit1: "Aké rozpätia ponúka HKDFINANC?",
                tit5Cont1: "<p>Účtujeme nízke rozpätia a žiadne provízie. </p>",
                tit5Tit2: "Ako vytvoriť trhový príkaz?",
                tit5Cont2: "<p>Kliknutím na [Obchod] (Obchod) sa zobrazí platforma, ktorá môže poskytovať obchodovanie so všetkými produktmi, môžete zadať pravú stranu vstupného poľa, zadať kód alebo názov druhu, ktorý chcete vyhľadať, vybrať produkt kliknutím na [Kúpiť / predať] SELL / BUY) sa zobrazí obchodné okno, v obchodnom okne môžete vidieť aktuálnu cenu a odhadovanú požadovanú maržu. Môžete ručne upraviť počet otvorených obchodov a nastaviť stop-loss a take-profit na kontrolu rizika</p>.",
                tit5Tit3: "Ako vytvoriť čakajúcu objednávku?",
                tit5Cont3: "<p>Vyberte produkt v ponuke [Obchod] a kliknutím na [Kúpiť/Predať] (SELL/BUY) zobrazte okno obchodovania.",
                tit5Tit4: "Ako uzavrieť pozíciu?",
                tit5Cont4: "<p>Ak chcete zatvoriť pozíciu, kliknite na tlačidlo Otvoriť na platforme v časti Pozícia, vyberte pozíciu, ktorú chcete zatvoriť, a potom kliknite na tlačidlo Zatvoriť vpravo. </p>",
                tit5Tit5: "Ako upraviť alebo odstrániť čakajúce objednávky?",
                tit5Cont5: "<p>Ak chcete upraviť alebo vymazať čakajúci príkaz, v rámci položky Pozícia kliknite na položku Príkazy na platforme a potom kliknite na čakajúci príkaz, ktorý chcete upraviť alebo vymazať v procese</p>.",
                tit6Tit1: "Bude vám účtovaná provízia za používanie obchodnej platformy?",
                tit6Cont1: "<p>HKDFINANC je obchodná platforma úplne bez poplatkov. Účtujeme nízke spready a v závislosti od vašej konkrétnej obchodnej situácie môžu byť na platforme účtované ďalšie poplatky, napríklad SWAP. </p>",
                tit6Tit2: "Existujú nejaké poplatky za uloženie?",
                tit6Cont2: "<p>Našim klientom neúčtujeme žiadne poplatky súvisiace s vkladmi, ale vaša platobná alebo tranzitná banka si môže účtovať poplatok. Odporúčame vám, aby ste si vo svojej banke overili, či vám neúčtuje nejaké poplatky. </p>",
                tit6Tit3: "Existujú nejaké poplatky za výbery?",
                tit6Cont3: "<p>HKDFINANC neúčtuje klientom žiadne poplatky súvisiace s výbermi, pokiaľ nevyberiete menej ako minimálnu požadovanú sumu alebo neprekročíte maximálny počet bezplatných výberov za mesiac. Podrobnejšie informácie nájdete v našich zásadách výberov.</p>",
                tit7Tit1: "Budú sa prostriedky uložené na mojom účte HKDFINANC používať na iné účely?",
                tit7Cont1: "<p>Nie. Vklady retailových klientov sú v prípade potreby v súlade s regulačnými požiadavkami vedené oddelene na správcovských účtoch. Pri vkladaní finančných prostriedkov na klientsky peňažný účet, ich výbere alebo uskutočňovaní platieb z neho HKDFINANC prísne dodržiava pravidlá týkajúce sa klientskych peňažných prostriedkov, ktoré sú stanovené v regulačných predpisoch. </p>",
                tit7Tit2: "Ak HKDFINANC ukončí svoju činnosť alebo sa zadlží, bude to mať vplyv aj na moje finančné prostriedky?",
                tit7Cont2: "<p>Vklady maloobchodných zákazníkov sú v prípade potreby oddelené na správcovských účtoch v súlade s regulačnou legislatívou. V prípade zlyhania spoločnosti HKDFINANC, zrušenia licencie alebo akéhokoľvek zlyhania pokračovania v činnosti, finančné prostriedky klientov zostávajú oddelené a podliehajú nariadeniam o peniazoch klientov v súlade s príslušnými právnymi predpismi</p>",
                tit7Tit3: "Dodržiava HKDFINANC zákony?",
                tit7Cont3: "<p>Spoločnosť je plne v súlade s príslušnými zákonmi o obchodných spoločnostiach a finančnými regulačnými zákonmi a je poskytovateľom finančných služieb, ktorý má povolenie a je prísne regulovaný príslušnými regulačnými orgánmi. Spoločnosť poskytuje komplexnú a primeranú ochranu bezpečnosti finančných prostriedkov klientov v súlade s prísnymi požiadavkami regulácie. </p>",
                tit7Tit4: "Sú osobné údaje zákazníka zabezpečené?",
                tit7Cont4: "<p>HKDFINANC prísne dodržiava ustanovenia nariadenia o ochrane osobných údajov, osobné údaje zákazníkov nikdy neuniknú. Spoločnosť dbá na to, aby jej zamestnanci dodržiavali prísne normy bezpečnosti a dôvernosti vyžadované zákonom, a venuje veľkú pozornosť dôvernosti a používaniu údajov zákazníkov. Viac informácií nájdete v dokumente o právnych informáciách spoločnosti. </p>",
            },
            tab4Row: {
                span: "Q Metódy overovania",
                p1: "HKDFINANC je fintech spoločnosť autorizovaná a regulovaná úradmi, ktorá sa zameriava na poskytovanie jednoduchého a pohodlného obchodovania investorom.",
                p2: "Inovatívna diverzifikovaná obchodná platforma HKDFINANC opakovane získala ocenenia ako Najlepšia mobilná obchodná platforma a Najinovatívnejšia maklérska spoločnosť.",
                p3: "Prostredníctvom platformy HKDFINANC môžete investovať a obchodovať na širšom spektre medzinárodných finančných trhov, ktoré zahŕňajú akcie, indexy, komodity, devízy a stovky ďalších populárnych druhov. HKDFINANC sa neobmedzuje len na užívateľsky prívetivé mobilné a webové obchodné platformy, ale poskytuje vám aj konkurencieschopné obchodné náklady, rýchle vykonávanie obchodov, vynikajúci zákaznícky servis a bohatú informačnú podporu, ktorá vám pomôže využiť včasné investičné a obchodné príležitosti.",
                p4: "HKDFINANC je prísne regulovaná priemyselnými orgánmi a viacerými organizáciami.",
                p5: "HKDFINANC Holding je autorizovaný a regulovaný menovým úradom Kajmanských ostrovov (CIMA) na základe licencie SIB č. 1612446. Viac informácií o kajmanských licenciách nájdete na oficiálnej webovej stránke CIMA www.cima.ky (",
                p6: ") Pre otázky. HKDFINANCGlobal je držiteľom licencie na poskytovanie finančných služieb (AFSL 398528), ktorú udelila Austrálska komisia pre cenné papiere a investície (ASIC). Podrobnejšie informácie o austrálskej licencii nájdete na oficiálnej webovej stránke ASIC na adrese www.asic.gov.au (",
                p7: ") HKDFINANC International je autorizovaná a regulovaná maurícijskou Komisiou pre finančné služby (FSC) pod licenčným číslom GB20025791. Podrobnosti o maurícijskej licencii nájdete na oficiálnej webovej stránke FSC www.fscmauritius.org (",
                p8: ")Všetky obchodné operácie HKDFINANC sa vykonávajú pod prísnym dohľadom a v súlade so všetkými predpismi.",
                p9: "Pozadie",
                p10: "HKDFINANC tvorí tím skúsených a kompetentných ľudí so skúsenosťami a znalosťami v oblasti finančného obchodovania a fintech priemyslu. Filozofiou spoločnosti HKDFINANC je uľahčiť obchodovanie a urobiť ho užívateľsky prívetivejším.",
                p11: "Filozofiou spoločnosti HKDFINANC je uľahčiť a spríjemniť obchodovanie. Naša holistická stratégia a obchodný model nám umožňujú mať globálny pohľad a pochopenie vývoja odvetvia a dopytu na trhu, takže môžeme cielenejšie alokovať zdroje, neustále inovovať technológie a optimalizovať efektivitu, aby sme našim klientom priniesli pohodlnejšie a užívateľsky prívetivejšie obchodovanie.",
                p12: "Prečo si vybrať",
                p13: "Nízkoprahové obchodné podmienky",
                p14: "Jednoduchá a intuitívna obchodná platforma",
                p15: "Konkurenčné transakčné náklady",
                p16: "Regulované priemyselnými orgánmi",
                p17: "Vysoká úroveň online podpory",
                p18: "Ochrana pred záporným zostatkom",
                p19: "Ocenenia a vyznamenania",
                p20: "HKDFINANC má tú česť získať ocenenie [Best Multi-Asset Broker] od World Finance Awards, [Best Mobile Trading Platform in Asia Pacific] [Fastest Growing FinTech Broker in the World] od Global BrandsMagazine a mnoho ďalších ocenení, ktoré sú dôkazom odhodlania a tvrdej práce tímu.",
                p21: "Najlepší maklér s viacerými aktívami",
                p22: "Prevádzkové koncepty",
                p23: "Záväzky",
                p24: "Každé obchodovanie je spojené s rizikom a spoločnosť HKDFINANC prijala ďalšie opatrenia na zabezpečenie zodpovedného obchodného prostredia pre svojich klientov. Spoločnosť HKDFINANC je regulovaná odvetvovými orgánmi, viacerými organizáciami a pôsobí v podmienkach vysokej úrovne bezpečnosti a stability. Naša platforma naďalej ponúka množstvo nástrojov zodpovedného obchodovania vrátane riadenia rizík.",
                p25: "Jednoduchý, ale krásny",
                p26: "V jednoduchosti je krása, v detailoch dokonalosť a v spoločnosti HKDFINANC sa snažíme o jednoduché používanie a používateľský komfort v každom detaile. Naďalej optimalizujeme našu platformu a služby v nádeji, že každý, od začiatočníka až po skúseného investora, bude môcť využiť obchodné príležitosti, ktoré sú k dispozícii prostredníctvom platformy HKDFINANC.",
                p27: "Otvorené (netajné)",
                p28: "Aby naši zákazníci mohli plne využívať výhody spravodlivého a efektívneho obchodného prostredia, HKDFINANC sa riadi prísnym a transparentným samoregulačným systémom, ktorý poskytuje komplexné a transparentné informácie o produktoch platformy, cenové prostredie a údaje, všetky poplatky sú jasné a otvorené, aby sa zabezpečilo, že celý proces služieb je úplne úprimný a transparentný.",
                p29: "Raziť nové cesty",
                p30: "HKDFINANC pevne verí, že technologický pokrok, finančné inovácie a širšie služby sú tým, čo poháňa rast, a že môžeme naďalej vytvárať a zdieľať hodnoty. S týmto cieľom podporujeme otvorené myslenie a pokračujeme v inováciách s cieľom byť inovátorom a lídrom v oblasti finančných technológií.",
                p31: "Ciele a vízia",
                p32: "Poskytnúť väčšiemu počtu ľudí, ktorí chcú obchodovať, spravodlivé, efektívne a transparentné obchodné prostredie, ktoré nie je obmedzené priestorom, časom a trhom, a byť lídrom v odvetví finančných a technologických inovácií.",
            },
        },
        language: {
            tit: "Vyberte jazyk",
        },
        market: {
            tit: "Ticker symbol",
            tit1: "Zobrazenie ceny",
            tit2: "Stúpajúce alebo klesajúce farby",
            buy_price: "Ponuková cena",
            buy_price_tip: "Kreslenie K-línie pri ponukovej cene",
            sell_price: "Predajná sadzba",
            sell_price_tip: "Vykreslenie K-línií pri predajnej cene",
            color1: "Zelené rastúce a červené pády",
            color2: "Červený vzostup a zelený pád",
        },
        notice: {
            tit: "Nastavenia oznámení",
            tit1: "Otvorenie oznamovacích kanálov",
            p1: "Otvorte/overte nasledujúce oznamovacie kanály, aby ste mali prehľad o dôležitých novinkách!",
            message: "Zasielanie textových správ",
            push: "Stlačte",
            verify: "Potvrdiť (teóriu)",
            verified: "Overené",
            email: "E-mailová adresa",
            type1: "Trhovisko",
            tit2: "Oznámenie o marketingových aktivitách",
            desc2: "Informácie o špeciálnej ponuke platformy, prevádzkové aktivity a ďalšie novinky",
            tit3: "Návod na obsluhu",
            desc3: "Bohatý a špecializovaný znalostný materiál",
            tit4: "Informačné upozornenia",
            desc4: "Najnovšie finančné údaje a informácie o novinkách",
            tit5: "Inteligentné upozornenie na výkyvy",
            desc5: "Zahŕňa cenové výkyvy dôležitých druhov, čo vám pomôže rýchlo využiť príležitosť",
            type2: "Typ správy",
            tit6: "Správy o účtoch",
            desc6: "Správy o vašom účte sa zasielajú mesačne nasledujúcim spôsobom",
            cycle: "Cyklickosť",
            month: "Každý mesiac",
        },
        protocol: {
            tit: "Vyhlásenia a dohody",
            label1: "Zásady ochrany osobných údajov",
            label2: "Vyhlásenie o zverejnení informácií o výrobku",
            label3: "Dohoda so zákazníkom",
            label4: "Vyhlásenie o zverejnení rizík",
        },
        setting: {
            tit: "Nastaviť",
            label1: "Nastavenia zabezpečenia",
            label2: "Ticker symbol",
            label3: "Nastavenia transakcií",
            label4: "Viacjazyčnosť",
            label5: "Režim témy",
            label6: "Nastavenia oznámení",
            label7: "Sprievodca obchodovaním",
            label8: "Vyhlásenia a dohody",
            label9: "Vymazanie vyrovnávacej pamäte",
            label10: "Číslo verzie",
            logout: "Odhlásenie",
        },
        theme: {
            tit: "Režim témy",
            theme1: "Svetlý režim",
            theme2: "Tmavý režim",
        },
        trade: {
            tit: "Nastavenia transakcií",
            onclick_close: "Zatvorenie pozície jedným kliknutím",
        }
    },
    trade: {
        fliter_type1: "Samoobslužné služby",
        fliter_type2: "Trh",
        place_search: "Zadajte názov/kód odrody na vyhľadávanie",
        fliter_type3: "V móde",
        fliter_type4: "Devízová mena",
        fliter_type5: "Tovar",
        fliter_type6: "Akcie",
        fliter_type7: "Exponenty",
        fliter_type8: "Zašifrované",
        tip1: "Dokončenie overenia totožnosti môže byť vložené do transakcie, investície do globálneho",
        sell: "Reach (cena v aukcii)",
        buy: "Buy-in",
        add_optional: "Pridať voliteľné",
        optional_recommend: "Voliteľné odporúčania",
        change_batch: "Dávka",
        edit_tit: "Výber redakcie",
        breed: "Sortiment",
        topping: "sticky (vlákna internetového fóra atď.)",
        sort: "Usporiadať v poradí",
        all: "Jednomyseľne",
    },
    table: {
        label_breed: "Sortiment",
        label_direction: "Orientácie",
        label_price: "Ceny",
        label_operate: "Súprava",
        empty_tip1: "Žiadne upozornenia",
        empty_tip2: "Žiadny záznam o vkladoch",
        empty_tip3: "Žiadny záznam o stiahnutí",
        label_amount: "Suma peňazí",
        label_type: "Typológia",
        label_time: "Krát",
        label_name: "Meno a priezvisko",
        label_email: "E-mailová adresa",
        label_address: "Krajina bydliska",
        label_account: "Číslo obchodného účtu",
        label_currency: "Základná mena",
        label_company: "Spoločnosť prevádzkujúca bežný účet",
    },
    form: {
        to: "Do",
        start_time: "Čas začatia",
        end_time: "Čas ukončenia",
        week: "V uplynulom týždni",
        month: "Asi mesiac",
        threeMonth: "Posledné tri mesiace",
        sixMonth: "Posledných šesť mesiacov",
        label_feedback: "Problémy a odporúčania",
        place_feedback: "Zadajte svoju otázku alebo návrh",
        label_img: "Fotografia",
        label_img_tip: "Nepovinné, uveďte snímku obrazovky s problémom",
        feedback_tip: "Ak máte naliehavú otázku, kontaktujte",
        online: "Online zákaznícky servis",
        label_cardNumber: "Číslo osvedčenia",
        label_realName: "Celé meno",
        place_realName: "Zadajte svoje meno",
        message_realName: "Zadajte svoje celé meno",
        label_firstName: "Priezvisko",
        place_firstName: "Zadajte svoje priezvisko",
        message_firstName: "Zadajte prosím priezvisko",
        label_lastName: "Názov",
        place_lastName: "Zadajte svoje druhé meno",
        message_lastName: "Zadajte meno",
        label_birthday: "Dátum narodenia",
        place_birthday: "Zadajte svoj dátum narodenia",
        message_birthday: "Zadajte svoj dátum narodenia",
        label_idType: "Typ dokumentu",
        tip_cardMain: "Nasnímajte alebo nahrajte fotografiu prednej strany preukazu totožnosti",
        tip_cardBack: "Nasnímajte alebo nahrajte fotografiu zadnej strany preukazu totožnosti",
        tip_cardMain1: "Odfoťte alebo nahrajte fotografiu prednej strany svojho vodičského preukazu",
        tip_cardBack1: "Odfoťte alebo nahrajte zadnú stranu svojho vodičského preukazu.",
        tip_cardMain2: "Fotografovanie alebo nahrávanie pasových fotografií",
        tip_cardBack2: "Fotografovanie alebo nahrávanie pasových fotografií",
        label_password: "Kryptografické",
        place_password: "Zadajte svoje heslo",
        message_password: "Zadajte svoje heslo",
        label_crmpsd: "Potvrdenie hesla",
        place_crmpsd: "Zadajte prosím svoje heslo znova",
        message_crmpsd: "Zadajte prosím svoje heslo znova",
        label_email: "E-mailová adresa",
        place_email: "E-mailová adresa",
        message_email: "Zadajte svoju e-mailovú adresu",
        label_captcha: "CAPTCHA",
        place_captcha: "Zadajte overovací kód",
        message_captcha: "Zadajte overovací kód",
        get_captcha: "Získať CAPTCHA",
        label_phone: "Telefóny",
        place_phone: "Zadajte svoje telefónne číslo",
        message_phone: "Zadajte svoje telefónne číslo",
        labelAmount: "Suma peňazí",
        placeAmount: "Zadajte, prosím, sumu",
        messageAmount: "Zadajte, prosím, sumu",
        labelWalletName: "Názov peňaženky",
        messageWalletName: "Zadajte názov svojej peňaženky",
        placeWalletName: "Zadajte názov svojej peňaženky",
        labelWalletAddress: "Adresa peňaženky",
        messageWalletAddress: "Zadajte adresu svojej peňaženky",
        placeWalletAddress: "Zadajte adresu svojej peňaženky",
        labelWithdrawAccount: "Výberový účet",
        placeWithdrawAccount: "Výberový účet",
        messageWithdrawAccount: "Výberový účet",
		placeTimeLimit: "Zadajte časový limit",
		messageTimeLimit: "Zadajte časový limit",
    },
	city: {
		albania: "Albánsko",
		algeria: "Alžírsko",
		argentina: "Argentína",
		armenia: "Arménsko",
		australia: "Austrália",
		pakistan: "Pakistan",
		austria: "Rakúska",
		bahrain: "Bahrajn",
		belgium: "Belgicko",
		bosnia_and_Herzegovina: "Bosna a Hercegovina",
		brazil: "Brazílsky",
		brunei: "Brunei",
		bulgaria: "Bulharsko",
		cambodia: "Kambodža",
		canada: "Kanadský",
		cameroon: "Kamerun",
		chile: "Čile",
		colombia: "Kolumbia",
		costa_Rica: "Kostarika",
		croatia: "Chorvátska republika (1991-)",
		cyprus: "Cyprus",
		czech_Republic: "Česká republika",
		denmark: "Dánsko",
		dominican_Republic: "Dominikánska republika",
		egypt: "Egypt",
		estonia: "Estónsko",
		ethiopia: "Etiópia",
		finland: "Suomi",
		france: "Francúzsky",
		georgia: "Gruzínsko (krajina)",
		germany: "Nemčina",
		ghana: "Ghana",
		greece: "Grécko",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Čína",
		hungary: "Maďarsko",
		iceland: "Islandský",
		ireland: "Írsky",
		italy: "Taliansko",
		india: "India",
		indonesia: "Indonézia",
		israel: "Izraelský",
		iran: "Iránsky",
		iraq: "Irak",
		japan: "Japonský",
		kazakstan: "Kazachstan",
		kenya: "Keňa",
		korea: "Kórea",
		kuwait: "Kuvajt",
		kyrgyzstan: "Kirgizsko",
		laos: "Laos",
		latvia: "Lotyšsko",
		lithuania: "Litva",
		luxembourg: "Luxembursko",
		macao_China: "Macao, Čína",
		macedonia: "Macedónčina",
		malaysia: "Malajzia",
		malta: "Maltský",
		mexico: "Mexiko",
		moldova: "Moldavsko",
		monaco: "Monaco",
		mongolia: "Mongolsko",
		montenegro: "Čierna Hora",
		morocco: "Maroko",
		myanmar: "Mjanmarsko",
		netherlands: "Holandsko",
		new_Zealand: "Nový Zéland",
		nepal: "Nepálsky",
		nigeria: "Nigéria, západná Afrika",
		norway: "Nórsko",
		oman: "Omán",
		palestine: "Palestína",
		panama: "Panama",
		paraguay: "Paraguaj",
		peru: "Peruánsky",
		philippines: "Filipíny",
		poland: "Poľský",
		portugal: "Portugalsko",
		puerto_Rico: "Puerto Rico",
		qatar: "Doha",
		romania: "Rumunsko",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad a Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudská Arábia",
		serbia: "Srbsko",
		singapore: "Singapur",
		slovakia: "Slovensko",
		slovenia: "Slovinsko",
		south_Africa: "Južná Afrika",
		spain: "Španielčina",
		sri_Lanka: "Srí Lanka",
		sweden: "Švédsko",
		switzerland: "Švajčiarsko",
		taiwan_China: "Čína-Taiwan",
		tajikistan: "Tadžikistan",
		tanzania: "Tanzánia",
		thailand: "Thajsko",
		turkey: "Istanbul",
		turkmenistan: "Turkménsko",
		ukraine: "Bielorusko",
		united_Arab_Emirates: "SAE",
		united_Kingdom: "Veľká Británia",
		united_States: "Spojené Štáty Americké",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afganistan",
		angola: "Angola",
		azerbaijan: "Azerbajdžan",
		bangladesh: "Bangladéš",
		belarus: "Bielorusko",
		belize: "Belizean",
		benin: "Benin",
		bhutan: "Bhután",
		bolivia: "Bolívia",
		botswana: "Botswana",
		british_Virgin_Islands: "Britské Panenské ostrovy",
		burkina_Faso: "Burkina Faso, západná Afrika",
		burundi: "Burundi",
		cape_Verde: "Kapverdy",
		cayman_Islands: "Kajmanské ostrovy",
		central_African_Republic: "Stredoafrická republika",
		chad: "Čadská",
		comoros: "Komory",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongo (Demokratická republika)",
		djibouti: "Džibutsko",
		ecuador: "Ekvádor",
		el_Salvador: "Salvádor",
		equatorial_Guinea: "Rovníková Guinea",
		eritrea: "Eritrea",
		fiji: "FidžiName",
		gabon: "Gabonské",
		gambia: "Gambia",
		greenland: "Grónsko",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Ostrov Mann",
		cote_d_Ivoire: "Pobrežie Slonoviny",
		jamaica: "Jamajka",
		jordan: "Jordan",
		lebanon: "Nepriateľstvo",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Líbya",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Maldivy",
		mali: "Mali",
		mauritania: "Mauritánia",
		mauritius: "Maurícius",
		mozambique: "Mozambik",
		namibia: "Namíbia",
		nicaragua: "Nikaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Severná Kórea",
		reunion: "Reunion Island",
		san_Marino: "San Maríno",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadišo",
		sudan: "Sudán",
		suriname: "Surinam",
		eswatini: "Svazijsko",
		syria: "Sýria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisko",
		united_States_Virgin_Islands: "Americké Panenské ostrovy (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguaj",
		vatican: "Vatikán",
		yemen: "Jemen",
		yugoslavia: "Juhoslávia",
		zambia: "Seychely",
		zimbabwe: "Zimbabwe",
		china: "Čína",
	}
};
