export default {
    crypto: {
        title: {
            0: "Buy Crypto",
            1: "Buy Crypto",
            2: "C2C",
        },
        list: {
            0: "C2C Trading",
            1: "Buy",
            2: "Sell",
            3: "Advertisers (Completion rate)",
            4: "Trades",
            5: "Price",
            6: "Available",
            7: "Limit",
            8: "Payment Method",
            9: "Trade",
            10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
        },
        buy: {
            0: "Buy Crypto",
            1: "Sell Crypto",
            2: "History",
            3: "Support",
            4: "Pay",
            5: "Receive",
            6: "Select payment method",
            7: "Est. arrival time",
            8: "FEE",
            9: "Order Details",
            10: "Payment method",
            11: "Learn more",
            12: "Price",
            13: "Total (fee included)",
            14: "Disclaimer",
            15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
            16: "I have read and agree to the Terms of Use",
            17: "You will get",
            18: "Submit",
            19: "Please enter the amount",
            20: "Insufficient account balance",
            21: "Available",
            22: "Spot Account Availiable",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        }
    },
    common: {
        view: "Tsekkaa",
        confirm: "Define",
        cancel: "Peruutukset",
        deposit: "Talletus",
        withdraw: "Nostaa rahaa",
        noData: "Tietoja ei ole saatavilla",
        submit: "Toimittaa (raportti jne.)",
        modify: "Muutokset",
        hand: "Hand",
        confirm_verify: "Validoi",
        next: "Seuraava askel",
        complete: "Täyttää",
        delete: "Poistaminen",
		countryCode: "Maan/alueen koodi",
    },
    addnew: {
        buyUp: "Kohoava",
        buyDown: "Ostaa ja myydä",
        confirmOrder: "Vahvista tilaus",
        name: "Nimi",
        fee: "Käsittelymaksu",
        orderCycle: "Valitse aika",
        loan: "Laina",
        loanTit: "Opintolaina",
        loanHostory: "Lainatiedot",
        labelProductTitle: "Tuotteen nimi",
        immediateRepayment: "Välitön takaisinmaksu",
        loadAmount: "Odotettu lainamäärä",
        RepaymentCycle: "Lainan takaisinmaksusykli",
        day: "Päivä",
        dailyRate: "Päivittäinen korko",
        repaymentMethod: "Takaisinmaksutapa",
        lendingCompany: "Luotonantoyhtiö",
        loanTip: "Luottolainat (varmista, että kuvat näkyvät selvästi)",
        loanTip1: "Lataa kiinteistötodistus",
        loanTip2: "Todiste tuloista (työsuhde)",
        loanTip3: "Pankkitiliotteen tiedot",
        loanTip4: "Lataa henkilökorttisi etukuva",
        interest: "Sake",
        repaid: "Maksettu takaisin",
        unpaid: "Takaisinmaksun laiminlyönti",
        tab1: "Kolikot",
        tab2: "Sopimus",
        tab3: "Vaihtoehdot",
        available: "Käytettävissä oleva luotto",
        pending: "Lukitus",
        equivalent: "Määrä on",
        fundTransfer: "Varojen siirto",
        flashExchange: "Käteinen",
        select_breed: "Valitse valuutta",
        from: "Alkaen",
        to: "Kunnes",
        transferQuantity: "Siirtojen määrä",
        repaymentMethod1:"Erääntyy maksettavaksi takaisin yhdessä erässä"
    },
    params: {
        product_foreign: "Valuutanvaihto",
        product_shop: "Kauppatavara",
        product_number: "Eksponentit",
        product_stock: "Osakkeet",
        product_coin: "Salattu",
        billTypes: [
            {v: 101, name: 'Lataus (rahaa kortille)'},
            {v: 102, name: 'Talletus'},
            {v: 103, name: 'Jäädyttäminen'},
            {v: 104, name: 'Sulata'},
            {v: 105, name: 'Talletus'},
            {v: 106, name: 'Talletus'},
            {v: 201, name: 'Nostojen jäädyttäminen'},
            {v: 202, name: 'Nostaa varoja'},
            {v: 203, name: 'Peruuttaminen onnistui'},
            {v: 204, name: 'Jättävät nostamatta varoja'},
            {v: 205, name: 'Peruuttamismaksu'},
            {v: 206, name: 'Siirtää pois'},
            {v: 207, name: 'Siirtyä'},
            {v: 208, name: 'Kolikon siirto'},
            {v: 209, name: 'Kolikon siirto'},
            {v: 301, name: 'Sopimuksen käsittelymaksu'},
            {v: 302, name: 'Sopimustulot'},
            {v: 303, name: 'Sopimustappio'},
            {v: 304, name: 'Sopimusmarginaali'},
            {v: 305, name: 'Sopimusmarginaalin tuotto'},
            {v: 311, name: 'Optioiden osto'},
            {v: 312, name: 'Optiovoitto'},
            {v: 313, name: 'Vaihtoehto Palaa'},
            {v: 314, name: 'Option käsittelymaksu'},
            {v: 315, name: 'Kolikkojen oston jäädyttäminen'},
            {v: 316, name: 'Kolikko-ostovähennys'},
            {v: 317, name: 'Coin Buy Palautukset'},
            {v: 318, name: 'Kolikon sisäänosto tilille'},
            {v: 319, name: 'Kolikon sisäänosto tilille'},
            {v: 320, name: 'Kolikon myynnin jäädyttäminen'},
            {v: 321, name: 'Kolikoiden myynnin vähennys'},
            {v: 322, name: 'Kolikon myyntipalautukset'},
            {v: 323, name: 'Kolikon myynti tilille'},
            {v: 324, name: 'Kolikon myynti tilille'},
            {v: 325, name: 'Kolikon käsittelymaksu'},
            {v: 401, name: 'Kaivosmiehet liittyvät'},
            {v: 402, name: 'Miner Returns'},
            {v: 403, name: 'Kaivoskoneista saatavat tulot'},
            {v: 404, name: 'Kaivoskoneiden poistuminen'},
            {v: 405, name: 'Kaivostoiminnan lopettamismaksu'},
            {v: 411, name: 'Lainatulot'},
            {v: 412, name: 'Lainausmaksu'},
            {v: 413, name: 'Onnistunut takaisinmaksu'},
        ],
    },

    footer: {
        nav1: "Fig. alku",
        nav2: "Liiketoimet",
        nav3: "Asemat",
        nav4: "Minun",
        kline_set1: "Pörssitunnus",
        kline_set2: "Tapahtuma-asetukset",
    },
    login: {
        login: "Kirjaudu sisään",
        register: "Ilmoittautuminen",
        type1: "Matkapuhelinnumero",
        type2: "Postilaatikko",
        login_btn: "Turvallinen sisäänkirjautuminen",
        register_btn: "Rekisteröidy nyt",
        psd_login: "Salasana kirjautuminen",
        verify_login: "CAPTCHA Kirjaudu sisään",
        forget: "Unohditko salasanan??",
        no_account: "Ei tiliä？",
        have_account: "Olemassa olevat tilit",
    },
    home: {
        home: {
            nav1: "Nousujen ja laskujen sijoittaminen",
            nav2: "Pikakäynnistys",
            nav3: "Tietoa meistä",
            nav4: "Kutsupalkkiot",
            most_follow: "Ajankohtaisuus",
            news_type1: "Olla tahdikas",
            news_type2: "Julkinen tiedottaminen",
            news_type3: "Talouskalenteri",
            news_tab1: "Full",
            news_tab2: "Valuutanvaihto",
            news_tab3: "Osakkeet",
            news_tab4: "Kauppatavara",
            news_tab5: "Eksponentit",
            news_tab6: "Numerot",
            news_tab7: "Tapahtuma",
            news_tab8: "Loma",
            label_previous: "Edellinen arvo",
            label_predictive: "Ennakoitu arvo",
            label_announced: "Julkaistu arvo",
        },
        kilne: {
            label_bp: "Eilinen sato",
            label_sp: "Nykypäivä",
            label_height: "Ylin",
            label_low: "Alhaisin",
            tip: "Hei, olen HKDFINANCGPT Variety Assistant.",
            row1_tit: "Olla tahdikas",
            row1_rinei: "Päivän sisällä",
            row1_short: "Lyhytaikainen",
            row1_middle: "Puolivälissä",
            row1_time: "Vapautumisaika",
            row1_tit2: "Kaupankäyntistrategia",
            data_sources: "Tietolähteet",
            row2_tit1: "Numerot",
            row2_tit2: "Kaupankäynnin mieliala",
            row2_label_sell: "Myyjä (tavaroiden)",
            row2_label_buy: "Ostaja",
            tip2: "Ainoastaan tiedoksi, ei jaoston kanta.",
            tip3: "Päivitetään 15 minuutin välein",
            row2_tit3: "Hinnanvaihtelu",
            minute: "Minuuttia",
            today: "Tällä hetkellä",
            right_tit7: "Hinnanmuutosalue",
            now_price: "Nykyhinta",
            right_low_price: "Alhaisin hinta",
            right_height_price: "Korkein hinta",
            details: "Tiedot",
            right_label1: "Yksittäisten tapahtumien määrä",
            right_label2: "Suurin vipuvaikutus",
            right_label3: "Sopimuksen koko",
            right_label4: "Työpaikkojen enimmäismäärä yhteensä",
            right_label5: "Kelluva spread",
            right_label6: "Yön yli lataus",
            hand: "Käsi",
            right_sell: "Reach (hinta huutokaupassa)",
            right_buy: "Buy-in",
            right_collection_time: "Keräysaika",
            right_tip3: "Kerätään kokonaistapahtuman koosta",
            right_label9: "Kaupankäynnin aukioloajat",
            right_label10: "Nykyinen aikaväli",
            right_label11: "Seuraava väli",
        },
        placeorder: {
            direction_buyPrice: "Tarjoushinta",
            direction_sellPrice: "Myyntikurssi",
            direction_sell: "Reach (hinta huutokaupassa)",
            direction_buy: "Buy-in",
            market_price: "Markkinahinnasto",
            check_price: "Vireillä oleva tilaus",
            sell_tip: "Myy, kun hinta täyttää ehdot",
            buy_tip: "Osta, kun hinta täyttää ehdot",
            trade_number: "Tapahtumien määrä",
            hand: "Käsi",
            select_lever: "Vipuvoiman valinta",
            right_tip: "Vaadittu talletus",
            balance: "Käytettävissä oleva saldo",
            label_stop_win_price: "Ottaa voittoa",
            label_stop_lose_price: "Stop-loss",
            expected_profit_loss: "Ennakoitu tulos",
            trailing_stop: "Jälkipysäytys",
        },
        ranking: {
            tit: "Päivän suurimmat voittajat ja häviäjät",
            p: "Kaappaa kuumat paikat ja tartu tilaisuuksiin.",
            renew: "Päivitetty",
            growth_chart: "Kasvukaavio",
            drop_list: "Luettelo häviäjistä",
            show_closed_market: "Hughin tuotemerkin esittely",
            filter1: "Full",
            filter2: "Valuutanvaihto",
            filter3: "Kauppatavara",
            filter4: "Osakkeet",
            filter5: "Eksponentit",
            filter6: "Salattu",
            td1: "Sijoitukset",
            td2: "Taipumukset",
            td3: "Päivän voitto / Myyntihinta",
        },
        remind: {
            now_sell_price: "Nykyinen hintapyyntö",
            direction_buyPrice: "Tarjoushinta",
            direction_sellPrice: "Myyntikurssi",
            direction_sell: "Reach (hinta huutokaupassa)",
            direction_buy: "Buy-in",
            equal: "Milloin",
            p: "Ilmoita minulle, kun seuraavat hinnat saavutetaan",
            btn1: "Muistutusten asettaminen",
            list_tit: "Muistutusluettelo",
        },
    },
    my: {
        report: {
            tit: "Tiliraportit",
            tab1: "Raportointi",
            tab2: "Päivittäinen selvitys",
            tab3: "Kuukausittainen tiliote",
            history_tit: "Tapahtumahistorian raportti",
            account_tit: "Tiliraportit",
            filter: "Seulonta",
            tip1: "Valitse sopiva päivämääräväli viedäksesi tapahtumahistorian raportin.。",
            tip2: "Mukautettu aika",
            email_send: "Lähetä sähköpostiin",
        },
        accountMsg: {
            tit: "Tilin tiedot",
            label1: "Henkilökohtaiset tiedot",
        },
        bill: {
            tit: "Varojen virtausta koskevat tiedot",
            all: "Full",
            time: "Kertaa",
        },
        feedback: {
            tit: "Toiminnalliset suositukset",
        },
        identity: {
            tit: "Todennus",
            type1: "Henkilökortit",
            type2: "Ajokortti",
            type3: "Passi",
            tip: "Vahvistan, että kaikki annetut tiedot ja asiakirjat ovat täydellisiä, totuudenmukaisia ja tarkkoja.",
        },
        my: {
            is: "Jo",
            no: "Vahvistamaton",
            verify: "Validoitu",
            id_verify: "Todennus",
            verify_tip: "Täytä henkilöllisyyden todentaminen tallettamista ja kaupankäyntiä varten",
            no_verify: "Vahvista nyt",
            balance: "Käytettävissä oleva saldo",
            profit_loss: "Nouseva ja laskeva",
            marginLevel: "Marginaalitaso",
            btn1: "Vaihda oikealle tilille",
            deposit_withdraw_record: "Talletus- ja nostotiedot",
            capital_flow_details: "Varojen virtausta koskevat tiedot",
            welfare_center: "Hyvinvointikeskus",
            tip1: "Sinulla on",
            tip2: "Lunastettavat kynäpalkinnot",
            feedback: "Toiminnalliset suositukset",
            report: "Tiliraportit",
            setting: "Perustaminen",
        },
        news: {
            tit: "Ilmoitukset",
        },
        record: {
            tit: "Talletus- ja nostotiedot",
            cycle: "Syklisyys",
        },
        share: {
            tit: "Kutsu ystäviä",
            invite_success: "Kutsu onnistuneesti ystäviä",
            tip1: "Käteiskannustin",
            tip2: "Kutsu ystäväsi ja ryhdy",
            tip3: "Kutsu ystäviä vastaanottamaan käteistä",
        },
        welfare: {
            tit: "Hyvinvointikeskus",
            tab1: "Kortin rulla",
            tab2: "Toiminto",
            history_record: "Historiallinen ennätys",
            period_validity: "Voimassaoloaika",
        },
        wallet: {
            tit: "Kukkarot",
            addNew: "Lisää uusi lompakko",
        },
        withdraw: {
            tit: "Nostaa rahaa",
            addNew: "Lisää uusi lompakko",
        },
    },
    order: {
        demo_account: "Demo-tili",
        real_account: "Live-tili",
        tip: "Voit tallettaa ja käydä kauppaa avaamisen jälkeen",
        tab1: "Tilat",
        tab2: "Vireillä oleva tilaus",
        tab3: "Historiat",
        list_empty: "Tällä hetkellä ei ole vireillä olevia tilauksia",
        start_trade: "Kaupankäynnin aloittaminen",
        hand_tip: "Tuloalue",
        kline: {
            direction_sell: "Reach (hinta huutokaupassa)",
            direction_buy: "Buy-in",
            open_price: "Avaushinta",
            now_price: "Nykyhinta",
            trade_account: "Kaupankäyntitili",
            demo_account: "Demo-tili",
            order_id: "Tilaus ID",
            open_time: "Aukioloaika",
            stop_profit: "Ottaa voittoa",
            stop_loss: "Stop-loss",
            trailing_stop: "Jälkipysäytys",
            float_profit_loss: "Muuttuva tulos",
            overnight_fee: "Yön yli lataus",
            margin: "Marginaali",
            maintainsMargin: "Ylläpitomarginaali",
            open_value: "Avausarvo",
            now_value: "Nykyinen arvo",
            notset: "Korjaamaton",
            close_position: "Sulkea asema",
            close_number: "Järjestysluku",
            onclick_close: "Sulje asema yhdellä napsautuksella",
            btn: "Aseman sulkemisen vahvistaminen",
            balance: "Käytettävissä oleva saldo",
            profit_loss: "Nouseva ja laskeva",
            netValue: "Nettoarvo",
            marginLevel: "Marginaalitaso",
        },
        modify: {
            tit: "Asemajärjestyksen muuttaminen",
            expected_profit_loss: "Ennakoitu tulos",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Uuden salasanan asettaminen",
            verify_email_tit: "Vahvista sähköpostiosoite",
            verify_password_tit: "Vahvista salasanasi",
            verify_password_tip: "Kirjoita HKDFINANC-salasanasi jatkaaksesi.",
            verify_phone_tit: "Tarkista matkapuhelinnumero",
            tit: "Tilin turvallisuus",
            tip: "Tilisi turvallisuuden suojaamiseksi ja palveluitamme koskevien ilmoitusten saamiseksi on suositeltavaa, että vähintään yksi todennustyyppi on käytössä.",
            label1: "Vaihda salasanasi",
            label2: "Tilien peruuttaminen",
            label3: "Kaksoistarkastus",
            label4: "Laitteiden hallinta",
            tip2: "Uuden laitteen kirjautumiseen vaaditaan tunnistautuminen",
        },
        tradenews: {
            tit: "Kaupankäyntiopas",
            tab1: "Pikakäynnistys",
            tab2: "Edistynyt opiskelu",
            tab3: "Ohjekeskus",
            tab4: "Tietoja HKDFINANCista",
            viewMore: "Näytä lisää",
            details: {
                tit: "Johdatus valuutanvaihtoon",
                tip1: "Opiskelun arvioitu kesto",
                tip2: "Minuuttia",
            },
            tab1Row: {
                tit: " Tämä on nopea luokka oppia kaupankäynnin HKDFINANC!",
                tit1: "Miten käyn kauppaa HKDFINANCissa?",
                tit2: "Kuinka avata live-tili？",
                tit3: "Kuinka avata kauppa？",
                tit4: "Miten asettaa Stop Loss ja Take Profit？",
                tit5: "Miten positio suljetaan？",
                p1: "Voit käydä kauppaa kohde-etuudella ostamalla tai myymällä. Positiosi avaaminen HKDFINANCissa tarkoittaa, että olet kiinnostunut kyseisestä",
                p2: "Kohde-etuutena olevan tuotteen hintavaihtelujen osto ja myynti.",
                p3: "Jos esimerkiksi odotat kullan hinnan nousevan, ostat tuotteen; jos taas ennustat kullan hinnan laskevan, myyt tuotteen. Jos tulevaisuus vastaa odotuksia, voit",
                p4: "Hyötyä siitä",
                p5: "Lisäksi HKDFINANC tarjoaa 'vipuvaikutuskauppaa'. Voit käyttää vipuvaikutusta voidaksesi käydä kauppaa useammalla erällä samalla rahamäärällä.",
                p6: "Vipu voi suurentaa voittoja ja tappioita.",
                p7: "Tämä tunnetaan myös nimellä 'marginaalikauppa'. Kun avaat position, HKDFINANC-tililtäsi veloitetaan tietty rahamäärä seuraavasti",
                p8: "Alkumarginaali",
                p9: "Valitut tuotteet ja osto- ja myyntiohjeet",
                p10: "Liiketoimet",
                p11: "Klikkaa 'Osta'/'Myy' saadaksesi tilauksen valmiiksi.",
                p12: "Asettamalla 'Take Profit' tai 'Stop Loss' auttaa sinua asettamaan",
                p13: "Voittojen lukitseminen ja tappioiden rajoittaminen ajoissa.",
                p14: "Voit avata aseman napsauttamalla",
                p15: "Take Profit/Stop Loss",
                p16: "Voit myös napsauttaa 'Muokkaa' avoimissa toimeksiannoissa asettaaksesi stop-loss ja take-profit.",
                p17: "Asettaminen tai peruuttaminen",
                p18: "Voit tarkastella voittoa ja tappiota kohdassa 'Suljetut positiot'.",
            },
            tab3Row: {
                h1: "Tervetuloa HKDFINANCiin",
                h2: "Ohjekeskus",
                tit0: "Kaikki kysymykset",
                tit1: "Demo-tili",
                tit2: "Avaa tili",
                tit3: "Talletus ja nosto",
                tit4: "Alustan asennus",
                tit5: "Kauppatoiminnot",
                tit6: "Maksut ja palkkiot",
                tit7: "Varojen turvallisuus ja säännökset",
                tit1Tit1: "HKDFINANC Tarjoaako HKDFINANC demotilejä?",
                tit1Cont1: "<p>HKDFINANC tarjoaa demotilejä, joilla asiakkaat voivat käydä kauppaa ja tutustua alustaan verkkopohjaisen alustan ja mobiilisovellusten avulla ilman riskiä</p>.",
                tit1Tit2: "Kuinka luoda demo-tili?",
                tit1Cont2: "<p>Ainoa mitä sinun tarvitsee tehdä, on klikata tästä täyttääksesi sähköpostiosoitteesi tai matkapuhelinnumerosi ja asettaa PIN-koodisi viimeistelläksesi demo-tilin rekisteröinnin. </p>",
                tit1Tit3: "Mitä hyötyä demotilistä on?",
                tit1Cont3: "<p>Demotili on enimmäkseen sama kuin oikea tili kaupankäynnin käyttöliittymän, tietojen ja toimintojen osalta. Demotilillä on 50 000 dollaria virtuaalisia varoja, ja se on suunniteltu siten, että asiakkaat voivat tutustua alustan ominaisuuksiin simuloitujen toimintojen avulla riskeeraamatta varojaan. </p>",
                tit1Tit4: "Kuinka rahoittaa demotiliä?",
                tit1Cont4: "<p>Sinun ei tarvitse rahoittaa demotiliäsi, kun olet onnistuneesti rekisteröitynyt demotilille, tililläsi on $50,000 USD tai AUD</p><p>Ja kun demotilisi nettoarvo laskee alle $200 USD tai AUD, järjestelmä täydentää tilisi automaattisesti takaisin $50,000 USD tai AUD</p>.",
                tit1Tit5: "Onko demotilillä päättymispäivä?",
                tit1Cont5: "<p>Demotili on voimassa 90 päivää. Jos et avaa live-tiliä 90 päivän jälkeen, demotili jäädytetään, etkä voi käydä sillä kauppaa, ja se vapautetaan, kun avaat live-tilin. </p><p>Jos avaat live-tilin 90 päivän kuluessa, demotili on voimassa pitkään. Voit jatkaa kaupankäyntiä demotilillä myös sen jälkeen, kun olet avannut live-tilin. </p>",
                tit2Tit1: "Kuinka monta tilityyppiä HKDFINANC tarjoaa?  ",
                tit2Cont1: "<p>Tarjoamme vain tavallisia ja ammattimaisia kaupankäyntitilejä yksityishenkilöille, emme tue yritysten ja yhteisten tilien avaamista</p>.",
                tit2Tit2: "Missä valuutassa voin avata kaupankäyntitilin??",
                tit2Cont3: "<p>Kun avaat tilin, järjestelmä määrittää maksuvaluutan automaattisesti sijaintisi perusteella, etkä voi muuttaa sitä</p>.",
                tit2Tit4: "Kuinka avata live-tili?",
                tit2Cont4: "<p>Avataksesi live-tilin noudata seuraavia ohjeita</p><p>1. Mene HKDFINANCin rekisteröintisivulle ja noudata ohjeita: rekisteröi sähköpostiosoitteesi ja matkapuhelinnumerosi, aseta kirjautumissalasana, lähetä vahvistus ja tili luodaan automaattisesti</p><p></p><p></p><p></p>.",
                tit3Tit1: "Miten voin tehdä talletuksen?",
                tit3Cont1: "<p>HKDFINANC tarjoaa asiakkailleen erilaisia talletusmenetelmiä, joista voi valita, mukaan lukien ja mahdollisesti enemmän kuin seuraavat:</p><p>1. Visa/Mastercard - yleensä välittömästi</p><p>2. Internet-pankki - yleensä 1 T-päivän kuluessa</p><p>3. Sähköiset lompakot (esim. Skrill, Momo, Zalo, Touch'n GO, Boost jne.) - yleensä välittömästi</p><p>4. Sähköiset lompakot (esim. Skrill, Momo, Zalo, Touch'n GO, Boost jne.) - yleensä välittömästi</p><p>4. Pankkikortit - yleensä välittömästi</p><p>5. QR Mobile - yleensä välittömästi</p>.",
                tit3Tit2: "Voinko tallettaa tai nostaa varoja käyttämällä kolmannen osapuolen nimissä olevaa pankkitiliä/korttia?",
                tit3Cont2: "<p>HKDFINANC ei hyväksy kolmansien osapuolten talletuksia. Käytä henkilökohtaista pankkitiliäsi/korttiasi omissa nimissäsi varojen tallettamiseen tai nostamiseen. Jos käytät toisen henkilön tai yrityksen pankkitiliä/korttia varojen tallettamiseen, varat palautetaan</p>.",
                tit3Tit3: "Miten pyydän nostoa HKDFINANC-tililtäni?   ",
                tit3Cont3: "<p>Voit kirjautua verkkosivuston alustalle, klikkaa [Nosto], valitse pankkitili tai Visa / Mastercard ja täytä nostosumma, paina sitten [Lähetä], kun olet varmistanut, että tiedot ovat oikein, HKDFINANC käsittelee hakemuksesi 1-2 työpäivän kuluessa</p>.",
                tit4Tit1: "Kuinka paljon vipuvaikutusta HKDFINANC tarjoaa?",
                tit4Cont1: "<p> HKDFINANC-alustojen tarjoama vipuvaikutus perustuu sääntelyviranomaisten määräyksiin, ja enimmäisvipuvaikutus vaihtelee tuotteittain. Lisätietoja saat kaupankäyntialustalta. </p>",
                tit4Tit2: "Miten vaihdan salasanani?",
                tit4Cont2: "<p>Voit nollata uuden salasanan alustalle kirjautumisen jälkeen valitsemalla [Lisää]- [Suojausasetukset]- [Vaihda salasana]</p>.",
                tit4Tit3: "Miten kirjaudun HKDFINANC-kaupankäyntialustalle?",
                tit4Cont3: "<p>Sovellus: Siirry kohtaan 'Omat' ja klikkaa vasemmalla ylhäällä olevaa 'Kirjaudu l Rekisteröidy', niin pääset kirjautumissivulle. </p>Web: Klikkaa 'Login' oikeassa yläkulmassa virallisella HKDFINANC-sivustolla siirtyäksesi kirjautumissivulle. <p></p><p></p><p></p><p></p><p></p><p></p>.",
                tit4Tit4: "Millä tavoin alustan hälytykset?",
                tit4Cont4: "<p>HKDFINANC Hälytykset sähköpostitse, tekstiviestillä, alustan push. </p>",
                tit5Tit1: "Millaisia marginaaleja HKDFINANC tarjoaa?",
                tit5Cont1: "<p>Maksamme alhaiset marginaalit ja ei palkkiota.  </p>",
                tit5Tit2: "Markkinatilauksen luominen?",
                tit5Cont2: "<p>Klikkaa [Kauppa] (Kauppa) näyttää alustan voi tarjota kaupankäynnin kaikkien tuotteiden, voit syöttää oikealla puolella syöttöruudussa, kirjoita koodi tai nimi lajin etsiä, valitse tuote klikkaa [Osta / Myy] SELL / BUY) avautuu kaupankäynnin ikkuna, kaupankäynnin ikkuna näet nykyisen hinnan ja arvioitu marginaali tarvitaan. Voit manuaalisesti säätää avoimien kauppojen määrää ja asettaa stop-loss ja take-profit -asetukset riskin hallitsemiseksi</p>.",
                tit5Tit3: "Kuinka luoda vireillä oleva tilaus?",
                tit5Cont3: "<p>Valitse tuote kohdassa [Kauppa] ja napsauta [Osta/Myydä] (SELL/BUY) avataksesi kauppaikkunan.</p>",
                tit5Tit4: "Miten positio suljetaan?",
                tit5Cont4: "<p>Position sulkemiseksi napsauta Asema-kohdan alustalla kohtaa Avaa, valitse positio, jonka haluat sulkea, ja napsauta sitten oikealla kohtaa Sulje.</p>",
                tit5Tit5: "Miten muokata tai poistaa vireillä olevia tilauksia?",
                tit5Cont5: "<p>Muuttaaaksesi tai poistaaksesi vireillä olevan toimeksiannon, napsauta Positiossa alustan Tilaukset-painiketta ja napsauta sitten vireillä olevaa toimeksiantoa, jota haluat muuttaa tai poistaa</p>.",
                tit6Tit1: "Veloitetaanko sinulta välityspalkkiota kaupankäyntialustan käytöstä??",
                tit6Cont1: "<p>HKDFINANC on täysin palkkioton kaupankäyntijärjestelmä. Veloitamme alhaisia marginaaleja, ja kaupankäyntitilanteestasi riippuen alustasta voi aiheutua lisämaksuja, kuten SWAP-maksuja. </p>",
                tit6Tit2: "Onko tallettamisesta perittävä maksuja?",
                tit6Cont2: "<p>Me emme veloita asiakkailtamme talletuksiin liittyviä maksuja, mutta maksu- tai siirtopankkisi voi veloittaa komissiosta. Suosittelemme, että tarkistat pankistasi, perivätkö ne sinulta maksuja. </p>",
                tit6Tit3: "Onko kotiutuksista perittäviä maksuja?",
                tit6Cont3: "<p>HKDFINANC ei veloita asiakkailta nostoihin liittyviä maksuja, ellet nosta vähemmän kuin vaadittu vähimmäismäärä tai ylitä kuukauden maksimimäärää ilmaisia nostoja. Lisätietoja saat nostokäytännöstämme.</p>",
                tit7Tit1: "Käytetäänkö HKDFINANC-tililleni talletettuja varoja muihin tarkoituksiin?",
                tit7Cont1: "<p>Ei. Vähittäisasiakkaiden talletukset säilytetään tarvittaessa erillisillä sijoitustileillä sääntelyvaatimusten mukaisesti. Kun HKDFINANC tallettaa varoja asiakasrahatilille tai nostaa varoja tai suorittaa maksuja asiakasrahatililtä, se noudattaa tiukasti sääntelykehyksessä vahvistettuja asiakasrahaa koskevia sääntöjä. </p>",
                tit7Tit2: "Jos HKDFINANC lopettaa toimintansa tai velkaantuu, vaikuttaako se myös varoihini?",
                tit7Cont2: "<p>Jälleenmyyntiasiakkaiden talletukset on tarvittaessa erotettu sijoitustileille sääntelylainsäädännön mukaisesti. Jos HKDFINANC epäonnistuu, toimilupa peruutetaan tai toimintaa ei jatketa, asiakasvarat pysyvät erillään ja niihin sovelletaan sovellettavan lainsäädännön mukaisia asiakasrahasäännöksiä</p>.",
                tit7Tit3: "Noudattaako HKDFINANC lakia?",
                tit7Cont3: "<p>Yhtiö noudattaa täysin asiaa koskevaa yhtiöoikeutta ja rahoitusalan sääntelyä koskevaa lainsäädäntöä, ja se on rahoituspalvelujen tarjoaja, jolla on asianomaisten sääntelyelinten myöntämä toimilupa ja joka on tiukasti säännelty. Yhtiö tarjoaa kattavan ja riittävän suojan asiakkaiden varojen turvallisuudelle sääntelyn tiukkojen vaatimusten mukaisesti. </p>",
                tit7Tit4: "Ovatko asiakkaan henkilötiedot turvassa??",
                tit7Cont4: "<p>HKDFINANC noudattaa tiukasti henkilötietosuoja-asetuksen säännöksiä, asiakkaiden henkilötietoja ei koskaan vuoda. Yhtiö varmistaa, että sen työntekijät noudattavat lain edellyttämiä tiukkoja turvallisuus- ja luottamuksellisuusvaatimuksia, ja kiinnittää erityistä huomiota asiakastietojen luottamuksellisuuteen ja käyttöön. Lisätietoja on saatavilla yhtiön oikeudellisesta ilmoitusasiakirjasta. </p>",
            },
            tab4Row: {
                span: "Q Tarkastusmenetelmät",
                p1: "HKDFINANC on viranomaisten valtuuttama ja sääntelemä fintech-yritys, joka keskittyy tarjoamaan sijoittajille yksinkertaisen ja kätevän kaupankäyntikokemuksen.",
                p2: "Innovatiivinen HKDFINANCin monipuolinen kaupankäyntijärjestelmä on toistuvasti voittanut palkintoja, kuten Best Mobile Trading Platform ja Most Innovative Brokerage.",
                p3: "HKDFINANC-alustan kautta voit sijoittaa ja käydä kauppaa laajemmilla kansainvälisillä rahoitusmarkkinoilla, jotka kattavat osakkeet, indeksit, hyödykkeet, valuutat ja sadat muut suositut lajit. Käyttäjäystävällisten mobiili- ja verkkopohjaisten kaupankäyntialustojen lisäksi HKDFINANC tarjoaa sinulle myös kilpailukykyiset kaupankäyntikustannukset, nopean kaupankäynnin toteuttamisen, erinomaisen asiakaspalvelun ja runsaan tietotuen, joka auttaa sinua tarttumaan oikea-aikaisiin sijoitus- ja kaupankäyntimahdollisuuksiin.",
                p4: "HKDFINANCia sääntelevät tiukasti alan viranomaiset ja useat organisaatiot.",
                p5: "HKDFINANC Holdingilla on Caymansaarten rahaviranomaisen (CIMA) myöntämä toimilupa ja sitä säännellään SIB-lisenssillä nro 1612446. Lisätietoja Caymansaarten lisenssistä on CIMA:n virallisilla verkkosivuilla osoitteessa www.cima.ky (",
                p6: ") HKDFINANCGlobalilla on Australian arvopaperi- ja sijoituskomission (ASIC) myöntämä rahoituspalvelulupa (AFSL 398528). Lisätietoja Australian toimiluvista saat ASIC:n virallisilta verkkosivuilta osoitteesta www.asic.gov.au.  (",
                p7: ") HKDFINANC Internationalilla on Mauritiuksen rahoituspalvelukomission (FSC) myöntämä toimilupa ja sitä säännellään toimiluvan numerolla GB20025791. Lisätietoja Mauritiuksen toimiluvasta on saatavilla FSC:n virallisilla verkkosivuilla osoitteessa www.fscmauritius.org. (",
                p8: ")HKDFINANCin kaikki liiketoiminta tapahtuu tiukassa valvonnassa ja kaikkien säännösten mukaisesti.",
                p9: "Tausta",
                p10: "HKDFINANC koostuu kokeneista ammattilaisista, joilla on kokemusta ja tietämystä rahoituskaupasta ja fintech-aloista. HKDFINANCin filosofia on tehdä kaupankäynnistä helpompaa ja käyttäjäystävällisempää.",
                p11: "HKDFINANCin filosofia on tehdä kaupankäynnistä helpompaa ja ystävällisempää. Kokonaisvaltaisen strategiamme ja liiketoimintamallimme ansiosta meillä on kokonaisvaltainen näkemys ja ymmärrys alan kehityksestä ja markkinoiden kysynnästä, joten voimme kohdentaa resursseja kohdennetummin, innovoida jatkuvasti teknologiaa ja optimoida tehokkuutta, jotta voimme tarjota asiakkaillemme entistä helpomman ja käyttäjäystävällisemmän kaupankäyntikokemuksen.",
                p12: "Miksi valita",
                p13: "Matalan kynnyksen kaupankäyntiolosuhteet",
                p14: "Yksinkertainen ja intuitiivinen kaupankäyntijärjestelmä",
                p15: "Kilpailukykyiset transaktiokustannukset",
                p16: "Alan viranomaisten sääntelemä",
                p17: "Korkeatasoinen verkkotuki",
                p18: "Negatiivisen saldon suojaus",
                p19: "Palkinnot ja kunniamaininnat",
                p20: "HKDFINANCilla on kunnia saada World Finance Awards -palkinto [Best Multi-Asset Broker], Global BrandsMagazine -palkinto [Best Mobile Trading Platform in Asia-Pacific], Global BrandsMagazine -palkinto [Fastest Growing FinTech Broker in the World] ja monia muita palkintoja, jotka ovat osoitus tiimin omistautumisesta ja kovasta työstä.",
                p21: "Paras Multi-Asset Broker",
                p22: "Toimintakonseptit",
                p23: "Velat",
                p24: "Kaikkeen kaupankäyntiin liittyy riski, ja HKDFINANC on ryhtynyt lisätoimiin tarjotakseen asiakkailleen vastuullisen kaupankäyntiympäristön. HKDFINANCia sääntelevät alan viranomaiset ja useat organisaatiot, ja se toimii korkealla turvallisuuden ja vakauden tasolla. Alustamme tarjoaa edelleen useita vastuullisia kaupankäyntivälineitä, mukaan lukien riskienhallinta.",
                p25: "Yksinkertainen mutta kaunis",
                p26: "Yksinkertaisuus on kauneutta, yksityiskohdat ovat huippuosaamista, ja HKDFINANCissa pyrimme helppokäyttöisyyteen ja käyttäjäkokemukseen kaikissa yksityiskohdissa. Jatkamme alustamme ja palveluidemme optimointia siinä toivossa, että kaikki, aloittelijoista kokeneisiin sijoittajiin, voivat hyödyntää HKDFINANC-alustan tarjoamia kaupankäyntimahdollisuuksia.",
                p27: "Avoin (ei-salainen)",
                p28: "Jotta asiakkaamme voivat täysin hyötyä oikeudenmukaisesta ja tehokkaasta kaupankäyntiympäristöstä, HKDFINANC noudattaa tiukkaa ja läpinäkyvää itsesääntelyjärjestelmää tarjotakseen kattavan ja läpinäkyvän alustan tuotetiedot, hinnoitteluympäristö ja tiedot, kaikki maksut ovat selkeitä ja avoimia sen varmistamiseksi, että koko palveluprosessi on täysin vilpitön ja avoin.",
                p29: "Kulkea uusia polkuja",
                p30: "HKDFINANC uskoo vahvasti, että teknologinen kehitys, rahoitusinnovaatiot ja laajemmat palvelut ovat kasvun moottoreita ja että voimme edelleen luoda ja jakaa arvoa. Tätä varten kannustamme avoimeen ajatteluun ja jatkamme innovaatiotoimintaa tavoitteenamme olla innovatiivinen ja johtava toimija finanssiteknologian alalla.",
                p31: "Tavoitteet ja visio",
                p32: "Tarjota useammille kaupankäyntiä haluaville oikeudenmukainen, tehokas ja läpinäkyvä kaupankäyntiympäristö, jota ei rajoita tila, aika tai markkinat, ja johtaa alaa rahoitus- ja teknologiainnovaatioissa.",
            },
        },
        language: {
            tit: "Valitse kieli",
        },
        market: {
            tit: "Pörssitunnus",
            tit1: "Hintanäyttö",
            tit2: "Nousevat tai laskevat värit",
            buy_price: "Tarjoushinta",
            buy_price_tip: "K-viivan piirtäminen tarjoushintaan",
            sell_price: "Myyntikurssi",
            sell_price_tip: "K-linjojen piirtäminen myyntihintaan",
            color1: "Vihreä nousee ja punainen putoaa",
            color2: "Punainen nousu ja vihreä lasku",
        },
        notice: {
            tit: "Ilmoitusasetukset",
            tit1: "Avoimet ilmoituskanavat",
            p1: "Avaa/vahvista seuraavat ilmoituskanavat, jotta pysyt kärryillä tärkeistä uutisista!",
            message: "Tekstiviestit",
            push: "Työnnä",
            verify: "Validointi",
            verified: "Validoitu",
            email: "Sähköpostiosoite",
            type1: "Markkinapaikka",
            tit2: "Markkinointitoimista ilmoittaminen",
            desc2: "Alustan erikoistarjoustiedot, toiminta ja muut uutiset",
            tit3: "Käyttöohjeet",
            desc3: "Runsas ja erikoistunut tietomateriaali",
            tit4: "Tietoilmoitukset",
            desc4: "Viimeaikaiset taloudelliset tiedot ja uutiset",
            tit5: "Älykäs vaihteluhälytys",
            desc5: "Sisältää tärkeiden lajikkeiden hintavaihtelut, mikä auttaa sinua tarttumaan nopeasti tilaisuuteen.包含重要品种价格波动，有助于您快速把握机会",
            type2: "Raportin tyyppi",
            tit6: "Tiliraportit",
            desc6: "Tiliraportit lähetetään kuukausittain seuraavasti",
            cycle: "Syklisyys",
            month: "Joka kuukausi",
        },
        protocol: {
            tit: "Julistukset ja sopimukset",
            label1: "Tietosuojakäytäntö",
            label2: "Tuotetta koskeva ilmoitus",
            label3: "Asiakassopimus",
            label4: "Riskien ilmoittaminen",
        },
        setting: {
            tit: "Perustaminen",
            label1: "Turvallisuusasetukset",
            label2: "Pörssitunnus",
            label3: "Tapahtuma-asetukset",
            label4: "Monikielisyys",
            label5: "Teematila",
            label6: "Ilmoitusasetukset",
            label7: "Kaupankäyntiopas",
            label8: "Julistukset ja sopimukset",
            label9: "Välimuistin tyhjentäminen",
            label10: "Versionumero",
            logout: "Kirjaudu ulos",
        },
        theme: {
            tit: "Teematila",
            theme1: "Vaalea tila",
            theme2: "Pimeä tila",
        },
        trade: {
            tit: "Tapahtuma-asetukset",
            onclick_close: "Sulje asema yhdellä napsautuksella",
        }
    },
    trade: {
        fliter_type1: "Itsepalvelu",
        fliter_type2: "Markkinat",
        place_search: "Syötä lajikkeen nimi/koodi hakua varten",
        fliter_type3: "Muodissa",
        fliter_type4: "Valuutanvaihto",
        fliter_type5: "Kauppatavara",
        fliter_type6: "Osakkeet",
        fliter_type7: "Eksponentit",
        fliter_type8: "Salattu",
        tip1: "Täydellinen henkilöllisyyden todentaminen voidaan tallettaa tapahtumaan, investointeja maailmanlaajuiseen",
        sell: "Reach (hinta huutokaupassa)",
        buy: "Buy-in",
        add_optional: "Lisää valinnainen",
        optional_recommend: "Valinnaiset suositukset",
        change_batch: "Erä",
        edit_tit: "Toimittajan valinta",
        breed: "Valikoima",
        topping: "Sticky (Internet-foorumin viestiketju jne.)",
        sort: "Järjestää järjestykseen",
        all: "Yksimielinen",
    },
    table: {
        label_breed: "Valikoima",
        label_direction: "Suunnat",
        label_price: "Hinnat",
        label_operate: "Kamppeet",
        empty_tip1: "Ei hälytyksiä",
        empty_tip2: "Talletuksia ei ole kirjattu",
        empty_tip3: "Ei peruutustietoja",
        label_amount: "Rahasumma",
        label_type: "Typologia",
        label_time: "Kertaa",
        label_name: "Etu- ja sukunimi",
        label_email: "Sähköpostiosoite",
        label_address: "Asuinmaa",
        label_account: "Kaupankäyntitilin numero",
        label_currency: "Perusvaluutta",
        label_company: "Käyttötili Yritys",
    },
    form: {
        to: "Kunnes",
        start_time: "Aloitusaika",
        end_time: "Päättymisaika",
        week: "Viime viikolla",
        month: "Noin kuukausi",
        threeMonth: "Viimeiset kolme kuukautta",
        sixMonth: "Viimeiset kuusi kuukautta",
        label_feedback: "Kysymykset ja suositukset",
        place_feedback: "Kirjoita kysymyksesi tai ehdotuksesi",
        label_img: "Valokuva",
        label_img_tip: "Valinnainen, anna kuvakaappaus ongelmasta",
        feedback_tip: "Jos sinulla on kiireellinen kysymys, ota yhteyttä",
        online: "Online-asiakaspalvelu",
        label_cardNumber: "Todistuksen numero",
        label_realName: "Täydellinen nimi",
        place_realName: "Kirjoita nimesi",
        message_realName: "Kirjoita koko nimesi",
        label_firstName: "Sukunimi",
        place_firstName: "Kirjoita sukunimesi",
        message_firstName: "Kirjoita sukunimi",
        label_lastName: "Nimi (jonkin asian nimi)",
        place_lastName: "Kirjoita toinen nimesi",
        message_lastName: "Kirjoita nimi",
        label_birthday: "Syntymäaika",
        place_birthday: "Kirjoita syntymäaikasi",
        message_birthday: "Kirjoita syntymäaikasi",
        label_idType: "Asiakirjan tyyppi",
        tip_cardMain: "Ota tai lataa valokuva henkilökorttisi etupuolelta.",
        tip_cardBack: "Ota tai lataa valokuva henkilökorttisi kääntöpuolelta.",
        tip_cardMain1: "Ota tai lataa valokuva ajokorttisi etupuolelta.",
        tip_cardBack1: "Ota tai lataa kuva ajokorttisi kääntöpuolelta.",
        tip_cardMain2: "Passikuvien ottaminen tai lataaminen",
        tip_cardBack2: "Passikuvien ottaminen tai lataaminen",
        label_password: "Salaus",
        place_password: "Anna salasanasi",
        message_password: "Anna salasanasi",
        label_crmpsd: "Vahvista salasana",
        place_crmpsd: "Syötä salasanasi uudelleen",
        message_crmpsd: "Syötä salasanasi uudelleen",
        label_email: "Sähköpostiosoite",
        place_email: "Sähköpostiosoite",
        message_email: "Kirjoita sähköpostiosoitteesi",
        label_captcha: "CAPTCHA",
        place_captcha: "Syötä vahvistuskoodi",
        message_captcha: "Syötä vahvistuskoodi",
        get_captcha: "Hanki CAPTCHA",
        label_phone: "Puhelimet",
        place_phone: "Kirjoita puhelinnumerosi",
        message_phone: "Kirjoita puhelinnumerosi",
        labelAmount: "Rahasumma",
        placeAmount: "Kirjoita summa",
        messageAmount: "Kirjoita summa",
        labelWalletName: "Lompakon nimi",
        messageWalletName: "Kirjoita lompakon nimi",
        placeWalletName: "Kirjoita lompakon nimi",
        labelWalletAddress: "Lompakon osoite",
        messageWalletAddress: "Anna lompakkosi osoite",
        placeWalletAddress: "Anna lompakkosi osoite",
        labelWithdrawAccount: "Nostotili",
        placeWithdrawAccount: "Nostotili",
        messageWithdrawAccount: "Nostotili",
		placeTimeLimit: "Anna aikaraja",
		messageTimeLimit: "Anna aikaraja",
    },
	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentiina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Itävallan",
		bahrain: "Bahrain",
		belgium: "Belgia",
		bosnia_and_Herzegovina: "Bosnia ja Hertsegovinan lyhenne",
		brazil: "Brasilialainen",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Kambodža",
		canada: "Kanadalainen",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Columbia",
		costa_Rica: "Costa Rica",
		croatia: "Kroatian tasavalta (1991-)",
		cyprus: "Kypros",
		czech_Republic: "Tšekin tasavalta ",
		denmark: "Tanska",
		dominican_Republic: "Dominikaaninen tasavalta",
		egypt: "Egypti",
		estonia: "Viro",
		ethiopia: "Etiopia",
		finland: "Suomi",
		france: "Ranskan",
		georgia: "Georgia (maa)",
		germany: "Saksan",
		ghana: "Ghana, Länsi-Afrikka",
		greece: "Kreikka",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Kiina",
		hungary: "Unkari",
		iceland: "Islantilainen",
		ireland: "Irlantilainen",
		italy: "Italia",
		india: "Intia",
		indonesia: "Indonesia",
		israel: "Israelilainen",
		iran: "Iranilainen",
		iraq: "Irakilainen",
		japan: "Japanilainen",
		kazakstan: "Kazakstan",
		kenya: "Kenia",
		korea: "Korea",
		kuwait: "Kuwaitilainen",
		kyrgyzstan: "Kirgisia",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Liettua",
		luxembourg: "Luxemburg",
		macao_China: "Macao, Kiina",
		macedonia: "Makedonia",
		malaysia: "Malesia",
		malta: "Maltalainen",
		mexico: "Meksiko",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Marokko",
		myanmar: "Myanmar",
		netherlands: "Alankomaat",
		new_Zealand: "New Zeeland",
		nepal: "Nepali",
		nigeria: "Nigeria",
		norway: "Norja",
		oman: "Omani",
		palestine: "Palestiina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Perulainen",
		philippines: "Filippiinit",
		poland: "Puolan",
		portugal: "Portugali",
		puerto_Rico: "Puerto Rico",
		qatar: "Doha",
		romania: "Romania",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad ja Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Saudi-Arabia",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "Etelä-Afrikka",
		spain: "Espanjan",
		sri_Lanka: "(Entinen) Ceylon",
		sweden: "Ruotsi",
		switzerland: "Sveitsi",
		taiwan_China: "Kiina-Taiwan",
		tajikistan: "Tadžikistan",
		tanzania: "Tansania",
		thailand: "Thaimaa",
		turkey: "Istanbul",
		turkmenistan: "Turkmenistan",
		ukraine: "Valko-Venäjä",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "Yhdistynyt kuningaskunta",
		united_States: "Yhdysvallat",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afganistan",
		angola: "Angola",
		azerbaijan: "Azerbaidžan",
		bangladesh: "Bangladesh",
		belarus: "Valko-Venäjä",
		belize: "Belizean",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Brittiläiset Neitsytsaaret",
		burkina_Faso: "Burkina Faso, Länsi-Afrikka",
		burundi: "Burundi",
		cape_Verde: "Kap Verde",
		cayman_Islands: "Caymansaaret",
		central_African_Republic: "Keski-Afrikan tasavalta",
		chad: "Tšadilainen",
		comoros: "Komorit",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongon demokraattinen tasavalta",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Päiväntasaajan Guinea",
		eritrea: "Eritrea",
		fiji: "Fidži",
		gabon: "Gabonilainen",
		gambia: "Gambia",
		greenland: "Grönlanti",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Mannin saari",
		cote_d_Ivoire: "Norsunluurannikko",
		jamaica: "Jamaikalainen",
		jordan: "Jordan",
		lebanon: "Vihollisuudet",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Malediivit",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mosambik",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Pohjois-Korea",
		reunion: "Reunionsaari",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadishu",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Swazimaa",
		syria: "Syyria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Yhdysvaltain Neitsytsaaret (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikaani",
		yemen: "Jemeniläinen",
		yugoslavia: "Jugoslavia",
		zambia: "Seychellit",
		zimbabwe: "Zimbabwe",
		china: "Kiina",
	}
};
