export default {
    crypto: {
        title: { "0": "Acquista cripto", "1": "Acquista cripto", "2": "C2C" },
        list: {
            "0": "Trading C2C",
            "1": "Compra",
            "2": "Vendi",
            "3": "Inserzionisti (tasso di completamento)",
            "4": "Negozi",
            "5": "Prezzo",
            "6": "Disponibile",
            "7": "Limite",
            "8": "Metodo di pagamento",
            "9": "Commercio",
            "10": "L'inserzionista può porre restrizioni sulle transazioni sull'annuncio e vedrai il motivo della restrizione dopo aver effettuato l'accesso.",
        },
        buy: {
            "0": "Acquista cripto",
            "1": "Vendi criptovalute",
            "2": "Storia",
            "3": "Supporto",
            "4": "Pagare",
            "5": "Ricevi",
            "6": "Seleziona il metodo di pagamento",
            "7": "Est. orario di arrivo",
            "8": "TASSE",
            "9": "Dettagli dell'ordine",
            "10": "Metodo di pagamento",
            "11": "Per saperne di più",
            "12": "Prezzo",
            "13": "Totale (tassa inclusa)",
            "14": "Disclaimer",
            "15": "Stai lasciando HKDFinanc e sarai reindirizzato a Mercuryo, un istituto finanziario autorizzato che fornisce servizi di pagamento con carta di credito/debito come terza parte. Qualsiasi rischio che si verifichi durante l'utilizzo del servizio sarà a carico di Mercuryo. Si prega di leggere e accettare i Termini di utilizzo prima di procedere.",
            "16": "Ho letto e accetto le Condizioni d'uso",
            "17": "You will get",
            "18": "Invia",
            "19": "Inserisci l'importo",
            "20": "Saldo insufficiente del conto",
            "21": "Disponibile",
            "22": "Conto spot disponibile",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        },
    },
    common: {
        view: "Controllare",
        confirm: "Definire",
        cancel: "Cancellazioni",
        deposit: "Deposito",
        withdraw: "Prelevare denaro",
        noData: "Nessun dato disponibile",
        submit: "Presentare (un rapporto, ecc.)",
        modify: "Modifiche",
        hand: "Mano",
        confirm_verify: "Convalidare",
        next: "Il passo successivo",
        complete: "Adempiere",
        delete: "Rimozione",
		countryCode: "Codice paese/area",
    },
    addnew: {
        buyUp: "Innalzarsi",
        buyDown: "Acquistare e vendere al ribasso (prezzi)",
        confirmOrder: "Confermare l'ordine",
        name: "Nome",
        fee: "Spese di gestione",
        orderCycle: "Selezionare l'ora",
        loan: "Prestito",
        loanTit: "Prestito studentesco",
        loanHostory: "Registri dei prestiti",
        labelProductTitle: "Nome del prodotto",
        immediateRepayment: "Rimborso immediato",
        loadAmount: "Importo previsto del prestito",
        RepaymentCycle: "Ciclo di rimborso del prestito",
        day: "Giorno",
        dailyRate: "Tasso d'interesse giornaliero",
        repaymentMethod: "Metodo di rimborso",
        lendingCompany: "Società di prestito",
        loanTip: "Prestiti di credito (assicurarsi che le foto siano ben visibili)",
        loanTip1: "Caricare il certificato di proprietà",
        loanTip2: "Prova di reddito (rapporto di lavoro)",
        loanTip3: "Dettagli dell'estratto conto bancario",
        loanTip4: "Caricare la foto frontale della carta d'identità",
        interest: "Sake",
        repaid: "Restituito",
        unpaid: "Mancato pagamento",
        tab1: "Monete",
        tab2: "Contrattuale",
        tab3: "Opzioni",
        available: "Credito disponibile",
        pending: "Bloccare",
        equivalent: "Importo a",
        fundTransfer: "Trasferimento di fondi",
        flashExchange: "Contanti",
        select_breed: "Selezionare la valuta",
        from: "Da",
        to: "Fino a quando",
        transferQuantity: "Numero di trasferimenti",
        repaymentMethod1:"Con obbligo di rimborso in un'unica soluzione"
    },
    params: {
        product_foreign: "Valuta estera",
        product_shop: "Merce",
        product_number: "Esponenti",
        product_stock: "Stock ",
        product_coin: "Crittografato",
        billTypes: [
            {v: 101, name: 'Ricarica'},
            {v: 102, name: 'Deposito'},
            {v: 103, name: 'Congelamento '},
            {v: 104, name: 'Scongelare'},
            {v: 105, name: 'Deposito'},
            {v: 106, name: 'Deposito'},
            {v: 201, name: 'Blocco dei prelievi'},
            {v: 202, name: 'Ritirare i fondi'},
            {v: 203, name: 'Ritiro riuscito'},
            {v: 204, name: 'Mancato ritiro dei fondi'},
            {v: 205, name: 'Tassa di ritiro'},
            {v: 206, name: 'Trasferimento'},
            {v: 207, name: 'Passare a'},
            {v: 208, name: 'Trasferimento di monete'},
            {v: 209, name: 'Trasferimento di monete'},
            {v: 301, name: 'Commissione di gestione del contratto'},
            {v: 302, name: 'Reddito contrattuale'},
            {v: 303, name: 'Perdita del contratto'},
            {v: 304, name: 'Margine del contratto'},
            {v: 305, name: 'Margine di rendimento del contratto'},
            {v: 311, name: 'Acquisto di opzioni'},
            {v: 312, name: 'Plusvalenza su opzione'},
            {v: 313, name: 'Opzione Rendimenti'},
            {v: 314, name: 'Commissione di gestione dell"opzione'},
            {v: 315, name: 'Blocco degli acquisti di monete'},
            {v: 316, name: 'Detrazione per l"acquisto di monete'},
            {v: 317, name: 'Rendimenti degli acquisti di monete'},
            {v: 318, name: 'Acquisto di monete sul conto'},
            {v: 319, name: 'Acquisto di monete sul conto'},
            {v: 320, name: 'Blocco della vendita di monete'},
            {v: 321, name: 'Deduzione per la vendita di monete'},
            {v: 322, name: 'Rendimenti della vendita di monete'},
            {v: 323, name: 'Moneta venduta al conto'},
            {v: 324, name: 'Moneta venduta al conto'},
            {v: 325, name: 'Tassa di gestione delle monete'},
            {v: 401, name: 'I minatori si uniscono'},
            {v: 402, name: 'Ritorno dei minatori'},
            {v: 403, name: 'Ricavi da macchine da miniera'},
            {v: 404, name: 'Uscita macchina mineraria'},
            {v: 405, name: 'Tassa di uscita dalle miniere'},
            {v: 411, name: 'Proventi da prestiti'},
            {v: 412, name: 'Commissione di prestito'},
            {v: 413, name: 'Rimborso riuscito'},
        ],
    },

    footer: {
        nav1: "Inizio fig.",
        nav2: "Rapporti",
        nav3: "Posizioni",
        nav4: "Mio",
        kline_set1: "Simbolo del ticker",
        kline_set2: "Impostazioni di transazione",
    },
    login: {
        login: "Accedi",
        register: "Iscrizione",
        type1: "Numero di cellulare",
        type2: "Cassetta postale",
        login_btn: "Accesso sicuro",
        register_btn: "Registrati ora",
        psd_login: "Password di accesso",
        verify_login: "Accesso CAPTCHA",
        forget: "password dimenticata??",
        no_account: "Nessun conto？",
        have_account: "Conti esistenti",
    },
    home: {
        home: {
            nav1: "Classifica degli alti e bassi",
            nav2: "Avvio rapido",
            nav3: "Chi siamo",
            nav4: "Premi su invito",
            most_follow: "Attualità",
            news_type1: "Avere tatto",
            news_type2: "Informazioni pubbliche",
            news_type3: "Calendario finanziario",
            news_tab1: "Completo",
            news_tab2: "Valuta estera",
            news_tab3: "Stock",
            news_tab4: "Merce",
            news_tab5: "Esponenti",
            news_tab6: "Numeri",
            news_tab7: "Evento",
            news_tab8: "Vacanza",
            label_previous: "Valore precedente",
            label_predictive: "Valore previsto",
            label_announced: "Valore pubblicato",
        },
        kilne: {
            label_bp: "Il raccolto di ieri",
            label_sp: "Giorno d'oggi",
            label_height: "Supremo",
            label_low: "Più basso",
            tip: "Salve, sono l'assistente di varietà HKDFINANCGPT.",
            row1_tit: "Avere tatto",
            row1_rinei: "In pochi giorni",
            row1_short: "A breve termine",
            row1_middle: "Medio termine",
            row1_time: "Tempo di rilascio",
            row1_tit2: "Strategia di trading",
            data_sources: "Fonti di dati",
            row2_tit1: "Numeri",
            row2_tit2: "Umore di trading",
            row2_label_sell: "Venditore (di beni)",
            row2_label_buy: "Acquirente",
            tip2: "A titolo puramente informativo, non costituisce una posizione della Divisione.",
            tip3: "Aggiornato ogni 15 minuti",
            row2_tit3: "Variazione del prezzo",
            minute: "Minuti",
            today: "Minuti",
            right_tit7: "Intervallo di variazione del prezzo",
            now_price: "Prezzo corrente",
            right_low_price: "Prezzo più basso",
            right_height_price: "Prezzo più alto",
            details: "Particolari",
            right_label1: "Numero di transazioni singole",
            right_label2: "Leva massima",
            right_label3: "Dimensione del contratto",
            right_label4: "Numero massimo di posizioni totali",
            right_label5: "Spread fluttuante",
            right_label6: "Carica notturna",
            hand: "Mano",
            right_sell: "Reach (prezzo in un'asta)",
            right_buy: "Buy-in",
            right_collection_time: "Tempo di raccolta",
            right_tip3: "Raccolta dalla dimensione complessiva della transazione",
            right_label9: "Orari di negoziazione",
            right_label10: "La fascia oraria corrente",
            right_label11: "Intervallo successivo",
        },
        placeorder: {
            direction_buyPrice: "Prezzo dell'offerta",
            direction_sellPrice: "Tasso di vendita",
            direction_sell: "Reach (prezzo in un'asta)",
            direction_buy: "Buy-in",
            market_price: "Listino prezzi di mercato",
            check_price: "Ordine pendente",
            sell_tip: "Vendere quando il prezzo soddisfa le condizioni",
            buy_tip: "Acquistare quando il prezzo soddisfa le condizioni",
            trade_number: "Numero di transazioni",
            hand: "Mano",
            select_lever: "Selezione della leva finanziaria",
            right_tip: "Deposito richiesto",
            balance: "Saldo disponibile",
            label_stop_win_price: "Prendere profitto",
            label_stop_lose_price: "Stop-loss",
            expected_profit_loss: "Profitti e perdite previsti",
            trailing_stop: "Trailing Stop",
        },
        ranking: {
            tit: "I principali guadagni e perdite della giornata",
            p: "Catturare i punti caldi e cogliere le opportunità",
            renew: "Aggiornato in",
            growth_chart: "Grafico della crescita",
            drop_list: "Elenco dei perdenti",
            show_closed_market: "Presentare il marchio Hugh's",
            filter1: "Completo",
            filter2: "Valuta estera",
            filter3: "Merce",
            filter4: "Stock",
            filter5: "Esponenti",
            filter6: "Crittografato",
            td1: "Classifiche",
            td2: "Tendenze",
            td3: "Guadagno del giorno / Prezzo di vendita",
        },
        remind: {
            now_sell_price: "Prezzo attuale richiesto",
            direction_buyPrice: "Prezzo dell'offerta",
            direction_sellPrice: "Tasso di vendita",
            direction_sell: "Reach (prezzo in un'asta)",
            direction_buy: "Buy-in",
            equal: "Quando",
            p: "Avvisami quando vengono raggiunti i seguenti prezzi",
            btn1: "Impostazione dei promemoria",
            list_tit: "Elenco dei promemoria",
        },
    },
    my: {
        report: {
            tit: "Rapporti sul conto",
            tab1: "Segnalazione",
            tab2: "Dichiarazione giornaliera",
            tab3: "Estratto conto mensile",
            history_tit: "Rapporto sulla cronologia delle transazioni",
            account_tit: "Rapporti sul conto",
            filter: "Screening",
            tip1: "Selezionare l'intervallo di date appropriato per esportare la cronologia delle transazioni.。",
            tip2: "Tempo personalizzato",
            email_send: "Invia a un'e-mail",
        },
        accountMsg: {
            tit: "Informazioni sul conto",
            label1: "Informazioni personali",
        },
        bill: {
            tit: "Dettagli del flusso di fondi",
            all: "Completo",
            time: "Tempi",
        },
        feedback: {
            tit: "Raccomandazioni funzionali",
        },
        identity: {
            tit: "Autenticazione",
            type1: "Carte d'identità",
            type2: "Patente di guida",
            type3: "Passaporto",
            tip: "Confermo che tutte le informazioni e i documenti forniti sono completi, veritieri e accurati.",
        },
        my: {
            is: "già",
            no: "Non verificato",
            verify: "Convalidato",
            id_verify: "Autenticazione",
            verify_tip: "Completare la verifica dell'identità per depositare e fare trading",
            no_verify: "Verifica ora",
            balance: "Saldo disponibile",
            profit_loss: "Cera e calante",
            marginLevel: "Livello di margine",
            btn1: "Passare al conto reale",
            deposit_withdraw_record: "Registri dei depositi e dei prelievi",
            capital_flow_details: "Dettagli del flusso di fondi",
            welfare_center: "Centro benessere",
            tip1: "Avete",
            tip2: "Premi in penna da richiedere",
            feedback: "Raccomandazioni funzionali",
            report: "Rapporti sul conto",
            setting: "Allestimento",
        },
        news: {
            tit: "Notifiche",
        },
        record: {
            tit: "Registri dei depositi e dei prelievi",
            cycle: "Ciclicità",
        },
        share: {
            tit: "Invitare gli amici",
            invite_success: "Invitare con successo gli amici",
            tip1: "Incentivo in denaro",
            tip2: "Invitate i vostri amici e fatevi coinvolgere da",
            tip3: "Invitare gli amici a ricevere contanti",
        },
        welfare: {
            tit: "Centro benessere",
            tab1: "Rotolo di carte",
            tab2: "Funzione",
            history_record: "Record storico",
            period_validity: "Periodo di validità",
        },
        wallet: {
            tit: "Borse",
            addNew: "Aggiungi un nuovo portafoglio",
        },
        withdraw: {
            tit: "Prelevare denaro",
            addNew: "Aggiungi un nuovo portafoglio",
        },
    },
    order: {
        demo_account: "Conto demo",
        real_account: "Conto live",
        tip: "È possibile depositare e fare trading dopo l'apertura",
        tab1: "Partecipazioni",
        tab2: "Ordine pendente",
        tab3: "Storie",
        list_empty: "Attualmente non ci sono ordini pendenti",
        start_trade: "Inizia a fare trading",
        hand_tip: "Campo di ingresso",
        kline: {
            direction_sell: "Reach (prezzo in un'asta)",
            direction_buy: "Buy-in",
            open_price: "Prezzo di apertura",
            now_price: "Prezzo corrente",
            trade_account: "Conto di trading",
            demo_account: "Conto demo",
            order_id: "ID ordine",
            open_time: "Orario di apertura",
            stop_profit: "Prendere profitto",
            stop_loss: "Stop-loss",
            trailing_stop: "Trailing Stop",
            float_profit_loss: "P/L galleggiante",
            overnight_fee: "Carica notturna",
            margin: "Margine",
            maintainsMargin: "Margine di manutenzione",
            open_value: "Valore di apertura",
            now_value: "Valore attuale",
            notset: "Non risolto",
            close_position: "Chiudere una posizione",
            close_number: "Numero ordinale",
            onclick_close: "Chiudere la posizione con un clic",
            btn: "Conferma della chiusura della posizione",
            balance: "Saldo disponibile",
            profit_loss: "Cera e calante",
            netValue: "Valore netto",
            marginLevel: "Livello di margine",
        },
        modify: {
            tit: "Modificare l'ordine di posizione",
            expected_profit_loss: "Profitti e perdite previsti",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Impostazione di una nuova password",
            verify_email_tit: "Verifica dell'e-mail",
            verify_password_tit: "Verifica della password",
            verify_password_tip: "Inserire la password di accesso a HKDFINANC per continuare.",
            verify_phone_tit: "Verifica del numero di cellulare",
            tit: "Sicurezza del conto",
            tip: "Per proteggere la sicurezza del vostro account e per ricevere le notifiche dei nostri servizi, si raccomanda di attivare almeno un tipo di autenticazione.",
            label1: "Cambiare la password",
            label2: "Cancellazione dei conti",
            label3: "Doppia verifica",
            label4: "Gestione delle attrezzature",
            tip2: "Autenticazione richiesta per il login di un nuovo dispositivo",
        },
        tradenews: {
            tit: "Guida al trading",
            tab1: "Avvio rapido",
            tab2: "Studio avanzato",
            tab3: "Centro di assistenza",
            tab4: "Informazioni su HKDFINANC",
            viewMore: "Per saperne di più",
            details: {
                tit: "Introduzione al commercio estero",
                tip1: "Durata stimata dello studio",
                tip2: "Minuti",
            },
            tab1Row: {
                tit: " Questo è un corso rapido per imparare a fare trading con HKDFINANC!",
                tit1: "Come posso fare trading su HKDFINANC?",
                tit2: "Come aprire un conto live？",
                tit3: "Come aprire un'operazione？",
                tit4: "Come impostare Stop Loss e Take Profit？",
                tit5: "Come chiudere una posizione？",
                p1: "Potete negoziare il sottostante acquistando o vendendo. Aprire una posizione in HKDFINANC significa avere un interesse in questo sottostante.",
                p2: "Acquisto e vendita delle fluttuazioni di prezzo del prodotto sottostante",
                p3: "Ad esempio, se si prevede che il prezzo dell'oro aumenterà, si acquista il prodotto; viceversa, se si prevede che il prezzo dell'oro diminuirà, si vende il prodotto. Se il futuro corrisponde alle aspettative, si può",
                p4: "Trarne profitto",
                p5: "Inoltre, HKDFINANC offre il 'Leverage Trading'. È possibile utilizzare la leva finanziaria per negoziare più lotti con la stessa somma di denaro.",
                p6: "La leva finanziaria può amplificare i profitti e le perdite.",
                p7: "Questa operazione è nota anche come 'trading a margine'. Quando si apre una posizione, sul conto HKDFINANC viene addebitato un certo importo di saldo come",
                p8: "Margine iniziale",
                p9: "Prodotti selezionati e indicazioni di acquisto e vendita",
                p10: "Rapporti",
                p11: "Fare clic su 'Acquista"/"Vendi' per completare l'ordine.",
                p12: "L'impostazione di un 'Take Profit' o di uno 'Stop Loss' 'consente di",
                p13: "Bloccare i profitti e limitare le perdite in modo tempestivo",
                p14: "È possibile aprire una posizione facendo clic sul pulsante",
                p15: "Take Profit/Stop Loss",
                p16: "È inoltre possibile fare clic su 'Modifica' sugli ordini aperti per impostare lo stop-loss e il take-profit.",
                p17: "Impostazione o annullamento",
                p18: "È possibile visualizzare i profitti e le perdite in 'Posizioni chiuse.",
            },
            tab3Row: {
                h1: "Benvenuti a HKDFINANC",
                h2: "Centro di assistenza",
                tit0: "Tutti i problemi",
                tit1: "Conto demo",
                tit2: "Aprire un conto",
                tit3: "Deposito e prelievo",
                tit4: "Impostazione della piattaforma",
                tit5: "Operazioni di trading",
                tit6: "Tasse e oneri",
                tit7: "Sicurezza finanziaria e regolamenti",
                tit1Tit1: "HKDFINANC Offre conti demo?",
                tit1Cont1: "<p>HKDFINANC offre ai clienti conti demo per operare e familiarizzare con la piattaforma utilizzando la nostra piattaforma web e le applicazioni mobili senza alcun rischio</p>.",
                tit1Tit2: "Come creare un conto demo?",
                tit1Cont2: "<p>Basta cliccare qui per inserire il proprio indirizzo e-mail o numero di cellulare e impostare il PIN per completare la registrazione di un conto demo. </p>",
                tit1Tit3: "A cosa serve un conto demo?",
                tit1Cont3: "<p>Il conto demo è per lo più identico al conto reale in termini di interfaccia di trading, dati e operazioni. Il conto demo dispone di 50.000 dollari di fondi virtuali ed è progettato per consentire ai clienti di familiarizzare con le caratteristiche della piattaforma attraverso operazioni simulate senza rischiare i propri fondi. </p",
                tit1Tit4: "Come finanziare un conto demo?",
                tit1Cont4: "<p>Non è necessario finanziare il vostro conto demo, quando vi iscrivete con successo ad un conto demo, ci saranno 50.000 USD o AUD nel vostro conto</p><p>E quando il valore netto del vostro conto demo scende al di sotto di 200 USD o AUD, il sistema reintegrerà automaticamente il vostro conto a 50.000 USD o AUD</p>.",
                tit1Tit5: "Il conto demo ha una data di scadenza?",
                tit1Cont5: "<p>Il conto demo è valido per 90 giorni. Se non si apre un conto live dopo 90 giorni, il conto demo sarà congelato e non sarà possibile operare su di esso, e sarà scongelato quando si aprirà un conto live. </p><p>Se si apre un conto live entro 90 giorni, il conto demo sarà valido per molto tempo. È possibile continuare a operare sul conto demo anche dopo aver aperto un conto live. </p>",
                tit2Tit1: "Quanti tipi di conto offre HKDFINANC?  ",
                tit2Cont1: "<p>Offriamo solo conti trading standard e professionali per privati, non supportiamo l'apertura di conti aziendali e conti congiunti</p>.",
                tit2Tit2: "In quale valuta posso aprire un conto di trading??",
                tit2Cont3: "<p>Quando aprite un conto, il sistema determinerà automaticamente la valuta di regolamento in base alla vostra posizione e non sarà possibile cambiarla</p>.",
                tit2Tit4: "Come aprire un conto live?",
                tit2Cont4: "<p>Per aprire un conto live, seguite i passaggi seguenti</p><p>1. Andate alla pagina di registrazione di HKDFINANC e seguite le istruzioni: registrate la vostra e-mail e il vostro numero di cellulare, impostate una password di accesso, inviate la conferma e il conto verrà creato automaticamente</p><p><><p><p></p>.",
                tit3Tit1: "Come posso effettuare un deposito?",
                tit3Cont1: "<p>HKDFINANC offre ai clienti diversi metodi di deposito tra cui scegliere, tra cui e possibilmente più dei seguenti:</p><p>1. Carta di credito Visa/Mastercard - generalmente istantanea</p><p>2. Internet Banking - generalmente entro 1 giorno lavorativo</p><p>3. Portafogli elettronici (ad es. Skrill, Momo, Zalo, Touch'n GO, Boost, ecc.) - generalmente istantanei</p><p>4. Carte bancomat - generalmente istantanee</p><p>4. Portafogli elettronici (ad es. Skrill, Momo, Zalo, Touch'n GO, Boost, ecc.) - generalmente istantanei</p><p>4. Carte bancomat - generalmente istantanee</p><p>5. QR Mobile - generalmente istantanei</p>.",
                tit3Tit2: "Posso depositare o prelevare fondi utilizzando un conto/carta bancaria intestata a terzi?",
                tit3Cont2: "<p>HKDFINANC non accetta depositi di terzi. Per depositare o prelevare fondi, si prega di utilizzare il proprio conto/carta bancaria personale a proprio nome. Se si utilizza il conto bancario/carta di un'altra persona o società per depositare fondi, questi verranno rimborsati</p>.",
                tit3Tit3: "Come posso richiedere un prelievo dal mio conto HKDFINANC?   ",
                tit3Cont3: "<p>È possibile accedere alla piattaforma del sito web, cliccare su [Prelievo], selezionare un conto bancario o una carta Visa/Mastercard e inserire l'importo del prelievo, quindi premere [Invia]; dopo essersi assicurati che le informazioni siano corrette, HKDFINANC elaborerà la richiesta entro 1-2 giorni lavorativi</p>.",
                tit4Tit1: "Qual è la leva finanziaria offerta da HKDFINANC?",
                tit4Cont1: "<p>La leva finanziaria offerta dalle piattaforme HKDFINANC si basa sui regolamenti delle autorità di regolamentazione e la leva massima varia da prodotto a prodotto. Per ulteriori informazioni, visitare la piattaforma di trading. </p",
                tit4Tit2: "Come cambiare la password?",
                tit4Cont2: "<p>È possibile reimpostare una nuova password dopo l'accesso alla piattaforma selezionando [Altro]- [Impostazioni di sicurezza]- [Modifica password]</p>.",
                tit4Tit3: "Come si accede alla Piattaforma di Trading HKDFINANC?",
                tit4Cont3: "<p>App: Andare su 'My' e cliccare su 'Login l Register' in alto a sinistra per accedere alla pagina di login. </p>Web: Cliccare su 'Login' nell'angolo in alto a destra del sito ufficiale di HKDFINANC per accedere alla pagina di login. <p></p><p></p><p></p><p></p>.",
                tit4Tit4: "Quali sono i modi in cui gli avvisi della piattaforma?",
                tit4Cont4: "<p>Avvisi HKDFINANC via e-mail, SMS, piattaforma push. </p>",
                tit5Tit1: "Che tipo di spread offre HKDFINANC?",
                tit5Cont1: "<p>Facciamo pagare una differenza di prezzo bassa e non applichiamo alcuna commissione. La differenza di prezzo è fluttuante e la differenza di prezzo effettiva addebitata dipenderà dalla varietà che stai negoziando, che verrà visualizzata sulla piattaforma di trading in tempo reale</P>",
                tit5Tit2: "Come creare un ordine di mercato?",
                tit5Cont2: "<p>Cliccando su [Trade] (Commercio) verrà visualizzata la piattaforma in grado di fornire il trading di tutti i prodotti, è possibile entrare nella parte destra della casella di input, inserire il codice o il nome della specie da ricercare, selezionare un prodotto cliccare su [Buy / Sell] (Compra / Vendi) SELL / BUY) farà apparire la finestra di trading, la finestra di trading si può vedere il prezzo corrente e il margine stimato richiesto. È possibile regolare manualmente il numero di operazioni aperte e impostare stop-loss e take-profit per controllare il rischio</p>.",
                tit5Tit3: "Come creare un ordine pendente?",
                tit5Cont3: "<p>Selezionare un prodotto in [Commercio] e fare clic su [Acquisto/Vendita] (SELL/BUY) per aprire la finestra di trading.",
                tit5Tit4: "Come chiudere una posizione?",
                tit5Cont4: "<p>Per chiudere una posizione, fare clic su Apri sulla piattaforma in Posizione, selezionare la posizione che si desidera chiudere e quindi fare clic su Chiudi sulla destra. </p",
                tit5Tit5: "Come modificare o eliminare gli ordini in sospeso?",
                tit5Cont5: "<p>Per modificare o cancellare un ordine pendente, all'interno di Position, cliccare su Orders (Ordini) sulla piattaforma e poi cliccare sull'ordine pendente che si desidera modificare o cancellare in corso</p>.",
                tit6Tit1: "Vi verrà addebitata una commissione per l'utilizzo della piattaforma di trading??",
                tit6Cont1: "<p>HKDFINANC è una piattaforma di trading completamente priva di commissioni. Addebitiamo spread bassi e, a seconda della vostra particolare situazione di trading, la piattaforma può sostenere spese aggiuntive come gli SWAP. </p>",
                tit6Tit2: "Ci sono commissioni per il deposito?",
                tit6Cont2: "<p>Non addebitiamo ai nostri clienti alcuna commissione relativa ai depositi, ma la vostra banca di pagamento o di transito potrebbe addebitare una commissione. Vi consigliamo di verificare con la vostra banca se vi stanno addebitando delle commissioni. </p>",
                tit6Tit3: "Ci sono commissioni per i prelievi?",
                tit6Cont3: "<p>HKDFINANC non addebita ai clienti alcuna commissione relativa ai prelievi, a meno che non si prelevi meno dell'importo minimo richiesto o si superi il numero massimo di prelievi gratuiti per il mese. Per maggiori dettagli, consultare la nostra politica di prelievo. </p",
                tit7Tit1: "I fondi depositati sul mio conto HKDFINANC potranno essere utilizzati per altri scopi?",
                tit7Cont1: "<p>No. I depositi dei clienti al dettaglio sono conservati in conti fiduciari separati, ove necessario, in conformità ai requisiti normativi. Quando si depositano o si prelevano fondi o si effettuano pagamenti da un conto di denaro del cliente, HKDFINANC si attiene rigorosamente alle norme che regolano il denaro del cliente come indicato nel Quadro normativo. </p>",
                tit7Tit2: "Se HKDFINANC chiude o si indebita, anche i miei fondi ne risentiranno?",
                tit7Cont2: "<p>I depositi dei clienti del settore retail sono segregati in conti fiduciari, ove necessario, in conformità alla normativa vigente. In caso di fallimento di HKDFINANC, di annullamento della licenza o di mancata prosecuzione dell'attività, i fondi dei clienti rimangono segregati e sono soggetti alla normativa sul denaro dei clienti ai sensi della legge vigente</p>.",
                tit7Tit3: "HKDFINANC rispetta la legge?",
                tit7Cont3: "<p>La Società è pienamente conforme al diritto societario e alle leggi di regolamentazione finanziaria, ed è un fornitore di servizi finanziari autorizzato e strettamente regolamentato dagli organi di regolamentazione competenti. La Società fornisce una protezione completa e adeguata per la sicurezza dei fondi dei clienti, in conformità ai rigorosi requisiti della normativa. </p>",
                tit7Tit4: "I dati personali del cliente sono al sicuro?",
                tit7Cont4: "<p>HKDFINANC si attiene rigorosamente alle disposizioni dell'Ordinanza sulla protezione dei dati personali, i dati personali dei clienti non saranno mai divulgati. La Società garantisce che i propri dipendenti rispettino i rigorosi standard di sicurezza e riservatezza stabiliti dalla legge e presta molta attenzione alla riservatezza e all'utilizzo dei dati dei clienti. Per ulteriori informazioni, si rimanda al documento di divulgazione legale della Società. </p>",
            },
            tab4Row: {
                span: "Q Metodi di verifica",
                p1: "HKDFINANC è una società fintech autorizzata e regolamentata dalle autorità, che si concentra sull'offerta agli investitori di un'esperienza di trading semplice e conveniente.",
                p2: "L'innovativa piattaforma di trading diversificata di HKDFINANC ha vinto ripetutamente premi come Miglior piattaforma di trading mobile e Brokeraggio più innovativo.",
                p3: "Attraverso la piattaforma HKDFINANC, è possibile investire e fare trading in una gamma più ampia di mercati finanziari internazionali, che coprono azioni, indici, materie prime, cambi e centinaia di altre specie popolari. Oltre alle piattaforme di trading mobili e web di facile utilizzo, HKDFINANC vi offre anche costi di trading competitivi, un'esecuzione rapida delle operazioni, un eccellente servizio clienti e un'ampia gamma di informazioni di supporto, aiutandovi a cogliere tempestivamente le opportunità di investimento e di trading.",
                p4: "HKDFINANC è strettamente regolamentata dalle autorità del settore e da molteplici organizzazioni.",
                p5: "HKDFINANC Holding è autorizzata e regolamentata dalla Cayman Islands Monetary Authority (CIMA) con la SIB Licence No. 1612446. Per maggiori dettagli sulla licenza delle Cayman, visitare il sito ufficiale della CIMA all'indirizzo www.cima.ky (",
                p6: ") HKDFINANCGlobal è titolare di una licenza per servizi finanziari (AFSL 398528) autorizzata dall'Australian Securities and Investments Commission (ASIC). Per ulteriori informazioni sulle licenze australiane, visitate il sito ufficiale dell'ASIC all'indirizzo www.asic.gov.au. (",
                p7: ") HKDFINANC International è autorizzata e regolamentata dalla Financial Services Commission (FSC) di Mauritius con il numero di licenza GB20025791. Per i dettagli sulla licenza di Mauritius, si prega di visitare il sito ufficiale della FSC all'indirizzo www.fscmauritius.org. (",
                p8: ")Tutte le operazioni commerciali di HKDFINANC sono condotte sotto stretta sorveglianza e nel rispetto di tutte le normative.",
                p9: "Sfondo",
                p10: "HKDFINANC è composta da un team di persone esperte e competenti, con esperienza e conoscenza dei settori del trading finanziario e del fintech. La filosofia di HKDFINANC è quella di rendere il trading più semplice e facile da usare.",
                p11: "La filosofia di HKDFINANC è quella di rendere il trading più semplice e amichevole. La nostra strategia olistica e il nostro modello di business ci consentono di avere una visione e una comprensione globale dello sviluppo del settore e della domanda di mercato, in modo da poter allocare le risorse in modo più mirato, innovare continuamente la tecnologia e ottimizzare l'efficienza per offrire ai nostri clienti un'esperienza di trading più comoda e semplice.",
                p12: "Perché scegliere",
                p13: "Condizioni di trading a bassa soglia",
                p14: "Piattaforma di trading semplice e intuitiva",
                p15: "Costi di transazione competitivi",
                p16: "Regolato dalle autorità di settore",
                p17: "Alto livello di supporto online",
                p18: "Protezione del saldo negativo",
                p19: "Premi e onorificenze",
                p20: "HKDFINANC è onorata di essere stata premiata come [Best Multi-Asset Broker] dai World Finance Awards, [Best Mobile Trading Platform in Asia-Pacific] [Fastest Growing FinTech Broker in the World] da Global BrandsMagazine e molti altri premi, che testimoniano la dedizione e il duro lavoro del team.",
                p21: "Miglior Broker Multi-Asset",
                p22: "Concetti operativi",
                p23: "Responsabilità",
                p24: "Tutte le operazioni di trading comportano dei rischi e HKDFINANC ha adottato ulteriori misure per fornire un ambiente di trading responsabile ai propri clienti. HKDFINANC è regolamentata dalle autorità del settore, da molteplici organizzazioni e opera in base a elevati livelli di sicurezza e stabilità. La nostra piattaforma continua a offrire una serie di strumenti di trading responsabile, tra cui la gestione del rischio.",
                p25: "Semplice ma bello",
                p26: "La semplicità è bellezza, il dettaglio è eccellenza, e in HKDFINANC ci impegniamo per garantire la facilità d'uso e l'esperienza dell'utente in ogni dettaglio. Continuiamo a ottimizzare la nostra piattaforma e i nostri servizi nella speranza che tutti, dal principiante all'investitore esperto, possano trarre vantaggio dalle opportunità di trading disponibili attraverso la piattaforma HKDFINANC.",
                p27: "Aperto (non segreto)",
                p28: "Al fine di consentire ai nostri clienti di beneficiare appieno di un ambiente di trading equo ed efficiente, HKDFINANC segue un sistema di autoregolamentazione rigoroso e trasparente per fornire una piattaforma completa e trasparente di informazioni sui prodotti, l'ambiente dei prezzi e i dati, tutte le commissioni sono chiare e aperte per garantire che l'intero processo di servizio sia completamente sincero e trasparente.",
                p29: "Aprire nuovi sentieri",
                p30: "HKDFINANC crede fermamente che il progresso tecnologico, l'innovazione finanziaria e i servizi più ampi siano gli elementi che guidano la crescita e che possiamo continuare a creare e condividere valore. A tal fine, incoraggiamo il pensiero aperto e continuiamo a guidare l'innovazione, con l'obiettivo di essere un innovatore e un leader nella tecnologia finanziaria.",
                p31: "Obiettivi e visione",
                p32: "Fornire a un maggior numero di persone che desiderano operare un ambiente di trading equo, efficiente e trasparente, non limitato da spazio, tempo e mercato, e guidare il settore nell'innovazione finanziaria e tecnologica.",
            },
        },
        language: {
            tit: "Selezionare la lingua",
        },
        market: {
            tit: "Simbolo del ticker",
            tit1: "Visualizzazione del prezzo",
            tit2: "Colori crescenti o decrescenti",
            buy_price: "Prezzo dell'offerta",
            buy_price_tip: "Tracciare una linea K in corrispondenza del prezzo di offerta",
            sell_price: "Tasso di vendita",
            sell_price_tip: "Tracciare le linee K al prezzo di vendita",
            color1: "Verde che sale e rosso che cade",
            color2: "Crescita rossa e caduta verde",
        },
        notice: {
            tit: "Impostazioni di notifica",
            tit1: "Aprire canali di notifica",
            p1: "Aprite/verificate i seguenti canali di notifica per tenere traccia delle novità più importanti!",
            message: "Messaggi di testo",
            push: "Spingere",
            verify: "Convalidare (una teoria)",
            verified: "Convalidato",
            email: "Indirizzo e-mail",
            type1: "Mercato",
            tit2: "Notifica delle attività di marketing",
            desc2: "Informazioni sulle offerte speciali della piattaforma, attività operative e altre notizie.",
            tit3: "Istruzioni per l'uso",
            desc3: "Materiale di conoscenza ricco e specializzato",
            tit4: "Avvisi informativi",
            desc4: "Dati finanziari e notizie recenti",
            tit5: "Avviso intelligente di fluttuazione",
            desc5: "Include le fluttuazioni dei prezzi di specie importanti, aiutandovi a cogliere rapidamente l'opportunità",
            type2: "Tipo di rapporto",
            tit6: "Rapporti sul conto",
            desc6: "Il rapporto sul conto verrà inviato mensilmente con le seguenti modalità",
            cycle: "Ciclicità",
            month: "Ogni mese",
        },
        protocol: {
            tit: "Dichiarazioni e accordi",
            label1: "Informativa sulla privacy",
            label2: "Dichiarazione di divulgazione del prodotto",
            label3: "Accordo con il cliente",
            label4: "Dichiarazione di rischio",
        },
        setting: {
            tit: "Allestimento",
            label1: "Impostazioni di sicurezza",
            label2: "Simbolo del ticker",
            label3: "Impostazioni di transazione",
            label4: "Multilinguismo",
            label5: "Modalità Tema",
            label6: "Impostazioni di notifica",
            label7: "Guida al trading",
            label8: "Dichiarazioni e accordi",
            label9: "Cancellazione della cache",
            label10: "Numero di versione",
            logout: "Disconnettersi",
        },
        theme: {
            tit: "Modalità Tema",
            theme1: "Modalità chiara",
            theme2: "Modalità scura",
        },
        trade: {
            tit: "Impostazioni di transazione",
            onclick_close: "Chiudere la posizione con un clic",
        }
    },
    trade: {
        fliter_type1: "Self-service",
        fliter_type2: "Mercato",
        place_search: "Inserire il nome della varietà/codice per la ricerca",
        fliter_type3: "In voga",
        fliter_type4: "Valuta estera",
        fliter_type5: "Merce",
        fliter_type6: "Stock",
        fliter_type7: "Esponenti",
        fliter_type8: "Crittografato",
        tip1: "Completare la verifica dell'identità può essere depositata nella transazione, l'investimento nel globale",
        sell: "Reach (prezzo in un'asta)",
        buy: "Buy-in",
        add_optional: "Aggiungi opzionale",
        optional_recommend: "Raccomandazioni facoltative",
        change_batch: "Lotto",
        edit_tit: "Scelta del redattore",
        breed: "Assortimento",
        topping: "Topping",
        sort: "Disporre in ordine",
        all: "All'unanimità",
    },
    table: {
        label_breed: "Assortimento",
        label_direction: "Orientamenti",
        label_price: "Prezzi",
        label_operate: "Impianto di perforazione",
        empty_tip1: "Nessun avviso",
        empty_tip2: "Nessuna registrazione dei depositi",
        empty_tip3: "Nessun record di ritiro",
        label_amount: "Denaro",
        label_type: "Tipologia",
        label_time: "Tempi",
        label_name: "Nome",
        label_email: "Indirizzo e-mail",
        label_address: "Paese di residenza",
        label_account: "Numero di conto di trading",
        label_currency: "Valuta di base",
        label_company: "Conto corrente Società",
    },
    form: {
        to: "Fino a quando",
        start_time: "Ora di inizio",
        end_time: "Ora di fine",
        week: "La scorsa settimana",
        month: "Circa un mese",
        threeMonth: "Ultimi tre mesi",
        sixMonth: "Gli ultimi sei mesi",
        label_feedback: "Problemi e raccomandazioni",
        place_feedback: "Inserite la vostra domanda o il vostro suggerimento",
        label_img: "Fotografia",
        label_img_tip: "Facoltativamente, fornire una schermata del problema",
        feedback_tip: "Se avete una domanda urgente, contattate",
        online: "Servizio clienti online",
        label_cardNumber: "Numero del certificato",
        label_realName: "Nome e cognome",
        place_realName: "Inserisci il tuo nome",
        message_realName: "Inserire il nome completo",
        label_firstName: "Nome della famiglia",
        place_firstName: "Inserire il proprio cognome",
        message_firstName: "Inserire il cognome",
        label_lastName: "Nome (di una cosa)",
        place_lastName: "Inserire il secondo nome",
        message_lastName: "Inserire un nome",
        label_birthday: "Data di nascita",
        place_birthday: "Inserire la data di nascita",
        message_birthday: "Inserire la data di nascita",
        label_idType: "Tipo di documento",
        tip_cardMain: "Scattare o caricare una foto della parte anteriore della carta d'identità.",
        tip_cardBack: "Scattate o caricate una foto del retro della vostra carta d'identità.",
        tip_cardMain1: "Scattare o caricare una foto del fronte della patente di guida",
        tip_cardBack1: "Scattare o caricare una foto del retro della patente di guida.",
        tip_cardMain2: "Scattare o caricare le foto del passaporto",
        tip_cardBack2: "Scattare o caricare le foto del passaporto",
        label_password: "Crittografico",
        place_password: "Inserire la password",
        message_password: "Inserire la password",
        label_crmpsd: "Conferma la password",
        place_crmpsd: "Inserire nuovamente la password",
        message_crmpsd: "Inserire nuovamente la password",
        label_email: "Indirizzo e-mail",
        place_email: "Indirizzo e-mail",
        message_email: "Inserisci il tuo indirizzo e-mail",
        label_captcha: "CAPTCHA",
        place_captcha: "Inserire il codice di verifica",
        message_captcha: "Inserire il codice di verifica",
        get_captcha: "Ottenere CAPTCHA",
        label_phone: "Telefoni",
        place_phone: "Inserire il numero di telefono",
        message_phone: "Inserire il numero di telefono",
        labelAmount: "Somma di denaro",
        placeAmount: "Inserire l'importo",
        messageAmount: "Inserire l'importo",
        labelWalletName: "Nome del portafoglio",
        messageWalletName: "Inserire il nome del portafoglio",
        placeWalletName: "Inserire il nome del portafoglio",
        labelWalletAddress: "Indirizzo del portafoglio",
        messageWalletAddress: "Inserire l'indirizzo del portafoglio",
        placeWalletAddress: "Inserire l'indirizzo del portafoglio",
        labelWithdrawAccount: "Conto di prelievo",
        placeWithdrawAccount: "Conto di prelievo",
        messageWithdrawAccount: "Conto di prelievo",
		placeTimeLimit: "Inserire un limite di tempo",
		messageTimeLimit: "Inserire un limite di tempo",
    },
	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austriaco",
		bahrain: "Bahrain",
		belgium: "Belgio",
		bosnia_and_Herzegovina: "Abbreviazione di Bosnia-Erzegovina",
		brazil: "Brasiliano",
		brunei: "Brunei Darussalam, sultanato indipendente nel Borneo nordoccidentale",
		bulgaria: "Bulgaria",
		cambodia: "Cambogiano",
		canada: "Canadese",
		cameroon: "Camerun",
		chile: "Cile",
		colombia: "Columbia ",
		costa_Rica: "Costa Rica",
		croatia: "Repubblica di Croazia (1991-)",
		cyprus: "Cipro",
		czech_Republic: "Repubblica Ceca (dal 1993)",
		denmark: "Danimarca",
		dominican_Republic: "Repubblica Dominicana",
		egypt: "Egitto",
		estonia: "Estonia",
		ethiopia: "Etiopia",
		finland: "Suomi",
		france: "Francese",
		georgia: "Georgia (paese)",
		germany: "Tedesco",
		ghana: "Ghana, Africa occidentale",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Cina",
		hungary: "Ungheria",
		iceland: "Islandese",
		ireland: "Irlandese",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israele",
		iran: "Iraniano",
		iraq: "Iracheno",
		japan: "Giapponese",
		kazakstan: "Kazakistan",
		kenya: "Kenya",
		korea: "Corea del Sud (Repubblica di Corea)",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonia",
		lithuania: "La repubblica lituana, ex repubblica sovietica del Baltico",
		luxembourg: "Lussemburgo",
		macao_China: "Macao, Cina",
		macedonia: "Macedone",
		malaysia: "Malesia",
		malta: "Maltese",
		mexico: "Messico",
		moldova: "Repubblica di Moldova, ex repubblica sovietica al confine con la Romania",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Marocco",
		myanmar: "Myanmar",
		netherlands: "Paesi Bassi",
		new_Zealand: "Nuova Zelanda",
		nepal: "Nepali",
		nigeria: "Nigeria, Africa occidentale",
		norway: "Norvegia",
		oman: "Omaniti",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peruviano",
		philippines: "Filippine",
		poland: "Polacco",
		portugal: "Portogallo",
		puerto_Rico: "Porto Rico, territorio non incorporato e autogovernato degli Stati Uniti.",
		qatar: "Doha",
		romania: "Romania",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad e Tobago",
		rwanda: "Ruandese",
		saudi_Arabia: "Arabia Saudita",
		serbia: "Serbia",
		singapore: "Singaporiano",
		slovakia: "Slovacchia",
		slovenia: "Slovenia",
		south_Africa: "Sudafrica",
		spain: "Spagnolo",
		sri_Lanka: "(Ex) Ceylon",
		sweden: "Svezia",
		switzerland: "Svizzera",
		taiwan_China: "Cina-Taiwan",
		tajikistan: "Tagikistan",
		tanzania: "Tanzania",
		thailand: "Thailandia",
		turkey: "Istanbul",
		turkmenistan: "Turkmenistan",
		ukraine: "Bielorussia",
		united_Arab_Emirates: "EMIRATI ARABI UNITI",
		united_Kingdom: "Regno Unito di Gran Bretagna e Irlanda del Nord",
		united_States: "Stati Uniti d'America",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaigian, ex repubblica sovietica e regione dell'Iran nordoccidentale nel Caucaso",
		bangladesh: "Bangladesh ",
		belarus: "Bielorussia",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Isole Vergini britanniche",
		burkina_Faso: "Burkina Faso, Africa occidentale",
		burundi: "Burundi",
		cape_Verde: "Capo Verde",
		cayman_Islands: "Isole Cayman",
		central_African_Republic: "Repubblica Centrafricana",
		chad: "Ciadiano",
		comoros: "Comore",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Repubblica Democratica del)",
		djibouti: "Gibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Equatoriale",
		eritrea: "Eritrea",
		fiji: "Figi ",
		gabon: "Gabonese",
		gambia: "Gambia",
		greenland: "Groenlandia",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti, il terzo occidentale dell'isola caraibica di Hispaniola",
		isle_of_Man: "Isola di Mann",
		cote_d_Ivoire: "Costa d'Avorio o Costa d'Avorio, in Africa occidentale.",
		jamaica: "Giamaicano",
		jordan: "jordan",
		lebanon: "Ostilità",
		lesotho: "Lesotho, capitale del Sudafrica",
		liberia: "Liberia",
		libya: "Libia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Le Maldive",
		mali: "Mali, Africa occidentale",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambico",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger (Stato africano)",
		north_Korea: "Dinastia coreana Joseon o Chosun 1392-1910",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadiscio",
		sudan: "Sultano",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Siria",
		togo: "Togo, Africa occidentale",
		tonga: "Tonga, regno dell'arcipelago del Pacifico meridionale",
		tunisia: "Tunisi, capitale della Tunisia",
		united_States_Virgin_Islands: "Isole Vergini Americane (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vaticano",
		yemen: "Yemenita",
		yugoslavia: "Jugoslavia, 1943-1992",
		zambia: "Seychelles",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
