export default {
    crypto: {
        title: {
            "0": "Compra de criptomonedas",
            "1": "Compra de criptomonedas",
            "2": "C2c",
        },
        list: {
            "0": "Transacciones c2c",
            "1": "Compra",
            "2": "Venta",
            "3": "Anunciantes (tasa de finalización)",
            "4": "Transacciones",
            "5": "Precio",
            "6": "Disponible",
            "7": "Restricciones",
            "8": "Método de pago",
            "9": "Comercio",
            "10": "Los anunciantes pueden establecer restricciones de transacción para los anuncios, y después de iniciar sesión, verá las razones de las restricciones.",
        },
        buy: {
            "0": "Compra de criptomonedas",
            "1": "Venta de criptomonedas",
            "2": "Historia",
            "3": "Apoyo",
            "4": "Pago",
            "5": "Recibir",
            "6": "Elegir el método de pago",
            "7": "Hora del este de los Estados Unidos. Hora de llegada",
            "8": "Gastos",
            "9": "Detalles del pedido",
            "10": "Método de pago",
            "11": "Aprende más",
            "12": "Precio",
            "13": "Total (incluidos los gastos)",
            "14": "Declaración de exención de responsabilidad",
            "15": "Usted abandonará HKDFinanc y será redirigido a mercury, una institución financiera autorizada que ofrece servicios de pago con tarjeta de crédito / débito como tercero. Cualquier riesgo que se produzca durante el uso del servicio es asumido por mercury. Lea y acepte los términos de uso antes de continuar.",
            "16": "He leído y aceptado los términos de uso",
            "17": "Lo conseguirás",
            "18": "Presentación",
            "19": "Por favor, introduzca la cantidad",
            "20": "Saldo insuficiente de la cuenta",
            "21": "Disponible",
            "22": "Cuenta al contado disponible",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        },
    },
    common: {
        view: "Check",
        confirm: "Confirmar",
        cancel: "Cancelar",
        deposit: "Depósito",
        withdraw: "Cashout",
        noData: "Actualmente, não existem dados disponíveis",
        submit: "Enviar",
        modify: "Modificar",
        hand: "Mão",
        confirm_verify: "Confirmar a Validação",
        next: "Próximo passo",
        complete: "Completo",
        delete: "Apagar",
		countryCode: "Código do país",
    },
    addnew: {
        realFirst: "Execute por favor a autenticação de identidade primeiro",
        payFirst: "Indique primeiro a senha da transacção",
        password1: "Alterar a senha de autenticação",
        password2: "Alterar a senha de retirada",
        paypassTit1: "Por favor, defina a sua senha de retirada",
        oldPass: "Senha antiga",
        allView: "Tudo lido",
        payPass: "Senha da transacção",
        buyUp: "Comprando",
        buyDown: "Compra baixo",
        confirmOrder: "Confirmar a Ordem",
        name: "Nome",
        fee: "Taxas de tratamento",
        orderCycle: "Seleccionar a hora",
        loan: "Empréstimo",
        loanTit: "Empréstimos  assistência",
        loanHostory: "Registos de empréstimos",
        labelProductTitle: "Nome do Produto",
        immediateRepayment: "Reembolso imediato",
        loadAmount: "Montante previsto do empréstimo",
        RepaymentCycle: "Ciclo de reembolso do empréstimo",
        day: "Dia",
        dailyRate: "Taxa de juro diária",
        repaymentMethod: "Método de reembolso",
        lendingCompany: "Empresa de empréstimos",
        loanTip: "Empréstimo de crédito (certifique-se de que a imagem está clara e visível)",
        loanTip1: "Enviar o certificado de propriedade",
        loanTip2: "Prova de rendimento (relação de trabalho)",
        loanTip3: "Detalhes do extracto bancário",
        loanTip4: "Carregue uma foto da frente do seu cartão de identificação",
        interest: "Juros",
        repaid: "Reembolso",
        unpaid: "Não pago",
        tab1: "Moedas",
        tab2: "Contrato",
        tab3: "Opção",
        available: "Crédito Disponível",
        pending: "Travamento",
        equivalent: "Equivalente",
        fundTransfer: "Transferência de fundos",
        flashExchange: "Troca flash",
        select_breed: "Seleccionar a Moeda",
        from: "De",
        to: "A",
        transferQuantity: "Quantidade de transferência",
        repaymentMethod1:"Um reembolso no prazo de vencimento"
    },
    params: {
        product_foreign: "Moeda estrangeira",
        product_shop: "Commodity",
        product_number: "índice",
        product_stock: "Acções",
        product_coin: "Encriptação",
        billTypes: [
            { v: 101, name: 'Recarregar' },
            { v: 102, name: 'Adicionar' },
            { v: 103, name: 'Congelar' },
            { v: 104, name: 'Descongelamento' },
            {v: 105, name: 'Depósito'},
            {v: 106, name: 'Depósito'},
            { v: 201, name: 'Congelamento de retirada' },
            { v: 202, name: 'Dedução' },
            { v: 203, name: 'Retirada bem sucedida' },
            { v: 204, name: 'A retirada falhou' },
            { v: 205, name: 'Taxa de retirada' },
            {v: 206, name: 'Transferência para fora'},
            {v: 207, name: 'Transferência para'},
            {v: 208, name: 'Transferência de moeda para fora'},
            {v: 209, name: 'Transferência de moeda em'},
            { v: 301, name: 'Dedução do depósito' },
            { v: 302, name: 'Dedução das taxas de tratamento' },
            { v: 303, name: 'Reembolso do depósito' },
            { v: 304, name: 'Lucro' },
            { v: 305, name: 'Perda' },
            {v: 311, name: 'Opções de Compra'},
            {v: 312, name: 'Retorna a opção'},
            {v: 313, name: 'Opção Reembolso'},
            {v: 314, name: 'Taxa de tratamento de opções'},
            {v: 315, name: 'Congelamento das compras monetárias'},
            {v: 316, name: 'Dedução de compra em moeda'},
            {v: 317, name: 'Retorno da Compra de Moedas'},
            {v: 318, name: 'Compra de moedas recebidas'},
            {v: 319, name: 'Compra de moedas recebidas'},
            {v: 320, name: 'Congelamento das vendas monetárias'},
            {v: 321, name: 'Dedução das vendas em moeda estrangeira'},
            {v: 322, name: 'Retorno das vendas de moedas'},
            {v: 323, name: 'Moedas vendidas à conta'},
            {v: 324, name: 'Moedas vendidas à conta'},
            {v: 325, name: 'Taxa de tratamento monetário'},
            {v: 401, name: 'Adição de máquinas de extracção'},
            {v: 402, name: 'Retorno da máquina de mineração'},
            {v: 403, name: 'Receitas de máquinas mineiras'},
            {v: 404, name: 'Saída da máquina mineira'},
            {v: 405, name: 'Taxa de manuseio de saída da máquina de mineração'},
            {v: 411, name: 'Rendimentos de empréstimos'},
            {v: 412, name: 'Taxas de tratamento de empréstimos'},
            {v: 413, name: 'Reembolso bem sucedido'},
        ],
    },
    footer: {
        nav1: "Página inicial",
        nav2: "Transacção",
        nav3: "Caixote",
        nav4: "O meu",
        kline_set1: "Definição do mercado",
        kline_set2: "Configuração da Transacção",
    },
    login: {
        login: "Login",
        register: "Registo",
        type1: "Número de telemóvel",
        type2: "Caixa de correio",
        login_btn: "Login seguro",
        register_btn: "Registe-se Agora",
        psd_login: "Senha de autenticação",
        verify_login: "Registo do código de verificação",
        forget: "Esqueceu- se da senha?",
        no_account: "Sem conta?",
        have_account: "Conta existente",
    },
    home: {
        home: {
            nav1: "Classificação",
            nav2: "Rápido começar",
            nav3: "Sobre nós",
            nav4: "Recompensas por convite",
            most_follow: "Mais seguidos",
            news_type1: "Estratégia",
            news_type2: "Notícias",
            news_type3: "Calendário Económico",
            news_tab1: "Inteiro",
            news_tab2: "Moeda",
            news_tab3: "Acções",
            news_tab4: "Commodity",
            news_tab5: "índice",
            news_tab6: "Dados",
            news_tab7: "Evento",
            news_tab8: "Feriado",
            label_previous: "Valor anterior",
            label_predictive: "Estimativa",
            label_announced: "Valor anunciado",
        },
        kilne: {
            label_bp: "Recebido",
            label_sp: "Abertura",
            label_height: "Mais alto",
            label_low: "Mínimo",
            tip: "Olá, sou o assistente de variedade HKDFINANCGPT",
            row1_tit: "Estratégia",
            row1_rinei: "Dentro do dia",
            row1_short: "Curto prazo",
            row1_middle: "A médio prazo",
            row1_time: "Tempo de libertação",
            row1_tit2: "Estratégia de negociação",
            data_sources: "Fontes de dados",
            row2_tit1: "Dados",
            row2_tit2: "Sentimento comercial",
            row2_label_sell: "Vendedor",
            row2_label_buy: "Comprador",
            tip2: "Apenas para referência, não como a posição da nossa empresa",
            tip3: "Actualizar a cada 15 minutos",
            row2_tit3: "Flutuações de preços",
            minute: "Minuto",
            today: "Hoje",
            right_tit7: "Variação de preços",
            now_price: "Preço actual",
            right_low_price: "Preço mais baixo",
            right_height_price: "Preço máximo",
            details: "Detalhes",
            right_label1: "Número de operações individuais",
            right_label2: "Alavancagem máxima",
            right_label3: "Dimensão do contrato",
            right_label4: "Quantidade máxima total da posição",
            right_label5: "Diferença flutuante de preços",
            right_label6: "Taxa overnight",
            hand: "Mão",
            right_sell: "Vender",
            right_buy: "Comprar",
            right_collection_time: "Tempo de recolha",
            right_tip3: "Recolher de todo o tamanho da transacção",
            right_label9: "Período de negociação",
            right_label10: "Período actual",
            right_label11: "Período Seguinte",
        },
        placeorder: {
            direction_buyPrice: "Preço de compra",
            direction_sellPrice: "Preço de venda",
            direction_sell: "Vender",
            direction_buy: "Comprar",
            market_price: "Lista preços mercado",
            check_price: "Listagem",
            sell_tip: "Vender quando o preço atender às condições",
            buy_tip: "Comprar quando o preço cumprir as condições",
            trade_number: "Quantidade da transacção",
            hand: "Mão",
            select_lever: "Seleccionar a alavanca",
            right_tip: "Depósito exigido",
            balance: "Saldo disponível",
            label_stop_win_price: "Parar o excedente",
            label_stop_lose_price: "Parar a perda",
            expected_profit_loss: "Lucros e perdas previstos",
            trailing_stop: "Trailing Stop",
        },
        ranking: {
            tit: "Classificação do dia para cima e para baixo",
            p: "Capturar pontos críticos e aproveitar oportunidades",
            renew: "Actualizado em",
            growth_chart: "Gráfico de crescimento",
            drop_list: "Lista de Soltar",
            show_closed_market: "Exibir as Marcas Fora do Mercado",
            filter1: "Inteiro",
            filter2: "Moeda estrangeira",
            filter3: "Commodity",
            filter4: "Acções",
            filter5: "índice",
            filter6: "Encriptação",
            td1: "Classificação",
            td2: "Tendência",
            td3: "Aumento diário/preço de venda",
        },
        remind: {
            now_sell_price: "Preço de venda corrente",
            direction_buyPrice: "Preço de compra",
            direction_sellPrice: "Preço de venda",
            direction_sell: "Vender",
            direction_buy: "Comprar",
            equal: "Quando",
            p: "Lembre-me quando os seguintes preços forem atingidos",
            btn1: "Definir lembretes",
            list_tit: "Lista de Lembretes",
        },
    },
    my: {
        report: {
            tit: "Relatório da Conta",
            tab1: "Relatório",
            tab2: "Declaração diária",
            tab3: "Demonstração mensal",
            history_tit: "Relatório do Histórico de Transacções",
            account_tit: "Relatório da Conta",
            filter: "Ecrã",
            tip1: "Selecione o intervalo de datas apropriado para exportar seu relatório de histórico de transações.",
            tip2: "Hora personalizada",
            email_send: "Enviar para e- mail",
        },
        accountMsg: {
            tit: "Informações da conta",
            label1: "Informações pessoais",
        },
        bill: {
            tit: "Dados relativos aos fluxos de capital",
            all: "Inteiro",
            time: "Tempo",
        },
        feedback: {
            tit: "Recomendações funcionais",
        },
        identity: {
            tit: "Autenticação",
            type1: "Cartão de identificação",
            type2: "Carta de condução",
            type3: "Passaporte",
            tip: "Confirmo que todas as informações e documentos fornecidos são completos, verdadeiros e precisos",
        },
        my: {
            is: "Já",
            no: "Não verificado",
            verify: "Verificado",
            id_verify: "Autenticação",
            verify_tip: "Verificação completa da identidade para depositar a transacção",
            no_verify: "Verificar Agora",
            balance: "Saldo disponível",
            profit_loss: "Lucros e perdas",
            marginLevel: "Nível da margem",
            btn1: "Mudar para uma conta real",
            deposit_withdraw_record: "Registos de depósitos e levantamentos",
            capital_flow_details: "Dados relativos aos fluxos de capital",
            welfare_center: "Centro de Bem-Estar",
            tip1: "Você tem",
            tip2: "Recompensa da caneta a reclamar",
            feedback: "Recomendações funcionais",
            report: "Relatório da Conta",
            setting: "Configurar",
        },
        news: {
            tit: "Aviso",
        },
        record: {
            tit: "Registos de depósitos e levantamentos",
            cycle: "Ciclo",
        },
        share: {
            tit: "Convidar amigos",
            invite_success: "Amigos convidados com sucesso",
            tip1: "Recompensas em dinheiro",
            tip2: "Convidar amigos para tomarem o máximo",
            tip3: "Convidar amigos para recolher dinheiro",
        },
        welfare: {
            tit: "Centro de Bem-Estar",
            tab1: "Rolo de cartões",
            tab2: "Actividade",
            history_record: "História",
            period_validity: "Período de validade",
        },
        wallet: {
            tit: "Carteira",
            addNew: "Adicionar uma carteira nova",
        },
        withdraw: {
            tit: "Cashout",
            addNew: "Adicionar uma carteira nova",
        },
    },
    order: {
        demo_account: "Conta simulada",
        real_account: "Conta real",
        tip: "Após a abertura, o ouro pode ser depositado para transações",
        tab1: "Posição de posição",
        tab2: "Listagem",
        tab3: "História",
        list_empty: "Não existem actualmente ordens pendentes",
        start_trade: "Iniciar a negociação",
        hand_tip: "Intervalo de entrada",
        kline: {
            direction_sell: "Vender",
            direction_buy: "Comprar",
            open_price: "Preço de abertura",
            now_price: "Preço actual",
            trade_account: "Conta de negociação",
            demo_account: "Conta simulada",
            order_id: "ID da encomenda",
            open_time: "Tempo Aberto",
            stop_profit: "Parar o excedente",
            stop_loss: "Parar a perda",
            trailing_stop: "Trailing Stop",
            float_profit_loss: "P/L flutuante",
            overnight_fee: "Taxa overnight",
            margin: "Margem",
            maintainsMargin: "Manter a margem",
            open_value: "Valor de abertura",
            now_value: "Valor actual",
            notset: "Não definido",
            close_position: "Posição de encerramento",
            close_number: "Quantidade normal",
            onclick_close: "Posição de fecho de um clique",
            btn: "Confirmar a posição de fecho",
            balance: "Saldo disponível",
            profit_loss: "Lucros e perdas",
            netValue: "Património líquido",
            marginLevel: "Nível da margem",
        },
        modify: {
            tit: "Modificar a Ordem de Posição",
            expected_profit_loss: "Lucros e perdas previstos",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Definir uma nova senha",
            verify_email_tit: "Verificar o e- mail",
            verify_password_tit: "Verificar a sua senha",
            verify_password_tip: "Digite sua senha de login do HKDFINANC para continuar",
            verify_phone_tit: "Verificar o número de telefone",
            tit: "Segurança da conta",
            tip: "Para proteger a segurança de sua conta e aceitar nossas notificações de serviço, recomenda-se ativar pelo menos um tipo de autenticação.",
            label1: "Mudar a senha",
            label2: "Cancelamento da conta",
            label3: "Verificação dupla",
            label4: "Gestão de dispositivos",
            tip2: "A autenticação do novo dispositivo requer verificação",
        },
        tradenews: {
            tit: "Guia de Transacções",
            tab1: "Rápido começar",
            tab2: "Aprendizagem avançada",
            tab3: "Centro de Ajuda",
            tab4: "Sobre o HKDFINANC",
            viewMore: "Ver mais",
            details: {
                tit: "Introdução ao câmbio",
                tip1: "Duração estimada da aprendizagem",
                tip2: "Minuto",
            },
            tab1Row: {
                tit: "Esta é uma sala de aula para aprender rapidamente a negociar com HKDFINANC",
                tit1: "Como é feito o trading no HKDFINANC?",
                tit2: "Como abrir uma conta real？",
                tit3: "Como abrir uma posição para negociação？",
                tit4: "Como definir stop profit e stop loss？",
                tit5: "Como fechar uma posição？",
                p1: "Você pode negociar o assunto comprando ou vendendo-o. Abrir uma posição na HKDFINANC significa que você está interessado no",
                p2: "Negociação baseada nas flutuações de preços do produto alvo",
                p3: "Por exemplo, se você espera que o preço do ouro suba, compre o produto; Pelo contrário, se você prever que o preço do ouro vai diminuir, então venda o produto. Se o futuro atender às expectativas, você pode",
                p4: "Lucrar com isso",
                p5: "Além disso, a HKDFINANC fornece ''negociação alavancada'. Você pode usar alavancagem para negociar mais alvos com a mesma quantidade de fundos.",
                p6: "Alavancagem pode amplificar lucros e também amplificar perdas",
                p7: "Isso também é conhecido como ''negociação de margem'. Quando você abre uma posição, sua conta HKDFINANC deduz um certo saldo como",
                p8: "Margem inicial",
                p9: "Selecionar produtos e direções de compra e venda",
                p10: "Transacção",
                p11: "Clique em ''Comprar/Vender'' para concluir a colocação do pedido",
                p12: "Definir 'stop profit' ou 'stop loss' pode ajudá-lo",
                p13: "Bloqueio oportuno dos lucros e limite as perdas",
                p14: "Você pode clicar na página de abertura bem sucedida",
                p15: "Stop profit/stop loss",
                p16: "Configure-o, ou você pode clicar no botão ''Modificar'' para que a ordem não balanceada pare os lucros e perdas",
                p17: "Definir ou cancelar",
                p18: "Você pode ver lucros e perdas em 'posições fechadas'",
            },
            tab3Row: {
                h1: "Bem-vindos a HKDFINANC.",
                h2: "Centro de Ajuda",
                tit0: "Todas as questões",
                tit1: "Conta simulada",
                tit2: "Abrir uma conta",
                tit3: "Entrada e saída de dinheiro",
                tit4: "Configuração da Plataforma",
                tit5: "Operações de Transacção",
                tit6: "Taxas e encargos",
                tit7: "Segurança financeira e regulamentos",
                tit1Tit1: "O HKDFINANC fornece contas simuladas",
                tit1Cont1: "<p>HKDFINANC fornece contas simuladas para que os clientes operem, permitindo que você use nossa plataforma online e aplicativos móveis para transações e se familiarize com as operações da plataforma sem riscos</p>",
                tit1Tit2: "Como criar uma conta simulada?",
                tit1Cont2: "<p>Você só precisa clicar aqui para preencher seu endereço de e-mail ou número de telefone, e definir sua senha pessoal para concluir o registro e obter uma conta simulada</p>",
                tit1Tit3: "Qual é o uso de contas simuladas?",
                tit1Cont3: "<p>Uma conta simulada e uma conta real são praticamente iguais em termos de interface de transação, dados e operações.A conta simulada contém US $ 50000 em fundos virtuais, com o objetivo de familiarizar os clientes com várias funções da plataforma através de operações simuladas sem qualquer risco financeiro</p>",
                tit1Tit4: "Como injetar fundos em uma conta simulada?",
                tit1Cont4: "<p>Você não precisa injetar fundos na conta de simulação. Depois de registrar com sucesso a conta de simulação, haverá um total de $50000 USD ou AUD na conta.</p><p>Quando o valor líquido na conta de simulação for inferior a $200 USD ou AUD, o sistema reabastecerá automaticamente os fundos da sua conta para $50000 USD ou AUD.</p></p>",
                tit1Tit5: "A conta simulada tem um período de uso?",
                tit1Cont5: "<p>O período de validade da conta simulada é de 90 dias. Se você não abrir uma conta real após 90 dias, a conta simulada será congelada e não poderá ser negociada, e será descongelada quando você abrir uma conta real</p> Se você abrir uma conta real dentro de 90 dias, a conta simulada será válida por um longo período de tempo. Mesmo depois de abrir uma conta real, você pode continuar a operar em uma conta simulada</p>",
                tit2Tit1: "Quantos tipos de conta a HKDFINANC oferece?",
                tit2Cont1: "<p>Nós fornecemos somente contas de negociação padrão e profissional para indivíduos, e não apoiamos a abertura de contas da empresa ou conjuntas</p>",
                tit2Tit2: "Em que moeda posso abrir uma conta de negociação?",
                tit2Cont3: "<p>Quando você abre uma conta, o sistema determinará automaticamente sua moeda de liquidação com base em sua região e não será modificável</p>",
                tit2Tit4: "Como abrir uma conta real?",
                tit2Cont4: "<p>Siga os passos abaixo para abrir uma conta real</p><p>1 Entre na página de registro do HKDFINANC e siga as instruções: Registre o número de telefone de e-mail, defina a senha de login e crie automaticamente após enviar a confirmação</p></p><p></p>",
                tit3Tit1: "Como posso fazer um depósito?",
                tit3Cont1: "<p>HKDFINANC oferece diferentes métodos de depósito para os clientes escolherem, incluindo, mas não limitado ao seguinte:</p><p>1. Visa/Mastercard - geralmente recebimento instantâneo</p><p>2. pagamentos bancários on-line geralmente são feitos dentro de 1 dia útil</p><p>3. carteiras eletrônicas (como Skrill, Momo, Zalo. Touch'n GO, Boost, etc.) - geralmente recebimento instantâneo</p><4. cartão ATM - geralmente recebimento instantâneo</p><p>5. QR mobile online banking - geralmente recebimento instantâneo</p>",
                tit3Tit2: "Posso usar uma conta bancária/cartão com um nome de terceiro para depositar ou retirar fundos",
                tit3Cont2: "<p>HKDFINANC não aceita depósitos de terceiros. Por favor, use sua conta bancária pessoal / cartão em seu próprio nome para depositar ou retirar fundos. Se você usar o nome de outra pessoa ou conta bancária/cartão da empresa para depositar fundos, os fundos serão reembolsados</p>",
                tit3Tit3: "Como solicitar a retirada da conta HKDFINANC?",
                tit3Cont3: "<p>Depois de fazer login na plataforma do site, você pode clicar em 'Cash Out', selecionar uma conta bancária ou Visa/Mastercard, preencher o valor de saque e, em seguida, pressionar 'Enviar'. Após confirmar que as informações estão corretas, HKDFINANC processará sua inscrição dentro de 1-2 dias úteis</p>",
                tit4Tit1: "Quantas vezes a HKDFINANC oferece negociação alavancada?",
                tit4Cont1: "<p>O rácio de alavancagem fornecido pela plataforma HKDFINANC é definido de acordo com os regulamentos das autoridades reguladoras, e a alavancagem máxima para diferentes produtos também varia.。</p>",
                tit4Tit2: "Como alterar a minha senha?",
                tit4Cont2: "<p>Depois de entrar na plataforma, você pode escolher [Mais] - [Configurações de segurança] - T Alterar senha] para redefinir a nova senha</p>",
                tit4Tit3: "Como fazer login na plataforma de negociação HKDFINANC?",
                tit4Cont3: "<p>App: Vá para 'Meu' e clique em 'Login l Registration' no canto superior esquerdo para entrar na página de login</ p> Web: Clique em 'Login' no canto superior direito do site oficial da HKDFINANC para entrar na página de login< p> </p><p></p><p><p></p><p><p></p>",
                tit4Tit4: "Quais são os métodos para lembretes de plataforma?",
                tit4Cont4: "<p>HKDFINANC fornece lembretes por e-mail, SMS e push da plataforma</p>",
                tit5Tit1: "Que diferença de preço a HKDFINANC oferece?",
                tit5Cont1: "<p>Nós cobramos uma diferença de preço baixa e não cobramos nenhuma comissão. A diferença de preço é flutuante, e a diferença de preço real cobrada dependerá da variedade que você está negociando, e as informações específicas serão exibidas na plataforma de negociação em tempo real</p>",
                tit5Tit2: "Como estabelecer uma lista de preços de mercado?",
                tit5Cont2: "<p>Clicando em 'Negociar' irá mostrar todos os produtos que a plataforma pode oferecer para negociação. Você pode inserir o código da variedade ou nome na caixa de entrada à direita para pesquisar, selecionar um produto e clicar em 'Comprar/Vender' SEL/COMPRAR 'para abrir uma janela de negociação. Na janela de negociação, você pode ver o preço atual e a margem necessária esperada. Você pode ajustar manualmente o número de posições abertas e configurar stop profit e stop loss para controle de risco</p>",
                tit5Tit3: "Como estabelecer um formulário de registo?",
                tit5Cont3: "<p>Selecionar um produto em [Negociar] e clicar em [Vender/COMPRAR] irá aparecer uma janela de negociação</p>",
                tit5Tit4: "Como fechar uma posição?",
                tit5Cont4: "<p>Para fechar uma posição, clique em ''Abrir'' na plataforma de visualização em ''Posição', selecione a posição que deseja fechar e clique no botão ''Fechar'' no lado direito</p>",
                tit5Tit5: "Como modificar ou excluir uma ordem pendente?",
                tit5Cont5: "<p>Para modificar ou excluir pedidos, clique em ''Pedidos'' na plataforma de visualização na seção ''Posição'' e selecione os pedidos que deseja modificar ou excluir em andamento</p>",
                tit6Tit1: "Haverá uma taxa de manuseio cobrada pela utilização da plataforma de negociação?",
                tit6Cont1: "<p>HKDFINANC é uma plataforma de negociação completamente livre de comissão. Cobramos uma diferença de preço baixa e, dependendo da situação específica da transação, a plataforma pode incorrer em taxas adicionais, como juros overnight</p>",
                tit6Tit2: "Existe alguma taxa para o depósito?",
                tit6Cont2: "<p>Nós não cobraremos quaisquer taxas relacionadas ao depósito aos clientes, mas seu pagamento ou banco intermediário pode cobrar taxas de manuseio. Sugerimos que você pergunte aos bancos relevantes se eles cobraram alguma taxa</p>",
                tit6Tit3: "Existe alguma taxa para a retirada?",
                tit6Cont3: "<p>HKDFINANC não cobrará aos clientes quaisquer taxas relacionadas com a retirada de fundos, a menos que o valor que você retirar esteja abaixo do requisito mínimo ou exceda o número máximo de levantamentos gratuitos para o mês. Para informações mais detalhadas, consulte a nossa política de depósito</p>",
                tit7Tit1: "Os fundos que deposito na minha conta HKDFINANC serão utilizados para outros fins?",
                tit7Cont1: "<p>No. Os depósitos de clientes de retalho são depositados separadamente em contas fiduciárias, quando necessário, de acordo com os regulamentos regulamentares. Ao depositar fundos na conta de um cliente ou retirar fundos da conta de um cliente ou fazer pagamentos, a HKDFINANC cumpre rigorosamente os regulamentos regulamentares relativos aos pagamentos de clientes</p>",
                tit7Tit2: "Se a HKDFINANC for à falência ou estiver endividada, meus fundos também serão afetados?",
                tit7Cont2: "<p>Depósitos de clientes de varejo são depositados separadamente em contas fiduciárias quando necessário de acordo com as regulamentações regulatórias. Se a HKDFINANC entrar em falência, tiver sua licença revogada ou não puder continuar operando, os fundos dos clientes ainda podem ser colocados em quarentena e sujeitos à supervisão dos regulamentos de fundos dos clientes nas leis regulatórias</p>",
                tit7Tit3: "A HKDFINANC cumpre os regulamentos legais?",
                tit7Cont3: "<p>Nossa empresa está totalmente em conformidade com as leis relevantes da empresa e leis e regulamentos regulatórios financeiros, e é um provedor de serviços financeiros autorizado e estritamente regulamentado por agências reguladoras relevantes. Nossa empresa fornece proteção abrangente e suficiente para a segurança financeira dos clientes de acordo com requisitos regulatórios rigorosos.</p>",
                tit7Tit4: "As informações pessoais do cliente estão seguras?",
                tit7Cont4: "<p>A HKDFINANC cumpre rigorosamente as disposições do Regulamento de Proteção de Dados Pessoais, e os dados pessoais dos clientes nunca serão divulgados. A empresa garantirá que os funcionários cumpram os rigorosos padrões de segurança e confidencialidade estipulados por leis e regulamentos, e prestem muita atenção aos arranjos para a confidencialidade e uso dos dados dos clientes. Para mais detalhes, consulte os documentos de divulgação legal da nossa empresa.</p>",
            },
            tab4Row: {
                span: "Q método de verificação",
                p1: "A HKDFINANC é uma empresa de tecnologia financeira autorizada e regulamentada por instituições de autoridade, com foco em fornecer aos investidores uma experiência de negociação simples e conveniente.",
                p2: "A inovadora plataforma de negociação diversificada HKDFINANC ganhou repetidamente prêmios como a melhor plataforma de negociação móvel e a empresa de valores mobiliários mais inovadora.",
                p3: "Através da plataforma HKDFINANC, você pode investir e negociar em uma ampla gama de mercados financeiros internacionais, cobrindo centenas de variedades populares, como ações, índices, commodities, câmbio, etc. Não apenas limitado a plataformas de negociação móveis e web fáceis de usar, a HKDFINANC também fornece custos de transação competitivos, execução rápida de transações, excelente atendimento ao cliente e suporte de informações ricas, ajudando você a aproveitar oportunidades de investimento e negociação em tempo hábil.",
                p4: "HKDFINANC é estritamente regulamentado por autoridades da indústria e várias instituições.",
                p5: "A HKDFINANC Holding é autorizada e regulamentada pela Autoridade Monetária das Ilhas Caimão (CIMA) com um número de licença SIB 1612446. Para mais detalhes sobre a matrícula das Ilhas Caimão, visite o site oficial da CIMA em www.cima.ky (",
                p6: ") Conduzir uma consulta. A HKDFINANCGlobal possui uma Licença de Serviços Financeiros (AFSL 398528) autorizada pela Comissão de Valores Mobiliários e Investimentos de Hong Kong (ASIC). Para mais detalhes sobre fotos de Hong Kong, visite o site oficial da ASIC em www.asic.gov.au(",
                p7: ") Faça uma consulta. A HKDFINANC International é autorizada e regulada pela Comissão de Serviços Financeiros das Maurícias (FSC) com um número de licença GB20025791. Para mais informações sobre a matrícula Maurícia, visite o site oficial do FSC em www.fscmauricius.org (",
                p8: ")Faça uma consulta. Todas as operações comerciais da HKDFINANC são conduzidas sob supervisão rigorosa e cumprem todos os regulamentos",
                p9: "Antecedentes",
                p10: "HKDFINANC é composto por uma equipe sênior com rica experiência e conhecimento nas indústrias de negociação financeira e fintech. A filosofia da HKDFINANC é tornar as transações mais fáceis e amigáveis",
                p11: "A filosofia da HKDFINANC é tornar as transações mais fáceis e amigáveis. Nossa estratégia holística e modelo de negócios nos permitem ter uma compreensão abrangente e profunda do desenvolvimento da indústria e da demanda do mercado, permitindo-nos alocar recursos mais direcionados, inovar continuamente a tecnologia e otimizar a eficiência, trazendo continuamente aos clientes experiências comerciais mais convenientes e amigáveis",
                p12: "Porquê escolher",
                p13: "Condições de negociação de limiares baixos",
                p14: "Plataforma de negociação simples e intuitiva",
                p15: "Custos de transacção competitivos",
                p16: "Supervisionado pelas autoridades industriais",
                p17: "Suporte online de alto nível",
                p18: "Protecção do saldo negativo",
                p19: "Prémios e Honras",
                p20: "A HKDFINANC tem a honra de receber vários prêmios, incluindo Melhor Corretora de Ativos Múltiplos do World Finance Awards, Melhor Plataforma de Negociação Móvel da Global BrandsMagazine na Região Ásia-Pacífico e Global Fast Growing Financial Technology Broker, que confirmam os esforços contínuos e dedicação da equipe.",
                p21: "Melhor Corretor Multiactivo",
                p22: "A nossa filosofia operacional",
                p23: "Responsabilidade",
                p24: "Todas as transações envolvem riscos, e a HKDFINANC está tomando mais medidas para fornecer aos clientes um ambiente de negociação responsável. A HKDFINANC está sujeita à autoridade da indústria, supervisão rigorosa de várias instituições e adere a padrões operacionais de alto nível de segurança e estabilidade. Nossa plataforma continua fornecendo várias ferramentas de negociação responsáveis, incluindo gerenciamento de riscos",
                p25: "Simples mas bonito",
                p26: "A HKDFINANC esforça-se por cada detalhe de simplicidade, facilidade de uso e experiência do usuário. Continuamos a otimizar a nossa plataforma e serviços, esperando que todos, sejam investidores iniciantes ou experientes, possam aproveitar facilmente as oportunidades de negociação através da plataforma HKDFINANC.",
                p27: "transparente",
                p28: "A fim de beneficiar plenamente os nossos clientes de um ambiente comercial justo e eficiente, a HKDFINANC segue um sistema de auto-regulação rigoroso e transparente, fornecendo informações abrangentes e transparentes sobre os produtos da plataforma, ambiente de preços e dados. Todas as taxas são claras e transparentes, garantindo a sinceridade e transparência completas de todo o processo de serviço.",
                p29: "Inovar",
                p30: "A HKDFINANC acredita firmemente que o progresso tecnológico, a inovação financeira e os serviços mais amplos são as forças motrizes para o crescimento, a fim de criar e compartilhar continuamente valor. Por esta razão, incentivamos o pensamento aberto, promovemos continuamente a inovação e pretendemos tornar-nos inovadores e líderes em tecnologia financeira.",
                p31: "Objectivos e Visão",
                p32: "Proporcionar um ambiente comercial justo, eficiente e transparente que não seja limitado por espaço, tempo ou mercado para mais pessoas que queiram realizar transações, liderando a inovação financeira e tecnológica da indústria.",
            },
        },
        language: {
            tit: "Seleccionar o Idioma",
        },
        market: {
            tit: "Definição do mercado",
            tit1: "Indicação dos preços",
            tit2: "Cor dos altos e baixos",
            buy_price: "Preço de compra",
            buy_price_tip: "Desenhar uma linha K ao preço de compra",
            sell_price: "Preço de venda",
            sell_price_tip: "Desenhar linha K a preço de venda",
            color1: "Ascensão verde e queda vermelha",
            color2: "Ascensão vermelha e queda verde",
        },
        notice: {
            tit: "Configuração das Notificações",
            tit1: "Abrir canais de notificação",
            p1: "Por favor, abra / verifique os seguintes canais de notificação, mensagens importantes não são perdidas!",
            message: "Mensagem",
            push: "Empurrar",
            verify: "Validar",
            verified: "Verificado",
            email: "E-mail",
            type1: "Marketing",
            tit2: "Notificação do Evento de Comercialização",
            desc2: "Informações promocionais da Plataforma, atividades operacionais e outras mensagens",
            tit3: "Instruções de funcionamento",
            desc3: "Materiais de conhecimento ricos e profissionais",
            tit4: "Lembrete de informação",
            desc4: "Dados financeiros recentes e notícias",
            tit5: "Lembrete inteligente de flutuação",
            desc5: "Incluindo flutuações de preços de variedades importantes, ajudando você a aproveitar rapidamente oportunidades",
            type2: "Classe de relatório",
            tit6: "Relatório da Conta",
            desc6: "Seu relatório de conta será enviado mensalmente da seguinte forma",
            cycle: "Ciclo",
            month: "Mensalmente",
        },
        protocol: {
            tit: "Declaração e acordo",
            label1: "Política de Privacidade",
            label2: "Declaração de Divulgação do Produto",
            label3: "Acordo com o Cliente",
            label4: "Declaração de Divulgação de Riscos",
        },
        setting: {
            tit: "Configurar",
            label1: "Configuração de segurança",
            label2: "Definição do mercado",
            label3: "Configuração da Transacção",
            label4: "Língua",
            label5: "Modo Tema",
            label6: "Configuração das Notificações",
            label7: "Guia de Transacções",
            label8: "Declaração e acordo",
            label9: "Limpar a 'cache'",
            label10: "Número da versão",
            logout: "Sair do login",
        },
        theme: {
            tit: "Modo Tema",
            theme1: "Modo de luz",
            theme2: "Modo Escuro",
        },
        trade: {
            tit: "Configuração da Transacção",
            onclick_close: "Posição de fecho de um clique",
        }
    },
    trade: {
        fliter_type1: "Selecção própria",
        fliter_type2: "Mercado",
        place_search: "Introduzir a pesquisa do nome/código da variedade",
        fliter_type3: "Popular",
        fliter_type4: "Moeda estrangeira",
        fliter_type5: "Commodity",
        fliter_type6: "Acções",
        fliter_type7: "índice",
        fliter_type8: "Encriptação",
        tip1: "Verificação de identidade completa para depositar transações e investir globalmente",
        sell: "Vender",
        buy: "Comprar",
        add_optional: "Adicionar Selecção Automática",
        optional_recommend: "Recomendação de auto-selecção",
        change_batch: "Alterar o lote",
        edit_tit: "Editar a Selecção",
        breed: "Variedade",
        topping: "Topping",
        sort: "Sort",
        all: "Seleccionar Tudo",
    },
    table: {
        label_breed: "Variedade",
        label_direction: "Direcção",
        label_price: "Preço",
        label_operate: "Operação",
        empty_tip1: "Nenhum lembrete de momento",
        empty_tip2: "Sem registos de depósitos",
        empty_tip3: "Sem registo de desembolsos em numerário",
        label_amount: "Dinheiro",
        label_type: "Tipo",
        label_time: "Tempo",
        label_name: "Nome",
        label_email: "E-mail",
        label_address: "País de residência",
        label_account: "Conta de negociação",
        label_currency: "Moeda de base",
        label_company: "Empresa de abertura de contas correntes",
    },
    form: {
        to: "A",
        start_time: "Hora de início",
        end_time: "Hora do fim",
        week: "Semana passada",
        month: "No mês passado",
        threeMonth: "Nos últimos três meses",
        sixMonth: "Nos últimos seis meses",
        label_feedback: "Perguntas e sugestões",
        place_feedback: "Insira sua pergunta ou sugestão",
        label_img: "Imagem",
        label_img_tip: "Opcional, forneça capturas de tela do problema",
        feedback_tip: "Se você tiver perguntas urgentes, entre em contato",
        online: "Serviço Online",
        label_cardNumber: "Número de identificação",
        label_realName: "Nome completo",
        place_realName: "Indique o seu primeiro nome",
        message_realName: "Indique o seu nome completo",
        label_firstName: "Apelido",
        place_firstName: "Por favor, indique o seu sobrenome",
        message_firstName: "Por favor, indique o seu sobrenome",
        label_lastName: "Nome",
        place_lastName: "Por favor indique o seu nome do meio",
        message_lastName: "Indique por favor um nome",
        label_birthday: "Data de nascimento",
        place_birthday: "Indique a data de nascimento",
        message_birthday: "Indique a data de nascimento",
        label_idType: "Tipo de documento",
        tip_cardMain: "Tire ou carregue uma foto da FRONT do seu bilhete de identidade",
        tip_cardBack: "Tire ou carregue uma foto do BAIXO do seu bilhete de identidade",
        tip_cardMain1: "Tire ou carregue uma foto da FRONT da sua carteira de motorista",
        tip_cardBack1: "Tire ou carregue uma foto do FUNDO da sua carteira de motorista",
        tip_cardMain2: "Tire ou carregue uma foto do seu passaporte",
        tip_cardBack2: "Tire ou carregue uma foto do seu passaporte",
        label_password: "Senha",
        place_password: "Introduza a senha",
        message_password: "Introduza a senha",
        label_crmpsd: "Confirmar a Senha",
        place_crmpsd: "Introduza a senha novamente",
        message_crmpsd: "Introduza a senha novamente",
        label_email: "E-mail",
		place_email: "Endereço de e- mail",
		message_email: "Introduza o seu endereço de e-mail",
        label_captcha: "Código de verificação",
		place_captcha: "Introduza o código de verificação",
		message_captcha: "Introduza o código de verificação",
		get_captcha: "Obter código de verificação",
        label_phone: "Telefone",
		place_phone: "Introduza um número de telefone",
		message_phone: "Introduza um número de telefone",
        labelAmount: "Dinheiro",
        placeAmount: "Indique o montante",
        messageAmount: "Indique o montante",
        labelWalletName: "Nome da carteira",
		messageWalletName: "Indique por favor o nome da carteira",
		placeWalletName: "Indique por favor o nome da carteira",
		labelWalletAddress: "Endereço da carteira",
		messageWalletAddress: "Indique por favor o endereço da carteira",
		placeWalletAddress: "Indique por favor o endereço da carteira",
        labelWithdrawAccount: "Conta de levantamento",
        placeWithdrawAccount: "Conta de levantamento",
        messageWithdrawAccount: "Conta de levantamento",
		placeTimeLimit: "Indique por favor um limite de tempo",
		messageTimeLimit: "Indique por favor um limite de tempo",
    },
	city: {
		albania: "Albânia",
		algeria: "Argélia",
		argentina: "Argentina",
		armenia: "Arménia",
		australia: "Austrália",
		pakistan: "Paquistão",
		austria: "Áustria",
		bahrain: "Bahrein",
		belgium: "Bélgica",
		bosnia_and_Herzegovina: "Bósnia e Herzegovina",
		brazil: "Brasil",
		brunei: "Brunei",
		bulgaria: "Bulgária",
		cambodia: "Camboja",
		canada: "Canadá",
		cameroon: "Camarões",
		chile: "Chile",
		colombia: "Colômbia",
		costa_Rica: "Costa Rica",
		croatia: "Croácia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estónia",
		ethiopia: "Etiópia",
		finland: "Finlândia",
		france: "França",
		georgia: "Geórgia",
		germany: "Alemanha",
		ghana: "Gana",
		greece: "Grécia",
		guyana: "Guiana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungria",
		iceland: "Islândia",
		ireland: "Irlanda",
		italy: "Itália",
		india: "Índia",
		indonesia: "Indonésia",
		israel: "Israel",
		iran: "Irão",
		iraq: "Iraque",
		japan: "Japão",
		kazakstan: "Cazaquistão",
		kenya: "Quénia",
		korea: "Coreia",
		kuwait: "Kuwait",
		kyrgyzstan: "Quirguizistão",
		laos: "Laos",
		latvia: "Letónia",
		lithuania: "Lituânia",
		luxembourg: "Luxemburgo",
		macao_China: "Macau, China",
		macedonia: "Macedónia",
		malaysia: "Malásia",
		malta: "Malta",
		mexico: "México",
		moldova: "Moldávia",
		monaco: "Mônaco",
		mongolia: "Mongólia",
		montenegro: "Montenegro",
		morocco: "Marrocos",
		myanmar: "MianmarName",
		netherlands: "Países Baixos",
		new_Zealand: "Nova Zelândia",
		nepal: "Nepal",
		nigeria: "Nigéria",
		norway: "Noruega",
		oman: "Omã",
		palestine: "Palestina",
		panama: "Panamá",
		paraguay: "Paraguai",
		peru: "Peru",
		philippines: "Filipinas",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roménia",
		russia: "Rússia",
		republic_of_Trinidad_and_Tobago: "Trinidad e Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arábia Saudita",
		serbia: "Sérvia",
		singapore: "Singapura",
		slovakia: "Eslováquia",
		slovenia: "Eslovénia",
		south_Africa: "África do Sul",
		spain: "Espanha",
		sri_Lanka: "Sri Lanka",
		sweden: "Suécia",
		switzerland: "Suíça",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajiquistão",
		tanzania: "Tanzânia",
		thailand: "Tailândia",
		turkey: "Türkiye",
		turkmenistan: "Turquemenistão",
		ukraine: "Ucrânia",
		united_Arab_Emirates: "EAU",
		united_Kingdom: "Grã-Bretanha",
		united_States: "Estados Unidos",
		uzbekistan: "Usbequistão",
		venezuela: "Venezuela",
		vietnam: "Vietname",
		afghanistan: "Afeganistão",
		angola: "Angola",
		azerbaijan: "Azerbaijão",
		bangladesh: "Bangladesh",
		belarus: "Bielorrússia",
		belize: "Belizefrance. kgm",
		benin: "Benin",
		bhutan: "Butão",
		bolivia: "Bolívia",
		botswana: "Botsuana",
		british_Virgin_Islands: "Ilhas Virgens Britânicas",
		burkina_Faso: "Burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cabo Verde",
		cayman_Islands: "Ilhas Caimão",
		central_African_Republic: "República Centro-Africana",
		chad: "ChadeName",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "JibutiName",
		ecuador: "Equador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guiné Equatorial",
		eritrea: "Eritreia",
		fiji: "Fiji",
		gabon: "Gabão",
		gambia: "Gâmbia",
		greenland: "Gronelândia",
		guatemala: "Guatemala",
		guinea: "Guiné",
		haiti: "Haiti",
		isle_of_Man: "Ilha de Man",
		cote_d_Ivoire: "Costa do Marfim",
		jamaica: "Jamaica",
		jordan: "Jordânia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldivas",
		mali: "Mali",
		mauritania: "Mauritânia",
		mauritius: "Maurícia",
		mozambique: "Moçambique",
		namibia: "Namíbia",
		nicaragua: "Nicarágua",
		republic_of_Niger: "Níger",
		north_Korea: "Coreia do Norte",
		reunion: "Reunião",
		san_Marino: "San Marino",
		senegal: "SenegalName",
		sierra_Leone: "sierra leone",
		somalia: "Somália",
		sudan: "Sudão",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Síria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunísia",
		united_States_Virgin_Islands: "Ilhas Virgens, Estados Unidos",
		uganda: "Uganda",
		uruguay: "Uruguai",
		vatican: "Vaticano",
		yemen: "Iémen",
		yugoslavia: "Jugoslávia",
		zambia: "Zâmbia",
		zimbabwe: "zimbabwe",
		china: "China",
	}
};
