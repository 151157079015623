export default {
    crypto: {
        title: {
            "0": "Acheter des crypto - monnaies",
            "1": "Acheter des crypto - monnaies",
            "2": "C2c",
        },
        list: {
            "0": "Transactions c2c",
            "1": "Acheter",
            "2": "Vendu",
            "3": "Annonceurs (taux de complétion)",
            "4": "Transactions",
            "5": "Prix",
            "6": "Disponible",
            "7": "Restrictions",
            "8": "Modes de paiement",
            "9": "Commerce",
            "10": "Les annonceurs peuvent définir des restrictions de transaction sur les annonces et vous verrez la raison de la restriction une fois connecté.",
        },
        buy: {
            "0": "Acheter des crypto - monnaies",
            "1": "Vendre des crypto - monnaies",
            "2": "Historique",
            "3": "Soutien",
            "4": "Paiement",
            "5": "Recevoir",
            "6": "Choisir un mode de paiement",
            "7": "Heure de l'est des États - Unis. Heure d'arrivée",
            "8": "Coût",
            "9": "Détails de la commande",
            "10": "Modes de paiement",
            "11": "En savoir plus",
            "12": "Prix",
            "13": "Total (frais inclus)",
            "14": "Disclaimer",
            "15": "Vous quittez HKDFinanc et serez redirigé vers mercuryo, une institution financière agréée qui fournit des services de paiement par carte de crédit / débit en tant que tiers. Tout risque encouru lors de l'utilisation des services est à la charge de mercuryo. Veuillez lire et accepter les conditions d'utilisation avant de continuer.",
            "16": "J'ai lu et j'accepte les conditions d'utilisation",
            "17": "Vous obtiendrez",
            "18": "Soumettre",
            "19": "Veuillez entrer le montant",
            "20": "Solde insuffisant du compte",
            "21": "Disponible",
            "22": "Compte au comptant disponible",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        },
    },
    common: {
        view: "Afficher",
        confirm: "Confirmer",
        cancel: "Annuler",
        deposit: "Dépôt",
        withdraw: "Retrait",
        noData: "Pas de données",
        submit: "Soumettre",
        modify: "Modifier",
        hand: "Lots",
        confirm_verify: "Confirmer la vérification",
        next: "Suivant",
        complete: "Terminé",
        delete: "Supprimer",
		countryCode: "Code pays / région",
    },
    addnew: {
        realFirst: "Veuillez d'abord vous authentifier",
        payFirst: "Veuillez d'abord définir un mot de passe de transaction",
        password1: "Modifier le mot de passe de connexion",
        password2: "Modifier le mot de passe de retrait",
        paypassTit1: "Veuillez définir votre mot de passe de retrait",
        oldPass: "Ancien mot de passe",
        allView: "Tous lus",
        payPass: "Mot de passe de transaction",
        buyUp: "Acheter",
        buyDown: "La baisse",
        confirmOrder: "Confirmer la commande",
        name: "Nom",
        fee: "Frais",
        orderCycle: "Sélectionner la durée",
        loan: "Prêt",
        loanTit: "Prêt de soutien",
        loanHostory: "Historique des prêts",
        labelProductTitle: "Nom du produit",
        immediateRepayment: "Remboursement immédiat",
        loadAmount: "Montant du prêt prévu",
        RepaymentCycle: "Période de remboursement du prêt",
        day: "Jour(s)",
        dailyRate: "Taux journalier",
        repaymentMethod: "Méthode de remboursement",
        lendingCompany: "Société de prêt",
        loanTip: "Prêt personnel (veuillez vous assurer que l'image est claire)",
        loanTip1: "Télécharger le certificat de propriété",
        loanTip2: "Preuve de revenu (relation de travail)",
        loanTip3: "Détails du relevé bancaire",
        loanTip4: "Télécharger la photo recto de la carte d'identité",
        interest: "Intérêt",
        repaid: "Remboursé",
        unpaid: "Non remboursé",
        tab1: "Spot",
        tab2: "Contrat",
        tab3: "Option",
        available: "Crédit disponible",
        pending: "En attente",
        equivalent: "Équivalent",
        fundTransfer: "Transfert de fonds",
        flashExchange: "Échange flash",
        select_breed: "Sélectionner la devise",
        from: "De",
        to: "À",
        transferQuantity: "Quantité à transférer",
        repaymentMethod1: "Remboursement en une seule fois à l'échéance",
    },
    params: {
        product_foreign: "Devises",
        product_shop: "Marchandises",
        product_number: "Indices",
        product_stock: "Actions",
        product_coin: "Cryptomonnaies",
        billTypes: [
            {v: 101, name: 'Dépôt'},
            {v: 102, name: 'Déposer'},
            {v: 103, name: 'Gel'},
            {v: 104, name: 'Débloquer'},
            {v: 105, name: 'Déposer'},
            {v: 106, name: 'Déposer'},
            {v: 201, name: 'Gel de retrait'},
            {v: 202, name: 'Retrait'},
            {v: 203, name: 'Retrait réussi'},
            {v: 204, name: 'Échec de retrait'},
            {v: 205, name: 'Frais de retrait'},
            {v: 206, name: 'Transfert sortant'},
            {v: 207, name: 'Transfert entrant'},
            {v: 208, name: 'Transfert de crypto-monnaie sortant'},
            {v: 209, name: 'Transfert de crypto-monnaie entrant'},
            {v: 301, name: 'Frais de contrat'},
            {v: 302, name: 'Bénéfices de contrat'},
            {v: 303, name: 'Pertes de contrat'},
            {v: 304, name: 'Marge de contrat'},
            {v: 305, name: 'Remboursement de marge de contrat'},
            {v: 311, name: "Achat d'options"},
            { v: 312, name: "Bénéfices d'options"},
            { v: 313, name: "Remboursement d'options"},
            { v: 314, name: "Frais d'options"},
            { v: 315, name: "Gel d'achat de cryptomonnaies"},
            { v: 316, name: "Déduction d'achat de cryptomonnaies"},
            { v: 317, name: "Remboursement d'achat de cryptomonnaies" },
            { v: 318, name: "Crédit d'achat de cryptomonnaies"},
            { v: 319, name: "Crédit d'achat de cryptomonnaies"},
            { v: 320, name: 'Gel de vente de cryptomonnaies' },
            { v: 321, name: 'Déduction de vente de cryptomonnaies' },
            { v: 322, name: 'Remboursement de vente de cryptomonnaies' },
            { v: 323, name: 'Crédit de vente de cryptomonnaies' },
            { v: 324, name: 'Crédit de vente de cryptomonnaies' },
            { v: 325, name: 'Frais de cryptomonnaies' },
            { v: 401, name: 'Adhésion à la mine' },
            { v: 402, name: 'Retour de la mine' },
            { v: 403, name: 'Revenu de la mine' },
            { v: 404, name: 'Sortie de la mine' },
            { v: 405, name: 'Frais de sortie de la mine' },
            { v: 411, name: 'Revenu des prêts' },
            { v: 412, name: 'Frais de prêts' },
            { v: 413, name: 'Remboursement réussi' },
        ],
    },

    footer: {
        nav1: "Accueil",
        nav2: "Trading",
        nav3: "Positions",
        nav4: "Mon compte",
        kline_set1: "Paramètres de graphique",
        kline_set2: "Paramètres de trading",
    },
    login: {
        login: "Connexion",
        register: "Inscription",
        type1: "Numéro de téléphone",
        type2: "Email",
        login_btn: "Connexion sécurisée",
        register_btn: "S'inscrire maintenant",
        psd_login: "Connexion par mot de passe",
        verify_login: "Connexion par code de vérification",
        forget: "Mot de passe oublié?",
        no_account: "Pas de compte?",
        have_account: "Déjà un compte",
    },
    home: {
        home: {
            nav1: "Top Gagnants et Perdants",
            nav2: "Démarrage rapide",
            nav3: "À propos de nous",
            nav4: "Programme de récompenses",
            most_follow: "Le plus suivi",
            news_type1: "Stratégies",
            news_type2: "Actualités",
            news_type3: "Calendrier économique",
            news_tab1: "Tout",
            news_tab2: "Devises",
            news_tab3: "Actions",
            news_tab4: "Marchandises",
            news_tab5: "Indices",
            news_tab6: "Données",
            news_tab7: "Événements",
            news_tab8: "Vacances",
            label_previous: "Précédent",
            label_predictive: "Prévision",
            label_announced: "Annoncé",
        },
        kilne: {
            label_bp: "Clôture précédente",
            label_sp: "Ouverture actuelle",
            label_height: "Plus haut",
            label_low: "Plus bas",
            tip: "Salut, je suis l'assistant HKDFINANCGPT pour les produits. ",
            row1_tit: "Stratégie",
            row1_rinei: "Intraday",
            row1_short: "Court terme",
            row1_middle: "Moyen terme",
            row1_time: "Heure de publication",
            row1_tit2: "Stratégie de trading",
            data_sources: "Sources de données",
            row2_tit1: "Données",
            row2_tit2: "Sentiment de trading",
            row2_label_sell: "Vendeur",
            row2_label_buy: "Acheteur",
            tip2: "Pour référence seulement, ne constitue pas une position de notre société",
            tip3: "Mis à jour toutes les 15 minutes",
            row2_tit3: "Variation des prix",
            minute: "Minute",
            today: "Aujourd'hui",
            right_tit7: "Plage de variation des prix",
            now_price: "Prix actuel",
            right_low_price: "Prix le plus bas",
            right_height_price: "Prix le plus élevé",
            details: "Détails",
            right_label1: "Quantité de transaction unique",
            right_label2: "Effet de levier maximal",
            right_label3: "Taille du contrat",
            right_label4: "Nombre maximal de contrats détenus",
            right_label5: "Écart flottant",
            right_label6: "Frais de nuit",
            hand: "Lots",
            right_sell: "Vendre",
            right_buy: "Acheter",
            right_collection_time: "Heure de collecte",
            right_tip3: "Prélevé sur la taille totale de la transaction",
            right_label9: "Sessions de trading",
            right_label10: "Session actuelle",
            right_label11: "Session suivante",
        },
        placeorder: {
            direction_buyPrice: "Prix d'achat",
            direction_sellPrice: "Prix de vente",
            direction_sell: "Vendre",
            direction_buy: "Acheter",
            market_price: "Ordre au marché",
            check_price: "Ordre en attente",
            sell_tip: "Vendre lorsque le prix atteint les conditions",
            buy_tip: "Acheter lorsque le prix atteint les conditions",
            trade_number: "Montant de la transaction",
            hand: "Lots",
            select_lever: "Sélectionnez l'effet de levier",
            right_tip: "Marge requise",
            balance: "Solde disponible",
            label_stop_win_price: "Stop de gain",
            label_stop_lose_price: "Stop de perte",
            expected_profit_loss: "Bénéfice/perte attendu",
            trailing_stop: "Stop suiveur",
        },
        ranking: {
            tit: "Classement des Gagnants et Perdants du Jour",
            p: "Saisissez les opportunités en suivant les tendances",
            renew: "Mis à jour à",
            growth_chart: "Gagnants",
            drop_list: "Perdants",
            show_closed_market: "Afficher les marchés fermés",
            filter1: "Tout",
            filter2: "Devises",
            filter3: "Marchandises",
            filter4: "Actions",
            filter5: "Indices",
            filter6: "Cryptomonnaies",
            td1: "Classement",
            td2: "Tendance",
            td3: "Variation du jour / Prix de vente",
        },
        remind: {
            now_sell_price: "Prix de vente actuel",
            direction_buyPrice: "Prix d'achat",
            direction_sellPrice: "Prix de vente",
            direction_sell: "Vendre",
            direction_buy: "Acheter",
            equal: "Lorsque",
            p: "Atteint le prix suivant, rappelez-moi",
            btn1: "Définir un rappel",
            list_tit: "Liste des rappels",
        },
    },
    my: {
        report: {
            tit: "Rapport de compte",
            tab1: "Rapports",
            tab2: "Déclarations journalières",
            tab3: "Déclarations mensuelles",
            history_tit: "Historique des transactions",
            account_tit: "Rapport de compte",
            filter: "Filtrer",
            tip1: "Veuillez sélectionner une plage de dates appropriée pour exporter votre historique de transactions.",
            tip2: "Personnaliser la période",
            email_send: "Envoyer par e-mail",
        },
        accountMsg: {
            tit: "Informations sur le compte",
            label1: "Informations personnelles",
        },
        bill: {
            tit: "Détails des flux de trésorerie",
            all: "Tous",
            time: "Heure",
        },
        feedback: {
            tit: "Suggestions de fonctionnalités",
        },
        identity: {
            tit: "Vérification d'identité",
            type1: "Carte d'identité",
            type2: "Permis de conduire",
            type3: "Passeport",
            tip: "Je confirme que toutes les informations et les documents fournis sont complets, authentiques et précis.",
        },
        my: {
            is: "Est",
            no: "Non vérifié",
            verify: "Vérifié",
            id_verify: "Vérification d'identité",
            verify_tip: "Une fois la vérification d'identité terminée, vous pourrez déposer et trader.",
            no_verify: "Vérifier maintenant",
            balance: "Solde disponible",
            profit_loss: "Bénéfice/perte",
            marginLevel: "Niveau de marge",
            btn1: "Passer en compte réel",
            deposit_withdraw_record: "Historique des dépôts et des retraits",
            capital_flow_details: "Détails des flux de trésorerie",
            welfare_center: "Centre des avantages",
            tip1: "Vous avez",
            tip2: "Récompenses en attente",
            feedback: "Suggestions de fonctionnalités",
            report: "Rapport de compte",
            setting: "Paramètres",
        },
        news: {
            tit: "Notifications",
        },
        record: {
            tit: "Historique des dépôts et des retraits",
            cycle: "Période",
        },
        share: {
            tit: "Invitez vos amis",
            invite_success: "Invitation réussie",
            tip1: "Récompense en espèces",
            tip2: "Gagnez jusqu'à",
            tip3: "Invitez des amis et gagnez de l'argent",
        },
        welfare: {
            tit: "Centre des avantages",
            tab1: "Bons",
            tab2: "Événements",
            history_record: "Historique",
            period_validity: "Validité",
        },
        wallet: {
            tit: "Portefeuille",
            addNew: "Ajouter un nouveau portefeuille",
        },
        withdraw: {
            tit: "Retraits",
            addNew: "Ajouter un nouveau portefeuille",
        },
    },
    order: {
        demo_account: "Compte de démonstration",
        real_account: "Compte réel",
        tip: "Une fois activé, vous pourrez effectuer des dépôts et des transactions.",
        tab1: "Positions",
        tab2: "Ordres en attente",
        tab3: "Historique",
        list_empty: "Pas d'ordres en attente actuellement",
        start_trade: "Commencer à trader",
        hand_tip: "Entrez une plage",
        kline: {
            direction_sell: "Vente",
            direction_buy: "Achat",
            open_price: "Prix d'ouverture",
            now_price: "Prix actuel",
            trade_account: "Compte de trading",
            demo_account: "Compte de démonstration",
            order_id: "ID de commande",
            open_time: "Heure d'ouverture",
            stop_profit: "Take profit",
            stop_loss: "Stop loss",
            trailing_stop: "Trailing stop",
            float_profit_loss: "Profit/perte flottant",
            overnight_fee: "Frais de nuit",
            margin: "Marge",
            maintainsMargin: "Maintien de la marge",
            open_value: "Valeur d'ouverture",
            now_value: "Valeur actuelle",
            notset: "Non défini",
            close_position: "Fermer la position",
            close_number: "Quantité de fermeture",
            onclick_close: "Fermer toutes les positions",
            btn: "Confirmer la fermeture",
            balance: "Solde disponible",
            profit_loss: "Bénéfice/perte",
            netValue: "Valeur nette",
            marginLevel: "Niveau de marge",
        },
        modify: {
            tit: "Modifier la position",
            expected_profit_loss: "Bénéfice/perte attendu",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Définir un nouveau mot de passe",
            verify_email_tit: "Vérifier l'adresse e-mail",
            verify_password_tit: "Vérifier votre mot de passe",
            verify_password_tip: "Saisissez votre mot de passe de connexion HKDFINANC pour continuer",
            verify_phone_tit: "Vérifier le numéro de téléphone",
            tit: "Sécurité du compte",
            tip: "Pour protéger la sécurité de votre compte et recevoir nos notifications de service, nous vous recommandons d'activer au moins une méthode de vérification d'identité.",
            label1: "Modifier le mot de passe",
            label2: "Déconnexion du compte",
            label3: "Authentification à deux facteurs",
            label4: "Gestion des appareils",
            tip2: "Vérification requise pour une nouvelle connexion",
        },
        tradenews: {
            tit: "Guide de Trading",
            tab1: "Démarrage Rapide",
            tab2: "Apprentissage Avancé",
            tab3: "Centre d'Aide",
            tab4: "À Propos de HKDFINANC",
            viewMore: "Voir Plus",
            details: {
                tit: "Introduction au Forex",
                tip1: "Durée d'apprentissage estimée",
                tip2: "Minutes",
            },
            tab1Row: {
                tit: " Il s'agit d'un tutoriel rapide pour apprendre à trader avec HKDFINANC",
                tit1: "Comment trader sur HKDFINANC ?",
                tit2: "Comment ouvrir un compte réel ?",
                tit3: "Comment ouvrir une position ?",
                tit4: "Comment configurer des ordres de take profit et stop loss ?",
                tit5: "Comment clôturer une position ?",
                p1: "Vous pouvez trader cet actif en achetant ou en vendant. Ouvrir une position sur HKDFINANC signifie que vous pariez sur les fluctuations de prix de cet actif",
                p2: "Trader sur les fluctuations de prix de l'actif sous-jacent en achetant ou en vendant ce produit.",
                p3: "Par exemple, si vous prévoyez une hausse du prix de l'or, vous achetez ce produit ; inversement, si vous anticipez une baisse du prix de l'or, vous vendez ce produit. Si vos prévisions se réalisent, vous pouvez ensuite",
                p4: "Réaliser des bénéfices",
                p5: "De plus, HKDFINANC propose le 'trading à effet de levier'. Vous pouvez utiliser l'effet de levier pour trader davantage de contrats avec le même capital",
                p6: "L'effet de levier peut amplifier les bénéfices, mais il peut également amplifier les pertes",
                p7: "Cela est également appelé 'trading sur marge'. Lorsque vous ouvrez une position, un certain montant sera déduit de votre compte HKDFINANC en tant que",
                p8: "Marge initiale",
                p9: "Sélectionnez le produit et la direction du trading",
                p10: "Tradez",
                p11: "Cliquez sur 'Acheter'/'Vendre' pour passer votre commande",
                p12: "Configurer un 'take profit' ou un 'stop loss' peut vous aider",
                p13: "À verrouiller les profits et à limiter les pertes en temps opportun",
                p14: "Vous pouvez configurer un 'take profit' ou un 'stop loss' sur la page de confirmation de l'ouverture de position",
                p15: "Définir des 'take profit' ou des 'stop loss'",
                p16: "Peut être effectué ici. Vous pouvez également les modifier ultérieurement en cliquant sur 'Modifier' sur une position ouverte non encore clôturée.",
                p17: "Configurer ou annuler",
                p18: "Vous pouvez consulter vos gains et pertes dans la section 'Positions clôturées'.",
            },
            tab3Row: {
                h1: "Bienvenue sur HKDFINANC",
                h2: "Centre d'aide",
                tit0: "Toutes les questions",
                tit1: "Compte de démonstration",
                tit2: "Ouvrir un compte",
                tit3: "Dépôts et retraits",
                tit4: "Paramètres de la plateforme",
                tit5: "Opérations de trading",
                tit6: "Frais et charges",
                tit7: "Sécurité des fonds et réglementation",
                tit1Tit1: "HKDFINANC propose-t-il des comptes de démonstration ?",
                tit1Cont1: "<p>HKDFINANC propose des comptes de démonstration pour que les clients puissent s'entraîner. Vous pouvez utiliser notre plateforme en ligne et notre application mobile pour trader et vous familiariser avec l'utilisation de la plateforme sans aucun risque.</p>",
                tit1Tit2: "Comment créer un compte de démonstration ?",
                tit1Cont2: "<p>Il vous suffit de cliquer ici, de saisir votre adresse e-mail ou votre numéro de téléphone, et de définir votre mot de passe personnel pour créer un compte de démonstration.</p>",
                tit1Tit3: "À quoi sert un compte de démonstration ?",
                tit1Cont3: "<p>Les comptes de démonstration sont très similaires aux comptes réels en termes d'interface de trading, de données et d'opérations. Les comptes de démonstration sont dotés de 50 000 dollars américains (USD) ou de dollars australiens (AUD) de fonds virtuels. L'objectif est de permettre aux clients de se familiariser avec les fonctionnalités de la plateforme grâce à des opérations de simulation sans risque financier.</p>",
                tit1Tit4: "Comment approvisionner un compte de démonstration ?",
                tit1Cont4: "<p>Vous n'avez pas besoin d'approvisionner un compte de démonstration. Une fois que vous avez réussi à créer un compte de démonstration, il sera crédité avec 50 000 dollars américains (USD) ou dollars australiens (AUD) de fonds virtuels.</p><p>Lorsque la valeur nette de votre compte de démonstration tombe en dessous de 200 dollars américains (USD) ou dollars australiens (AUD), le système reconstituera automatiquement votre solde à 50 000 dollars américains (USD) ou dollars australiens (AUD).</p>",
                tit1Tit5: "Y a-t-il une période d'utilisation limitée pour les comptes de démonstration ?",
                tit1Cont5: "<p>Les comptes de démonstration ont une durée de validité de 90 jours. Passé ce délai et si vous n'avez pas ouvert de compte réel, le compte de démonstration sera gelé et ne pourra pas être utilisé pour effectuer des transactions. Il sera débloqué lorsque vous ouvrirez un compte réel.</p><p>Cependant, si vous ouvrez un compte réel dans les 90 jours, votre compte de démonstration restera actif indéfiniment. Vous pouvez continuer à effectuer des opérations sur le compte de démonstration même après avoir ouvert un compte réel.</p>",
                tit2Tit1: "Combien de types de comptes HKDFINANC propose-t-il ?",
                tit2Cont1: "<p>Nous ne proposons que des comptes de trading standards et professionnels aux particuliers. Nous n'acceptons pas l'ouverture de comptes d'entreprise ni de comptes conjoints.</p>",
                tit2Tit2: "Dans quelle devise puis-je ouvrir un compte de trading ?",
                tit2Cont3: "<p>Lorsque vous ouvrez un compte, le système détermine automatiquement la devise de règlement en fonction de votre région, et cette devise ne peut pas être modifiée.</p>",
                tit2Tit4: "Comment ouvrir un vrai compte de trading ?",
                tit2Cont4: "<p>Pour ouvrir un vrai compte de trading, suivez les étapes suivantes :</p><p>1. Accédez à la page d'inscription HKDFINANC, suivez les instructions : enregistrez votre adresse e-mail ou votre numéro de téléphone, définissez votre mot de passe de connexion, et soumettez pour créer automatiquement un compte.</p>",
                tit3Tit1: "De quelle manière puis-je effectuer un dépôt ?",
                tit3Cont1: "<p>HKDFINANC propose différentes méthodes de dépôt pour les clients, y compris, mais sans s'y limiter :</p><p>1. Visa/Mastercard - Généralement, le dépôt est instantané</p><p>2. Virement bancaire en ligne - Généralement, dans un délai d'un jour ouvrable</p><p>3. Portefeuilles électroniques (comme Skrill, Momo, Zalo, Touch'n GO, Boost, etc.) - Généralement, le dépôt est instantané</p><p>4. Carte de guichet automatique (ATM) - Généralement, le dépôt est instantané</p><p>5. Paiement mobile QR - Généralement, le dépôt est instantané</p>",
                tit3Tit2: "Puis-je utiliser un compte bancaire ou une carte appartenant à une tierce personne pour effectuer des dépôts ou des retraits ?",
                tit3Cont2: "<p>HKDFINANC n'accepte pas les dépôts de tiers. Veuillez utiliser votre propre compte bancaire ou carte bancaire personnelle pour effectuer des dépôts ou des retraits. Si vous utilisez le compte bancaire ou la carte bancaire d'une autre personne ou d'une entreprise, les fonds seront renvoyés.</p>",
                tit3Tit3: "Comment puis-je demander un retrait de mon compte HKDFINANC ?",
                tit3Cont3: "<p>Vous pouvez effectuer une demande de retrait en vous connectant à la plateforme web, en cliquant sur 'Retrait', en sélectionnant votre compte bancaire ou Visa/Mastercard, en indiquant le montant du retrait, puis en appuyant sur 'Soumettre'. Après vérification des informations, HKDFINANC traitera votre demande dans un délai de 1 à 2 jours ouvrables.</p>",
                tit4Tit1: "Quel est le niveau de l'effet de levier proposé par HKDFINANC ?",
                tit4Cont1: "<p>Le niveau d'effet de levier offert par la plateforme HKDFINANC est déterminé en conformité avec les réglementations des autorités de régulation, et il varie en fonction des produits. Pour plus de détails, veuillez vous connecter à la plateforme de trading et vérifier les niveaux d'effet de levier spécifiques à chaque produit.</p>",
                tit4Tit2: "Comment puis-je changer mon mot de passe ?",
                tit4Cont2: "<p>Vous pouvez changer votre mot de passe en vous connectant à la plateforme, en sélectionnant 'Plus' > 'Paramètres de sécurité' > 'Changer le mot de passe', puis en définissant un nouveau mot de passe.</p>",
                tit4Tit3: "Comment puis-je me connecter à la plateforme de trading HKDFINANC ?",
                tit4Cont3: "<p>Application : Accédez à 'Mon compte' et cliquez sur 'Connexion/Inscription' en haut à gauche pour accéder à la page de connexion.</p>Web : Cliquez sur 'Connexion' dans le coin supérieur droit du site web officiel HKDFINANC pour accéder à la page de connexion.</p>",
                tit4Tit4: "Comment HKDFINANC envoie-t-il des notifications ?",
                tit4Cont4: "<p>HKDFINANC envoie des notifications par e-mail, SMS et messages push via la plateforme.</p>",
                tit5Tit1: "Quels sont les spreads proposés par HKDFINANC ?",
                tit5Cont1: "<p>Nous appliquons des spreads serrés et ne facturons aucune commission. Les spreads sont variables en fonction des instruments que vous tradez, et les valeurs spécifiques sont affichées en temps réel sur la plateforme de trading.</p>",
                tit5Tit2: "Comment passer un ordre au marché ?",
                tit5Cont2: "<p>Cliquez sur 'Trading' (Trade) pour voir tous les instruments disponibles sur la plateforme. Vous pouvez utiliser la barre de recherche à droite pour trouver un instrument en entrant son code ou son nom. Sélectionnez un instrument et cliquez sur 'Acheter/Vendre' (SELL/BUY) pour ouvrir une fenêtre de trading. Dans cette fenêtre, vous verrez le prix actuel et la marge requise estimée. Vous pouvez ajuster manuellement la taille de la position et définir des ordres stop pour gérer le risque.</p>",
                tit5Tit3: "Comment passer un ordre en attente ?",
                tit5Cont3: "<p>Pour passer un ordre en attente, sélectionnez un instrument dans 'Trading' (Trade) et cliquez sur 'Acheter/Vendre' (SELL/BUY) pour ouvrir une fenêtre de trading.</p>",
                tit5Tit4: "Comment clôturer une position ?",
                tit5Cont4: "<p>Pour clôturer une position, allez dans 'Position' (Position) pour voir vos positions ouvertes. Sélectionnez la position que vous souhaitez clôturer, puis cliquez sur 'Clôturer' (Close) à droite.</p>",
                tit5Tit5: "Comment modifier ou supprimer un ordre en attente ?",
                tit5Cont5: "<p>Pour modifier ou supprimer un ordre en attente, allez dans 'Position' (Position) pour voir vos ordres en attente. Sélectionnez l'ordre que vous souhaitez modifier ou supprimer.</p>",
                tit6Tit1: "Suis-je facturé des frais d'utilisation de la plateforme de trading ?",
                tit6Cont1: "<p>HKDFINANC est une plateforme de trading entièrement sans commission. Nous proposons des spreads serrés, et en fonction de votre activité de trading spécifique, des frais supplémentaires peuvent s'appliquer, tels que les frais de financement de nuit.</p>",
                tit6Tit2: "Y a-t-il des frais pour les dépôts ?",
                tit6Cont2: "<p>Nous ne facturons aucun frais pour les dépôts des clients, cependant, votre banque de paiement ou de transfert peut prélever des frais. Nous vous recommandons de vérifier auprès de votre banque si des frais vous seront facturés.</p>",
                tit6Tit3: "Y a-t-il des frais pour les retraits ?",
                tit6Cont3: "<p>HKDFINANC ne facture pas de frais pour les retraits des clients, sauf si le montant retiré est inférieur aux exigences minimales ou dépasse le nombre maximum de retraits gratuits par mois. Pour plus d'informations, veuillez consulter notre politique de retrait.</p>",
                tit7Tit1: "Les fonds que je dépose dans mon compte HKDFINANC sont-ils utilisés à d'autres fins ?",
                tit7Cont1: "<p>Non. Les dépôts des clients de détail sont détenus séparément conformément à la réglementation lorsque cela est nécessaire, dans des comptes de fiducie distincts. Lorsque des fonds sont déposés dans des comptes de client ou sont retirés de ces comptes, HKDFINANC respecte strictement les règles de réglementation concernant les fonds de client.</p>",
                tit7Tit2: "Si HKDFINANC fait faillite ou est endetté, mes fonds seront-ils affectés ?",
                tit7Cont2: "<p>Les dépôts des clients de détail sont détenus séparément conformément à la réglementation lorsque cela est nécessaire, dans des comptes de fiducie distincts. En cas de faillite de HKDFINANC, de révocation de licence ou de toute autre situation où l'entreprise ne peut plus continuer ses activités, les fonds des clients restent isolés et sont soumis à la réglementation des lois sur les fonds de clients.</p>",
                tit7Tit3: "HKDFINANC est-il conforme à la réglementation ?",
                tit7Cont3: "<p>Notre entreprise est entièrement conforme aux lois et réglementations financières applicables, elle est autorisée par les autorités de régulation compétentes et fait l'objet d'une réglementation stricte en tant que prestataire de services financiers. Nous nous conformons strictement aux exigences de réglementation pour garantir la sécurité des fonds de nos clients.</p>",
                tit7Tit4: "Mes informations personnelles sont-elles sécurisées ?",
                tit7Cont4: "<p>HKDFINANC respecte strictement les réglementations sur la protection des données personnelles, et les informations personnelles des clients ne sont jamais divulguées. Nous veillons à ce que nos employés respectent les normes de sécurité et de confidentialité imposées par la loi, et nous accordons une grande importance à la confidentialité et à l'utilisation des données client. Pour plus de détails, veuillez consulter nos documents de divulgation légale.</p>",
            },
            tab4Row: {
                span: "Méthodes de Vérification",
                p1: "HKDFINANC est une entreprise technologique financière réglementée par des autorités de premier plan, se concentrant sur la fourniture d'une expérience de trading simple et pratique pour les investisseurs.",
                p2: "La plateforme de trading diversifiée innovante de HKDFINANC a remporté plusieurs distinctions, dont celle de la meilleure plateforme de trading mobile et du courtier le plus innovant.",
                p3: "Avec la plateforme HKDFINANC, vous pouvez investir et trader sur un large éventail de marchés financiers internationaux, notamment des actions, des indices, des matières premières, des devises et des centaines d'autres instruments populaires. En plus des plateformes de trading conviviales pour les appareils mobiles et les ordinateurs de bureau, HKDFINANC propose des coûts de trading compétitifs, une exécution rapide des ordres, un excellent service client et un support d'informations riches pour vous aider à saisir rapidement les opportunités d'investissement et de trading.",
                p4: "HKDFINANC est soumis à une réglementation stricte par plusieurs autorités de l'industrie.",
                p5: "HKDFINANC Holding est autorisé et réglementé par l'Autorité monétaire des îles Caïmans (CIMA) avec le numéro de licence SIB 1612446. Pour plus d'informations sur la licence des îles Caïmans, vous pouvez consulter le site officiel de la CIMA à l'adresse www.cima.ky (",
                p6: "), Faire une requête. Hkdfinancglobal détient une licence de services financiers (afsl 398528) agréée par la Hong Kong Securities and Investments Commission (ASIC). Pour plus de détails sur les photos de Hong Kong, visitez le site officiel de l'ASIC à l'adresse www.asic.gov.au (",
                p7: "). HKDFINANC International est autorisé et réglementé par la Financial Services Commission (FSC) de Maurice, avec le numéro de licence GB20025791. Pour plus d'informations sur la licence de Maurice, vous pouvez consulter le site officiel de la FSC à l'adresse www.fscmauritius.org (",
                p8: "). Toutes les opérations de HKDFINANC sont menées dans le respect strict de la réglementation et de toutes les exigences réglementaires.",
                p9: "Contexte",
                p10: "Hkdfinanc est composé d'une équipe chevronnée possédant une expérience et des connaissances approfondies dans le secteur des transactions financières et des technologies financières. La philosophie de hkdfinanc est que le trading devient plus facile et plus convivial.",
                p11: "La philosophie de HKDFINANC est de simplifier et de convivialiser le trading. Notre stratégie et notre modèle d'entreprise intégrés nous permettent d'avoir une vision approfondie et une compréhension globale du développement de l'industrie et des demandes du marché, ce qui nous permet d'allouer de manière plus ciblée nos ressources, d'innover continuellement dans la technologie et d'optimiser l'efficacité pour offrir en permanence à nos clients une expérience de trading plus pratique et conviviale.",
                p12: "Pourquoi Choisir",
                p13: "Conditions de trading à faible seuil",
                p14: "Plateforme de trading simple et intuitive",
                p15: "Coûts de trading compétitifs",
                p16: "Réglementation par des autorités de l'industrie",
                p17: "Support en ligne de haute qualité",
                p18: "Protection contre les soldes négatifs",
                p19: "Récompenses et distinctions",
                p20: "HKDFINANC est honoré d'avoir reçu de nombreuses distinctions, notamment le titre de [Meilleur courtier en actifs multiples] aux World Finance Awards, [Meilleure plateforme de trading mobile en Asie-Pacifique] et [Courtier en technologie financière à la croissance la plus rapide au monde] par Global Brands Magazine. Tout cela reconnaît les efforts constants de notre équipe.",
                p21: "Meilleur courtier en actifs multiples",
                p22: "Philosophie de fonctionnement",
                p23: "Responsabilité",
                p24: "Tous les échanges comportent des risques, c'est pourquoi HKDFINANC prend des mesures supplémentaires pour offrir un environnement de trading responsable à ses clients. HKDFINANC est strictement réglementé par des autorités de l'industrie et suit des normes opérationnelles de sécurité et de stabilité élevées. Notre plateforme offre en permanence des outils de trading responsables, y compris la gestion des risques.",
                p25: "Simplicité et élégance",
                p26: "La simplicité est synonyme d'élégance, et HKDFINANC s'efforce de perfectionner chaque détail de la convivialité et de l'expérience utilisateur. Nous continuons à améliorer notre plateforme et nos services pour que chacun, qu'il soit novice ou investisseur chevronné, puisse facilement saisir les opportunités de trading sur la plateforme HKDFINANC.",
                p27: "Transparence",
                p28: "Pour que nos clients puissent bénéficier pleinement d'un environnement de trading équitable et efficace, HKDFINANC suit un système d'autorégulation strict et transparent, fournissant des informations complètes et transparentes sur les produits, les conditions de prix et les données. Tous les coûts sont clairement affichés, assurant une transparence totale dans l'ensemble du processus de service.",
                p29: "Innovation",
                p30: "HKDFINANC croit fermement que le progrès technologique, l'innovation financière et le service élargi sont les moteurs de la croissance, capables de créer et de partager constamment de la valeur. C'est pourquoi nous encourageons la pensée ouverte, la promotion continue de l'innovation et aspirons à devenir des innovateurs et des leaders de la technologie financière.",
                p31: "Objectifs et vision",
                p32: "Offrir à davantage de personnes désireuses de trader un environnement de trading équitable, efficace et transparent, sans restrictions géographiques, temporelles ou de marché, et devenir un pionnier de l'innovation financière et technologique de l'industrie."
            },
        },
        language: {
            tit: "Sélection de la langue",
        },
        market: {
            tit: "Paramètres du marché",
            tit1: "Affichage des prix",
            tit2: "Couleurs des hausses et baisses",
            buy_price: "Prix d'achat",
            buy_price_tip: "Afficher les bougies en fonction du prix d'achat",
            sell_price: "Prix de vente",
            sell_price_tip: "Afficher les bougies en fonction du prix de vente",
            color1: "Vert pour la hausse, rouge pour la baisse",
            color2: "Rouge pour la hausse, vert pour la baisse",
        },
        notice: {
            tit: "Paramètres des notifications",
            tit1: "Activer les canaux de notification",
            p1: "Veuillez activer/vérifier les canaux de notification suivants pour ne pas manquer les messages importants !",
            message: "Messages texte",
            push: "Notifications push",
            verify: "Vérification",
            verified: "Vérifié",
            email: "E-mail",
            type1: "Notifications marketing",
            tit2: "Notifications d'activités marketing",
            desc2: "Informations sur les promotions et les activités opérationnelles de la plateforme",
            tit3: "Guides d'utilisation",
            desc3: "Documents de formation complets et professionnels",
            tit4: "Alertes d'informations",
            desc4: "Données économiques récentes et actualités financières",
            tit5: "Alertes de volatilité intelligente",
            desc5: "Comprend les variations de prix importantes des principaux actifs pour vous aider à saisir rapidement les opportunités",
            type2: "Rapports",
            tit6: "Rapports de compte",
            desc6: "Vos rapports de compte seront envoyés chaque mois selon le calendrier suivant",
            cycle: "Fréquence",
            month: "Mensuellement",
        },
        protocol: {
            tit: "Déclarations et accords",
            label1: "Politique de confidentialité",
            label2: "Déclaration de divulgation des produits",
            label3: "Accord du client",
            label4: "Déclaration de divulgation des risques",
        },
        setting: {
            tit: "Paramètres",
            label1: "Paramètres de sécurité",
            label2: "Paramètres du marché",
            label3: "Paramètres de trading",
            label4: "Langue",
            label5: "Mode thème",
            label6: "Paramètres de notification",
            label7: "Guide de trading",
            label8: "Déclarations et accords",
            label9: "Effacer le cache",
            label10: "Numéro de version",
            logout: "Déconnexion",
        },
        theme: {
            tit: "Mode thème",
            theme1: "Mode clair",
            theme2: "Mode sombre",
        },
        trade: {
            tit: "Paramètres de trading",
            onclick_close: "Fermeture en un clic",
        }
    },
    trade: {
        fliter_type1: "Sélectionné",
        fliter_type2: "Marché",
        place_search: "Saisissez le nom/le code de l'instrument de recherche",
        fliter_type3: "Populaire",
        fliter_type4: "Forex",
        fliter_type5: "Matières premières",
        fliter_type6: "Actions",
        fliter_type7: "Indices",
        fliter_type8: "Cryptomonnaies",
        tip1: "Effectuez la vérification d'identité pour déposer et trader, investissez dans le monde entier",
        sell: "Vente",
        buy: "Achat",
        add_optional: "Ajouter aux favoris",
        optional_recommend: "Recommandations de favoris",
        change_batch: "Changer de lot",
        edit_tit: "Modifier les favoris",
        breed: "Instrument",
        topping: "Épingler en haut",
        sort: "Trier",
        all: "Tout sélectionner",
    },
    table: {
        label_breed: "Instrument",
        label_direction: "Direction",
        label_price: "Prix",
        label_operate: "Opération",
        empty_tip1: "Aucune notification pour le moment",
        empty_tip2: "Aucun enregistrement de dépôt",
        empty_tip3: "Aucun enregistrement de retrait",
        label_amount: "Montant",
        label_type: "Type",
        label_time: "Heure",
        label_name: "Nom",
        label_email: "Adresse e-mail",
        label_address: "Pays de résidence",
        label_account: "Compte de trading",
        label_currency: "Devise de base",
        label_company: "Société de compte actuelle",
    },
    form: {
        to: "À",
        start_time: "Heure de début",
        end_time: "Heure de fin",
        week: "Dernière semaine",
        month: "Dernier mois",
        threeMonth: "Derniers trois mois",
        sixMonth: "Derniers six mois",
        label_feedback: "Questions et suggestions",
        place_feedback: "Veuillez saisir vos questions ou suggestions",
        label_img: "Image",
        label_img_tip: "Facultatif, fournissez une capture d'écran du problème",
        feedback_tip: "Si vous avez un problème urgent, veuillez contacter",
        online: "Service client en ligne",
        label_cardNumber: "Numéro de pièce d'identité",
        label_realName: "Nom complet",
        place_realName: "Veuillez saisir votre nom complet",
        message_realName: "Veuillez saisir votre nom complet",
        label_firstName: "Prénom",
        place_firstName: "Veuillez saisir votre prénom",
        message_firstName: "Veuillez saisir votre prénom",
        label_lastName: "Nom",
        place_lastName: "Veuillez saisir votre nom de famille",
        message_lastName: "Veuillez saisir votre nom",
        label_birthday: "Date de naissance",
        place_birthday: "Veuillez saisir votre date de naissance",
        message_birthday: "Veuillez saisir votre date de naissance",
        label_idType: "Type de pièce d'identité",
        tip_cardMain: "Prenez ou téléchargez une photo du recto de votre carte d'identité",
        tip_cardBack: "Prenez ou téléchargez une photo au dos de votre carte d'identité",
        tip_cardMain1: "Prenez ou téléchargez une photo du recto de votre permis de conduire",
        tip_cardBack1: "Prenez ou téléchargez une photo derrière votre permis de conduire",
        tip_cardMain2: "prendre ou télécharger une photo de passeport",
        tip_cardBack2: "prendre ou télécharger une photo de passeport",
        label_password: "Mot de passe",
        place_password: "Veuillez saisir un mot de passe",
        message_password: "Veuillez saisir un mot de passe",
        label_crmpsd: "Confirmez le mot de passe",
        place_crmpsd: "Veuillez saisir à nouveau le mot de passe",
        message_crmpsd: "Veuillez saisir à nouveau le mot de passe",
        label_email: "Adresse e-mail",
        place_email: "Adresse e-mail",
        message_email: "Veuillez saisir une adresse e-mail",
        label_captcha: "Code de vérification",
        place_captcha: "Veuillez saisir le code de vérification",
        message_captcha: "Veuillez saisir le code de vérification",
        get_captcha: "Obtenir le code de vérification",
        label_phone: "Téléphone",
        place_phone: "Veuillez saisir le numéro de téléphone",
        message_phone: "Veuillez saisir le numéro de téléphone",
        labelAmount: "Montant",
        placeAmount: "Veuillez saisir le montant",
        messageAmount: "Veuillez saisir le montant",
        labelWalletName: "Nom du portefeuille",
        messageWalletName: "Veuillez saisir le nom du portefeuille",
        placeWalletName: "Veuillez saisir le nom du portefeuille",
        labelWalletAddress: "Adresse du portefeuille",
        messageWalletAddress: "Veuillez saisir l'adresse du portefeuille",
        placeWalletAddress: "Veuillez saisir l'adresse du portefeuille",
        labelWithdrawAccount: "Compte de retrait",
        placeWithdrawAccount: "Compte de retrait",
        messageWithdrawAccount: "Compte de retrait",
        placeTimeLimit: "Veuillez saisir la limite de temps",
        messageTimeLimit: "Veuillez saisir la limite de temps",
    },
    city: {
		albania: "Albanie",
		algeria: "Algérie",
		argentina: "Argentine",
		armenia: "Arménie",
		australia: "Australie",
		pakistan: "Pakistan",
		austria: "Autriche",
		bahrain: "Bahreïn",
		belgium: "Belgique",
		bosnia_and_Herzegovina: "Bosnie _ Herzégovine",
		brazil: "Brésil",
		brunei: "Brunei",
		bulgaria: "Bulgarie",
		cambodia: "Cambodge",
		canada: "Au Canada",
		cameroon: "Cameroun",
		chile: "Chili",
		colombia: "Colombie",
		costa_Rica: "Costa Rica",
		croatia: "Croatie",
		cyprus: "Chypre",
		czech_Republic: "République tchèque",
		denmark: "Danemark",
		dominican_Republic: "République dominicaine",
		egypt: "Égypte",
		estonia: "Estonie",
		ethiopia: "Éthiopie",
		finland: "Finlande",
		france: "France",
		georgia: "Géorgie",
		germany: "Allemagne",
		ghana: "Ghana",
		greece: "Grèce",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Chine",
		hungary: "Hongrie",
		iceland: "Islande",
		ireland: "Irlande",
		italy: "Italie",
		india: "Inde",
		indonesia: "Indonésie",
		israel: "Israël",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japon",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Corée du Sud",
		kuwait: "Koweït",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonie",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		macao_China: "Macao, Chine",
		macedonia: "Macédoine",
		malaysia: "Malaisie",
		malta: "Malte",
		mexico: "Mexique",
		moldova: "Moldavie",
		monaco: "Monaco",
		mongolia: "Mongolie",
		montenegro: "République du Monténégro",
		morocco: "Maroc",
		myanmar: "Myanmar",
		netherlands: "Pays _ Ba",
		new_Zealand: "Nouvelle _ Zélande",
		nepal: "Népal",
		nigeria: "Nigéria",
		norway: "Norvège",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Pérou",
		philippines: "Philippines",
		poland: "Pologne",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roumanie",
		russia: "Russie",
		republic_of_Trinidad_and_Tobago: "République de Trinité _ et _ Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Arabie saoudite",
		serbia: "Serbie",
		singapore: "Singapour",
		slovakia: "Slovaquie",
		slovenia: "Slovénie",
		south_Africa: "Afrique du Sud",
		spain: "Espagne",
		sri_Lanka: "Sri Lanka",
		sweden: "Suède",
		switzerland: "Suisse",
		taiwan_China: "Taïwan, Chine",
		tajikistan: "Tadjikistan",
		tanzania: "Tanzanie",
		thailand: "Thaïlande",
		turkey: "Turquie",
		turkmenistan: "Turkménistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Émirats arabes unis",
		united_Kingdom: "Royaume _ Uni",
		united_States: "États _ Unis",
		uzbekistan: "Ouzbékistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaïdjan",
		bangladesh: "Bangladesh",
		belarus: "Biélorussie",
		belize: "Belize",
		benin: "Bénin",
		bhutan: "Bhoutan",
		bolivia: "Bolivie",
		botswana: "Botswana",
		british_Virgin_Islands: "Îles Vierges britanniques",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cap _ Vert",
		cayman_Islands: "Îles Caïmans",
		central_African_Republic: "République centrafricaine",
		chad: "Tchad",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Bu)",
		democratic_Republic_of_the_Congo: "Congo (or)",
		djibouti: "Djibouti",
		ecuador: "Équateur",
		el_Salvador: "Salvador",
		equatorial_Guinea: "Guinée équatoriale",
		eritrea: "Érythrée",
		fiji: "Fidji",
		gabon: "Gabon",
		gambia: "Gambie",
		greenland: "Groenland",
		guatemala: "Guatemala",
		guinea: "Guinée",
		haiti: "Haïti",
		isle_of_Man: "Île de Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaïque",
		jordan: "Jordanie",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Libye",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Maurice",
		mozambique: "Mozambique",
		namibia: "Namibie",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corée du Nord",
		reunion: "La réunion",
		san_Marino: "Saint _ Marin",
		senegal: "Sénégal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalie",
		sudan: "Soudan",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Syrie",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisie",
		united_States_Virgin_Islands: "Îles Vierges américaines",
		uganda: "Ouganda",
		uruguay: "Uruguay",
		vatican: "Le Vatican",
		yemen: "Yémen",
		yugoslavia: "Yougoslavie",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
		china: "Chine",
	}
};
