export default {
    crypto: {
        title: {
            0: "Buy Crypto",
            1: "Buy Crypto",
            2: "C2C",
        },
        list: {
            0: "C2C Trading",
            1: "Buy",
            2: "Sell",
            3: "Advertisers (Completion rate)",
            4: "Trades",
            5: "Price",
            6: "Available",
            7: "Limit",
            8: "Payment Method",
            9: "Trade",
            10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
        },
        buy: {
            0: "Buy Crypto",
            1: "Sell Crypto",
            2: "History",
            3: "Support",
            4: "Pay",
            5: "Receive",
            6: "Select payment method",
            7: "Est. arrival time",
            8: "FEE",
            9: "Order Details",
            10: "Payment method",
            11: "Learn more",
            12: "Price",
            13: "Total (fee included)",
            14: "Disclaimer",
            15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
            16: "I have read and agree to the Terms of Use",
            17: "You will get",
            18: "Submit",
            19: "Please enter the amount",
            20: "Insufficient account balance",
            21: "Available",
            22: "Spot Account Availiable",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        }
    }, common: {
        view: "Semak",
        confirm: "Sahkan",
        cancel: "Batalkan",
        deposit: "Deposit tunai",
        withdraw: "Uang keluar",
        noData: "Semasa tiada data tersedia",
        submit: "Hantar",
        modify: "Ubahsuai",
        hand: "Tangan",
        confirm_verify: "Sahkan Pengesahan",
        next: "Langkah berikutnya",
        complete: "Selesai",
        delete: "Padam",
		countryCode: "Kod negara",
    },
    addnew: {
        realFirst: "Sila lakukan pengesahihan identiti dahulu",
        payFirst: "Sila tetapkan kata laluan transaksi dahulu",
        password1: "Ubah kata laluan log masuk",
        password2: "Ubah kata laluan penarikan",
        paypassTit1: "Sila tetapkan kata laluan penarikan anda",
        oldPass: "Katalaluan lama",
        allView: "Semua dibaca",
        payPass: "Katalaluan transaksi",
        buyUp: "Membeli",
        buyDown: "Beli turun",
        confirmOrder: "Sahkan Perintah",
        name: "Nama",
        fee: "Gaji pengurusan",
        orderCycle: "Pilih masa",
        loan: "Pinjaman",
        loanTit: "Pinjaman bantuan",
        loanHostory: "Rekod pinjaman",
        labelProductTitle: "Nama Produk",
        immediateRepayment: "Pembayaran segera",
        loadAmount: "Jumlah pinjaman dijangka",
        RepaymentCycle: "Ciklus pembayaran pinjaman",
        day: "Hari",
        dailyRate: "Kadar faedah sehari",
        repaymentMethod: "Kaedah pembayaran",
        lendingCompany: "Syarikat Pinjaman",
        loanTip: "Pinjaman kredit (sila pastikan imej jelas dan kelihatan)",
        loanTip1: "Muat naik sijil sifat",
        loanTip2: "Bukti pendapatan (hubungan kerja)",
        loanTip3: "Perincian pernyataan bank",
        loanTip4: "Muat naik foto depan kad ID anda",
        interest: "Interest",
        repaid: "Membayar semula",
        unpaid: "Tidak dibayar",
        tab1: "Tidak dibayar",
        tab2: "Kontrak",
        tab3: "Pilihan",
        available: "Kredit Tersedia",
        pending: "Kunci",
        equivalent: "Sama",
        fundTransfer: "Pemindahan dana",
        flashExchange: "Penukaran Flash",
        select_breed: "Pilih Uang",
        from: "Dari",
        to: "Ke",
        transferQuantity: "Kuantiti pemindahan",
        repaymentMethod1:"Satu pembayaran selepas dewasa"
    },
    params: {
        product_foreign: "Pertukaran",
        product_shop: "Komoditi",
        product_number: "Indeks",
        product_stock: "Kongsi",
        product_coin: "Penyulitan",
        billTypes: [
            { v: 101, name: 'Muat Semula' },
            { v: 102, name: 'Tambah' },
            { v: 103, name: 'Beku' },
            { v: 104, name: 'Meluap' },
            {v: 105, name: 'Deposit'},
            {v: 106, name: 'Deposit'},
            { v: 201, name: 'Pembekalan tarik' },
            { v: 202, name: 'Penambahan' },
            { v: 203, name: 'Pengunduran berjaya' },
            { v: 204, name: 'Pengunduran gagal' },
            { v: 205, name: 'Gaji tarik' },
            {v: 206, name: 'Pindahkan keluar'},
            {v: 207, name: 'Pemindahan masuk'},
            {v: 208, name: 'Pindahan wang keluar'},
            {v: 209, name: 'Pindahan wang dalam'},
            { v: 301, name: 'Pengurangan deposit' },
            { v: 302, name: 'Pengurangan biaya pengendalian' },
            { v: 303, name: 'Kembalian deposit' },
            { v: 304, name: 'Keuntungan' },
            { v: 305, name: 'Kehilangan' },
            {v: 311, name: 'Pilihan Pembelian'},
            {v: 312, name: 'Opsyen mengembalikan'},
            {v: 313, name: 'Pembalikan Opsyen'},
            {v: 314, name: 'Gaji pengendalian opsyen'},
            {v: 315, name: 'Pembelian wang beku'},
            {v: 316, name: 'Pengurangan pembelian wang'},
            {v: 317, name: 'Kembali Pembelian Coin'},
            {v: 318, name: 'Belian Coin Diterima'},
            {v: 319, name: 'Belian Coin Diterima'},
            {v: 320, name: 'Penjualan wang beku'},
            {v: 321, name: 'Pengurangan jualan wang'},
            {v: 322, name: 'Kembalian jualan monet'},
            {v: 323, name: 'Coins dijual untuk akaun'},
            {v: 324, name: 'Coins dijual untuk akaun'},
            {v: 325, name: 'Gaji pengendalian wang'},
            {v: 401, name: 'Tambahan mesin tambang'},
            {v: 402, name: 'Kembali mesin mining'},
            {v: 403, name: 'Penghasilan mesin tambang'},
            {v: 404, name: 'Keluar mesin perlombongan'},
            {v: 405, name: 'Gaji pengendalian keluar mesin tambang'},
            {v: 411, name: 'Pinjaman pendapatan'},
            {v: 412, name: 'Gaji pengendalian pinjaman'},
            {v: 413, name: 'Pembalasan berjaya'},
        ],
    },
    footer: {
        nav1: "Halaman rumah",
        nav2: "Transaksi",
        nav3: "Bin",
        nav4: "Saya",
        kline_set1: "Tetapan pasar",
        kline_set2: "Tetapan Transaksi",
    },
    login: {
        login: "Login",
        register: "Daftar",
        type1: "Nombor telefon bimbit",
        type2: "Kotak mel",
        login_btn: "Logmasuk selamat",
        register_btn: "Daftar Sekarang",
        psd_login: "Logmasuk kata laluan",
        verify_login: "Logmasuk kod pengesahan",
        forget: "Lupakan kata laluan?",
        no_account: "Tiada akaun?",
        have_account: "Akaun yang wujud",
    },
    home: {
        home: {
            nav1: "Rangkaian",
            nav2: "Cepat mula",
            nav3: "Tentang",
            nav4: "Hadiah ",
            most_follow: "Kebanyakan diikuti",
            news_type1: "Strategi",
            news_type2: "Berita",
            news_type3: "Kalendar Ekonomik",
            news_tab1: "Keseluruhan",
            news_tab2: "Pertukaran asing",
            news_tab3: "Kongsi",
            news_tab4: "Komoditi",
            news_tab5: "Indeks",
            news_tab6: "Data",
            news_tab7: "Peristiwa",
            news_tab8: "Cuti",
            label_previous: "Nilai terdahulu",
            label_predictive: "Estimate",
            label_announced: "Nilai yang diumumkan",
        },
        kilne: {
            label_bp: "Diterima semalam",
            label_sp: "Buka hari ini",
            label_height: "Tertinggi",
            label_low: "Minimum",
            tip: "Hai, saya pembantu pelbagai HKDFINANCGPT",
            row1_tit: "Strategi",
            row1_rinei: "Dalam hari",
            row1_short: "Short-term",
            row1_middle: "Tempoh tengah",
            row1_time: "Masa pembebasan",
            row1_tit2: "Strategi perdagangan",
            data_sources: "Sumber data",
            row2_tit1: "Data",
            row2_tit2: "Sentimen perdagangan",
            row2_label_sell: "Penjual",
            row2_label_buy: "Pembeli",
            tip2: "Untuk rujukan sahaja, bukan sebagai kedudukan syarikat kita",
            tip3: "Kemaskini setiap 15 minit",
            row2_tit3: "Perubahan harga",
            minute: "Minit",
            today: "Hari ini",
            right_tit7: "Julat variasi harga",
            now_price: "Harga semasa",
            right_low_price: "Harga paling rendah",
            right_height_price: "Harga maksimum",
            details: "Perincian",
            right_label1: "Bilangan transaksi tunggal",
            right_label2: "Pangkalan maksimum",
            right_label3: "Saiz kontrak",
            right_label4: "Jumlah kedudukan maksimum",
            right_label5: "Perbezaan harga mengapung",
            right_label6: "Gaji semalam",
            hand: "Tangan",
            right_sell: "Jual",
            right_buy: "Beli",
            right_collection_time: "Masa koleksi",
            right_tip3: "Kumpul dari saiz keseluruhan transaksi",
            right_label9: "Masa perdagangan",
            right_label10: "Masa semasa",
            right_label11: "Period Berikutnya",
        },
        placeorder: {
            direction_buyPrice: "Harga membeli",
            direction_sellPrice: "Harga menjual",
            direction_sell: "Jual",
            direction_buy: "Beli",
            market_price: "Senarai harga pasaran",
            check_price: "Senarai",
            sell_tip: "Jual bila harga memenuhi syarat",
            buy_tip: "Beli bila harga memenuhi syarat",
            trade_number: "Kuantiti transaksi",
            hand: "Tangan",
            select_lever: "Pilih lever",
            right_tip: "Deposit diperlukan",
            balance: "Imbangan tersedia",
            label_stop_win_price: "Hentikan tambahan",
            label_stop_lose_price: "Hentikan kehilangan",
            expected_profit_loss: "Gaji dan kehilangan yang dijangka",
            trailing_stop: "Berhenti",
        },
        ranking: {
            tit: "Hari naik dan turun",
            p: "Tangkap titik panas dan ambil peluang",
            renew: "Dikemaskini pada",
            growth_chart: "Carta pertumbuhan",
            drop_list: "Jatuhkan Senarai",
            show_closed_market: "Papar Tanda Daripada Pasar",
            filter1: "Keseluruhan",
            filter2: "Pertukaran asing",
            filter3: "Komoditi",
            filter4: "Kongsi",
            filter5: "Indeks",
            filter6: "Penyulitan",
            td1: "Peringkat",
            td2: "Trend",
            td3: "Tingkat harian/harian jualan",
        },
        remind: {
            now_sell_price: "Harga jualan semasa",
            direction_buyPrice: "Harga membeli",
            direction_sellPrice: "Harga menjual",
            direction_sell: "Jual",
            direction_buy: "Beli",
            equal: "Bila",
            p: "Ingatkan saya bila harga berikut telah mencapai",
            btn1: "Tetapkan peringatan",
            list_tit: "Senarai Pengingatan",
        },
    },
    my: {
        report: {
            tit: "Laporan Akaun",
            tab1: "Laporan",
            tab2: "Pernyataan sehari",
            tab3: "Pernyataan bulanan",
            history_tit: "Laporan Sejarah Transaksi",
            account_tit: "Laporan Akaun",
            filter: "Skrin",
            tip1: "Sila pilih julat tarikh yang sesuai untuk mengeksport laporan sejarah transaksi anda.",
            tip2: "Masa Suai",
            email_send: "Hantar ke emel",
        },
        accountMsg: {
            tit: "Maklumat akaun",
            label1: "Maklumat peribadi",
        },
        bill: {
            tit: "Perincian aliran utama",
            all: "Keseluruhan",
            time: "Masa",
        },
        feedback: {
            tit: "Rekomendasi Fungsi",
        },
        identity: {
            tit: "Pengesahihan",
            type1: "Kad ID",
            type2: "Lesen pemandu",
            type3: "Pasport",
            tip: "Saya mengesahkan bahawa semua maklumat dan dokumen yang diberikan adalah lengkap, benar, dan tepat",
        },
        my: {
            is: "Sudah",
            no: "Tidak disahkan",
            verify: "Disahkan",
            id_verify: "Pengesahihan",
            verify_tip: "Pengesahan identiti lengkap untuk deposit transaksi",
            no_verify: "Sahkan Sekarang",
            balance: "Imbangan tersedia",
            profit_loss: "Gaji dan kehilangan",
            marginLevel: "Aras Margin",
            btn1: "Tukar ke akaun sebenar",
            deposit_withdraw_record: "Rekod deposit dan penarikan",
            capital_flow_details: "Perincian aliran utama",
            welfare_center: "Pusat Keselamatan",
            tip1: "Anda mempunyai",
            tip2: "Hadiah Pen yang akan ditanggung",
            feedback: "Rekomendasi Fungsi",
            report: "Laporan Akaun",
            setting: "Tetapkan",
        },
        news: {
            tit: "Notice",
        },
        record: {
            tit: "Rekod deposit dan penarikan",
            cycle: "Siklus",
        },
        share: {
            tit: "Panggil kawan",
            invite_success: "Kawan yang diundang dengan berjaya",
            tip1: "Hadiah tunai",
            tip2: "Panggil kawan untuk mengambil yang tertinggi",
            tip3: "Panggil kawan untuk mengumpulkan wang tunai",
        },
        welfare: {
            tit: "Pusat Keselamatan",
            tab1: "Rol kad",
            tab2: "Aktiviti",
            history_record: "Sejarah",
            period_validity: "Masa kehormatan",
        },
        wallet: {
            tit: "Dompet",
            addNew: "Tambah dompet baru",
        },
        withdraw: {
            tit: "Uang keluar",
            addNew: "Tambah dompet baru",
        },
    },
    order: {
        demo_account: "Akaun Simulasi",
        real_account: "Akaun sebenar",
        tip: "Selepas membuka, emas boleh didepositkan untuk transaksi",
        tab1: "Kedudukan memegang",
        tab2: "Senarai",
        tab3: "Sejarah",
        list_empty: "Tidak ada perintah tunggu",
        start_trade: "Mulakan perdagangan",
        hand_tip: "Julat input",
        kline: {
            direction_sell: "Jual",
            direction_buy: "Beli",
            open_price: "Harga pembukaan",
            now_price: "Harga semasa",
            trade_account: "Akaun perdagangan",
            demo_account: "Akaun Simulasi",
            order_id: "ID Arahan",
            open_time: "Masa Buka",
            stop_profit: "Hentikan tambahan",
            stop_loss: "Hentikan kehilangan",
            trailing_stop: "Berhenti",
            float_profit_loss: "P/L mengapung",
            overnight_fee: "Gaji semalam",
            margin: "Margin",
            maintainsMargin: "Kekalkan margin",
            open_value: "Membuka nilai",
            now_value: "Nilai semasa",
            notset: "Tidak ditetapkan",
            close_position: "Posisi menutup",
            close_number: "Kuantiti biasa",
            onclick_close: "Satu klik menutup posisi",
            btn: "Sahkan posisi tutup",
            balance: "Imbangan tersedia",
            profit_loss: "Gaji dan kehilangan",
            netValue: "Nilai neto",
            marginLevel: "Aras Margin",
        },
        modify: {
            tit: "Ubah Tarikh Posisi",
            expected_profit_loss: "Gaji dan kehilangan yang dijangka",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Tetapkan kata laluan baru",
            verify_email_tit: "Sahkan emel",
            verify_password_tit: "Sahkan kata laluan anda",
            verify_password_tip: "Masukkan kata laluan log masuk HKDFINANC anda untuk teruskan",
            verify_phone_tit: "Sahkan nombor telefon",
            tit: "Keselamatan akaun",
            tip: "Untuk melindungi keselamatan akaun anda dan menerima pemberitahuan perkhidmatan kami, ia disarankan untuk benarkan sekurang-kurangnya satu jenis pengesahihan.",
            label1: "Ubah kata laluan",
            label2: "Pembatalan akaun",
            label3: "Pengesahan Dua",
            label4: "Pengurusan peranti",
            tip2: "Logmasuk peranti baru memerlukan pengesahan",
        },
        tradenews: {
            tit: "Panduan Transaksi",
            tab1: "Cepat mula",
            tab2: "Pemelajaran Lanjutan",
            tab3: "Pusat Bantuan",
            tab4: "Tentang HKDFINANC",
            viewMore: "Lihat lebih",

            details: {
                tit: "Perkenalan ke Pertukaran Luar Negeri",
                tip1: "Perjangkaan pembelajaran dijangka",
                tip2: "Minit",
            },
            tab1Row: {
                tit: "Ini adalah kelas untuk belajar dengan cepat untuk bertukar dengan HKDFINANC",
                tit1: "Bagaimana perdagangan di HKDFINANC?",
                tit2: "Bagaimana untuk membuka akaun sebenar？",
                tit3: "Bagaimana untuk membuka kedudukan untuk perdagangan？",
                tit4: "Bagaimana untuk menghentikan keuntungan dan menghentikan kehilangan？",
                tit5: "Bagaimana menutup posisi？",
                p1: "Anda boleh tukar subjek dengan membelinya atau menjualnya. Membuka kedudukan di HKDFINANC bermakna anda tertarik pada",
                p2: "Perdagangan berdasarkan perubahan harga produk sasaran",
                p3: "Contohnya, jika anda mengharapkan harga emas meningkat, beli produk; Sebaliknya, jika anda meramalkan bahawa harga emas akan menurun, kemudian jual produk. Jika masa depan memenuhi harapan, anda boleh",
                p4: "Menggunakannya",
                p5: "Selain itu, HKDFINANC menyediakan 'perdagangan yang berguna'. Anda boleh menggunakan leverage untuk perdagangan lebih banyak sasaran dengan jumlah dana yang sama.",
                p6: "Penyerangan boleh meningkatkan keuntungan dan juga meningkatkan kerugian",
                p7: "Ini juga dikenali sebagai 'perdagangan margin'. Apabila anda membuka kedudukan, akaun HKDFINANC anda akan mengurangi keseimbangan tertentu sebagai",
                p8: "Margin awal",
                p9: "Pilih produk dan arah membeli dan menjual",
                p10: "Transaksi",
                p11: "Klik 'Buy/Sell' untuk selesaikan tempatan tertib",
                p12: "Tetapkan 'berhenti keuntungan' atau 'berhenti kehilangan' boleh membantu anda",
                p13: "Waktu mengunci keuntungan dan hadapi kerugian",
                p14: "Anda boleh klik pada halaman pembukaan yang berjaya",
                p15: "Hentikan keuntungan/hentikan kehilangan",
                p16: "Tetapkannya, atau anda boleh klik butang 'Ubahsuai' untuk tertib tidak seimbang untuk menghentikan keuntungan dan kerugian",
                p17: "Tetapkan atau batalkan",
                p18: "Anda boleh melihat keuntungan dan kerugian dalam 'posisi tertutup'",
            },
            tab3Row: {
                h1: "Selamat datang di HKDFINANC",
                h2: "Pusat Bantuan",
                tit0: "Semua isu",
                tit1: "Akaun Simulasi",
                tit2: "Membuka akaun",
                tit3: "Uang masuk dan keluar",
                tit4: "Tetapan Platform",
                tit5: "Operasi Transaksi",
                tit6: "Gaji dan biaya",
                tit7: "Keselamatan Kewangan dan Peraturan",
                tit1Tit1: "Adakah HKDFINANC menyediakan akaun simulasi",
                tit1Cont1: "<p>HKDFINANC menyediakan akaun simulasi untuk pelanggan untuk beroperasi, membolehkan anda menggunakan platform online dan aplikasi bimbit kami untuk transaksi dan memperkenalkan diri anda dengan operasi platform tanpa sebarang risiko</p>",
                tit1Tit2: "Bagaimana mencipta akaun simulasi?",
                tit1Cont2: "<p>Anda hanya perlu klik di sini untuk mengisi alamat e-mel atau nombor telefon and a, dan tetapkan kata laluan peribadi anda untuk menyelesaikan pendaftaran dan mendapatkan akaun simulasi.</p>",
                tit1Tit3: "Apa guna akaun simulasi?",
                tit1Cont3: "<p>Akaun simulasi kebanyakan sama dengan akaun sebenar dalam terma antaramuka transaksi, data, dan operasi. Terdapat dana maya $50000 dalam akaun simulasi, dengan tujuan untuk memperkenalkan pelanggan dengan berbagai fungsi platform melalui operasi simulasi tanpa sebarang risiko keuangan。</p>",
                tit1Tit4: "Bagaimana menyuntik dana ke dalam akaun simulasi?",
                tit1Cont4: "<p>Anda tidak perlu suntik dana ke dalam akaun simulasi. Bila anda berjaya mendaftar akaun simulasi, akan ada jumlah 50000 USD atau AUD dalam akaun</p><p>Apabila nilai neto dalam akaun simulasi kurang dari $200 USD atau AUD, sistem akan automatik mengisi semula dana akaun anda ke $50000 USD atau AUD</p>",
                tit1Tit5: "Adakah akaun simulasi mempunyai masa penggunaan?",
                tit1Cont5: "<p>Waktu kesilapan akaun simulasi adalah 90 hari. Jika and a tidak membuka akaun sebenar selepas 90 hari, akaun simulasi akan dibekukan dan tidak dapat ditukar, dan akan dibekukan apabila anda membuka akaun sebenar。</p><p>而如您在90天内开立真实账户，模拟账户将长期有效。即使开通真实账户后，也可继续在模拟账户上进行操作。</p>",
                tit2Tit1: "Berapa jenis akaun yang HKDFINANC tawarkan?",
                tit2Cont1: "<p>Kami hanya menyediakan akaun perdagangan piawai dan profesional untuk individu, dan tidak menyokong pembukaan syarikat atau akaun bersama</p>",
                tit2Tit2: "Apa wang yang boleh saya buka akaun perdagangan?",
                tit2Cont3: "<p>Apabila anda membuka akaun, sistem akan secara automatik menentukan wang penyelesaian anda berdasarkan kawasan anda dan ia tidak boleh diubahsuai</p>",
                tit2Tit4: "Bagaimana untuk membuka akaun sebenar?",
                tit2Cont4: "<p>Sila ikut langkah di bawah untuk membuka akaun sebenar</p><p>1. Masukkan halaman pendaftaran HKDFINANC dan ikut maklumat untuk mendaftar nombor telefon e-mel, tetapkan kata laluan daftar masuk, dan secara automatik ciptakannya selepas menghantar pengesahan</p><p></p><p></p>",
                tit3Tit1: "Bagaimana saya boleh membuat deposit?",
                tit3Cont1: "<p>HKDFINANC Serahkan kaedah deposit yang berbeza untuk pelanggan untuk memilih dari, termasuk tetapi tidak terbatas kepada berikut:</p><p>1.Visa/Mastercard - Biasanya diterima segera</p><p>2.Pembayaran bank secara talian biasanya dilakukan dalam 1 hari kerja di hadapan</p><p>3.Dompet elektronik (seperti Skrill, Momo, Zalo. Touch'n GO, Boost, dll.) - biasanya menerima segera</p><p>4.Kad ATM - biasanya menerima segera</p><p>5.Pengurusan bank online bimbit QR - biasanya menerima segera</p>",
                tit3Tit2: "Boleh saya gunakan akaun/kad bank dibawah nama pihak ketiga untuk deposit atau tarik dana",
                tit3Cont2: "<p>HKDFINANC tidak menerima deposit pihak ketiga. Sila gunakan akaun/kad bank peribadi anda di bawah nama anda sendiri untuk deposit atau tarik dana. Jika anda menggunakan nama orang lain atau akaun bank syarikat/kad untuk deposit dana, dana akan dikembalikan</p>",
                tit3Tit3: "Bagaimana untuk meminta penarikan diri dari akaun HKDFINANC?",
                tit3Cont3: "<p>Selepas log masuk ke platform laman web, and a boleh klik pada 'Cash Out', pilih akaun bank atau Visa/Mastercard, mengisi jumlah tunai keluar, kemudian tekan 'Hantar'. Selepas mengesahkan maklumat yang betul, HKDFINANC akan memproses aplikasi anda dalam 1-2 hari kerja</p>",
                tit4Tit1: "Berapa kali HKDFINANC menawarkan perdagangan yang berguna?",
                tit4Cont1: "<p>Nisbah leverage yang disediakan oleh platform HKDFINANC ditetapkan mengikut peraturan pihak berkuasa peraturan, dan leverage maksimum untuk produk berbeza juga berbeza. Sila log masuk ke platform perdagangan untuk perincian.</p>",
                tit4Tit2: "Bagaimana untuk mengubah kata laluan saya?",
                tit4Cont2: "<p>Selepas log masuk ke platform, anda boleh pilih [Lebih] - [Tetapan Keselamatan] - T Ubah Katalaluan] untuk tetapkan semula kata laluan baru</p>",
                tit4Tit3: "Bagaimana untuk log masuk ke platform perdagangan HKDFINANC?",
                tit4Cont3: "<p>App:Pergi ke 'Saya' dan klik pada 'Daftar Masuk' di atas kiri untuk masukkan halaman daftar masuk.。</p>Web: Klik pada 'Login' di sudut kanan atas laman web rasmi HKDFINANC untuk masukkan halaman log masuk。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Apa kaedah pengingatan platform?",
                tit4Cont4: "<p>Amaran HKDFINANC melalui e-mel, SMS, dan tekan platform。</p>",
                tit5Tit1: "Apa perbezaan harga yang HKDFINANC tawarkan?",
                tit5Cont1: "Kami meminta perbezaan harga yang rendah dan tidak meminta sebarang komisi. Perbezaan harga mengambang, dan perbezaan harga sebenar yang ditanggung akan bergantung pada pelbagai yang anda perdagangkan, dan maklumat khusus akan dipaparkan pada platform perdagangan dalam masa-real.</p>",
                tit5Tit2: "Bagaimana untuk menetapkan senarai harga pasar?",
                tit5Cont2: "<p>Klik pada 'Perdagangan' akan memaparkan semua produk yang platform boleh tawarkan untuk perdagangan. Anda boleh masukkan kod atau nama pelbagai dalam kotak input di sebelah kanan untuk mencari, pilih produk, dan klik 'Buy/Sell' SEL/BUY' untuk muncul tetingkap perdagangan. Dalam tetingkap perdagangan, and a boleh melihat harga semasa dan marjin yang diperlukan. Anda boleh menyesuaikan secara manual bilangan kedudukan terbuka dan menetapkan berhenti keuntungan dan berhenti kehilangan untuk kawalan risiko</p>",
                tit5Tit3: "Bagaimana untuk menetapkan formulir pendaftaran?",
                tit5Cont3: "<p>Memilih produk dalam [Perdagangan] dan mengklik pada [jual/BUY] akan muncul tetingkap perdagangan</p>",
                tit5Tit4: "Bagaimana menutup posisi?",
                tit5Cont4: "<p>Untuk menutup kedudukan, klik butang ''Buka'' pada platform dalam seksyen ''Posisi', pilih kedudukan yang and a ingin menutup, kemudian klik butang ''Tutup'' di sebelah kanan.</p>",
                tit5Tit5: "Bagaimana mengubahsuai atau hapuskan tertib yang tunggu?",
                tit5Cont5: "<p>Untuk ubahsuai atau padam arahan, klik 'Arahan' pada platform pemandangan dalam seksyen 'Posisi', kemudian pilih arahan yang anda ingin ubahsuai atau padam dalam proses</p>",
                tit6Tit1: "Adakah akan ada bayaran pengendalian yang ditanggung untuk menggunakan platform perdagangan?",
                tit6Cont1: "HKDFINANC adalah sebuah platform perdagangan bebas. Kami meminta perbezaan harga rendah, dan bergantung pada situasi transaksi spesifik and a, platform mungkin mengalami biaya tambahan, seperti bunga semalam.</p>",
                tit6Tit2: "Adakah terdapat sebarang bayaran untuk deposit?",
                tit6Cont2: "<p>Kami tidak akan meminta bayaran yang berkaitan dengan deposit kepada pelanggan, tetapi bayaran atau bank intermedier anda boleh meminta bayaran pengendalian. Kami cadangkan anda bertanya kepada bank yang berkaitan sama ada mereka telah meminta anda sebarang bayaran.</p>",
                tit6Tit3: "Adakah terdapat bayaran untuk penarikan diri?",
                tit6Cont3: "<p>HKDFINANC tidak akan meminta kepada pelanggan sebarang biaya yang berkaitan dengan penarikan dana kecuali jumlah yang anda tarik adalah di bawah keperluan minimum atau melebihi jumlah maksimum penarikan bebas untuk bulan. Untuk maklumat terperinci, sila rujuk ke polisi penarikan kami.</p>",
                tit7Tit1: "Adakah dana yang saya simpan dalam akaun HKDFINANC saya akan digunakan untuk tujuan lain?",
                tit7Cont1: "Tidak. Deposit pelanggan retail diposisikan secara terpisah dalam akaun kepercayaan bila diperlukan sesuai dengan peraturan peraturan. Apabila mendepositkan dana ke akaun pelanggan atau menarik dana dari akaun pelanggan atau membuat pembayaran, HKDFINANC memenuhi peraturan peraturan mengenai pembayaran pelanggan.</p>",
                tit7Tit2: "Jika HKDFINANC bangkrut atau berhutang, adakah dana saya juga akan terpengaruh?",
                tit7Cont2: "<p>Deposit pelanggan menjual secara terpisah ditempatkan dalam akaun kepercayaan bila diperlukan sesuai dengan peraturan peraturan. Jika HKDFINANC bangkrut, mempunyai lesen dibatalkan, atau tidak dapat terus beroperasi, dana pelanggan masih boleh dikrantina dan subjek pengawasan peraturan dana pelanggan dalam undang-undang peraturan</p>",
                tit7Tit3: "Adakah HKDFINANC mematuhi peraturan undang-undang?",
                tit7Cont3: "<p>Syarikat kami sepenuhnya mematuhi undang-undang syarikat yang berkaitan dan undang-undang dan peraturan pengaturan keuangan, dan adalah penyedia perkhidmatan keuangan yang dibenarkan dan diatur secara ketat oleh agensi pengaturan yang berkaitan. Syarikat kami menyediakan perlindungan yang meliputi dan cukup untuk keselamatan keuangan pelanggan sesuai dengan peraturan ketat.</p>",
                tit7Tit4: "Adakah maklumat peribadi pelanggan selamat?",
                tit7Cont4: "<p>HKDFINANC memenuhi persyaratan Peraturan Perlindungan Data Peribadi, dan data peribadi pelanggan tidak akan pernah dikeluarkan. Syarikat akan memastikan pekerja mematuhi standar keselamatan dan kerahasiaan yang ketat yang ditetapkan oleh undang-undang dan peraturan, dan memberi perhatian tinggi kepada pengaturan untuk kerahasiaan dan penggunaan data pelanggan. Untuk perincian, sila rujuk kepada dokumen penemuan undang-undang syarikat kami.</p>",
            },
            tab4Row: {
                span: "Kaedah pengesahan Q",
                p1: "HKDFINANC adalah syarikat teknologi keuangan yang dibenarkan dan diuruskan oleh institusi yang berwenang, fokus pada menyediakan pelabur dengan pengalaman perdagangan sederhana dan selesa.",
                p2: "Platform perdagangan berbagai-bagai HKDFINANC yang inovatif telah berulang kali memenangkan hadiah seperti platform perdagangan bimbit terbaik dan syarikat sekuriti yang paling inovatif.",
                p3: "Melalui platform HKDFINANC, and a boleh melabur dan perdagangan dalam julat yang lebih luas pasar kewangan antarabangsa, meliputi ratusan jenis populari seperti stok, indeks, komoditi, pertukaran asing, dll. Bukan sahaja terbatas kepada platform perdagangan bimbit dan sesawang yang ramah bagi pengguna, HKDFINANC juga menyediakan and a dengan kos transaksi kompetitif, pelaksanaan transaksi cepat, perkhidmatan pelanggan yang baik, dan sokongan maklumat yang kaya, membantu anda mengambil peluang pelaburan dan perdagangan dengan cara yang tepat.",
                p4: "Melalui platform HKDFINANC, and a boleh melabur dan perdagangan dalam julat yang lebih luas pasar kewangan antarabangsa, meliputi ratusan jenis populari seperti stok, indeks, komoditi, dan pertukaran asing. Bukan sahaja terbatas kepada platform perdagangan bimbit dan sesawang yang ramah bagi pengguna, HKDFINANC juga menyediakan and a dengan kos transaksi kompetitif, pelaksanaan transaksi cepat, perkhidmatan pelanggan yang baik, dan sokongan maklumat yang kaya, membantu anda mengambil peluang pelaburan dan perdagangan dengan cara yang tepat. Kekuatan industri dan pengawasan ketat oleh beberapa institusi.",
                p5: "HKDFINANC Holding dibenarkan dan diatur oleh Kepulauan Kewangan Kepulauan Cayman (CIMA) dengan nombor lesen SIB 1612446. Untuk maklumat lanjut pada plat lesen Cayman, sila lawati laman web rasmi CIMA di www.cima.ky_;",
                p6: ") Lakukan pertanyaan. HKDFINANCGlobal mempunyai Lesen Perkhidmatan Kewangan (AFSL 398528) yang dibenarkan oleh Komisi Securities and Investment Hong Kong (ASIC). Untuk maklumat lanjut mengenai foto Hong Kong, sila lawati laman web rasmi ASIC di www.asic.gov.au (",
                p7: ") Lakukan pertanyaan. HKDFINANC International dibenarkan dan diatur oleh Komisi Perkhidmatan Kewangan Mauritius (FSC) dengan nombor lesen GB20025791. Untuk maklumat lanjut pada plat lesen Mauritius, sila lawati laman web rasmi FSC di www.fscmauricius.org (",
                p8: ")Lakukan pertanyaan. Semua operasi bisnes HKDFINANC dilakukan di bawah pengawasan ketat dan mematuhi semua peraturan",
                p9: "Latar belakang",
                p10: "HKDFINANC terdiri dari pasukan senior dengan pengalaman dan pengetahuan yang kaya dalam perdagangan keuangan dan industri fintech. Filosofi HKDFINANC adalah untuk membuat transaksi lebih mudah dan lebih ramah",
                p11: "Filosofi HKDFINANC adalah untuk membuat transaksi lebih mudah dan lebih ramah. Strategi holistik dan model perniagaan kita memungkinkan kita untuk mempunyai pemahaman yang meliputi dan mendalam tentang pembangunan industri dan permintaan pasar, memungkinkan kita untuk memberikan sumber yang lebih sasaran, terus-menerus inovasi teknologi, dan optimumkan efisiensi, terus-menerus membawa pelanggan lebih selesa dan ramah pengalaman perdagangan",
                p12: "Mengapa memilih",
                p13: "Keadaan perdagangan ambang rendah",
                p14: "Platform perdagangan sederhana dan intuitif",
                p15: "Kost transaksi bersaing",
                p16: "Dipengawasi oleh pihak berwenang industri",
                p17: "Sokongan talian tahap tinggi",
                p18: "Perlindungan keseimbangan negatif",
                p19: "Hadiah dan Kehormatan",
                p20: "HKDFINANC dihormati untuk menerima banyak hadiah, termasuk Pengawal Kewangan Dunia Kewangan Terbaik Multi Asset Broker, Global BrandsMagazine's Best Mobile Trading Platform di Kawasan Asia Pacific, dan Global Fastest Growing Financial Technology Broker, semuanya mengesahkan usaha dan dedikasi terus menerus pasukan.",
                p21: "Penyedar Asset Berbilang Terbaik",
                p22: "Filosofi operasi kita",
                p23: "Tanggungjawab",
                p24: "Semua transaksi melibatkan risiko, dan HKDFINANC mengambil lebih banyak tindakan untuk menyediakan pelanggan dengan persekitaran perdagangan yang bertanggungjawab. HKDFINANC terkena kuasa industri, pengawasan ketat dari beberapa institusi, dan mematuhi standar operasi keselamatan tinggi dan kestabilan. Platform kami terus menyediakan pelbagai alat perdagangan bertanggungjawab, termasuk pengurusan risiko",
                p25: "Mudah tapi cantik",
                p26: "Kesederhanaan adalah keindahan, dan perincian mencapai kelebihan. HKDFINANC berusaha untuk setiap perincian kesederhanaan, kemudahan penggunaan, dan pengalaman pengguna. Kami terus memperbaiki platform dan perkhidmatan kami, berharap bahawa semua orang, sama ada pelabur baru atau yang mengalami pengalaman, boleh dengan mudah mengambil peluang perdagangan melalui platform HKDFINANC.",
                p27: "Transparen",
                p28: "Untuk memberi keuntungan penuh kepada pelanggan kita dari persekitaran perdagangan yang adil dan efisien, HKDFINANC mengikuti sistem pengendalian diri yang ketat dan transparan, menyediakan maklumat platform yang meliputi dan transparan produk, persekitaran harga, dan data. Semua bayaran adalah jelas dan transparan, memastikan keseluruhan jujur dan transparan seluruh proses perkhidmatan.",
                p29: "Innovate",
                p30: "HKDFINANC percaya dengan teguh bahawa kemajuan teknologi, inovasi keuangan, dan perkhidmatan yang lebih luas adalah kekuatan mendorong pertumbuhan, untuk terus mencipta dan berkongsi nilai. Untuk sebab ini, kami mendorong pemikiran terbuka, terus menerus mempromosikan inovasi, dan bertujuan untuk menjadi inovator dan pemimpin dalam teknologi keuangan.",
                p31: "Tujuan dan Visi",
                p32: "Untuk menyediakan persekitaran perdagangan yang adil, efisien dan transparan yang tidak terbatas oleh ruang, masa, atau pasar untuk lebih ramai orang yang mahu melakukan transaksi, memimpin inovasi keuangan dan teknologi industri.",
            },
        },
        language: {
            tit: "Pilih Bahasa",
        },
        market: {
            tit: "Tetapan pasar",
            tit1: "Paparan harga",
            tit2: "Warna naik dan turun",
            buy_price: "Harga membeli",
            buy_price_tip: "Lukis garis-K pada harga pembelian",
            sell_price: "Harga menjual",
            sell_price_tip: "Lukis garis-K pada harga jualan",
            color1: "Green rise and red fall",
            color2: "Bangun merah dan jatuh hijau",
        },
        notice: {
            tit: "Tetapan Pemberitahuan",
            tit1: "Buka saluran pemberitahuan",
            p1: "Sila buka/sahkan saluran pemberitahuan berikut, mesej penting tidak terlepas!",
            message: "Mesej",
            push: "Tekan",
            verify: "Sahkan",
            verified: "Disahkan",
            email: "E-mel",
            type1: "Pemasaran",
            tit2: "Pemberitahuan Peristiwa Pemasaran",
            desc2: "Maklumat promosi platform, aktiviti operasi, dan mesej lain",
            tit3: "Arahan operasi",
            desc3: "Bahan pengetahuan kaya dan profesional",
            tit4: "Peringatan maklumat",
            desc4: "Data keuangan dan maklumat berita baru-baru ini",
            tit5: "Pengingatan pengalihan cerdas",
            desc5: "Termasuk perubahan harga berbagai jenis penting, membantu anda dengan cepat mengambil peluang",
            type2: "Kelas Laporan",
            tit6: "Laporan Akaun",
            desc6: "Laporan akaun anda akan dihantar setiap bulan dengan cara berikut",
            cycle: "Siklus",
            month: "Setiap bulan",
        },
        protocol: {
            tit: "Deklarasi dan Perjanjian",
            label1: "Polisi Privasi",
            label2: "Penjelasan Produk",
            label3: "Perjanjian Pelanggan",
            label4: "Penjelasan Risiko",
        },
        setting: {
            tit: "Tetapkan",
            label1: "Tetapan keselamatan",
            label2: "Tetapan pasar",
            label3: "Tetapan Transaksi",
            label4: "Bahasa",
            label5: "Mod tema",
            label6: "Tetapan Pemberitahuan",
            label7: "Panduan Transaksi",
            label8: "Deklarasi dan Perjanjian",
            label9: "Kosongkan cache",
            label10: "Nombor versi",
            logout: "Log keluar dari log masuk",
        },
        theme: {
            tit: "Mod tema",
            theme1: "Mod cahaya",
            theme2: "Mod Gelap",
        },
        trade: {
            tit: "Tetapan Transaksi",
            onclick_close: "Satu klik menutup posisi",
        }
    },
    trade: {
        fliter_type1: "Pilihan sendiri",
        fliter_type2: "Pasar",
        place_search: "Masukkan nama/carian kod pelbagai",
        fliter_type3: "Popular",
        fliter_type4: "Pertukaran asing",
        fliter_type5: "Komoditi",
        fliter_type6: "Kongsi",
        fliter_type7: "Indeks",
        fliter_type8: "Penyulitan",
        tip1: "Selesaikan pengesahan identiti untuk deposit transaksi dan melaburkan secara global",
        sell: "Jual",
        buy: "Beli",
        add_optional: "Tambah Pemilihan Sendiri",
        optional_recommend: "Rekomendasi pemilihan diri",
        change_batch: "Ubah batch",
        edit_tit: "Edit Pemilihan",
        breed: "Variasi",
        topping: "Atas",
        sort: "Isih",
        all: "Pilih Semua",
    },
    table: {
        label_breed: "Variasi",
        label_direction: "Arah",
        label_price: "Harga",
        label_operate: "Operasi",
        empty_tip1: "Tiada peringatan pada saat ini",
        empty_tip2: "Tiada rekod deposit",
        empty_tip3: "Tiada rekod pembayaran tunai",
        label_amount: "Wang",
        label_type: "Jenis",
        label_time: "Masa",
        label_name: "Nama",
        label_email: "E-mel",
        label_address: "Negara tinggal",
        label_account: "Akaun perdagangan",
        label_currency: "Warna asas",
        label_company: "Syarikat pembukaan akaun semasa",
    },
    form: {
        to: "Ke",
        start_time: "Masa permulaan",
        end_time: "Masa akhir",
        week: "Minggu lepas",
        month: "Bulan lepas",
        threeMonth: "Dalam tiga bulan terakhir",
        sixMonth: "Dalam enam bulan terakhir",
        label_feedback: "Soalan dan cadangan",
        place_feedback: "Sila masukkan soalan atau cadangan anda",
        label_img: "Gambar",
        label_img_tip: "Pilihan, sediakan gambar skrin masalah",
        feedback_tip: "Jika anda mempunyai soalan penting, sila hubungi",
        online: "Perkhidmatan Dalam Talian",
        label_cardNumber: "Nombor Identifikasi",
        label_realName: "Nama penuh",
        place_realName: "Sila masukkan nama pertama anda",
        message_realName: "Sila masukkan nama pertama anda",
        label_firstName: "Nama keluarga",
        place_firstName: "Sila masukkan nama terakhir anda",
        message_firstName: "Sila masukkan nama terakhir anda",
        label_lastName: "Nama",
        place_lastName: "Sila masukkan nama tengah anda",
        message_lastName: "Sila masukkan nama",
        label_birthday: "Tarikh lahir",
        place_birthday: "Sila masukkan tarikh kelahiran anda",
        message_birthday: "Sila masukkan tarikh kelahiran anda",
        label_idType: "Jenis dokumen",
        tip_cardMain: "Ambil atau muat naik foto FRONT kad identiti anda",
        tip_cardBack: "Ambil atau muat naik foto dari BALAT kad identiti anda",
        tip_cardMain1: "Ambil atau muat naik foto FRONT lesen pemandu anda",
        tip_cardBack1: "Ambil atau muat naik foto dari BALANG lesen pemandu anda",
        tip_cardMain2: "Ambil atau muat naik foto pasport anda",
        tip_cardBack2: "Ambil atau muat naik foto pasport anda",
        label_password: "Katalaluan",
        place_password: "Sila masukkan kata laluan",
        message_password: "Sila masukkan kata laluan",
        label_crmpsd: "Sahkan Katalaluan",
        place_crmpsd: "Sila masukkan kata laluan lagi",
        message_crmpsd: "Sila masukkan kata laluan lagi",
        label_email: "E-mel",
		place_email: "Alamat mel",
		message_email: "Sila masukkan alamat e-mel anda",
        label_captcha: "Kod pengesahan",
		place_captcha: "Sila masukkan kod pengesahan",
		message_captcha: "Sila masukkan kod pengesahan",
		get_captcha: "Dapatkan kod pengesahan",
        label_phone: "Telefon",
		place_phone: "Sila masukkan nombor telefon",
		message_phone: "Sila masukkan nombor telefon",
        labelAmount: "Wang",
        placeAmount: "Sila masukkan jumlah",
        messageAmount: "Sila masukkan jumlah",
        labelWalletName: "Nama Dompet",
		messageWalletName: "Sila masukkan nama dompet",
		placeWalletName: "Sila masukkan nama dompet",
		labelWalletAddress: "Alamat dompet",
		messageWalletAddress: "Sila masukkan alamat dompet",
		placeWalletAddress: "Sila masukkan alamat dompet",
        labelWithdrawAccount: "Akaun tarik",
        placeWithdrawAccount: "Akaun tarik",
        messageWithdrawAccount: "Akaun tarik",
		placeTimeLimit: "Sila masukkan had masa",
		messageTimeLimit: "Sila masukkan had masa",
    },
	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "ARMENIA",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgium",
		bosnia_and_Herzegovina: "Bosnia and Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Cambodia",
		canada: "Canada",
		cameroon: "Cameroon",
		chile: "Chile",
		colombia: "Columbia",
		costa_Rica: "Costa Rica",
		croatia: "Croatia",
		cyprus: "Cyprus",
		czech_Republic: "Czech Republic",
		denmark: "Denmark",
		dominican_Republic: "DOMINICAN REPUBLIC",
		egypt: "Egypt",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "France",
		georgia: "Georgia",
		germany: "Germany",
		ghana: "Ghana",
		greece: "Greece",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungary",
		iceland: "Iceland",
		ireland: "Ireland",
		italy: "Italy",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Japan",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "South Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao_China: "Macao, China",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexico",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "MONTENEGRO",
		morocco: "Morocco",
		myanmar: "Myanmar",
		netherlands: " Netherlands",
		new_Zealand: "New Zealand",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norway",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "The Philippines",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Russia",
		republic_of_Trinidad_and_Tobago: "Trinidad and tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudi Arabia",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "South Africa",
		spain: "Spain",
		sri_Lanka: "Sri Lanka",
		sweden: "Sweden",
		switzerland: "Switzerland",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "Turkey",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "The United Arab Emirates",
		united_Kingdom: "Britain",
		united_States: "America",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "British virgin islands",
		burkina_Faso: "Burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Cayman Islands",
		central_African_Republic: "Central African Republic",
		chad: "Chad",
		comoros: "Comoros",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Equatorial Guinea",
		eritrea: "ERITREA",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isle of Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "The Niger",
		north_Korea: "Korea",
		reunion: "Reunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sultan",
		suriname: "Surinam",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Virgin Islands, United States",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatican",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
