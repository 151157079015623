export default function lang() {
    let lang = navigator.language; //浏览器语言判断
    lang = lang.substr(0, 2);
    switch (lang) {
        // case "zh": //中文
        //     lang = "zh";
        //     break;
        case "zhTw": //中文
            lang = "zhTw";
            break;
        case "en": //英文
            lang = "en";
            break;
        case "in": //印地
            lang = "in";
            break;
        case "ko": //韩语
            lang = "ko";
            break;
        case "ptbr": //葡萄牙语
            lang = "PtBr";
            break;
        case "es": //西班牙语
            lang = "es";
            break;
        case "th": //泰语
            lang = "th";
            break;
        case "vi": //越南
            lang = "vi";
            break;
        case "id": //印尼
            lang = "id";
            break;
        case "my": //马来
            lang = "my";
            break;
        case "de": //德语
            lang = "de";
            break;
        case "fr": //法语
            lang = "fr";
            break;
        case "ja": //日语
            lang = "ja";
            break;
        case "svse": //瑞典语
            lang = "svse";
            break;
        case "it": //意大利语
            lang = "it";
            break;
        case "hu": //匈牙利语
            lang = "hu";
            break;
        case "fi": //芬兰
            lang = "fi";
            break;
        case "sk": //斯洛伐克语
            lang = "sk";
            break;

        default:
            lang = "en";
    }
    return lang;
}
