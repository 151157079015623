export default {
    crypto: {
        title: {
            0: "Buy Crypto",
            1: "Buy Crypto",
            2: "C2C",
        },
        list: {
            0: "C2C Trading",
            1: "Buy",
            2: "Sell",
            3: "Advertisers (Completion rate)",
            4: "Trades",
            5: "Price",
            6: "Available",
            7: "Limit",
            8: "Payment Method",
            9: "Trade",
            10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
        },
        buy: {
            0: "Buy Crypto",
            1: "Sell Crypto",
            2: "History",
            3: "Support",
            4: "Pay",
            5: "Receive",
            6: "Select payment method",
            7: "Est. arrival time",
            8: "FEE",
            9: "Order Details",
            10: "Payment method",
            11: "Learn more",
            12: "Price",
            13: "Total (fee included)",
            14: "Disclaimer",
            15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
            16: "I have read and agree to the Terms of Use",
            17: "You will get",
            18: "Submit",
            19: "Please enter the amount",
            20: "Insufficient account balance",
            21: "Available",
            22: "Spot Account Availiable",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        }
    },
    common: {
        view: "Cek",
        confirm: "Konfirmasi",
        cancel: "Batalkan",
        deposit: "Deposit tunai",
        withdraw: "Uang keluar",
        noData: "Tidak ada data yang tersedia",
        submit: "Kirim",
        modify: "Ubah",
        hand: "Tangan",
        confirm_verify: "Konfirmasi Validasi",
        next: "Langkah berikutnya",
        complete: "Selesai",
        delete: "Hapus",
		countryCode: "Kode negara",
    },
    addnew: {
        realFirst: "Silakan lakukan autentikasi identitas pertama",
        payFirst: "Silakan tetapkan sandi transaksi pertama",
        password1: "Ubah kata sandi daftar masuk",
        password2: "Ubah sandi penarikan",
        paypassTit1: "Silakan tetapkan sandi penarikan Anda",
        oldPass: "Kata sandi lama",
        allView: "Harga",
        payPass: "Sandi transaksi",
        buyUp: "Beli",
        buyDown: "Harga",
        confirmOrder: "Konfirmasi Perintah",
        name: "Nama",
        fee: "Gaji pengurusan",
        orderCycle: "Gaji pengurusan",
        loan: "Pinjaman",
        loanTit: "Pinjaman bantuan",
        loanHostory: "Catatan pinjaman",
        labelProductTitle: "Nama Produk",
        immediateRepayment: "Pembayaran segera",
        loadAmount: "Jumlah pinjaman yang dijangka",
        RepaymentCycle: "Siklus pembayaran pinjaman",
        day: "Hari",
        dailyRate: "Rata bunga sehari",
        repaymentMethod: "Metode pembayaran",
        lendingCompany: "Perusahaan Pinjaman",
        loanTip: "Pinjaman kredit (pastikan gambar jelas dan terlihat)",
        loanTip1: "Memuat naik sertifikat properti",
        loanTip2: "Bukti pendapatan (hubungan pekerjaan)",
        loanTip3: "Rincian pernyataan bank",
        loanTip4: "Naikkan foto dari depan kartu ID Anda",
        interest: "Bunga",
        repaid: "Balas",
        unpaid: "Tidak dibayar",
        tab1: "Coins",
        tab2: "Kontrak",
        tab3: "Pilihan",
        available: "Kredit tersedia",
        pending: "Mengunci",
        equivalent: "Sama",
        fundTransfer: "Transfer dana",
        flashExchange: "Penukaran Flash",
        select_breed: "Pilih Uang",
        from: "Dari",
        to: "Ke",
        transferQuantity: "Beralih jumlah",
        repaymentMethod1:"Satu pembayaran setelah dewasa"
	},
    params: {
        product_foreign: "Forex",
        product_shop: "Komoditas",
        product_number: "Indeks",
        product_stock: "Saham",
        product_coin: "Coin",
        billTypes: [
            { v: 101, name: 'Isi ulang' },
            { v: 102, name: 'Tambah' },
            { v: 103, name: 'Freeze' },
            { v: 104, name: 'Meluap' },
            { v: 105, name: 'Deposit' },
            { v: 106, name: 'Deposit' },
            { v: 201, name: 'Pembeku tarik' },
            { v: 202, name: 'Deduksi' },
            { v: 203, name: 'Pengunduran berhasil' },
            { v: 204, name: 'Pengunduran gagal' },
            { v: 205, name: 'Gaji penarikan' },
            {v: 206, name: 'Transfer keluar'},
            {v: 207, name: 'Transfer masuk'},
            {v: 208, name: 'Transfer uang keluar'},
            {v: 209, name: 'Transfer uang masuk'},
            { v: 301, name: 'Deduksi deposit' },
            { v: 302, name: 'Pengurangan biaya pengurusan' },
            { v: 303, name: 'Kembalian deposit' },
            { v: 304, name: 'Keuntungan' },
            { v: 305, name: 'Kehilangan' },
            {v: 311, name: 'Opsi Pembelian'},
            {v: 312, name: 'Opsi mengembalikan'},
            {v: 313, name: 'Pembalikan Opsi'},
            {v: 314, name: 'Gaji pengurusan opsi'},
            {v: 315, name: 'Pembelian uang membeku'},
            {v: 316, name: 'Deduksi pembelian uang'},
            {v: 317, name: 'Kembali Pembelian Coin'},
            {v: 318, name: 'Pembelian Coin Diterima'},
            {v: 319, name: 'Pembelian Coin Diterima'},
            {v: 320, name: 'Penjualan uang beku'},
            {v: 321, name: 'Pengurangan penjualan uang'},
            {v: 322, name: 'Penjualan koin kembali'},
            {v: 323, name: 'Coins dijual untuk akun'},
            {v: 324, name: 'Coins dijual untuk akun'},
            {v: 325, name: 'Gaji pengendalian uang'},
            {v: 401, name: 'Tambahan mesin penambangan'},
            {v: 402, name: 'Kembali mesin pengembara'},
            {v: 403, name: 'Penghasilan mesin penambangan'},
            {v: 404, name: 'Keluar mesin pengembara'},
            {v: 405, name: 'Gaji pengurusan keluar mesin penambangan'},
            {v: 411, name: 'Pinjaman pendapatan'},
            {v: 412, name: 'Gaji pengurusan pinjaman'},
            {v: 413, name: 'Pembayaran berhasil'},
        ],
    },
    footer: {
        nav1: "Halaman rumah",
        nav2: "Transaksi",
        nav3: "Bin",
        nav4: "Aku",
        kline_set1: "Pengaturan pasar",
        kline_set2: "Pengaturan Transaksi",
    },
    login: {
        login: "Login",
        register: "Register",
        type1: "Nomor ponsel",
        type2: "Kotak surat",
        login_btn: "Daftar masuk aman",
        register_btn: "Daftar Sekarang",
        psd_login: "Login kata sandi",
        verify_login: "Login kode verifikasi",
        forget: "Lupakan kata sandi??",
        no_account: "Tidak ada rekening？",
        have_account: "Akaun yang ada",
    },
    home: {
        home: {
            nav1: "Tingkat naik dan turun",
            nav2: "Cepat mulai",
            nav3: "Tentang kita",
            nav4: "Hadiah undangan",
            most_follow: "Kebanyakan diikuti",
            news_type1: "Strategi",
            news_type2: "Berita",
            news_type3: "Kalendar Ekonomi",
            news_tab1: "Semua ",
            news_tab2: "Pertukaran asing",
            news_tab3: "Saham",
            news_tab4: "Komoditas",
            news_tab5: "Indeks",
            news_tab6: "Data",
            news_tab7: "Event ",
            news_tab8: "Liburan",
            label_previous: "Nilai sebelumnya",
            label_predictive: "Perkiraan",
            label_announced: "Nilai yang diumumkan",
        },
        kilne: {
            label_bp: "Diterima kemarin",
            label_sp: "Buka hari ini",
            label_height: "Tertinggi",
            label_low: "Minimum",
            tip: "Hai, aku asisten HKDFINANCGPT",
            row1_tit: "Strategi",
            row1_rinei: "Dalam siang hari",
            row1_short: "Jangka pendek",
            row1_middle: "Pertengahan jangka",
            row1_time: "Waktu pembebasan",
            row1_tit2: "Strategi perdagangan",
            data_sources: "Sumber data",
            row2_tit1: "Data",
            row2_tit2: "Sentimen perdagangan",
            row2_label_sell: "Penjual",
            row2_label_buy: "Pembeli",
            tip2: "Untuk referensi saja, bukan sebagai posisi perusahaan kita",
            tip3: "Kemaskini setiap 15 menit",
            row2_tit3: "Penukaran harga",
            minute: "Menit",
            today: "Hari ini",
            right_tit7: "Jarak variasi harga",
            now_price: "Harga saat ini",
            right_low_price: "Harga paling rendah",
            right_height_price: "Harga maksimum",
            details: "Rincian",
            right_label1: "Jumlah transaksi tunggal",
            right_label2: "Maksimum leverage",
            right_label3: "Ukuran kontrak",
            right_label4: "Maksimum jumlah posisi total",
            right_label5: "Perbedaan harga yang mengambang",
            right_label6: "Hadiah sepanjang malam",
            hand: "Tangan",
            right_sell: "Jual",
            right_buy: "Beli",
            right_collection_time: "Waktu koleksi",
            right_tip3: "Kumpulkan dari seluruh ukuran transaksi",
            right_label9: "Periode perdagangan",
            right_label10: "Periode saat ini",
            right_label11: "Period Berikutnya",
        },
        placeorder: {
            direction_buyPrice: "Harga membeli",
            direction_sellPrice: "Harga penjualan",
            direction_sell: "Jual",
            direction_buy: "Beli",
            market_price: "Daftar harga pasar",
            check_price: "Mendaftar",
            sell_tip: "Jual ketika harganya memenuhi syarat",
            buy_tip: "Beli ketika harganya memenuhi syarat",
            trade_number: "Kwantitas transaksi",
            hand: "Tangan",
            select_lever: "Pilih lever",
            right_tip: "Deposit diperlukan",
            balance: "Kesimbangan yang tersedia",
            label_stop_win_price: "Hentikan surplus",
            label_stop_lose_price: "Hentikan kehilangan",
            expected_profit_loss: "Profit dan kehilangan yang diharapkan",
            trailing_stop: "Berhenti",
        },
        ranking: {
            tit: "Hari naik dan turun",
            p: "Tangkap titik panas dan mengambil kesempatan",
            renew: "Dikemaskini pada",
            growth_chart: "Graf pertumbuhan",
            drop_list: "Daftar Jatuh",
            show_closed_market: "Tampilkan Tanda Pasar",
            filter1: "Seluruh",
            filter2: "Pertukaran asing",
            filter3: "Komoditas",
            filter4: "Saham",
            filter5: "Indeks",
            filter6: "Enkripsi",
            td1: "Peringkat",
            td2: "Trend",
            td3: "Tingkat harian/harian penjualan",
        },
        remind: {
            now_sell_price: "Harga penjualan saat ini",
            direction_buyPrice: "Harga membeli",
            direction_sellPrice: "Harga penjualan",
            direction_sell: "Jual",
            direction_buy: "Beli",
            equal: "Ketika",
            p: "Ingatkan saya ketika harga berikutnya telah mencapai",
            btn1: "Tetapkan peringatan",
            list_tit: "Senarai Pengingatan",
        },
    },
    my: {
        report: {
            tit: "Laporan Akun",
            tab1: "Laporan",
            tab2: "Pernyataan harian",
            tab3: "Pernyataan bulanan",
            history_tit: "Laporan Sejarah Transaksi",
            account_tit: "Laporan Akun",
            filter: "Layar",
            tip1: "Silakan pilih jangkauan tanggal yang sesuai untuk mengeksport laporan sejarah transaksi Anda。",
            tip2: "Waktu tersendiri",
            email_send: "Kirim ke email",
        },
        accountMsg: {
            tit: "Informasi Akaun",
            label1: "Informasi pribadi",
        },
        bill: {
            tit: "Rincian aliran kapital",
            all: "Seluruh",
            time: "Waktu",
        },
        feedback: {
            tit: "Rekomendasi fungsional",
        },
        identity: {
            tit: "Otentikasi",
            type1: "Kartu ID",
            type2: "Lesen pengemudi",
            type3: "Pasport",
            tip: "Saya mengkonfirmasi bahwa semua informasi dan dokumen yang diberikan adalah lengkap, benar, dan akurat",
        },
        my: {
            is: "Sudah",
            no: "Tidak Diverifikasi",
            verify: "Diverifikasi",
            id_verify: "Otentikasi",
            verify_tip: "Selesaikan verifikasi identitas untuk depositasi transaksi",
            no_verify: "Verify Now",
            balance: "Kesimbangan yang tersedia",
            profit_loss: "Profit dan kehilangan",
            marginLevel: "Tingkat Margin",
            btn1: "Beralih ke akun nyata",
            deposit_withdraw_record: "Catatan deposit dan penarikan",
            capital_flow_details: "Rincian aliran kapital",
            welfare_center: "Pusat Keselamatan",
            tip1: "Anda memiliki",
            tip2: "Hadiah Pen yang akan diklaim",
            feedback: "Rekomendasi fungsional",
            report: "Laporan Akun",
            setting: "Mengatur",
        },
        news: {
            tit: "Notis",
        },
        record: {
            tit: "Catatan deposit dan penarikan",
            cycle: "Siklus",
        },
        share: {
            tit: "Invite friends",
            invite_success: "Teman yang diundang dengan sukses",
            tip1: "Hadiah tunai",
            tip2: "Invite friends to take the highest",
            tip3: "Suruh teman untuk mengumpulkan uang tunai",
        },
        welfare: {
            tit: "Pusat Keselamatan",
            tab1: "Rol kartu",
            tab2: "Aktivitas",
            history_record: "Sejarah",
            period_validity: "Period kehendak",
        },
        wallet: {
            tit: "Dompet",
            addNew: "Tambah dompet baru",
        },
        withdraw: {
            tit: "Uang keluar",
            addNew: "Tambah dompet baru",
        },
    },
    order: {
        demo_account: "Akaun Simulasi",
        real_account: "Akun nyata",
        tip: "Setelah dibuka, emas dapat dideposisi untuk transaksi",
        tab1: "Posisi memegang",
        tab2: "Mendaftar",
        tab3: "Sejarah",
        list_empty: "Tidak ada perintah yang menunggu",
        start_trade: "Mulai perdagangan",
        hand_tip: "Jarak masukan",
        kline: {
            direction_sell: "Jual",
            direction_buy: "Beli",
            open_price: "Harga pembukaan",
            now_price: "Harga saat ini",
            trade_account: "Akaun perdagangan",
            demo_account: "Akaun Simulasi",
            order_id: "ID Order",
            open_time: "Waktu Buka",
            stop_profit: "Hentikan surplus",
            stop_loss: "Hentikan kehilangan",
            trailing_stop: "Berhenti",
            float_profit_loss: "P/L mengapung",
            overnight_fee: "Hadiah sepanjang malam",
            margin: "Margin",
            maintainsMargin: "Jaga margin",
            open_value: "Nilai pembukaan",
            now_value: "Nilai saat ini",
            notset: "Tidak ditetapkan",
            close_position: "Posisi menutup",
            close_number: "Kuntitas normal",
            onclick_close: "Satu klik menutup posisi",
            btn: "Konfirmasi posisi penutup",
            balance: "Kesimbangan yang tersedia",
            profit_loss: "Profit dan kehilangan",
            netValue: "Nilai neto",
            marginLevel: "Tingkat Margin",
        },
        modify: {
            tit: "Ubah Order Posisi",
            expected_profit_loss: "Profit dan kehilangan yang diharapkan",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Tetapkan kata sandi baru",
            verify_email_tit: "Periksa email",
            verify_password_tit: "Periksa sandi anda",
            verify_password_tip: "Masukkan kata sandi daftar masuk HKDFINANC Anda untuk terus",
            verify_phone_tit: "Periksa nomor telepon",
            tit: "Keamanan rekening",
            tip: "Untuk melindungi keamanan rekening Anda dan menerima pemberitahuan layanan kami, disarankan untuk memungkinkan setidaknya satu jenis autentikasi.",
            label1: "Ubah kata sandi",
            label2: "Pembatalan rekening",
            label3: "Verifikasi Dua",
            label4: "Manajemen perangkat",
            tip2: "Login perangkat baru membutuhkan verifikasi",
        },
        tradenews: {
            tit: "Panduan Transaksi",
            tab1: "Cepat mulai",
            tab2: "Belajar maju",
            tab3: "Pusat Bantuan",
            tab4: "Tentang HKDFINANC",
            viewMore: "Lihat lebih",
            details: {
                tit: "Perkenalan ke Exchange Luar Negeri",
                tip1: "Perhitungan durasi belajar",
                tip2: "Menit",
            },
            tab1Row: {
                tit: " Ini adalah ruang kelas untuk cepat belajar untuk bertukar dengan HKDFINANC",
                tit1: "Bagaimana perdagangan di HKDFINANC?",
                tit2: "Bagaimana membuka rekening nyata?",
                tit3: "Bagaimana membuka posisi untuk perdagangan?",
                tit4: "Bagaimana menghentikan kehilangan dan menghentikan kehilangan?",
                tit5: "Bagaimana menutup posisinya?",
                p1: "Anda dapat menukar subjek dengan membelinya atau menjualnya. Membuka posisi di HKDFINANC berarti bahwa Anda tertarik pada",
                p2: "Penjualan berdasarkan fluktuasi harga produk sasaran",
                p3: "Contohnya, jika Anda mengharapkan harga emas meningkat, membeli produknya; Sebaliknya, jika Anda memprediksi bahwa harga emas akan menurun, maka menjual produk. Jika masa depan memenuhi harapan, Anda dapat",
                p4: "Memberi keuntungan darinya",
                p5: "Selain itu, HKDFINANC menyediakan 'perdagangan yang berguna'. Anda dapat menggunakan leverage untuk perdagangan lebih banyak sasaran dengan jumlah dana yang sama.",
                p6: "Peningkatan dapat meningkatkan keuntungan dan juga meningkatkan kehilangan",
                p7: "Ini juga dikenal sebagai 'perdagangan margin'. Ketika Anda membuka posisi, akun HKDFINANC Anda akan mengurangi keseimbangan tertentu sebagai",
                p8: "Margin awal",
                p9: "Pilih produk dan arah membeli dan menjual",
                p10: "Transaksi",
                p11: "Klik 'Buy/Sell' untuk menyelesaikan lokasi perintah",
                p12: "Menetapkan 'berhenti keuntungan' atau 'berhenti kehilangan' dapat membantumu",
                p13: "Waktunya mengunci keuntungan dan membatasi kehilangan",
                p14: "Anda dapat klik pada halaman pembukaan yang sukses",
                p15: "Hentikan keuntungan/hentikan kehilangan",
                p16: "Tetapkan, atau Anda dapat klik pada tombol 'Ubah' untuk urutan tidak seimbang untuk menghentikan keuntungan dan kehilangan",
                p17: "Tetapkan atau batalkan",
                p18: "Anda dapat melihat keuntungan dan kerugian dalam 'posisi tertutup'",
            },
            tab3Row: {
                h1: "Selamat datang di HKDFINANC",
                h2: "Pusat Bantuan",
                tit0: "Semua masalah",
                tit1: "Akaun Simulasi",
                tit2: "Membuka akun",
                tit3: "Uang masuk dan keluar",
                tit4: "Pengaturan Platform",
                tit5: "Operasi Transaksi",
                tit6: "Gaji dan biaya",
                tit7: "Keamanan Keuangan dan Peraturan",
                tit1Tit1: "HKDFINANC Apakah ada akun simulasi yang disediakan",
                tit1Cont1: "<p>HKDFINANC Memberikan akun simulasi untuk pelanggan untuk beroperasi, memungkinkan Anda menggunakan platform online kami dan aplikasi ponsel untuk transaksi dan memperkenalkan diri dengan operasi platform tanpa risiko apapun</p>",
                tit1Tit2: "Bagaimana menciptakan akun simulasi?",
                tit1Cont2: "<p>Anda hanya perlu klik di sini untuk mengisi alamat email atau nomor telepon Anda, dan menetapkan kata sandi pribadi Anda untuk menyelesaikan registrasi dan mendapatkan akun simulasi.</p>",
                tit1Tit3: "Apa gunanya akun simulasi?",
                tit1Cont3: "<p>Akun simulasi dan akun nyata kebanyakan sama dalam terma antarmuka transaksi, data, dan operasi. Akun simulasi mengandung $ 50000 dalam dana virtual, dengan tujuan untuk memperkenalkan pelanggan dengan berbagai fungsi platform melalui operasi simulasi tanpa risiko keuangan apapun.</p>",
                tit1Tit4: "Bagaimana menyuntik dana ke akun simulasi?",
                tit1Cont4: "<p>Anda tidak perlu menyuntik dana ke rekening simulasi. Setelah Anda berhasil mendaftar rekening simulasi, akan ada jumlah 50000 USD atau AUD dalam rekening</p><p>Ketika nilai neto dalam akun simulasi adalah kurang dari $200 USD atau AUD, sistem akan secara otomatis mengisi kembali dana akun Anda ke $50000 USD atau AUD</p>",
                tit1Tit5: "Apakah akun simulasi memiliki periode penggunaan?",
                tit1Cont5: "<p>Period validitas akun simulasi adalah 90 hari. Jika Anda tidak membuka akun nyata setelah 90 hari, akun simulasi akan dibekukan dan tidak dapat ditukar, dan akan dibekukan ketika Anda membuka akun nyata.</p><p>Jika Anda membuka rekening nyata dalam waktu 90 hari, rekening simulasi akan valid untuk waktu yang lama. Bahkan setelah membuka rekening nyata, Anda dapat terus beroperasi pada rekening simulasi.</p>",
                tit2Tit1: "HKDFINANC Berapa banyak jenis rekening tersedia?  ",
                tit2Cont1: "<p>Kami hanya menyediakan rekening perdagangan standar dan profesional untuk individu, dan tidak mendukung pembukaan perusahaan atau rekening kongsi</p>",
                tit2Tit2: "Uang apa yang bisa kubuka akun perdagangan?",
                tit2Cont3: "<p>Ketika Anda membuka rekening, sistem akan secara otomatis menentukan uang penyelesaian Anda berdasarkan wilayah Anda dan tidak akan dapat diubah</p>",
                tit2Tit4: "Bagaimana membuka akun nyata?",
                tit2Cont4: "<p>Silakan ikuti langkah di bawah untuk membuka akun nyata</p><p>1. Masukkan halaman pendaftaran HKDFINANC dan ikuti permintaan untuk mendaftar nomor telepon e-mail, menetapkan sandi daftar masuk, dan secara otomatis menciptakannya setelah mengirimkan konfirmasi</p><p></p><p></p>",
                tit3Tit1: "Bagaimana aku bisa membuat deposit?",
                tit3Cont1: "<p>HKDFINANC Menyediakan metode deposit yang berbeda untuk pelanggan untuk memilih dari, termasuk tetapi tidak terbatas kepada berikut:</p><p>1.Visa/Mastercard - Biasanya diterima segera</p><p>2.Pembayaran bank online biasanya dilakukan dalam waktu 1 hari kerja di depan</p><p>3.Dompet elektronik (Contohnya: Skrill, Momo, Zalo. Touch'n GO, Boost, dll.) -Biasanya diterima segera</p><p>4.Kartu ATM - biasanya menerima instant</p><p>5.QR mobil banking online - biasanya menerima instant</p>",
                tit3Tit2: "Dapatkah saya menggunakan akun bank/kartu di bawah nama pihak ketiga untuk mendepositasi atau menarik dana",
                tit3Cont2: "<p>HKDFINANCJangan menerima deposit dari pihak ketiga. Silakan gunakan akun/kartu bank pribadi Anda di bawah nama Anda sendiri untuk mendepositasi atau menarik dana. Jika Anda menggunakan nama orang lain atau rekening bank perusahaan/kartu untuk mendeposisi dana, dana akan dikembalikan</p>",
                tit3Tit3: "Bagaimana untuk meminta pengunduran dari rekening HKDFINANC?   ",
                tit3Cont3: "<p>Setelah log masuk ke platform website, Anda dapat klik pada 'Cash Out', pilih rekening bank atau Visa/Mastercard, isi jumlah uang tunai keluar, dan kemudian tekan 'Kirim'. Setelah mengkonfirmasi bahwa informasi benar, HKDFINANC akan memproses aplikasi Anda dalam 1-2 hari kerja</p>",
                tit4Tit1: "Berapa kali HKDFINANC menawarkan perdagangan yang berguna?",
                tit4Cont1: "<p>Rasio leverage yang disediakan oleh platform HKDFINANC ditetapkan menurut peraturan pihak berwenang, dan leverage maksimum untuk produk berbeda juga berbeda. Silakan log masuk ke platform perdagangan untuk rincian.</p>",
                tit4Tit2: "Bagaimana mengubah kata sandiku?",
                tit4Cont2: "<p>Setelah log masuk ke platform, Anda dapat memilih [Lebih] - [Pengaturan Keselamatan] - T Ubah Sandi] untuk reset sandi baru</p>",
                tit4Tit3: "Bagaimana untuk log masuk ke platform perdagangan HKDFINANC?",
                tit4Cont3: "<p>App:Pergi ke 'Saya' dan klik pada 'Login Registration' di atas kiri untuk memasukkan halaman log.</p>Web: Klik pada 'Login' di sudut kanan atas situs resmi HKDFINANC untuk memasukkan halaman log.<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Apa metode pengingatan platform?",
                tit4Cont4: "<p>HKDFINANC Ingatkan melalui email, SMS, dan platform push.</p>",
                tit5Tit1: "HKDFINANC Apa jenis perbedaan harga yang diberikan?",
                tit5Cont1: "<p>Kami menuntut perbedaan harga rendah dan tidak menuntut komisi apapun. Perbedaan harga mengambang, dan perbedaan harga yang sebenarnya ditanggung akan tergantung pada varietas yang Anda perdagangan, dan informasi khusus akan dipaparkan di platform perdagangan dalam waktu nyata.</p>",
                tit5Tit2: "Bagaimana membuat daftar harga pasar?",
                tit5Cont2: "<p>Klik pada 'Perdagangan' akan menampilkan semua produk yang platform dapat menawarkan untuk perdagangan. Anda dapat memasukkan kode variasi atau nama dalam kotak masukan di sebelah kanan untuk mencari, memilih produk, dan klik 'Buy/Sell' SEL/BUY' untuk muncul jendela perdagangan. Di jendela perdagangan, Anda dapat melihat harga saat ini dan margin yang diperlukan. Anda dapat secara manual menyesuaikan jumlah posisi terbuka dan mengatur menghentikan keuntungan dan menghentikan kehilangan untuk pengendalian risiko</p>",
                tit5Tit3: "Bagaimana untuk mendirikan formulir pendaftaran?",
                tit5Cont3: "<p>Memilih produk dalam [Perdagangan] dan mengklik pada [Menjual/BUI] akan muncul jendela perdagangan</p>",
                tit5Tit4: "Bagaimana menutup posisi?",
                tit5Cont4: "<p>Untuk menutup posisi, klik tombol 'Buka' pada platform di bagian 'Posisi', pilih posisi yang ingin ditutup, lalu klik tombol 'Tutup' di sisi kanan。</p>",
                tit5Tit5: "Bagaimana mengubah atau menghapus perintah yang menunggu?",
                tit5Cont5: "<p>Untuk mengubah atau menghapus perintah, klik 'Perintah' pada platform pemandangan di bagian 'Posisi', dan kemudian pilih perintah yang ingin Anda ubah atau menghapus dalam proses</p>",
                tit6Tit1: "Apakah akan ada biaya pengurusan yang ditanggung untuk menggunakan platform perdagangan?",
                tit6Cont1: "<p>HKDFINANC Untuk sepenuhnya komisi platform perdagangan bebas. Kami menuntut perbedaan harga rendah, dan tergantung pada situasi transaksi spesifik Anda, platform mungkin mengalami biaya tambahan, seperti bunga overnight.</p>",
                tit6Tit2: "Apakah ada biaya untuk deposit?",
                tit6Cont2: "<p>Kami tidak akan meminta biaya deposit terkait kepada pelanggan, tapi bayaran atau bank intermedier Anda dapat meminta biaya pengendalian. Kami sarankan kau bertanya pada bank-bank relevan apakah mereka telah meminta bayaran apapun.</p>",
                tit6Tit3: "Apakah ada biaya untuk penarikan diri?",
                tit6Cont3: "<p>HKDFINANC Kami tidak akan menuntut pelanggan biaya apapun yang berhubungan dengan pembayaran dana kecuali jumlah yang telah Anda kontribusikan adalah di bawah permintaan minimum atau melebihi jumlah maksimum pembayaran gratis untuk bulan. Untuk informasi terperinci, silakan merujuk kebijakan deposit kami.</p>",
                tit7Tit1: "Apakah dana yang saya depositasikan di akun HKDFINANC saya digunakan untuk tujuan lain?",
                tit7Cont1: "<p>Tidak, tidak, tidak. Deposit pelanggan retail terpisah didepositasi di akun kepercayaan ketika diperlukan sesuai dengan peraturan peraturan. Ketika mendeposisi dana ke akun pelanggan atau menarik dana dari akun pelanggan atau membuat pembayaran, HKDFINANC secara ketat mematuhi peraturan peraturan mengenai pembayaran pelanggan.</p>",
                tit7Tit2: "Jika HKDFINANC bangkrut atau berhutang, akan danaku juga terkena kesan?",
                tit7Cont2: "<p>Deposit pelanggan retail terpisah didepositasi di akun kepercayaan ketika diperlukan sesuai dengan peraturan peraturan. Jika HKDFINANC bangkrut, memiliki lisensinya dibatalkan, atau tidak dapat terus beroperasi, dana pelanggan masih bisa dikrantina dan didalam pengawasan peraturan dana pelanggan dalam undang-undang regulasi</p>",
                tit7Tit3: "Apakah HKDFINANC mematuhi peraturan hukum?",
                tit7Cont3: "<p>Perusahaan kita sepenuhnya mematuhi undang-undang perusahaan yang relevan dan undang-undang regulasi keuangan, dan adalah penyedia layanan keuangan yang diberi otorisasi dan ketat diatur oleh agensi regulasi relevan. Perusahaan kami menyediakan perlindungan komprensif dan cukup untuk keamanan keuangan pelanggan sesuai dengan keperluan regulasi yang ketat.</p>",
                tit7Tit4: "Apakah informasi pribadi pelanggan aman?",
                tit7Cont4: "<p>HKDFINANC Tetap mengikuti aturan perlindungan data pribadi, dan jangan pernah mengungkapkan data pribadi pelanggan. Perusahaan akan memastikan bahwa karyawan memenuhi standar ketat keamanan dan rahasia yang ditetapkan oleh undang-undang dan peraturan, dan memperhatikan tinggi pengaturan untuk rahasia dan penggunaan data pelanggan. Untuk rincian, silakan merujuk dokumen penemuan hukum perusahaan kita.</p>",
            },
            tab4Row: {
                span: "Metode verifikasi Q",
                p1: "HKDFINANC adalah perusahaan teknologi keuangan yang diijinkan dan diatur oleh institusi otoritas, fokus pada menyediakan investor pengalaman perdagangan sederhana dan nyaman.",
                p2: "Platform perdagangan yang diversifikasi HKDFINANC inovatif telah berulang kali memenangkan penghargaan seperti platform perdagangan ponsel terbaik dan perusahaan securities yang paling inovatif",
                p3: "Melalui platform HKDFINANC, Anda dapat menginvestasi dan perdagangan dalam jangkauan yang lebih luas dari pasar keuangan internasional, meliputi ratusan varietas popular seperti saham, indeks, komoditas, dan pertukaran asing. Tidak hanya Anda terbatas kepada platform perdagangan ponsel dan web yang mudah digunakan, HKDFINANC juga memberikan Anda biaya transaksi kompetitif, eksekusi transaksi cepat, layanan pelanggan yang baik, dan dukungan informasi yang kaya, membantu Anda mengambil investasi dan kesempatan perdagangan dalam waktu yang tepat",
                p4: "HKDFINANC diperintahkan oleh otoritas industri dan beberapa institusi",
                p5: "HKDFINANC Holding diijinkan dan diatur oleh Kepulauan Cayman Monetary Authority (CIMA) dengan nomor SIB 1612446. Untuk lebih rincian tentang lisensi Cayman, silakan kunjungi situs resmi CIMA di www.cima.ky",
                P6: ") Lakukan pertanyaan. HKDFINANCGlobal memiliki Lesen Layanan Finanzial (AFSL 398528) yang diijinkan oleh Komisi Securities and Investment Hong Kong (ASIC). Untuk lebih rincian tentang foto Hong Kong, silakan kunjungi situs resmi ASIC di www.asic.gov.au",
                P7: ") Pencarian. HKDFINANC International diijinkan dan diatur oleh Komisi Layanan Finanzial Mauritius (FSC) dengan nomor lisensi GB20025791. Untuk informasi lebih lanjut tentang lisensi Mauritius, silakan kunjungi situs resmi FSC di www.fscmauritius. org",
                P8: ") Menjalankan penyelidikan. Semua operasi bisnis HKDFINANC dilakukan di bawah pengawasan ketat dan mematuhi semua peraturan",
                P9: 'Latar belakang',
                P10: "HKDFINANC adalah tim senior dengan pengalaman dan kecerdasan yang kaya dalam perdagangan keuangan dan industri teknologi, yang didirikan di Melbourne, Australia",
                P11: "Konsep HKDFINANC adalah untuk membuat transaksi lebih mudah dan lebih ramah. strategi holistik kami dan model bisnis memungkinkan kita memiliki pemahaman komprensif dan mendalam tentang perkembangan industri dan permintaan pasar, memungkinkan kita untuk mengalokasi sumber daya yang lebih ditujukan, terus-menerus inovasi teknologi dan optimisasi efisiensi untuk terus menerus membawa pelanggan pengalaman perdagangan yang lebih nyaman dan ramah",
                P12: "Mengapa memilih",
                P13: "Kondisi perdagangan batas rendah",
                P14: "Platform perdagangan sederhana dan intuitif",
                P15: "Harga transaksi kompetitif",
                P16: "Diregulasikan oleh pihak berwenang industri",
                P17: "Tingkat tinggi dukungan online",
                P18: "Perlindungan keseimbangan negatif",
                P19: "Hadiah dan Kehormatan",
                P20: "HKDFINANC dihormati untuk menerima banyak hadiah, termasuk Pengawal Keuangan Dunia Best Multi Asset Broker, Global BrandsMagazine's Best Mobile Trading Platform di Asia Pasifik, dan Global Fast Growing Financial Technology Broker, yang semua mengkonfirmasi usaha dan usaha terus menerus tim",
                P21: "Best Multi Asset Broker",
                P22: "Filosofi operasi kita",
                P23: "Responsibility",
                P24: "Semua transaksi melibatkan risiko, dan HKDFINANC mengambil lebih banyak tindakan untuk menyediakan pelanggan dengan lingkungan perdagangan yang bertanggung jawab. HKDFINANC subjek otoritas industri, pengawasan ketat dari beberapa institusi, dan mematuhi standar operasi keamanan tinggi dan stabilitas platform kami terus menyediakan banyak alat perdagangan bertanggung jawab, termasuk manajemen risiko",
                P25: "Simplicity and Beauty",
                P26: "Kesederhanaan adalah keindahan, dan rincian mencapai kecantikan. HKDFINANC berusaha untuk setiap rincian kesederhanaan, mudah digunakan, dan pengalaman pengguna. Kami terus optimisasi platform dan layanan kami, berharap bahwa semua orang, baik yang baru atau yang mengalami pengalaman investor, dapat dengan mudah mengambil kesempatan perdagangan melalui platform HKDFINANC",
                P27: "Transparensi",
                P28: "Untuk memberikan keuntungan penuh kepada pelanggan kita dari lingkungan perdagangan yang adil dan efisien, HKDFINANC mengikuti sistem pengaturan diri yang ketat dan transparan, menyediakan informasi platform komprensif dan transparan produk, lingkungan penghargaan, dan data Semua biaya jelas dan transparan, memastikan keseluruhan jujur dan transparan dari seluruh proses layanan",
                P29: "Innovasi",
                P30: "HKDFINANC percaya dengan teguh bahwa kemajuan teknologi, inovasi keuangan, dan layanan yang lebih luas adalah kekuatan mendorong untuk pertumbuhan, memungkinkan penciptaan terus menerus dan berbagi nilai. untuk tujuan ini, kami mendorong pemikiran terbuka dan terus mempromosikan inovasi, bertujuan untuk menjadi inovator dan pemimpin dalam teknologi keuangan",
                P31: "Goals and Vision",
                P32: "Untuk menyediakan lingkungan perdagangan yang adil, efisien dan transparan yang tidak terbatas oleh ruang, waktu, atau pasar untuk lebih banyak orang yang ingin melakukan transaksi, memimpin industri dalam inovasi keuangan dan teknologi",
            },
        },
        language: {
            tit: "Pilih Bahasa",
        },
        market: {
            tit: "Pengaturan pasar",
            tit1: "Tampilan harga",
            tit2: "Warna naik dan turun",
            buy_price: "Harga membeli",
            buy_price_tip: "Lukis garis K dengan harga pembelian",
            sell_price: "Harga penjualan",
            sell_price_tip: "Lukis garis K dengan harga jualan",
            color1: "Green rise and red fall",
            color2: "Red rise and green fall",
        },
        notice: {
            tit: "Pengaturan Notifikasi",
            tit1: "Buka saluran pemberitahuan",
            p1: "Silakan buka/verifikasi saluran pemberitahuan berikut, pesan penting tidak terlepas!",
            message: "Pesan",
            push: "Dorong",
            verify: "Validate",
            verified: "Diverifikasi",
            email: "E-mail",
            type1: "Pemasaran",
            tit2: "Notifikasi Kejadian Pemasaran",
            desc2: "Informasi promosional platform, aktivitas operasional, dan pesan lainnya",
            tit3: "Instruksi operasi",
            desc3: "Material pengetahuan kaya dan profesional",
            tit4: "Peringatan informasi",
            desc4: "Data keuangan dan informasi berita baru-baru ini",
            tit5: "Pengingat fluktuasi cerdas",
            desc5: "Termasuk variasi harga berbagai jenis penting, membantu Anda cepat mengambil kesempatan",
            type2: "Kelas Laporan",
            tit6: "Laporan Akun",
            desc6: "Laporan akun Anda akan dikirim bulanan dengan cara berikutnya",
            cycle: "Siklus",
            month: "Setiap bulan",
        },
        protocol: {
            tit: "Deklarasi dan Perjanjian",
            label1: "Polisi Privasi",
            label2: "Penjelasan Produk",
            label3: "Perjanjian Pelanggan",
            label4: "Penjelasan Risiko",
        },
        setting: {
            tit: "Mengatur",
            label1: "Pengaturan keamanan",
            label2: "Pengaturan pasar",
            label3: "Pengaturan Transaksi",
            label4: "Bahasa",
            label5: "Mod tema",
            label6: "Pengaturan Notifikasi",
            label7: "Panduan Transaksi",
            label8: "Deklarasi dan Perjanjian",
            label9: "Bersihkan cache",
            label10: "Nomor versi",
            logout: "Daftar keluar dari daftar masuk",
        },
        theme: {
            tit: "Mod tema",
            theme1: "Mod cahaya",
            theme2: "Mod Gelap",
        },
        trade: {
            tit: "Pengaturan Transaksi",
            onclick_close: "Satu klik menutup posisi",
        }
    },
    trade: {
        fliter_type1: "Pilihan sendiri",
        fliter_type2: "Pasar",
        place_search: "Masukkan nama/pencarian kode variasi",
        fliter_type3: "Popular",
        fliter_type4: "Pertukaran asing",
        fliter_type5: "Komoditas",
        fliter_type6: "Saham",
        fliter_type7: "Indeks",
        fliter_type8: "Enkripsi",
        tip1: "Complete identity verification to deposit transactions and invest globally",
        sell: "Jual",
        buy: "Beli",
        add_optional: "Tambah Pemilihan Sendiri",
        optional_recommend: "Rekomendasi pemilihan diri",
        change_batch: "Ubah batch",
        edit_tit: "Sunting Pemilihan",
        breed: "Variasi",
        topping: "Atas",
        sort: "Sort",
        all: "Pilih Semua",
    },
    table: {
        label_breed: "Variasi",
        label_direction: "Arah",
        label_price: "Harga",
        label_operate: "Operasi",
        empty_tip1: "Tidak ada peringatan saat ini",
        empty_tip2: "Tidak ada catatan deposit",
        empty_tip3: "Tidak ada catatan pembayaran tunai",
        label_amount: "Uang",
        label_type: "Tipe",
        label_time: "Waktu",
        label_name: "Nama",
        label_email: "E-mail",
        label_address: "Negara tinggal",
        label_account: "Akaun perdagangan",
        label_currency: "Uang dasar",
        label_company: "Perusahaan pembukaan rekening saat ini",
    },
    form: {
        to: "Ke",
        start_time: "Waktu awal",
        end_time: "Waktu akhir",
        week: "Minggu lalu",
        month: "Bulan lalu",
        threeMonth: "Dalam tiga bulan terakhir",
        sixMonth: "Dalam enam bulan terakhir",
        label_feedback: "Pertanyaan dan saran",
        place_feedback: "Silakan masukkan pertanyaan atau saran Anda",
        label_img: "Gambar",
        label_img_tip: "Opsional, menyediakan foto layar dari masalah",
        feedback_tip: "Jika Anda memiliki pertanyaan mendesak, silakan hubungi",
        online: "Layanan Online",
        label_cardNumber: "Nomor Identifikasi",
        label_realName: "Nama penuh",
        place_realName: "Silakan masukkan nama depanmu",
        message_realName: "Silakan masukkan nama penuh Anda",
        label_firstName: "Nama keluarga",
        place_firstName: "Silakan masukkan nama belakangmu",
        message_firstName: "Silakan masukkan nama belakang",
        label_lastName: "Nama",
        place_lastName: "Silakan masukkan nama tengah Anda",
        message_lastName: "Silakan masukkan nama",
        label_birthday: "Tanggal lahir",
        place_birthday: "Silakan masukkan tanggal kelahiranmu",
        message_birthday: "Silakan masukkan tanggal lahir",
        label_idType: "Jenis dokumen",
        tip_cardMain: "Ambil atau mengunggah foto FRONT dari kartu identitasmu",
        tip_cardBack: "Ambil atau mengunggah foto dari BAlik kartu identitasmu",
        tip_cardMain1: "Ambil atau mengunggah foto FRONT dari lisensi pengemudi Anda",
        tip_cardBack1: "Ambil atau mengunggah foto dari BAlik lisensi pengemudi Anda",
        tip_cardMain2: "Ambil atau mengunggah foto paspor Anda",
        tip_cardBack2: "Ambil atau mengunggah foto paspor Anda",
        label_password: "Sandi",
        place_password: "Silakan masukkan sandi",
        message_password: "Silakan masukkan sandi",
        label_crmpsd: "Konfirmasi Sandi",
        place_crmpsd: "Silakan masukkan sandi lagi",
        message_crmpsd: "Silakan masukkan sandi lagi",
        label_email: "E-mail",
		place_email: "Alamat surel",
		message_email: "Silakan masukkan alamat email Anda",
        label_captcha: "Kode verifikasi",
		place_captcha: "Silakan masukkan kode verifikasi",
		message_captcha: "Silakan masukkan kode verifikasi",
		get_captcha: "Dapatkan kode verifikasi",
        label_phone: "Telepon",
		place_phone: "Silakan masukkan nomor telepon",
		message_phone: "Silakan masukkan nomor telepon",
        labelAmount: "Uang",
        placeAmount: "Silakan masukkan jumlah",
        messageAmount: "Silakan masukkan jumlah",
        labelWalletName: "Nama dompet",
		messageWalletName: "Silakan masukkan nama dompet",
		placeWalletName: "Silakan masukkan nama dompet",
		labelWalletAddress: "Alamat dompet",
		messageWalletAddress: "Silakan masukkan alamat dompet",
		placeWalletAddress: "Silakan masukkan alamat dompet",
        labelWithdrawAccount: "Akaun penarikan",
        placeWithdrawAccount: "Akaun penarikan",
        messageWithdrawAccount: "Akaun penarikan",
		placeTimeLimit: "Silakan masukkan batas waktu",
		messageTimeLimit: "Silakan masukkan batas waktu",
    },
	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgia",
		bosnia_and_Herzegovina: "Bosnia dan Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Kambodja",
		canada: "Kanada",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Kolombia",
		costa_Rica: "Costa Rica",
		croatia: "KroatiaName",
		cyprus: "Cyprus",
		czech_Republic: "Republik Ceko",
		denmark: "Denmark",
		dominican_Republic: "Republik DominikanName",
		egypt: "Mesir",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "Perancis",
		georgia: "Georgia",
		germany: "Jerman",
		ghana: "Ghana",
		greece: "Yunani",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Cina",
		hungary: "Hungary",
		iceland: "Islandia",
		ireland: "Irlandia",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Irak",
		japan: "Jepang",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgistan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxemburg",
		macao_China: "Macau, Cina",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Meksiko",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Maroko",
		myanmar: "Myanmar",
		netherlands: "Belanda",
		new_Zealand: "Seland Baru",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norwegia",
		oman: "Oman",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Filipina",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Rumania",
		russia: "Rusia",
		republic_of_Trinidad_and_Tobago: "Trinidad dan Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arab Saudi",
		serbia: "Serbia",
		singapore: "Singapura",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "Afrika Selatan",
		spain: "Spanyol",
		sri_Lanka: "Sri Lanka",
		sweden: "Swedia",
		switzerland: "Swiss",
		taiwan_China: "Taiwan, Cina",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "T ürkiye",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraina",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "Inggris",
		united_States: "Amerika",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Pulau perawan Inggris",
		burkina_Faso: "Burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Kepulauan Cayman",
		central_African_Republic: "Republik Afrika Tengah",
		chad: "Chad",
		comoros: "Komor",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongo (Kinshasa)",
		djibouti: "Djibouti",
		ecuador: "	Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Ekvatorial",
		eritrea: "Eritrea",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Pulau Man",
		cote_d_Ivoire: "Pantai Ivory",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Korea Utara",
		reunion: "Reunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Surinam",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Kepulauan Perawan, Amerika Serikat",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikan",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "zimbabwe",
		china: "Tiongkok",
	}
}
