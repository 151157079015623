export default {
    crypto: {
        title: { "0": "Krypto kaufen", "1": "Krypto kaufen", "2": "C2C" },
        list: {
            "0": "C2C-Handel",
            "1": "Kaufen",
            "2": "Verkaufen",
            "3": "Werbetreibende (Abschlussquote)",
            "4": "Handel",
            "5": "Preis",
            "6": "Verfügbar",
            "7": "Grenzwert",
            "8": "Zahlungsmethode",
            "9": "Handel",
            "10": "Der Werbetreibende kann Transaktionsbeschränkungen für die Anzeige festlegen, und Sie werden den Grund für die Einschränkung sehen, nachdem Sie sich angemeldet haben.",
        },
        buy: {
            "0": "Krypto kaufen",
            "1": "Krypto verkaufen",
            "2": "Geschichte",
            "3": "Unterstützung",
            "4": "Bezahlen",
            "5": "Empfangen",
            "6": "Zahlungsart auswählen",
            "7": "Est. Ankunftszeit",
            "8": "GEBÜHRE",
            "9": "Bestelldetails",
            "10": "Zahlungsmethode",
            "11": "Erfahren Sie mehr",
            "12": "Preis",
            "13": "Insgesamt (Gebühr inbegriffen)",
            "14": "Haftungsausschluss",
            "15": "Sie verlassen HKDFinanc und werden zu Mercuryo weitergeleitet, einem lizenzierten Finanzinstitut, das Kredit-/Debitkartenzahlungsdienste als Drittanbieter anbietet. Alle Risiken, die während der Nutzung des Dienstes auftreten, gehen zu Lasten von Mercuryo. Bitte lesen und stimmen Sie den Nutzungsbedingungen zu, bevor Sie fortfahren.",
            "16": "Ich habe die Nutzungsbedingungen gelesen und bin damit einverstanden",
            "17": "Du wirst bekommen",
            "18": "Absenden",
            "19": "Bitte geben Sie den Betrag ein",
            "20": "Unzureichender Kontostand",
            "21": "Verfügbar",
            "22": "Spot-Konto verfügbar",
            23: "Single purchase limit",
            24: "Minimum",
            25: "Maximum",
        },
    },
    common: {
        view: "Anzeigen",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        deposit: "Einzahlen",
        withdraw: "Abheben",
        noData: "Keine Daten verfügbar",
        submit: "Absenden",
        modify: "Ändern",
        hand: "Los",
        confirm_verify: "Bestätigung der Überprüfung",
        next: "Nächster",
        complete: "Fertigstellen",
        delete: "Löschen",
		countryCode: "Ländercode",
    },
    addnew: {
        realFirst: "Bitte führen Sie zuerst Identitätsauthentifizierung durch",
        payFirst: "Bitte legen Sie zuerst das Transaktionskennwort fest",
        password1: "Login Passwort ändern",
        password2: "Passwort für Auszahlungen ändern",
        paypassTit1: "Bitte geben Sie Ihr Auszahlungskennwort ein",
        oldPass: "Altes Passwort",
        allView: "Todo leído",
        payPass: "Transaktionskennwort",
        buyUp: "Aufkaufen",
        buyDown: "Verkaufen",
        confirmOrder: "Bestellung bestätigen",
        name: "Name",
        fee: "Gebühr",
        orderCycle: "Zeitraum auswählen",
        loan: "Darlehen",
        loanTit: "Kredit unterstützen",
        loanHostory: "Kreditverlauf",
        labelProductTitle: "Produktname",
        immediateRepayment: "Sofortige Rückzahlung",
        loadAmount: "Erwarteter Darlehensbetrag",
        RepaymentCycle: "Rückzahlungszyklus",
        day: "Tag",
        dailyRate: "Tageszinssatz",
        repaymentMethod: "Rückzahlungsmethode",
        lendingCompany: "Kreditgesellschaft",
        loanTip: "Kreditvergabe (Stellen Sie sicher, dass das Bild klar sichtbar ist)",
        loanTip1: "Immobilienurkunde hochladen",
        loanTip2: "Einkommensnachweis (Arbeitsverhältnis)",
        loanTip3: "Detaillierte Bankkontoauszüge hochladen",
        loanTip4: "Vorderseite des Personalausweises hochladen",
        interest: "Zinsen",
        repaid: "Bereits zurückgezahlt",
        unpaid: "Noch nicht zurückgezahlt",
        tab1: "Spot",
        tab2: "Futures",
        tab3: "Optionen",
        available: "Verfügbares Guthaben",
        pending: "Ausstehend",
        equivalent: "Äquivalent",
        fundTransfer: "Fondstransfer",
        flashExchange: "Blitztausch",
        select_breed: "Währung auswählen",
        from: "Von",
        to: "Bis",
        transferQuantity: "Transfermenge",
        repaymentMethod1: "Einmalige Rückzahlung bei Fälligkeit",
    },
    params: {
        product_foreign: "Devisen",
        product_shop: "Waren",
        product_number: "Indizes",
        product_stock: "Aktien",
        product_coin: "Kryptowährung",
        billTypes: [
            {v: 101, name: 'Einzahlung'},
            {v: 102, name: 'Einlage'},
            {v: 103, name: 'Gesperrt'},
            {v: 104, name: 'Entsperrt'},
            {v: 105, name: 'Einlage'},
            {v: 106, name: 'Einlage'},
            {v: 201, name: 'Abhebung gesperrt'},
            {v: 202, name: 'Abhebung'},
            {v: 203, name: 'Abhebung erfolgreich'},
            {v: 204, name: 'Abhebung fehlgeschlagen'},
            {v: 205, name: 'Abhebungsgebühr'},
            {v: 206, name: 'Überweisung'},
            {v: 207, name: 'Einzahlung'},
            {v: 208, name: 'Spot zu Spot Transfer'},
            {v: 209, name: 'Spot zu Spot Transfer'},
            {v: 301, name: 'Futures-Gebühr'},
            {v: 302, name: 'Futures-Gewinn'},
            {v: 303, name: 'Futures-Verlust'},
            {v: 304, name: 'Futures-Margin'},
            {v: 305, name: 'Futures-Margin-Rückgabe'},
            {v: 311, name: 'Optionen kaufen'},
            {v: 312, name: 'Optionen-Gewinn'},
            {v: 313, name: 'Optionen-Rückgabe'},
            {v: 314, name: 'Optionen-Gebühr'},
            {v: 315, name: 'Spot-Kauf gesperrt'},
            {v: 316, name: 'Spot-Kauf abgezogen'},
            {v: 317, name: 'Spot-Kauf zurückgegeben'},
            {v: 318, name: 'Spot-Kauf gebucht'},
            {v: 319, name: 'Spot-Kauf gebucht'},
            {v: 320, name: 'Spot-Verkauf gesperrt'},
            {v: 321, name: 'Spot-Verkauf abgezogen'},
            {v: 322, name: 'Spot-Verkauf zurückgegeben'},
            {v: 323, name: 'Spot-Verkauf gebucht'},
            {v: 324, name: 'Spot-Verkauf gebucht'},
            {v: 325, name: 'Spot-Gebühr'},
            {v: 401, name: 'Mining beitreten'},
            {v: 402, name: 'Mining-Rückkehr'},
            {v: 403, name: 'Mining-Einnahmen'},
            {v: 404, name: 'Mining beenden'},
            {v: 405, name: 'Mining-Ausstiegsgebühr'},
            {v: 411, name: 'Einnahmen aus Krediten'},
            {v: 412, name: 'Kreditgebühr'},
            {v: 413, name: 'Rückzahlung erfolgreich'},
        ],
    },

    footer: {
        nav1: "Startseite",
        nav2: "Handel",
        nav3: "Positionen",
        nav4: "Mein Konto",
        kline_set1: "Chart-Einstellungen",
        kline_set2: "Handels-Einstellungen",
    },
    login: {
        login: "Anmelden",
        register: "Registrieren",
        type1: "Telefonnummer",
        type2: "E-Mail",
        login_btn: "Sichere Anmeldung",
        register_btn: "Jetzt registrieren",
        psd_login: "Passwort-Anmeldung",
        verify_login: "Anmeldung mit Bestätigungscode",
        forget: "Passwort vergessen?",
        no_account: "Noch kein Konto?",
        have_account: "Bereits registriert?",
    },
    home: {
        home: {
            nav1: "Gewinner und Verlierer",
            nav2: "Schnellstart",
            nav3: "Über uns",
            nav4: "Einladungsprämien",
            most_follow: "Meistgefolgt",
            news_type1: "Strategie",
            news_type2: "Nachrichten",
            news_type3: "Wirtschaftskalender",
            news_tab1: "Alle",
            news_tab2: "Devisen",
            news_tab3: "Aktien",
            news_tab4: "Rohstoffe",
            news_tab5: "Indizes",
            news_tab6: "Daten",
            news_tab7: "Ereignisse",
            news_tab8: "Feiertage",
            label_previous: "Vorheriger Wert",
            label_predictive: "Prognostizierter Wert",
            label_announced: "Veröffentlichter Wert",
        },
        kilne: {
            label_bp: "Gestern erhalten",
            label_sp: "Eröffnungskurs heute",
            label_height: "Höchstwert",
            label_low: "Tiefstwert",
            tip: "Hallo, ich bin der HKDFINANCE GPT-Assistent für Vermögenswerte",
            row1_tit: "Strategie",
            row1_rinei: "Intraday",
            row1_short: "Kurzfristig",
            row1_middle: "Mittelfristig",
            row1_time: "Freigabezeit",
            row1_tit2: "Handelsstrategie",
            data_sources: "Datenquellen",
            row2_tit1: "Daten",
            row2_tit2: "Handelsstimmung",
            row2_label_sell: "Verkäufer",
            row2_label_buy: "Käufer",
            tip2: "Nur zu Referenzzwecken, keine Meinungsäußerung unsererseits",
            tip3: "Alle 15 Minuten aktualisiert",
            row2_tit3: "Preisänderung",
            minute: "Minuten",
            today: "Heute",
            right_tit7: "Preisspanne",
            now_price: "Aktueller Preis",
            right_low_price: "Niedrigster Preis",
            right_height_price: "Höchster Preis",
            details: "Details",
            right_label1: "Einzelhandelsmenge",
            right_label2: "Max. Hebel",
            right_label3: "Vertragsgröße",
            right_label4: "Max. Gesamt-Positionsmenge",
            right_label5: "Spread",
            right_label6: "Übernachtgebühren",
            hand: "Kontrakte",
            right_sell: "Verkaufen",
            right_buy: "Kaufen",
            right_collection_time: "Einzugszeit",
            right_tip3: "Gesammelt aus dem gesamten Handelsvolumen",
            right_label9: "Handelszeitfenster",
            right_label10: "Aktuelles Zeitfenster",
            right_label11: "Nächstes Zeitfenster",
        },
        placeorder: {
            direction_buyPrice: "Kaufpreis",
            direction_sellPrice: "Verkaufspreis",
            direction_sell: "Verkaufen",
            direction_buy: "Kaufen",
            market_price: "Marktpreis",
            check_price: "Limit-Auftrag",
            sell_tip: "Verkaufen, wenn der Preis bestimmte Bedingungen erfüllt",
            buy_tip: "Kaufen, wenn der Preis bestimmte Bedingungen erfüllt",
            trade_number: "Handelsmenge",
            hand: "Kontrakte",
            select_lever: "Hebel wählen",
            right_tip: "Benötigte Margin",
            balance: "Verfügbares Guthaben",
            label_stop_win_price: "Take Profit",
            label_stop_lose_price: "Stop Loss",
            expected_profit_loss: "Erwarteter Gewinn/Verlust",
            trailing_stop: "Trailing Stop",
        },
        ranking: {
            tit: "Tagesgewinner und -verlierer",
            p: "Erfassen Sie Trends und ergreifen Sie Chancen",
            renew: "Aktualisiert um",
            growth_chart: "Gewinner",
            drop_list: "Verlierer",
            show_closed_market: "Geschlossene Märkte anzeigen",
            filter1: "Alle",
            filter2: "Devisen",
            filter3: "Rohstoffe",
            filter4: "Aktien",
            filter5: "Indizes",
            filter6: "Kryptowährungen",
            td1: "Rang",
            td2: "Trend",
            td3: "Tagesveränderung/Verkaufspreis",
        },
        remind: {
            now_sell_price: "Aktueller Verkaufspreis",
            direction_buyPrice: "Kaufpreis",
            direction_sellPrice: "Verkaufspreis",
            direction_sell: "Verkaufen",
            direction_buy: "Kaufen",
            equal: "Wenn",
            p: "Benachrichtigen Sie mich, wenn der Preis die folgenden Werte erreicht",
            btn1: "Benachrichtigung einrichten",
            list_tit: "Benachrichtigungsliste",
        },
    },
    my: {
        report: {
            tit: "Kontoauszug",
            tab1: "Bericht",
            tab2: "Tagesabrechnung",
            tab3: "Monatsabrechnung",
            history_tit: "Handelsverlauf-Bericht",
            account_tit: "Kontoauszug",
            filter: "Filter",
            tip1: "Bitte wählen Sie einen geeigneten Datumsbereich aus, um Ihren Handelsverlauf-Bericht zu exportieren.",
            tip2: "Benutzerdefinierter Zeitraum",
            email_send: "An E-Mail senden",
        },
        accountMsg: {
            tit: "Kontoinformationen",
            label1: "Persönliche Informationen",
        },
        bill: {
            tit: "Transaktionshistorie",
            all: "Alle",
            time: "Zeit",
        },
        feedback: {
            tit: "Feedback",
        },
        identity: {
            tit: "Identitätsprüfung",
            type1: "Personalausweis",
            type2: "Führerschein",
            type3: "Reisepass",
            tip: "Ich bestätige, dass alle von mir bereitgestellten Informationen und Dokumente vollständig, echt und korrekt sind.",
        },
        my: {
            is: "Ist",
            no: "Nicht verifiziert",
            verify: "Verifiziert",
            id_verify: "Identitätsprüfung",
            verify_tip: "Vollständige Identitätsprüfung, um Einzahlungen und Handel durchzuführen",
            no_verify: "Jetzt verifizieren",
            balance: "Verfügbares Guthaben",
            profit_loss: "Gewinn/Verlust",
            marginLevel: "Margin-Level",
            btn1: "Zu Echtgeldkonto wechseln",
            deposit_withdraw_record: "Einzahlungs- und Auszahlungsverlauf",
            capital_flow_details: "Kapitalflussdetails",
            welfare_center: "Wohlfahrtszentrum",
            tip1: "Sie haben",
            tip2: "Prämienzahlungen ausstehend",
            feedback: "Feedback",
            report: "Kontoauszug",
            setting: "Einstellungen",
        },
        news: {
            tit: "Benachrichtigungen",
        },
        record: {
            tit: "Einzahlungs- und Auszahlungsverlauf",
            cycle: "Zyklus",
        },
        share: {
            tit: "Freunde einladen",
            invite_success: "Freunde erfolgreich eingeladen",
            tip1: "Bargeldbelohnung",
            tip2: "Maximale Belohnung für Freunde",
            tip3: "Einladen von Freunden, um Bargeld zu erhalten",
        },
        welfare: {
            tit: "Wohlfahrtszentrum",
            tab1: "Gutscheine",
            tab2: "Aktionen",
            history_record: "Verlaufsaufzeichnungen",
            period_validity: "Gültigkeitsdauer",
        },
        wallet: {
            tit: "Wallet",
            addNew: "Neue Wallet hinzufügen",
        },
        withdraw: {
            tit: "Auszahlung",
            addNew: "Neue Wallet hinzufügen",
        },
    },
    order: {
        demo_account: "Demo-Konto",
        real_account: "Echtgeldkonto",
        tip: "Nach der Aktivierung können Sie Einzahlungen tätigen und handeln",
        tab1: "Positionen",
        tab2: "Ausstehende Aufträge",
        tab3: "Verlauf",
        list_empty: "Keine ausstehenden Aufträge",
        start_trade: "Mit dem Handel beginnen",
        hand_tip: "Bereich eingeben",
        kline: {
            direction_sell: "Verkaufen",
            direction_buy: "Kaufen",
            open_price: "Eröffnungspreis",
            now_price: "Aktueller Preis",
            trade_account: "Handelskonto",
            demo_account: "Demo-Konto",
            order_id: "Auftrags-ID",
            open_time: "Eröffnungszeit",
            stop_profit: "Take Profit",
            stop_loss: "Stop Loss",
            trailing_stop: "Trailing Stop",
            float_profit_loss: "Schwebender Gewinn/Verlust",
            overnight_fee: "Übernachtgebühren",
            margin: "Margin",
            maintainsMargin: "Margin-Bedarf",
            open_value: "Eröffnungswert",
            now_value: "Aktueller Wert",
            notset: "Nicht festgelegt",
            close_position: "Position schließen",
            close_number: "Schließmenge",
            onclick_close: "Alle Positionen schließen",
            btn: "Position schließen bestätigen",
            balance: "Verfügbares Guthaben",
            profit_loss: "Gewinn/Verlust",
            netValue: "Nettovermögen",
            marginLevel: "Margin-Level",
        },
        modify: {
            tit: "Position bearbeiten",
            expected_profit_loss: "Erwarteter Gewinn/Verlust",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Neues Passwort festlegen",
            verify_email_tit: "E-Mail verifizieren",
            verify_password_tit: "Ihr Passwort verifizieren",
            verify_password_tip: "Geben Sie Ihr HKDFINANCE-Login-Passwort ein, um fortzufahren",
            verify_phone_tit: "Mobiltelefon verifizieren",
            tit: "Kontosicherheit",
            tip: "Zum Schutz Ihres Kontos und zum Empfang von Servicebenachrichtigungen wird empfohlen, mindestens eine Art der Identitätsüberprüfung zu aktivieren.",
            label1: "Login Passwort ändern",
            label2: "Konto abmelden",
            label3: "Zweifache Authentifizierung",
            label4: "Geräteverwaltung",
            tip2: "Bei der Anmeldung mit einem neuen Gerät ist eine Verifizierung erforderlich",
        },
        tradenews: {
            tit: "Handelsanleitungen",
            tab1: "Schnellstart",
            tab2: "Fortgeschrittene Schulung",
            tab3: "Hilfezentrum",
            tab4: "Über HKDFINANC",
            viewMore: "Mehr anzeigen",
            details: {
                tit: "Forex Einführung",
                tip1: "Geschätzte Lernzeit",
                tip2: "Minuten",
            },
            tab1Row: {
                tit: "Dies ist eine Schnelllernklasse für den Handel mit HKDFINANC",
                tit1: "Wie funktioniert der Handel auf HKDFINANC?",
                tit2: "Wie eröffnet man ein echtes Konto?",
                tit3: "Wie platziert man Handelspositionen?",
                tit4: "Wie setzt man Take Profit und Stop Loss?",
                tit5: "Wie schließt man eine Position?",
                p1: "Sie können handeln, indem Sie das Asset kaufen oder verkaufen. Das Öffnen einer Position auf HKDFINANC bedeutet, dass Sie auf",
                p2: "Preisschwankungen dieses Anlageprodukts wetten.",
                p3: "Zum Beispiel, wenn Sie erwarten, dass der Goldpreis steigt, kaufen Sie das Produkt. Andernfalls, wenn Sie erwarten, dass der Goldpreis fällt, verkaufen Sie das Produkt. Wenn Ihre Vorhersage in der Zukunft zutrifft, können Sie",
                p4: "Gewinne erzielen.",
                p5: "Darüber hinaus bietet HKDFINANC auch den Handel mit Hebelwirkung an. Sie können den Hebel nutzen, um mit demselben Kapital mehr Kontrakte zu handeln.",
                p6: "Der Hebel kann Gewinne vervielfachen, aber auch Verluste.",
                p7: "Dies wird auch als Margin-Handel bezeichnet. Wenn Sie eine Position eröffnen, wird ein bestimmter Betrag von Ihrem HKDFINANC-Konto als",
                p8: "Anfängliche Margin",
                p9: "Ausgewählt Produkt und Handelsrichtung",
                p10: "Handeln",
                p11: "Klicken Sie auf 'Kaufen' oder 'Verkaufen', um die Bestellung abzuschließen",
                p12: "Das Festlegen von 'Take Profit' oder 'Stop Loss' kann Ihnen helfen",
                p13: "Gewinne rechtzeitig sichern und Verluste begrenzen",
                p14: "Sie können auf der Seite nach erfolgreicher Positionseröffnung klicken",
                p15: "'Take Profit/Stop Loss' einrichten",
                p16: "Sie können diese Einstellungen vornehmen. Sie können sie auch später durch Klicken auf 'Ändern' für offene Positionen festlegen oder aufheben.",
                p17: "Einstellen oder aufheben",
                p18: "Sie können Ihre Gewinne und Verluste im 'Geschlossene Positionen'-Bereich einsehen.",
            },
            tab3Row: {
                h1: "Willkommen bei HKDFINANC",
                h2: "Hilfe-Center",
                tit0: "Alle Fragen",
                tit1: "Demo-Konto",
                tit2: "Konto eröffnen",
                tit3: "Einzahlung und Auszahlung",
                tit4: "Plattformeinstellungen",
                tit5: "Handelsvorgänge",
                tit6: "Gebühren und Kosten",
                tit7: "Sicherheit und Vorschriften",
                tit1Tit1: "Bietet HKDFINANC ein Demo-Konto an?",
                tit1Cont1: "<p>HKDFINANC bietet ein Demo-Konto für Kunden an, auf dem Sie risikofrei unsere Handelsplattform und mobile App nutzen und sich mit der Plattform vertraut machen können.</p>",
                tit1Tit2: "Wie erstelle ich ein Demo-Konto?",
                tit1Cont2: "<p>Sie müssen nur hier klicken, Ihre E-Mail-Adresse oder Telefonnummer eingeben und Ihr persönliches Passwort festlegen, um ein Demo-Konto zu erstellen.</p>",
                tit1Tit3: "Wofür ist ein Demo-Konto gut?",
                tit1Cont3: "<p>Ein Demo-Konto ist nahezu identisch mit einem echten Konto in Bezug auf Benutzeroberfläche, Daten und Abläufe. Im Demo-Konto stehen Ihnen 50.000 US-Dollar virtuelles Kapital zur Verfügung, um die Plattform und ihre Funktionen ohne finanzielles Risiko kennenzulernen.</p>",
                tit1Tit4: "Wie kann ich mein Demo-Konto kapitalisieren?",
                tit1Cont4: "<p>Sie müssen Ihr Demo-Konto nicht kapitalisieren. Nach erfolgreicher Registrierung eines Demo-Kontos erhalten Sie ein virtuelles Guthaben von 50.000 USD oder AUD. Wenn der Netto-Wert Ihres Demo-Kontos unter 200 USD oder AUD fällt, wird Ihr Konto automatisch auf 50.000 USD oder AUD aufgefüllt.</p>",
                tit1Tit5: "Hat das Demo-Konto eine Ablaufzeit?",
                tit1Cont5: "<p>Die Gültigkeit eines Demo-Kontos beträgt 90 Tage. Wenn Sie nach 90 Tagen kein echtes Konto eröffnen, wird Ihr Demo-Konto gesperrt und Sie können keine Trades mehr ausführen. Das Konto wird bei der Eröffnung eines echten Kontos wieder freigegeben. Wenn Sie innerhalb der 90 Tage ein echtes Konto eröffnen, bleibt Ihr Demo-Konto unbegrenzt gültig, und Sie können weiterhin damit handeln, selbst nach der Eröffnung eines echten Kontos.</p>",
                tit2Tit1: "Welche Arten von Konten bietet HKDFINANC an?",
                tit2Cont1: "<p>Wir bieten nur Standard- und Profi-Handelskonten für Privatpersonen an und unterstützen keine Geschäfts- oder Gemeinschaftskonten.</p>",
                tit2Tit2: "In welcher Währung kann ich ein Handelskonto eröffnen?",
                tit2Cont3: "<p>Die Währung Ihres Handelskontos wird automatisch basierend auf Ihrem Standort festgelegt und kann nicht geändert werden.</p>",
                tit2Tit4: "Wie eröffne ich ein echtes Handelskonto?",
                tit2Cont4: "<p>Um ein echtes Handelskonto zu eröffnen, folgen Sie diesen Schritten:</p><p>1. Gehen Sie zur HKDFINANC-Registrierungsseite und folgen Sie den Anweisungen, um Ihre E-Mail-Adresse oder Telefonnummer zu registrieren, ein Passwort festzulegen und die Bestätigung abzuschließen.</p><p></p>",
                tit3Tit1: "Wie kann ich Einzahlungen vornehmen?",
                tit3Cont1: "<p>HKDFINANC bietet verschiedene Einzahlungsmethoden an, darunter, aber nicht beschränkt auf:</p><p>1. Visa/Mastercard - Normalerweise sofortige Gutschrift</p><p>2. Banküberweisung - Normalerweise innerhalb von 1 Werktag</p><p>3. E-Wallets (z. B. Skrill, Momo, Zalo, Touch'n GO und Boost) - Normalerweise sofortige Gutschrift</p><p>4. ATM-Karte - Normalerweise sofortige Gutschrift</p><p>5. QR Mobile Banking - Normalerweise sofortige Gutschrift</p>",
                tit3Tit2: "Kann ich Ein- oder Auszahlungen über ein Bankkonto oder eine Karte eines Dritten tätigen?",
                tit3Cont2: "<p>HKDFINANC akzeptiert keine Einzahlungen von Dritten. Bitte verwenden Sie Ihr eigenes persönliches Bankkonto oder Ihre eigene Karte für Ein- oder Auszahlungen. Wenn Sie das Bankkonto oder die Karte einer anderen Person oder eines Unternehmens verwenden, wird die Transaktion abgelehnt.</p>",
                tit3Tit3: "Wie beantrage ich eine Auszahlung von meinem HKDFINANC-Konto?",
                tit3Cont3: "<p>Um eine Auszahlung vorzunehmen, melden Sie sich auf der Website an, klicken Sie auf 'Auszahlung', wählen Sie Ihr Bankkonto oder Visa/Mastercard aus und geben Sie den Auszahlungsbetrag ein. Klicken Sie dann auf 'Einreichen'. HKDFINANC wird Ihre Anfrage innerhalb von 1-2 Werktagen bearbeiten.</p>",
                tit4Tit1: "Welchen Hebel bietet HKDFINANC an?",
                tit4Cont1: "<p>HKDFINANC bietet Hebel entsprechend den Vorschriften der Aufsichtsbehörden an. Die maximale Hebelwirkung kann je nach Produkt variieren. Weitere Details finden Sie auf der Handelsplattform.</p>",
                tit4Tit2: "Wie ändere ich mein Passwort?",
                tit4Cont2: "<p>Sie können Ihr Passwort ändern, indem Sie sich auf der Plattform anmelden, auf 'Mehr' -> 'Sicherheitseinstellungen' -> 'Passwort ändern' klicken und ein neues Passwort festlegen.</p>",
                tit4Tit3: "Wie melde ich mich bei der HKDFINANC-Handelsplattform an?",
                tit4Cont3: "<p>App: Gehen Sie zu 'Mein Konto' und klicken Sie oben links auf 'Anmelden/Registrieren', um zur Anmeldeseite zu gelangen.</p><p>Web: Klicken Sie auf der offiziellen Website von HKDFINANC oben rechts auf 'Anmelden', um zur Anmeldeseite zu gelangen.</p><p></p><p></p><p></p><p></p>",
                tit4Tit4: "Wie erhalte ich Benachrichtigungen von der Plattform?",
                tit4Cont4: "<p>HKDFINANC sendet Benachrichtigungen per E-Mail, SMS und in der Plattform.</p>",
                tit5Tit1: "Welche Spreads bietet HKDFINANC an?",
                tit5Cont1: "<p>Wir erheben niedrige Spreads und keine Kommissionen. Die tatsächlich erhobenen Spreads können je nach gehandeltem Instrument variieren. Überprüfen Sie die aktuellen Spreads auf der Handelsplattform.</p>",
                tit5Tit2: "Wie platziere ich eine Market Order?",
                tit5Cont2: "<p>Klicken Sie auf 'Handeln', um alle auf der Plattform verfügbaren Produkte anzuzeigen. Sie können nach Produktcodes oder -namen suchen, ein Produkt auswählen und auf 'Kaufen/Verkaufen' klicken, um ein Handelsfenster zu öffnen. Im Handelsfenster sehen Sie den aktuellen Preis und die geschätzte erforderliche Marge. Sie können die Handelsmenge manuell anpassen und Take-Profit und Stop-Loss festlegen, um das Risiko zu steuern.</p>",
                tit5Tit3: "Wie platziere ich eine Pending Order?",
                tit5Cont3: "<p>Um eine Pending Order zu platzieren, wählen Sie ein Produkt aus und klicken Sie auf 'Kaufen/Verkaufen', um das Handelsfenster zu öffnen.</p>",
                tit5Tit4: "Wie schließe ich eine Position?",
                tit5Cont4: "<p>Um eine Position zu schließen, klicken Sie im Register 'Positionen' auf 'Offen' und wählen Sie die Position aus, die Sie schließen möchten. Klicken Sie dann rechts auf die Schaltfläche 'Schließen'.</p>",
                tit5Tit5: "Wie ändere oder lösche ich eine Pending Order?",
                tit5Cont5: "<p>Um eine Pending Order zu ändern oder zu löschen, gehen Sie zu 'Positionen' und wählen Sie 'Ausstehende Aufträge' aus. Wählen Sie die ausstehende Order aus, die Sie ändern oder löschen möchten.</p>",
                tit6Tit1: "Werden auf der Handelsplattform Gebühren erhoben?",
                tit6Cont1: "<p>HKDFINANC ist eine provisionsfreie Handelsplattform. Wir erheben niedrige Spreads und bieten wettbewerbsfähige Handelskosten. Je nach Ihren spezifischen Handelsbedingungen können auf der Plattform zusätzliche Gebühren anfallen, z. B. Übernachtungsgebühren.</p>",
                tit6Tit2: "Gibt es Gebühren für Einzahlungen?",
                tit6Cont2: "<p>Wir erheben keine Einzahlungsgebühren. Bitte beachten Sie jedoch, dass Ihre Zahlungs- oder Transferbank möglicherweise Gebühren erhebt. Wir empfehlen Ihnen, Ihre Bank bezüglich etwaiger Gebühren zu konsultieren.</p>",
                tit6Tit3: "Gibt es Gebühren für Auszahlungen?",
                tit6Cont3: "<p>HKDFINANC erhebt normalerweise keine Auszahlungsgebühren für Kunden. Es sei denn, Sie beantragen eine Auszahlung unterhalb des Mindestbetrags oder überschreiten die maximale Anzahl kostenloser monatlicher Auszahlungen. Weitere Informationen finden Sie in unserer Auszahlungsrichtlinie.</p>",
                tit7Tit1: "Werden meine auf dem HKDFINANC-Konto eingezahlten Gelder für andere Zwecke verwendet?",
                tit7Cont1: "<p>Nein, Einlagen von Privatkunden werden gemäß den Vorschriften in getrennten Treuhandkonten aufbewahrt, wenn dies erforderlich ist. Wenn Sie Geld auf Ihr Handelskonto einzahlen oder von Ihrem Handelskonto abheben, wird HKDFINANC strikt den Regeln zur Behandlung von Kundengeldern gemäß den geltenden Vorschriften folgen.</p>",
                tit7Tit2: "Was passiert mit meinen Geldern, wenn HKDFINANC zahlungsunfähig wird oder Schulden hat?",
                tit7Cont2: "<p>Die Einlagen von Privatkunden werden gemäß den Vorschriften in getrennten Treuhandkonten aufbewahrt, wenn dies erforderlich ist. Wenn HKDFINANC insolvent wird, seine Lizenz widerrufen wird oder in eine Situation gerät, in der es nicht mehr geschäftsfähig ist, bleiben die Kundengelder getrennt und unterliegen weiterhin der Aufsicht gemäß den gesetzlichen Bestimmungen zur Behandlung von Kundengeldern.</p>",
                tit7Tit3: "Erfüllt HKDFINANC die gesetzlichen Bestimmungen?",
                tit7Cont3: "<p>HKDFINANC entspricht vollständig den einschlägigen Unternehmens- und Finanzregulierungen und ist ein von den entsprechenden Aufsichtsbehörden autorisiertes und streng reguliertes Finanzdienstleistungsunternehmen. Wir bieten umfassenden und ausreichenden Schutz für die Sicherheit der Kundengelder gemäß den strengen Anforderungen der Aufsichtsbehörden.</p>",
                tit7Tit4: "Sind meine persönlichen Informationen sicher?",
                tit7Cont4: "<p>HKDFINANC achtet strikt auf die Bestimmungen zum Schutz personenbezogener Daten und gibt keine Kundeninformationen weiter. Das Unternehmen stellt sicher, dass Mitarbeiter die geltenden gesetzlichen Sicherheits- und Vertraulichkeitsstandards einhalten und hohe Standards für den Schutz und die Verwendung von Kundeninformationen festlegen. Weitere Informationen finden Sie in unseren rechtlichen Offenlegungsdokumenten.</p>",
            },
            tab4Row: {
                span: "",
                p1: "HKDFINANC ist ein behördlich zugelassenes und reguliertes Fintech-Unternehmen, das sich darauf konzentriert, Anlegern ein einfaches und bequemes Handelserlebnis zu bieten.",
                p2: "Die innovative, breit gefächerte Handelsplattform von HKDFINANC wurde bereits mehrfach mit Preisen wie Best Mobile Trading Platform und Most Innovative Brokerage ausgezeichnet.",
                p3: "Über die HKDFINANC-Plattform können Sie in ein breiteres Spektrum an internationalen Finanzmärkten investieren und handeln, das Aktien, Indizes, Rohstoffe, Devisen und Hunderte anderer beliebter Arten umfasst. HKDFINANC beschränkt sich nicht nur auf die benutzerfreundlichen mobilen und webbasierten Handelsplattformen, sondern bietet Ihnen auch wettbewerbsfähige Handelskosten, eine schnelle Handelsausführung, einen ausgezeichneten Kundenservice und eine Fülle von Informationen, die Ihnen helfen, rechtzeitig Investitions- und Handelsmöglichkeiten zu nutzen.",
                p4: "HKDFINANC unterliegt einer strengen Regulierung durch Branchenbehörden und verschiedene Organisationen.",
                p5: "Die HKDFINANC Holding ist von der Cayman Islands Monetary Authority (CIMA) unter der SIB-Lizenz Nr. 1612446 zugelassen und wird von ihr reguliert. Weitere Informationen über die Lizenz der Kaimaninseln finden Sie auf der offiziellen Website der CIMA unter www.cima.ky (",
                p6: "(Führen Sie eine Anfrage aus. HKDFINANCGlobal besitzt eine Financial Services License (AFSL 398528), die von der Hong Kong Securities and Investment Commission (ASIC) genehmigt wurde. Weitere Informationen zu Hong Kong Fotos finden Sie auf der offiziellen ASIC Website unter www.asic.gov.au(",
                p7: "HKDFINANC International ist von der mauritischen Finanzdienstleistungskommission (Financial Services Commission, FSC) unter der Lizenznummer GB20025791 zugelassen und wird von ihr reguliert. Einzelheiten zur mauritischen Lizenz finden Sie auf der offiziellen Website der FSC unter www.fscmauritius.org (",
                p8: "Alle Geschäftstätigkeiten von HKDFINANC werden unter strenger Aufsicht und unter Einhaltung aller Vorschriften durchgeführt.",
                p9: "Hintergrund",
                p10: "HKDFINANC besteht aus einem Senior-Team mit reicher Erfahrung und Wissen in der Finanz- und Fintech-Industrie. Die Philosophie von HKDFINANC ist es, Transaktionen einfacher und freundlicher zu machen",
                p11: "Die Philosophie von HKDFINANC ist es, den Handel einfacher und freundlicher zu gestalten. Dank unserer ganzheitlichen Strategie und unseres Geschäftsmodells haben wir einen globalen Überblick über die Entwicklung der Branche und die Marktnachfrage. So können wir unsere Ressourcen gezielter einsetzen, die Technologie kontinuierlich erneuern und die Effizienz optimieren, um unseren Kunden ein bequemeres und benutzerfreundlicheres Handelserlebnis zu bieten.",
                p12: "Warum wählen",
                p13: "Niedrigschwellige Handelsbedingungen",
                p14: "Einfache und intuitive Handelsplattformen",
                p15: "Wettbewerbsfähige Transaktionskosten",
                p16: "Von den Behörden der Branche reguliert",
                p17: "Hohes Niveau der Online-Unterstützung",
                p18: "Schutz vor negativem Saldo",
                p19: "Auszeichnungen und Anerkennungen",
                p20: "HKDFINANC fühlt sich geehrt durch die Auszeichnungen [Bester Multi-Asset-Broker] bei den World Finance Awards, [Beste mobile Handelsplattform im asiatisch-pazifischen Raum] [Schnellstwachsender FinTech-Broker der Welt] beim Global BrandsMagazine und viele andere Preise, die das Engagement und die harte Arbeit des Teams belegen.",
                p21: "Bester Multi-Asset-Broker",
                p22: "Operative Philosophie",
                p23: "Verantwortung",
                p24: "Jeder Handel ist mit Risiken verbunden, und HKDFINANC hat zusätzliche Maßnahmen ergriffen, um seinen Kunden ein verantwortungsvolles Handelsumfeld zu bieten. HKDFINANC wird von Branchenbehörden und mehreren Organisationen reguliert und arbeitet mit einem hohen Maß an Sicherheit und Stabilität. Unsere Plattform bietet weiterhin eine Reihe von Instrumenten für einen verantwortungsvollen Handel, einschließlich Risikomanagement.",
                p25: "Einfachheit ist Schönheit",
                p26: "Einfachheit ist Schönheit, Details machen Exzellenz aus, und HKDFINANC strebt in jedem Detail nach Einfachheit, Benutzerfreundlichkeit und Benutzererfahrung. Wir werden unsere Plattform und unsere Dienstleistungen weiter optimieren, in der Hoffnung, dass jeder, ob Anfänger oder erfahrener Anleger, in der Lage sein wird, die Handelsmöglichkeiten auf der HKDFINANC-Plattform problemlos zu nutzen",
                p27: "Transparenz",
                p28: "Damit unsere Kunden in vollem Umfang von einem fairen und effizienten Handelsumfeld profitieren können, folgt HKDFINANC einem strengen und transparenten Selbstregulierungssystem, das umfassende und transparente Informationen über die Plattformprodukte, die Preisgestaltung und die Daten bereitstellt, und alle Gebühren werden klar und offen dargelegt, um die vollständige Aufrichtigkeit und Transparenz des gesamten Dienstleistungsprozesses zu gewährleisten.",
                p29: "Innovation",
                p30: "HKDFINANC ist der festen Überzeugung, dass technologischer Fortschritt, Finanzinnovationen und ein breiteres Spektrum an Dienstleistungen das Wachstum vorantreiben und dass wir nur dadurch weiterhin Werte schaffen und teilen können. Zu diesem Zweck fördern wir offenes Denken und treiben Innovationen weiter voran, mit dem Ziel, ein Innovator und Vorreiter im Bereich Fintech zu sein.",
                p31: "Ziel und Vision",
                p32: "Wir wollen mehr Menschen, die handeln wollen, ein faires, effizientes und transparentes Handelsumfeld bieten, das nicht durch Raum, Zeit oder Markt begrenzt ist, und die Branche bei finanziellen und technologischen Innovationen anführen.",
            },
        },
        language: {
            tit: "Sprache auswählen",
        },
        market: {
            tit: "Tickersymbol",
            tit1: "Preisanzeige",
            tit2: "Steigende oder",
            buy_price: "Angebotspreis",
            buy_price_tip: "Zeichnen einer K-Linie zum Angebotspreis",
            sell_price: "Verkaufskurs",
            sell_price_tip: "K-Linien zum Verkaufspreis aufzeichnen",
            color1: "Lit. grün steigt und rot fällt",
            color2: "Lit. rot steigt, grün fällt",
        },
        notice: {
            tit: "Einstellungen für Benachrichtigungen",
            tit1: "Offene Benachrichtigungskanäle",
            p1: "Bitte öffnen/bestätigen Sie die folgenden Benachrichtigungskanäle, um über wichtige Neuigkeiten informiert zu werden!",
            message: "Textnachrichten",
            push: "Drücken.",
            verify: "Validieren (eine Theorie)",
            verified: "Validiert",
            email: "E-Mail Adresse",
            type1: "Marktplatz",
            tit2: "Benachrichtigung über Marketingaktivitäten",
            desc2: "Informationen über Sonderangebote der Plattform, Betriebsaktivitäten und andere Neuigkeiten、运营活动等消息",
            tit3: "Betriebsanleitung",
            desc3: "Reichhaltiges und spezialisiertes Wissensmaterial",
            tit4: "Informationen Alerts",
            desc4: "Aktuelle Finanzdaten und Nachrichten",
            tit5: "Intelligenter Schwankungsalarm",
            desc5: "Einschließlich der Preisschwankungen wichtiger Arten, damit Sie die Gelegenheit schnell ergreifen können",
            type2: "Berichtstyp",
            tit6: "Konto-Berichte",
            desc6: "Ihre Kontoberichte werden monatlich auf folgende Weise versandt",
            cycle: "Zyklizität",
            month: "Jeden Monat",
        },
        protocol: {
            tit: "Erklärungen und Vereinbarungen",
            label1: "Datenschutzbestimmungen",
            label2: "Erklärung zur Produktoffenlegung",
            label3: "Kundenvereinbarung",
            label4: "Erklärung zur Offenlegung von Risiken",
        },
        setting: {
            tit: "Einstellungen",
            label1: "Sicherheitseinstellungen",
            label2: "Markteinstellungen",
            label3: "Handelseinstellungen",
            label4: "Sprache",
            label5: "Themenmodus",
            label6: "Benachrichtigungseinstellungen",
            label7: "Handelsanleitung",
            label8: "Erklärungen und Vereinbarungen",
            label9: "Cache leeren",
            label10: "Versionsnummer",
            logout: "Abmelden",
        },
        theme: {
            tit: "Themenmodus",
            theme1: "Hellmodus",
            theme2: "Dunkelmodus",
        },
        trade: {
            tit: "Handelseinstellungen",
            onclick_close: "Alle Positionen schließen",
        }
    },
    trade: {
        fliter_type1: "Benutzerdefiniert",
        fliter_type2: "Markt",
        place_search: "Geben Sie den Vermögenswert ein (Name/Code)",
        fliter_type3: "Beliebt",
        fliter_type4: "Devisen",
        fliter_type5: "Rohstoffe",
        fliter_type6: "Aktien",
        fliter_type7: "Indizes",
        fliter_type8: "Kryptowährung",
        tip1: "Führen Sie die Identitätsprüfung durch, um Einzahlungen zu tätigen und weltweit zu investieren.",
        sell: "Verkaufen",
        buy: "Kaufen",
        add_optional: "Zu Favoriten hinzufügen",
        optional_recommend: "Empfohlene Favoriten",
        change_batch: "Nächste Gruppe",
        edit_tit: "Favoriten bearbeiten",
        breed: "Vermögenswert",
        topping: "Anheften",
        sort: "Sortieren",
        all: "Alle auswählen",
    },
    table: {
        label_breed: "Vermögenswert",
        label_direction: "Richtung",
        label_price: "Preis",
        label_operate: "Aktion",
        empty_tip1: "Keine Benachrichtigungen vorhanden",
        empty_tip2: "Keine Einzahlungsprotokolle vorhanden",
        empty_tip3: "Keine Auszahlungsprotokolle vorhanden",
        label_amount: "Betrag",
        label_type: "Typ",
        label_time: "Zeit",
        label_name: "Name",
        label_email: "E-Mail-Adresse",
        label_address: "Land des Wohnsitzes",
        label_account: "Handelskonto",
        label_currency: "Basiswährung",
        label_company: "Eröffnet bei",
    },
    form: {
        to: "Bis",
        start_time: "Startzeit",
        end_time: "Endzeit",
        week: "Letzte Woche",
        month: "Letzter Monat",
        threeMonth: "Letzte drei Monate",
        sixMonth: "Letzte sechs Monate",
        label_feedback: "Fragen und Anregungen",
        place_feedback: "Geben Sie Ihre Fragen oder Anregungen ein",
        label_img: "Bild",
        label_img_tip: "Optional: Fügen Sie einen Screenshot hinzu",
        feedback_tip: "Bei dringenden Fragen kontaktieren Sie bitte unseren",
        online: "Online-Kundendienst",
        label_cardNumber: "Ausweisnummer",
        label_realName: "Vollständiger Name",
        place_realName: "Geben Sie Ihren vollständigen Namen ein",
        message_realName: "Bitte geben Sie Ihren vollständigen Namen ein",
        label_firstName: "Vorname",
        place_firstName: "Geben Sie Ihren Vornamen ein",
        message_firstName: "Bitte geben Sie Ihren Vornamen ein",
        label_lastName: "Nachname",
        place_lastName: "Geben Sie Ihren Nachnamen ein",
        message_lastName: "Bitte geben Sie Ihren Nachnamen ein",
        label_birthday: "Geburtsdatum",
        place_birthday: "Geben Sie Ihr Geburtsdatum ein",
        message_birthday: "Bitte geben Sie Ihr Geburtsdatum ein",
        label_idType: "Ausweistyp",
        tip_cardMain: "Foto von der Vorderseite Ihres Personalausweises machen oder hochladen",
        tip_cardBack: "Machen oder Laden Sie ein Foto von dem Hintern Ihres Personalausweises",
        tip_cardMain1: "Foto von der Vorderseite Ihres Führerscheins machen oder hochladen",
        tip_cardBack1: "Machen oder laden Sie ein Foto von der Rückseite Ihres Führerscheins",
        tip_cardMain2: "Machen oder Laden Sie ein Foto Ihres Reisepasses",
        tip_cardBack2: "Machen oder Laden Sie ein Foto Ihres Reisepasses",
        label_password: "Passwort",
        place_password: "Geben Sie ein Passwort ein",
        message_password: "Bitte geben Sie ein Passwort ein",
        label_crmpsd: "Passwort bestätigen",
        place_crmpsd: "Bitte bestätigen Sie das Passwort",
        message_crmpsd: "Bitte bestätigen Sie das Passwort",
        label_email: "E-Mail-Adresse",
        place_email: "Geben Sie Ihre E-Mail-Adresse ein",
        message_email: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        label_captcha: "Sicherheitscode",
        place_captcha: "Geben Sie den Sicherheitscode ein",
        message_captcha: "Bitte geben Sie den Sicherheitscode ein",
        get_captcha: "Code erhalten",
        label_phone: "Telefonnummer",
        place_phone: "Geben Sie Ihre Telefonnummer ein",
        message_phone: "Bitte geben Sie Ihre Telefonnummer ein",
        labelAmount: "Betrag",
        placeAmount: "Geben Sie den Betrag ein",
        messageAmount: "Bitte geben Sie den Betrag ein",
        labelWalletName: "Wallet-Name",
        messageWalletName: "Geben Sie den Wallet-Namen ein",
        placeWalletName: "Geben Sie den Wallet-Namen ein",
        labelWalletAddress: "Wallet-Adresse",
        messageWalletAddress: "Geben Sie die Wallet-Adresse ein",
        placeWalletAddress: "Geben Sie die Wallet-Adresse ein",
        labelWithdrawAccount: "Abhebungskonto",
        placeWithdrawAccount: "Abhebungskonto auswählen",
        messageWithdrawAccount: "Abhebungskonto auswählen",
        placeTimeLimit: "Geben Sie die Zeitbegrenzung ein",
        messageTimeLimit: "Geben Sie die Zeitbegrenzung ein",
    },
    city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Austria",
	    bahrain: "Bahréin",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "Abreviatura de Bosnia-Herzegovina",
	    brazil: "Brasileño",
	    brunei: "Brunei Darussalam, sultanato independiente en el noroeste de Borneo",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadiense",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Columbia (Distrito de, o Universidad, etc.)",
		costa_Rica: "Costa Rica",
		croatia: "República de Croacia (1991-)",
		cyprus: "Chipre",
		czech_Republic: "República Checa (desde 1993)",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Suomi",
		france: "Francés",
		georgia: "Georgia (país)",
		germany: "Alemán",
		ghana: "Ghana, África Occidental",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlandés",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Iraqi",
		japan: "Japonés",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur (República de Corea)",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"La república lituana, antigua república soviética báltica",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonio",
		malaysia:"Malasia",
		malta:"Maltés",
		mexico:"México",
		moldova:"República de Moldavia, antigua república soviética fronteriza con Rumanía",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar (o Birmania)",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepalí",
		nigeria:"Nigeria, África Occidental",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Peruano",
		philippines:"Filipinas",
		poland:"Polaco",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Doha",
		romania:"Rumanía",
		russia:"Georgia",
		republic_of_Trinidad_and_Tobago:"Trinidad y Tobago",
		rwanda:"Ruanda",
		saudi_Arabia:"Arabia Saudí",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"Español",
		sri_Lanka:"(Antiguamente) Ceilán",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"China-Taiwán",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Estambul",
		turkmenistan:"Turkmenistán",
		ukraine:"Bielorrusia",
		united_Arab_Emirates:"EAU",
		united_Kingdom:"Reino Unido de Gran Bretaña e Irlanda del Norte",
		united_States:"Estados Unidos de América",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán, ",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benín",
		bhutan:"Bután",
		bolivia:"Bolivia",
		botswana:"Botsuana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso, África Occidental",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (Brazzaville)",
		democratic_Republic_of_the_Congo:"Congo (República Democrática del)",
		djibouti:"Yibuti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiyi ",
		gabon:"Gabonesa",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Mann",
		cote_d_Ivoire:"Costa de Marfil en África Occidental",
		jamaica:"Jamaica",
		jordan:"jordan",
		lebanon:"Hostilidades",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí, África Occidental",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Dinastía coreana Joseon o Chosun 1392-1910",
		reunion:"Reunión (isla del océano Índico, departamento francés de ultramar)",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Mogadiscio",
		sudan:"Sultán",
		suriname:"Surinam",
		eswatini:"Suazilandia",
		syria:"Siria",
		togo:"Togo, África Occidental",
		tonga:"Tonga,",
		tunisia:"Túnez, capital de Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de Estados Unidos (USVI)",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemení",
		yugoslavia:"Yugoslavia, 1943-1992",
		zambia:"Seychelles",
		zimbabwe:"Zimbabue",
		china:"China",
    }
};
